import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react'
import clsx from 'clsx'
import 'moment/locale/pt-br'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import MomentUtils from '@date-io/moment'

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'

import makeStyles from '@material-ui/core/styles/makeStyles'

import useUtils from '../../hooks/useUtils'

const useStyles = makeStyles(() => ({
  dateInput: {
    '& Button': {
      padding: '0px !important',
    },
  },
  darkMode: {
    '& .MuiInputBase-root .MuiInput-input': {
      color: '#fff',
    },
    '& .MuiInputBase-root .MuiInputAdornment-root .MuiButtonBase-root': {
      color: '#fff',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #fff',
    },
  },
}))

const DateInput = (props) => {
  const {
    id,
    name,
    value,
    onBlur,
    minDate,
    maxDate,
    onChange,
    darkMode,
    className,
  } = props

  const ref = useRef()
  const classes = useStyles()
  const { isBlank, date, dateHoursZero } = useUtils()
  const [inputValue, setInputValue] = useState(null)

  const dark = darkMode
  const inputProps = { ...props }
  delete inputProps.darkMode

  const checkMinMaxDate = useCallback((dateValue) => (
    dateValue && (
      (!minDate || (minDate && dateHoursZero(dateValue).isSameOrAfter(dateHoursZero(minDate))))
      || (!maxDate || (maxDate && dateHoursZero(dateValue).isSameOrBefore(dateHoursZero(maxDate))))
    )
  ), [minDate, maxDate, dateHoursZero])

  useEffect(() => {
    let data = null
    if (typeof value === 'object') {
      data = value
    }

    if (!isBlank(value)) {
      data = date(value)
    }

    setInputValue(data)
  }, [value, isBlank, date, dateHoursZero])

  const handleBlur = (event) => {
    if (!isEmpty(inputValue) && (!inputValue.isValid() || !checkMinMaxDate(inputValue))) {
      setInputValue(null)
    }

    onBlur(event)
  }

  const handleChange = (data) => {
    let current = ''
    let valueDate = null

    if (!isEmpty(data) && data.isValid()) {
      valueDate = data
      current = data.toJSON()
    }

    setInputValue(valueDate)
    onChange({
      target: {
        id,
        name,
        value: current,
      },
    })
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
      <KeyboardDatePicker
        {...inputProps}
        autoOk
        margin="normal"
        className={clsx(className, classes.dateInput, dark && classes.darkMode)}
        disableToolbar
        inputProps={{ ref }}
        variant="inline"
        value={inputValue}
        format="DD/MM/YYYY"
        placeholder="dd/mm/yyyy"
        onBlur={handleBlur}
        onChange={handleChange}
        onClose={() => setTimeout(() => ref.current && ref.current.focus())}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

DateInput.propTypes = {
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  darkMode: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
}

DateInput.defaultProps = {
  id: '',
  name: '',
  minDate: undefined,
  maxDate: undefined,
  className: '',
  darkMode: false,
  onChange: () => { },
  onBlur: () => { },
}

export default DateInput
