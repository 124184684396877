import React from 'react'
import { PropTypes } from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import useQuotationClient from '../../../../clients/QuotationClient/useQuotationClient'
import { ReactComponent as DownloadIcon } from '../../../../assets/img/icons/ico-download.svg'

import useDownload from '../../../../shared/hooks/useDownload'
import { useAlert } from '../../../../shared/components'
import useUtils from '../../../../shared/hooks/useUtils'
import useStyles from './styles/containerFields'

const productLabel = 'Produto:'
const modalityLabel = 'Modalidade:'

const contractorLabel = 'Proponente:'
const cepLabel = 'CEP:'
const cityLabel = 'Cidade:'
const stateLabel = 'Estado:'
const numberLabel = 'Número:'
const districtLabel = 'Bairro:'
const placeLabel = 'Logradouro:'
const complementLabel = 'Complemento:'

const nameLabel = 'Nome do Responsável Técnico:'
const phoneLabel = 'Telefone do Responsável Técnico:'
const emailLabel = 'E-mail do Responsável Técnico:'
const objectLabel = 'Descrição da Obra (Objeto):'
const valueContractLabel = 'Valor Contrato:'
const startValidityLabel = 'Início de Vigência:'
const endValidityLabel = 'Fim de Vigência:'
const deadlineLabel = 'Prazo (Dias):'

const requesterName = 'Nome do Solicitante:'
const requesterPhone = 'Telefone do Solicitante:'
const requesterEmail = 'Email do Solicitante:'
const requesterNote = 'Observações:'
const idClientControlLabel = 'Identificador do Controle do Cliente:'

const ContainerFields = (props) => {
  const classes = useStyles()
  const { quotation } = props
  const {
    formatDate,
    formatCurrency,
    formatPhoneOrCellphone,
  } = useUtils()

  const { downloadPDF } = useDownload()
  const { addMsgDanger } = useAlert()
  const quotationClient = useQuotationClient()

  const downloadDocuments = (document) => {
    quotationClient().downloadDocumentsWings(document).then((response) => {
      downloadPDF(response?.data, document)
    }, () => {
      addMsgDanger('Não foi possível baixar o arquivo, tente mais tarde ou contate o Administrador.')
    })
  }

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Detalhes do Produto"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} lg={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {productLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Product?.Name ?? 'Risco de Engenharia'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Contratante e Local da Obra/Risco"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} lg={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {modalityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.contractorWorkEngineering?.modality}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {contractorLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.contractorWorkEngineering?.contractor}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} sm={3} lg={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {cepLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.contractorWorkEngineering.cep}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9} lg={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {placeLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.contractorWorkEngineering.place}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} lg={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {numberLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.contractorWorkEngineering.number}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {complementLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.contractorWorkEngineering.complement}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} lg={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {districtLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.contractorWorkEngineering.district}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} lg={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {stateLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.contractorWorkEngineering.state}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} lg={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {cityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.contractorWorkEngineering.city}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Objeto Segurado"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} lg={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {nameLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.objectProperty.name}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {phoneLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.objectProperty.phone}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {emailLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.objectProperty.email}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {objectLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.objectProperty.objectText}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {valueContractLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.objectProperty.contractValue)}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {startValidityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatDate(quotation?.objectProperty.startDateValidity)}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {endValidityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatDate(quotation?.objectProperty.endDateValidity)}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {deadlineLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.objectProperty.deadline}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Solicitante"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {requesterName}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.requester.name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {requesterPhone}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatPhoneOrCellphone(quotation?.requester.phone)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {requesterEmail}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.requester.email}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} hidden={!quotation?.requester.customerControlIdentifier}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {idClientControlLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.requester.customerControlIdentifier}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} hidden={!quotation?.requester.note}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {requesterNote}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.requester.note}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.containerFileUpload}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Arquivos"
          />
          <Grid className={classes.fileUpload}>
            <Grid item xs={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                Nome do Arquivo:
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                Download:
              </Typography>
            </Grid>
          </Grid>
          {(quotation?.FileUpload?.Documents ?? quotation?.FileUpload)
            ?.map((item, index) => (
              <Grid key={index} item xs={12} className={classes.fileUpload}>
                <Grid item xs={4}>
                  <Typography className={classes.confirmationValue}>
                    {item?.DocumentName}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Box>
                    <Button
                      variant="text"
                      onClick={() => downloadDocuments(item?.DocumentID)}
                    >
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <Box mr={1}>
                          <DownloadIcon />
                        </Box>

                        <Box sx={{ textDecoration: 'underline', fontSize: 17 }}>
                          <b>Download</b>
                        </Box>
                      </Box>
                    </Button>
                  </Box>
                </Grid>
              </Grid>

            ))}
        </Grid>
      </Grid>
    </>
  )
}

ContainerFields.propTypes = {
  quotation: PropTypes.any.isRequired,
}

export default ContainerFields
