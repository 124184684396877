import Signin from '../pages/access/Signin'
import AccessCallback from '../pages/access/AccessCallback'
import DraftViewer from '../pages/price/quotations/payment/DraftViewer'
import PolicyViewer from '../pages/policy/policy-details/PolicyViewer'
import TicketViewer from '../pages/policy/policy-details/TicketViewer'
import AllTicketViewer from '../pages/policy/policy-details/AllTicketViewer'
import QuoteReceiptViewer from '../pages/status-request/QuoteReceiptViewer'
import EndorsementReceiptViewer from '../pages/status-request/EndorsementReceiptViewer'
import ExecutivePortalRequest from '../pages/executive-portal-request/ExecutivePortalRequest'

const ExternalRoutes = [
  { path: '/signin', name: 'Acesso', Component: Signin },
  { path: '/callback', name: 'Callback', Component: AccessCallback },

  { path: '/boleto/:billingId', name: 'Boleto', Component: TicketViewer },
  { path: '/boleto-all/:documentNumber', name: 'Todos boletos', Component: AllTicketViewer },
  { path: '/apolices/:policyCode/documento', name: 'Documento', Component: PolicyViewer },
  { path: '/cotacao/:quotationIdentifier/minuta', name: 'Minuta', Component: DraftViewer },
  { path: '/cotacao/:quotationIdentifier/comprovante', name: 'Comprovante de Cotação', Component: QuoteReceiptViewer },
  { path: '/endosso/:quotationIdentifier/comprovante', name: 'Comprovante de Cotação', Component: EndorsementReceiptViewer },
  { path: '/solicitacao-executivo/endossos/:token', name: 'Solicitação Executivo Endossos', Component: ExecutivePortalRequest },
  { path: '/solicitacao-executivo/cotacao/:token', name: 'Solicitação Executivo Cotação', Component: ExecutivePortalRequest },
]

export default ExternalRoutes
