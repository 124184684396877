import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Input from '@material-ui/core/Input'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

import makeStyles from '@material-ui/core/styles/makeStyles'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isEqual, isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { usePrice } from '../../../../../shared/contexts/PriceContext'
import useYup from '../../../../../shared/hooks/useYup'

import useUserClient from '../../../../../clients/UserClient/useUserClient'

import {
  ButtonLight,
  ButtonGradient,
} from '../../../../../shared/components'

import {
  QUOTE_URL_EXECUTIVE_REQUEST_JDR,
} from '../../../../../constants'

import { GlobalMessages } from '../../../../../shared/messages'
import PhoneInput from '../../../../../shared/components/PhoneInput'
import CollapsePrice from '../../../../../shared/components/CollapsePrice/CollapsePrice'

import useSecurity from '../../../../../security/useSecurity'

import RequesterData from '../../../../../models/JudicialGuaranteeAppealModels/RequesterData'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  info: {
    verticalAlign: 'bottom',
  },
}))

const Requester = forwardRef((props, ref) => {
  const classes = useStyles()
  const {
    quote,
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props

  const userClient = useUserClient()

  const { phoneOrCellphone } = useYup()

  const {
    onNext,
    actionNext,
    setFormikChanged,
    judicialGuaranteeAppeal,
    setJudicialGuaranteeAppeal,
  } = usePrice()

  const location = useLocation()

  const getUrl = location?.pathname?.split(':')
  const routeExecutiveRequest = getUrl[0]

  const {
    getName,
    getEmail,
  } = useSecurity()

  const [openTooltip, setOpenTooltip] = useState(false)
  const [clientEmails, setClientEmails] = useState([])

  useEffect(() => {
    if ((QUOTE_URL_EXECUTIVE_REQUEST_JDR === routeExecutiveRequest)
      && judicialGuaranteeAppeal && judicialGuaranteeAppeal.policyHolderClaimant
      && judicialGuaranteeAppeal.policyHolderClaimant.document) {
      const clientDocument = judicialGuaranteeAppeal.policyHolderClaimant.document
      userClient().findUserEmailsByClient(clientDocument).then((response) => {
        setClientEmails(response.data)
      })
    }
  }, [
    userClient,
    judicialGuaranteeAppeal,
    judicialGuaranteeAppeal?.policyHolderClaimant,
    judicialGuaranteeAppeal?.policyHolderClaimant?.document,
    routeExecutiveRequest,
  ])

  const toggleClick = (bool) => {
    setOpenTooltip(bool)
  }

  const initialValuesForm = useMemo(() => (Object.assign(
    RequesterData(quote?.Requester ?? {
      Name: (QUOTE_URL_EXECUTIVE_REQUEST_JDR !== routeExecutiveRequest) ? getName() : '',
      Email: (QUOTE_URL_EXECUTIVE_REQUEST_JDR !== routeExecutiveRequest) ? getEmail() : '',
    }),
  )), [quote?.Requester, getName, getEmail, routeExecutiveRequest])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesForm,
    validationSchema: Yup.object({
      note: Yup.string(),
      phone: phoneOrCellphone.required(),
      name: Yup.string().max(200).required(),
      email: Yup.string().email().required(),
      customerControlIdentifier: Yup.string().max(100),
    }),
    onSubmit: (data) => {
      const values = {
        ...judicialGuaranteeAppeal,
        ...{ requester: { ...data } },
      }
      setJudicialGuaranteeAppeal(values)

      if (actionNext) {
        setFormikChanged(!isEqual(initialValuesForm, data))
        onNext(values)
      }
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))
  const noteLabel = 'Observações'
  const requesterLabel = 'Nome do Solicitante'
  const phoneLabel = 'Telefone do Solicitante'
  const idClientControlLabel = 'Identificador do Controle do Cliente'
  const clientEmailLabel = 'Email do Solicitante'

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={isEmpty(quote?.Requester) && !formik.isSubmitting}
        title="Solicitante"
        subtitle="Informe abaixo os dados do Solicitante"
      >
        <Grid container spacing={5}>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="requesterLabel" className={classes.label}>{requesterLabel}</label>
              <Input
                fullWidth
                id="name"
                color="secondary"
                value={getName()}
                {...formik.getFieldProps('name')}
                error={formik.touched.name && !!formik.errors.name}
              />
              <FormHelperText
                hidden={!formik.touched.name || !formik.errors.name}
                error={formik.touched.name && !!formik.errors.name}
              >
                {formik.errors.name}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="phoneLabel" className={classes.label}>{phoneLabel}</label>
              <PhoneInput
                id="phone"
                phoneType="auto"
                placeholder="(00) 0000-0000"
                {...formik.getFieldProps('phone')}
                error={formik.touched.phone && !!formik.errors.phone}
              />
              <FormHelperText
                hidden={!formik.touched.phone || !formik.errors.phone}
                error={formik.touched.phone && !!formik.errors.phone}
              >
                {formik.errors.phone}
              </FormHelperText>
            </FormControl>
          </Grid>
          {(QUOTE_URL_EXECUTIVE_REQUEST_JDR === routeExecutiveRequest)
          && (
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="clientEmailLabel" className={classes.label}>{clientEmailLabel}</label>
              <Autocomplete
                id="email"
                onInputChange={(event, newValue) => {
                  formik.setFieldValue('email', newValue)
                }}
                options={clientEmails}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    error={formik.touched.email && !!formik.errors.email}
                  />
                )}
                {...formik.getFieldProps('email')}
              />
              <FormHelperText
                error={formik.touched.email && !!formik.errors.email}
              >
                {formik.errors.email}
              </FormHelperText>
            </FormControl>
          </Grid>
          )}
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="idClientControlLabel" className={classes.label}>
                {idClientControlLabel}
                <Box
                  mr={1}
                  onClick={() => toggleClick(true)}
                  onMouseOver={() => toggleClick(true)}
                >
                  <Tooltip
                    placement="right-end"
                    className={classes.info}
                    title="Número de Controle de Cliente (campo opcional)"
                    open={openTooltip}
                    onClose={() => toggleClick(false)}
                  >
                    <Icon
                      data-html
                      style={{ cursor: 'help', marginLeft: 5 }}
                    >
                      help_outline
                    </Icon>
                  </Tooltip>
                </Box>
              </label>
              <Input
                fullWidth
                id="customerControlIdentifier"
                color="secondary"
                {...formik.getFieldProps('customerControlIdentifier')}
                error={formik.touched.customerControlIdentifier
                  && !!formik.errors.customerControlIdentifier}
              />
              <FormHelperText
                hidden={!formik.touched.customerControlIdentifier
                  || !formik.errors.customerControlIdentifier}
                error={formik.touched.customerControlIdentifier
                  && !!formik.errors.customerControlIdentifier}
              >
                {formik.errors.customerControlIdentifier}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="noteLabel" className={classes.label}>{noteLabel}</label>
              <TextField
                minRows={3}
                fullWidth
                multiline
                id="note"
                variant="outlined"
                {...formik.getFieldProps('note')}
                error={formik.touched.note && !!formik.errors.note}
              />
              <FormHelperText
                hidden={!formik.touched.note || !formik.errors.note}
                error={formik.touched.note && !!formik.errors.note}
              >
                {formik.errors.note}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Box className={classes.buttonsStep}>
              <Box>
                <ButtonLight
                  onClick={handlePrevious}
                  title="Voltar"
                >
                  Voltar
                </ButtonLight>
              </Box>
              <Box ml={4}>
                <ButtonGradient
                  onClick={handleNext}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
            <ReactTooltip place="top" type="dark" />
          </Grid>
        </Grid>
      </CollapsePrice>
    </>
  )
})

Requester.propTypes = {
  quote: PropTypes.any,
  checked: PropTypes.bool,
  children: PropTypes.any,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

Requester.defaultProps = {
  children: '',
  checked: false,
  quote: undefined,
}

export default Requester
