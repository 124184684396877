import React from 'react'
import Error from '../pages/error/Error'

import BackgroundHome from '../assets/img/backgrounds/bg-home.webp'
import BackgroundCotacao from '../assets/img/backgrounds/bg-solicitar-cotacao.webp'
import BackgroundGarantia from '../assets/img/backgrounds/bg-garantia-produto.webp'
import BackgroundRiscoEquipamento from '../assets/img/backgrounds/bg-risco-equipamento-produto.webp'
import BackgroundJudicial from '../assets/img/backgrounds/bg-judicial-produto.webp'
import backgroundFinancaLocaticia from '../assets/img/backgrounds/bg-locaticia-produto.webp'
import BackgroundRiscoEngenharia from '../assets/img/backgrounds/bg-engenharia-produto.webp'
import BackgroundBeneficio from '../assets/img/backgrounds/bg-beneficio-produto.webp'
import BackgroundPatrimonial from '../assets/img/backgrounds/bg-patrimonial-produto.webp'
import BackgroundApolices from '../assets/img/backgrounds/bg-apolices.webp'
import BackgroundAjuda from '../assets/img/backgrounds/bg-ajuda.webp'
import BackgroundCyber from '../assets/img/backgrounds/bg-cyber.webp'
import BackgroundPagamento from '../assets/img/backgrounds/bg-pagamento.webp'

import PriceRoutes from '../pages/price/PriceRoutes'
import PolicyRoutes from '../pages/policy/PolicyRoutes'
import EndorsementRoutes from '../pages/endorsement/EndorsementRoutes'

const Help = React.lazy(() => import('../pages/help/Help'))
const Home = React.lazy(() => import('../pages/home/Home'))
const Panel = React.lazy(() => import('../pages/panel/Panel'))
const Reload = React.lazy(() => import('../pages/access/Reload'))
const NewUser = React.lazy(() => import('../pages/register/NewUser'))
const ManageUser = React.lazy(() => import('../pages/register/ManageUser'))
const ChangePassword = React.lazy(() => import('../pages/register/ChangePassword'))

const Policy = React.lazy(() => import('../pages/policy/Policies'))
const StatusRequest = React.lazy(() => import('../pages/status-request/Status'))
const CategoryProduct = React.lazy(() => import('../pages/price/CategoryProduct'))
const FinancialTickets = React.lazy(() => import('../pages/financial/FinancialTickets'))
const Opportunities = React.lazy(() => import('../pages/opportunities/Opportunities'))
const ComplaintsSuggestions = React.lazy(() => import('../pages/complaints-suggestions/ComplaintsSuggestions'))
const ImportPolicies = React.lazy(() => import('../pages/import-policies/ImportPolicies'))
const RegistrationUpdate = React.lazy(() => import('../pages/registration-update/RegistrationUpdate'))
const TermsOfUse = React.lazy(() => import('../pages/terms-of-use/TermsOfUse'))

const ProductEquity = React.lazy(() => import('../pages/product/ProductEquity'))
const ProductBenefits = React.lazy(() => import('../pages/product/ProductBenefits'))
const ProductLeaseBail = React.lazy(() => import('../pages/product/ProductLeaseBail'))
const ProductGuarantee = React.lazy(() => import('../pages/product/ProductGuarantee'))
const ProductEquipmentRisk = React.lazy(() => import('../pages/product/ProductEquipmentRisk'))
const ProductResponsability = React.lazy(() => import('../pages/product/ProductResponsability'))
const ProductEngineeringRisk = React.lazy(() => import('../pages/product/ProductEngineeringRisk'))
const ProductJudicialGuaranteeAppeal = React.lazy(() => import('../pages/product/ProductJudicialGuaranteeAppeal'))

const EndorsementDetails = React.lazy(() => import('../pages/endorsement/Endorsement'))

const MasterRoutes = [
  {
    path: '/',
    Component: Home,
    bgImage: BackgroundHome,
  },
  { path: '/error/:status', name: 'Erro', Component: Error },
  {
    path: '/inicio',
    Component: Panel,
    name: 'Página Inicial',
    bgImage: BackgroundHome,
  },
  {
    path: '/garantia',
    Component: ProductGuarantee,
    name: 'Seguro Garantia',
    bgImage: BackgroundGarantia,
  },
  {
    path: '/risco-equipamento',
    Component: ProductEquipmentRisk,
    name: 'Risco Diversos de Equipamento',
    bgImage: BackgroundRiscoEquipamento,
  },
  {
    path: '/judicial-deposito-recursal',
    Component: ProductJudicialGuaranteeAppeal,
    name: 'Garantia Judicial Depósito Recursal',
    bgImage: BackgroundJudicial,
  },
  {
    path: '/fianca-locaticia',
    Component: ProductLeaseBail,
    name: 'Fiança Locatícia',
    bgImage: backgroundFinancaLocaticia,
  },
  {
    path: '/risco-engenharia',
    Component: ProductEngineeringRisk,
    name: 'Risco de Engenharia',
    bgImage: BackgroundRiscoEngenharia,
  },
  {
    path: '/beneficios',
    Component: ProductBenefits,
    name: 'Benefícios',
    bgImage: BackgroundBeneficio,
  },
  {
    path: '/responsabilidades',
    Component: ProductResponsability,
    name: 'Seguro de Responsabilidades',
    bgImage: BackgroundBeneficio,
  },
  {
    path: '/patrimonial',
    Component: ProductEquity,
    name: 'Patrimonial',
    bgImage: BackgroundPatrimonial,
  },
  {
    path: '/cotacao',
    routes: PriceRoutes,
    Component: CategoryProduct,
    name: 'Solicitação de Apólice',
    bgImage: BackgroundCotacao,
  },
  {
    path: '/importacao-de-apolices',
    Component: ImportPolicies,
    name: 'Importação de Apólices',
    bgImage: BackgroundCotacao,
  },
  {
    path: '/status-solicitacao',
    Component: StatusRequest,
    name: 'Status da Solicitação',
    bgImage: BackgroundCotacao,
  },
  {
    path: '/cadastro-novos-usuarios',
    Component: NewUser,
    name: 'Cadastrar Novos Usuários',
    bgImage: BackgroundCyber,
  },
  {
    path: '/gerenciar-usuarios',
    Component: ManageUser,
    name: 'Gerenciar Usuários',
    bgImage: BackgroundCyber,
  },
  {
    path: '/endossos',
    routes: EndorsementRoutes,
    Component: EndorsementDetails,
    name: 'Solicitação de Endosso',
    bgImage: BackgroundApolices,
  },
  {
    path: '/apolices',
    routes: PolicyRoutes,
    Component: Policy,
    name: 'Apólices',
    bgImage: BackgroundApolices,
  },
  {
    path: '/financeiro',
    Component: FinancialTickets,
    name: 'Financeiro',
    bgImage: BackgroundPagamento,
  },
  {
    path: '/oportunidades',
    Component: Opportunities,
    name: 'Oportunidades',
    bgImage: BackgroundPagamento,
  },
  {
    path: '/atualizacao-cadastral',
    Component: RegistrationUpdate,
    name: 'Atualização Cadastral',
    bgImage: BackgroundApolices,
  },
  {
    path: '/ajuda',
    Component: Help,
    name: 'Ajuda',
    bgImage: BackgroundAjuda,
  },
  {
    path: '/reclamacoes-sugestoes',
    Component: ComplaintsSuggestions,
    name: 'Reclamações e Sugestões',
    bgImage: BackgroundAjuda,
  },
  {
    path: '/termos-de-uso',
    Component: TermsOfUse,
    name: 'Termos de Uso',
    bgImage: BackgroundAjuda,
  },
  {
    path: '/alterar-senha',
    Component: ChangePassword,
    name: 'Alterar Senha',
    bgImage: BackgroundCyber,
  },
  { path: '/reload', name: 'Reload', Component: Reload },
]

export default MasterRoutes
