import { useCallback } from 'react'
import axios from 'axios'

const useRegistrationUpdateClient = () => {
  const editUser = useCallback(async (data) => axios.put('/user/updateOutdatedUser', data).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const getUserOutdated = useCallback((email) => axios.get(`/user/isOutdated?email=${email}`), [])

  const registerDateOutdated = useCallback((email) => axios.post(`/user/updateDenied?email=${email}`), [])

  return useCallback(() => ({
    editUser,
    getUserOutdated,
    registerDateOutdated,
  }), [
    editUser,
    getUserOutdated,
    registerDateOutdated,
  ])
}

export default useRegistrationUpdateClient
