import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import makeStyles from '@material-ui/core/styles/makeStyles'

import {
  ModalInfo,
  ButtonLight,
  GreenCheckbox,
  ButtonGradient,
  useAlert,
} from '../../shared/components'
import ButtonDownload from '../../shared/components/Button/ButtonDownload'

import useUserClient from '../../clients/UserClient/useUserClient'
import useTermsOfUseClient from '../../clients/TermsOfUseClient/useTermsOfUseClient'

import termsOfUseDownload from '../../assets/documents/TERMOS-DE-USO-DA-AREA-DE-CLIENTE-vf.pdf'
import useSecurity from '../../security/useSecurity'
import { useSecurityAction } from '../../store/ducks/security'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        '&.MuiBox-root': {
          minWidth: 120,
          maxWidth: 120,
        },
      },
    },
    '@media (max-width: 699px)': {
      justifyContent: 'center',
    },

  },
  modal: {
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
    },
    '& .MuiPaper-root': {
      width: 1000,
      [theme.breakpoints.down('md')]: {
        width: 800,
      },
      [theme.breakpoints.down('xs')]: {
        width: 350,
      },
    },
  },
  root: {
    paddingRight: 10,
  },
  head: {
    fontSize: '28px',
    lineHeight: '36px',
    fontWeight: 700,
    height: 50,
  },
  scroll: {
    maxHeight: 400,
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto',
      width: 800,
    },
    [theme.breakpoints.down('xs')]: {
      overflowX: 'auto',
      width: 350,
    },
  },
  title: {
    marginTop: 7,
    marginBottom: 7,
    fontWeight: 700,
    color: theme.palette.text.tertiary,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  descriptionContainer: {
    width: 900,
    [theme.breakpoints.down('xs')]: {
      width: 350,
    },
  },
  description: {
    color: theme.palette.text.tertiary,
    marginTop: 7,
    marginBottom: 7,
    [theme.breakpoints.down('md')]: {
      width: 800,
    },
    [theme.breakpoints.down('xs')]: {
      width: 350,
    },

  },
  checkBox: {
    minHeight: 70,
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

const TERMO_DE_USO = 'Os termos de uso devem ser lidos e aceitos.'

const TermsOfUseModal = (props) => {
  const classes = useStyles()
  const { addMsgSuccess, addMsgDanger } = useAlert()

  const { openModal, closeModal, logoutCloseModal } = props

  const {
    getUser,
  } = useSecurity()

  const {
    setUser,
  } = useSecurityAction()

  const user = getUser()

  const [checked, setChecked] = useState(false)
  const [termsItems, setTermsItems] = useState([])

  const userClient = useUserClient()
  const termsOfUseClient = useTermsOfUseClient()

  const formik = useFormik({
    initialValues: { iAcceptTheTermsOfUse: '' },
    validationSchema: Yup.object({
      iAcceptTheTermsOfUse: Yup.boolean()
        .required()
        .oneOf([true], TERMO_DE_USO),
    }),
    onSubmit: () => {
      const values = localStorage.getItem('ipAddress')

      termsOfUseClient().sendAceptLastVersion(values).then(() => {
        closeModal()
        setUser({ ...user, hasAceptedTermsOfUse: true })
        addMsgSuccess('Sua assinatura nos termos de uso foi recebida com sucesso.')
      }, (err) => {
        addMsgDanger({ title: err?.status, message: 'Contate o administrador do sistema!' })
      })
    },
  })

  useEffect(() => {
    const getIpAddress = async () => {
      const res = await userClient().getIPAddress()
      localStorage.setItem('ipAddress', res?.ip)
    }

    getIpAddress()
    setTermsItems(termsOfUseClient().getTermsOfUse().sort())
  }, [userClient, termsOfUseClient])

  return (
    <>
      {openModal && (
        <ModalInfo
          close={false}
          open={openModal}
          className={classes.modal}
        >
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #8080802e' }}>
              <Box>
                <Typography className={classes.head}>
                  {user?.newUser ? 'Termos de Uso' : 'Atualizações do Termo de Uso'}
                </Typography>
              </Box>
              <Box style={{ marginRight: 30 }}>
                <ButtonDownload
                  label="Baixar Termos de Uso"
                  url={termsOfUseDownload}
                />
              </Box>
            </Grid>
            <Grid item id="scroll" className={classes.scroll}>
              {termsItems?.map((item) => (
                <Card key={item.id}>
                  <Grid
                    container
                  >
                    <Grid item xs={12} lg={6}>
                      <Typography className={classes.title}>
                        {item.title}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid className={classes.descriptionContainer}>
                    {item.subDescription?.map((subDescription) => (
                      <Grid key={subDescription.id}>
                        <Typography className={classes.description}>
                          {subDescription.textTitle}
                        </Typography>
                        <Typography className={classes.description}>
                          {subDescription.textDescription}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              ))}
            </Grid>
            <Grid item xs={12} lg={12} className={classes.checkBox}>
              <FormControlLabel
                control={(
                  <GreenCheckbox
                    id="iAcceptTheTermsOfUse"
                    checked={checked}
                    {...formik.getFieldProps('iAcceptTheTermsOfUse')}
                    onChange={() => {
                      setChecked(!checked)
                      formik.values.iAcceptTheTermsOfUse = !checked
                    }}
                  />
                )}
              />

              <Typography style={{ textDecoration: 'underline', marginRight: 20 }}>
                Li e Aceito os Termos de Uso
              </Typography>
              <FormHelperText
                hidden={!formik.touched.iAcceptTheTermsOfUse
                    || !formik.errors.iAcceptTheTermsOfUse}
                error={formik.touched.iAcceptTheTermsOfUse
                    && !!formik.errors.iAcceptTheTermsOfUse}
              >
                {TERMO_DE_USO}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <Box justifyContent="center" className={classes.buttonsStep}>
                <Box>
                  <ButtonLight onClick={logoutCloseModal}>
                    Sair
                  </ButtonLight>
                </Box>
                <Box>
                  <ButtonGradient
                    onClick={formik.submitForm}
                  >
                    Confirmar
                  </ButtonGradient>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ModalInfo>
      )}
    </>
  )
}

TermsOfUseModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  logoutCloseModal: PropTypes.func.isRequired,
}

export default TermsOfUseModal
