const muiPaperConfig = {
  elevation1: {
    boxShadow: 'none',
    borderRadius: 14,
    '& .MuiCardHeader-root': {
      borderBottom: '1px solid #F6F6F6',
    },
  },
  rounded: {
    borderRadius: 14,
  },
}

export default muiPaperConfig
