import React, {
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'

import ReactTooltip from 'react-tooltip'
import { isCnpj } from 'validator-brazil'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

import makeStyles from '@material-ui/core/styles/makeStyles'

import useYup from '../../../shared/hooks/useYup'
import { useEndorsement } from '../../../shared/contexts/EndorsementContext'

import {
  useAlert,
  useLoader,
  ButtonLight,
  ButtonGradient,
  CPFOrCNPJInput,
} from '../../../shared/components'
import CollapsePrice from '../../../shared/components/CollapsePrice/CollapsePrice'

import { GlobalMessages } from '../../../shared/messages'

import useUserClient from '../../../clients/UserClient/useUserClient'
import useReceitaWSClient from '../../../clients/ReceitaWSClient/useReceitaWSClient'

import EndorsementInsuredData from '../../../models/PolicyModels/EndorsementInsuredData'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  help: {
    color: theme.palette.text.quaternary,
  },
}))

const Insured = forwardRef((props, ref) => {
  const classes = useStyles()
  const userClient = useUserClient()
  const receitaWSClient = useReceitaWSClient()

  const {
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props
  const { cpfOrCnpj } = useYup()
  const { addMsgDanger } = useAlert()
  const { showLoader, hideLoader } = useLoader()

  const {
    onNext,
    actionNext,
    endorsement,
    setEndorsement,
    otherBrokerageDocument,
  } = useEndorsement()

  const initialValuesForm = useMemo(() => (Object.assign(
    EndorsementInsuredData({
      name: endorsement?.insured?.name ?? undefined,
      document: endorsement?.insured?.document ?? undefined,
    }),
  )), [endorsement])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesForm,
    validationSchema: Yup.object({
      document: cpfOrCnpj.required(),
      name: Yup.string().max(200).required(),
    }),
    onSubmit: (data) => {
      const insured = {
        ...data,
        ...endorsement?.insured,
      }

      const values = {
        ...endorsement,
        insured,
      }

      setEndorsement(values)

      const {
        name,
        document,
      } = values.insured

      const valueInsuredObject = {
        name,
        document,
      }

      if (actionNext) {
        onNext(values)
        if (!isCnpj(valueInsuredObject.document)) {
          userClient().createUpdateInsuredObjectProperty(valueInsuredObject)
            .then((response) => {
              addMsgDanger(response.data.error)
            })
        }
      }
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  const handleInfoCnpj = (evento) => {
    const { value } = evento.target

    const cnpj = value?.replace(/[^0-9]/g, '')

    if (isCnpj(cnpj)) {
      showLoader()
      receitaWSClient().getInfoByCnpj(cnpj).then(async (response) => {
        if (response) {
          formik.setFieldValue('name', response.nome)
          hideLoader()
        }
      }).catch(() => {
        hideLoader()
      })
    }
    if (cnpj && !isCnpj(cnpj)) {
      showLoader()
      userClient().getClientInsuredObjectProperty(cnpj).then(async (response) => {
        if (response) {
          formik.setFieldValue('name', response?.data?.nome)
          hideLoader()
        }
      }).catch(() => {
        hideLoader()
      })
    }
  }

  const placeholderCPFCNPJ = '000.000.000-00 / 00.000.000/0000-00'

  const nameLabel = 'Nome'
  const cpfCnpjLabel = 'CPF/CNPJ do Segurado'

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={!formik.isSubmitting}
        title="Dados do Segurado"
        subtitle="Visualize abaixo os dados do segurado"
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="cpfCnpjLabel" className={classes.label}>{cpfCnpjLabel}</label>
                  <CPFOrCNPJInput
                    fullWidth
                    disabled={!otherBrokerageDocument}
                    id="document"
                    color="secondary"
                    placeholder={placeholderCPFCNPJ}
                    {...formik.getFieldProps('document')}
                    onBlur={handleInfoCnpj}
                    error={formik.touched.document && !!formik.errors.document}
                  />
                  <FormHelperText
                    hidden={!formik.touched.document || !formik.errors.document}
                    error={formik.touched.document && !!formik.errors.document}
                  >
                    {formik.errors.document}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="nameLabel" className={classes.label}>{nameLabel}</label>
                  <Input
                    fullWidth
                    disabled={!otherBrokerageDocument}
                    id="name"
                    color="secondary"
                    placeholder="Nome do Segurado"
                    {...formik.getFieldProps('name')}
                    error={formik.touched.name && !!formik.errors.name}
                  />
                  <FormHelperText
                    hidden={!formik.touched.name || !formik.errors.name}
                    error={formik.touched.name && !!formik.errors.name}
                  >
                    {formik.errors.name}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.buttonsStep}>
              <Box>
                <ButtonLight
                  onClick={handlePrevious}
                  title="Voltar"
                >
                  Voltar
                </ButtonLight>
              </Box>
              <Box ml={4}>
                <ButtonGradient
                  onClick={handleNext}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
            <ReactTooltip place="top" type="dark" />
          </Grid>
        </Grid>
      </CollapsePrice>
    </>
  )
})

Insured.propTypes = {
  checked: PropTypes.bool,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

Insured.defaultProps = {
  checked: false,
}

export default Insured
