import React from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

import {
  makeStyles,
  Typography,
  Link,
} from '@material-ui/core'

import icoDownload from '../../../assets/img/icons/ico-download-secundary-2.svg'

const useCustomStyle = makeStyles(() => ({
  containerDownload: {
    marginTop: 2,
    display: 'flex',
    cursor: 'pointer',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  labelDownload: {
    fontWeight: 500,
    textDecoration: 'underline',
  },
  IconDownload: {
    marginRight: 5,
    color: '#0E347D',
  },
}))

const ButtonDownload = (props) => {
  const { label, url } = props
  const classes = useCustomStyle()
  return (
    <Link className={classes.containerDownload} href={url} target="_blank">
      <SVG src={icoDownload} width={21} height={21} className={classes.IconDownload} />
      <Typography className={classes.labelDownload}>
        {label}
      </Typography>
    </Link>
  )
}

ButtonDownload.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default ButtonDownload
