import React, {
  useState,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'

import SVG from 'react-inlinesvg'

import Box from '@material-ui/core/Box'
import Slide from '@material-ui/core/Slide'
import Radio from '@material-ui/core/Radio'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'

import { styled } from '@material-ui/core/styles'
import withStyles from '@material-ui/styles/withStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'

import iconClock from '../../../../../../assets/img/icons/ico-clock.svg'

import { SlashScreen, useAlert } from '../../../../../../shared/components'
import { useQuotationClient } from '../../../../../../clients/QuotationClient'

const TypographyGradient = withStyles({
  root: {
    fontSize: 17,
    fontWeight: 700,
    fontFamily: 'Montserrat',
    background: '-webkit-linear-gradient(180deg, #1BD4ED -20.4%, #0F347D 124.8%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
})(Typography)

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  textLoading: {
    marginTop: 10,
    fontSize: 15,
    color: 'white',
    marginBottom: 60,
  },
  modalContainer: {
    width: 569,
    padding: 15,
    paddingLeft: 20,
    height: 406,
    marginTop: 6,
    color: 'white',
    borderRadius: 13,
    textAlign: 'start',
    backgroundColor: '#fff',
    [theme.breakpoints.down('xs')]: {
      width: 360,
    },
  },
  actionContainer: {
    height: 55,
    width: 525,
    marginTop: 15,
    borderRadius: 8,
    color: 'white',
    backgroundColor: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    [theme.breakpoints.down('xs')]: {
      width: 320,
    },
  },
  textQuestion: {
    fontSize: 20,
    marginBottom: 5,
    fontWeight: 700,
    color: '#000000',
    fontFamily: 'Montserrat',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  textResponse: {
    fontSize: 14,
    paddingLeft: 10,
    color: '#000000',
    fontWeight: 700,
  },
  radioButton: {
    height: 10,
    width: 10,
    color: '#D9D9D9',
    backgroundColor: '#D9D9D9',
  },
}))

const LinearProgressStyle = {
  height: 5,
  width: '98%',
  marginTop: 5,
  borderRadius: 8,
  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: '#D5D7DC',
  },
}

const BorderLinearProgress = styled(LinearProgress)(() => ({
  ...LinearProgressStyle,
  '& .MuiLinearProgress-bar': {
    borderRadius: 8,
    background: '#5785DC',
  },
}))

const ModalQuestionOne = ({
  text,
  payload,
  nextModal,
  closeModal,
  onClickSubmit,
  questionSubmit,
  thanksAnswerModal,
  setIsShowModalSelected,
  setOpenModalThanksAnswer,
}) => {
  const classes = useStyles()
  const { addMsgDanger } = useAlert()
  const quotationClient = useQuotationClient()
  const [progress, setProgress] = useState(100)
  const containerRef = React.useRef(null)

  const errorMessage = (err) => {
    addMsgDanger({
      title: err?.data,
      message: 'Contate o administrador do sistema!',
      labelButton: 'Fechar',
    })
    setTimeout(() => {
      onClickSubmit(true)
      setIsShowModalSelected(3)
    }, 1000)
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress - 1.25))
      if (progress <= 0) {
        closeModal(true)
        quotationClient().sendRERequestPerformance({
          ...payload,
          ExigeSeguro: null,
        }).then(() => {
          setOpenModalThanksAnswer(false)
          setTimeout(() => {
            nextModal(3)
          }, 1000)
        }, (err) => {
          errorMessage(err)
        })
      }
    }, 250)
    return () => {
      clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, closeModal])

  const slideEffect = true

  const icon = (
    <Box className={classes.modalContainer}>
      <Box style={{ marginTop: 5 }}>
        <TypographyGradient>
          Enquanto isso responda a pergunta abaixo.
        </TypographyGradient>
      </Box>
      <Box style={{ marginTop: 12, marginBottom: 30 }}>
        <Typography className={classes.textQuestion}>
          {text}
        </Typography>
      </Box>
      <Box className={classes.actionContainer}>
        <Box width="80%" textAlign="start">
          <Typography className={classes.textResponse}>
            Sim
          </Typography>
        </Box>
        <Radio
          className={classes.radioButton}
          onClick={() => {
            questionSubmit()
            nextModal(3)
          }}
        />
      </Box>
      <Box className={classes.actionContainer}>
        <Box width="80%" textAlign="start">
          <Typography className={classes.textResponse}>
            Não
          </Typography>
        </Box>
        <Radio
          className={classes.radioButton}
          onClick={() => {
            thanksAnswerModal(false)
          }}
        />
      </Box>
      <Box mt={4} width="100%">
        <Box display="flex" textAlign="center" justifyContent="start">
          <SVG
            src={iconClock}
            width={12.48}
            height={12.48}
            style={{
              marginTop: 4,
              marginRight: 5,
            }}
          />
          <Typography style={{ color: '#6E7191', fontSize: 13 }}>
            Tempo para responder
          </Typography>
        </Box>
        <BorderLinearProgress
          variant="determinate"
          value={progress}
          sx={{
            '& .MuiLinearProgress-colorPrimary': {
              backgroundColor: '#D5D7DC',
            },
            '& .MuiLinearProgress-barColorPrimary': {
              backgroundColor: '#5785DC',
            },
          }}
        />
      </Box>
    </Box>
  )

  return (
    <SlashScreen>
      <Box className={classes.container} ref={containerRef}>
        <Typography align="center" variant="h6">
          <div className={classes.textLoading}>
            Aguarde,
            <br />
            estamos processando sua solicitação...
            <br />
          </div>
        </Typography>
        <Slide
          in={slideEffect}
          direction="left"
          container={containerRef.current}
        >
          {icon}
        </Slide>
      </Box>
    </SlashScreen>
  )
}

ModalQuestionOne.propTypes = {
  text: PropTypes.string,
  payload: PropTypes.object,
  nextModal: PropTypes.func,
  closeModal: PropTypes.func,
  onClickSubmit: PropTypes.func,
  questionSubmit: PropTypes.func,
  thanksAnswerModal: PropTypes.func,
  setOpenModalThanksAnswer: PropTypes.func,
  setIsShowModalSelected: PropTypes.func,
}

ModalQuestionOne.defaultProps = {
  text: '',
  payload: {},
  nextModal: () => { },
  closeModal: () => { },
  onClickSubmit: () => { },
  questionSubmit: () => { },
  thanksAnswerModal: () => { },
  setOpenModalThanksAnswer: () => { },
  setIsShowModalSelected: () => { },
}

export default ModalQuestionOne
