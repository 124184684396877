import React from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import makeStyles from '@material-ui/core/styles/makeStyles'

import icoDocumentNotFound from '../../../assets/img/icons/icon-document-not-found.svg'

import { ButtonLight, ButtonGradient, ModalInfo } from '../../../shared/components'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& .MuiBox-root': {
      minWidth: 190,
    },
    '@media (max-width: 699px)': {
      justifyContent: 'center',
    },
  },
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 700,
    },
  },
}))

const ModalIssuedByAnotherBrokerage = (props) => {
  const classes = useStyles()
  const {
    openModal,
    closeModal,
    documentNumber,
    incorrectNumber,
    setOtherBrokerageDocument,
  } = props

  return (
    <>
      {openModal && (
        <ModalInfo
          close={false}
          open={openModal}
          className={classes.modal}
        >
          <Box p={2} alignItems="center" justifyContent="center">
            <Box>
              <Box flexGrow={1} textAlign="center">
                <img width={150} src={icoDocumentNotFound} alt="documento não encontrado" />
              </Box>
              <Box>
                <Typography align="center" variant="h6"> Documento não encontrado.</Typography>
              </Box>
              <Box mb={2} alignItems="center" justifyContent="center">
                <Grid container>
                  <Grid item xs={6}>
                    <Typography align="center" variant="h6">
                      Favor confirmar o número
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{
                      fontSize: 18,
                      marginTop: 5,
                      color: '#5785DC',
                    }}
                    >
                      {documentNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={5} ml={8} width="80%" textAlign="center" justifyContent="center">
                <Typography align="center" style={{ fontSize: 16 }}>
                  Caso esteja correto e emitido por outra corretora,
                  favor seguir com a solicitação de endosso.
                </Typography>
              </Box>
              <Grid item xs={12}>
                <Box justifyContent="center" className={classes.buttonsStep}>
                  <Box>
                    <ButtonLight
                      onClick={() => {
                        incorrectNumber()
                        closeModal(true)
                      }}
                    >
                      Cancelar
                    </ButtonLight>
                  </Box>
                  <Box>
                    <ButtonGradient
                      onClick={() => {
                        setOtherBrokerageDocument(true)
                        closeModal(true)
                      }}
                    >
                      Sim, seguir com solicitação
                    </ButtonGradient>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Box>
        </ModalInfo>
      )}
    </>
  )
}

ModalIssuedByAnotherBrokerage.propTypes = {
  openModal: PropTypes.bool.isRequired,
  incorrectNumber: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  documentNumber: PropTypes.string,
  setOtherBrokerageDocument: PropTypes.func.isRequired,
}
ModalIssuedByAnotherBrokerage.defaultProps = {
  documentNumber: '',
}

export default ModalIssuedByAnotherBrokerage
