import { useCallback } from 'react'
import axios from 'axios'

const useQuotationClient = () => {
  const registerBail = useCallback(async (quotation) => axios.post('/quote/bail', quotation).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const registerGuaranteeBID = useCallback(async (quotation) => axios.post('/quote/bid', quotation).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const registerGuaranteePerformance = useCallback(async (quotation) => axios.post('/quote/performance', quotation).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const registerJudicialGuaranteeAppeal = useCallback(async (quotation) => axios.post('/quote/judicial', quotation).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const registerJudicialGuaranteeCivel = useCallback(async (quotation) => axios.post('quote/judicialcivel', quotation).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const registerJudicialGuaranteeLabor = useCallback(async (quotation) => axios.post('/judicial-labor', quotation).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const registerEngineeringRisk = useCallback(async (quotation) => axios.post('/quote/engineeringrisk', quotation).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const registerGuaranteeOther = useCallback(async (quotation) => axios.post('/quote/guaranteeOther', quotation).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const sendInfoPayment = useCallback(async (quotation) => axios.post('/quote/payment', quotation).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const sendAnalysisRequest = useCallback(async (analysisRequest) => axios.post('/quote/analysis', { request: { ...analysisRequest } }).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const getQuotationDetails = useCallback(async (quotationIdentifier) => axios.get(`/quote?identifier=${quotationIdentifier}`).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const getQuotationManualDetails = useCallback(async (quotationIdentifier) => axios.get(`/quote/manual?identifier=${quotationIdentifier}`).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const getStatusQuotationSimplified = useCallback(async () => axios.get('/statusCotacaoSimplificado').then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const edit = useCallback(async (quotation) => axios.put('/quote/edit', { request: { ...quotation } }).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const deleteQuote = useCallback(async (quotationIdentifier, clientEmail) => axios.delete(`/quote/delete?identifier=${quotationIdentifier}&clientEmail=${clientEmail}`).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const sendDocumentsWings = useCallback(async (data) => axios.post('/document/save', data, {
    noLoading: true,
  }).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const downloadDocumentsWings = useCallback(async (document) => axios.get(`/wings/document?wingsDocumentId=${document}&base64=true`), [])

  const getPaymentType = useCallback(async (document) => axios.get(`/client/paymentType?cpfCnpj=${document}`), [])

  const getDraftQuote = useCallback(async (data) => axios.get('/quote/draft/document', {
    params: { ...data },
  }).then((response) => (response?.data ? response : { data: { ...response } })), [])

  const getQuoteReceiptViewer = useCallback(async (documentNumber) => axios.get(`/quotes/export/pdf/${documentNumber}?base64=true`)
    .then((response) => (response?.data ? response : { data: { ...response } })), [])

  const getQuoteEndorsementReceiptViewer = useCallback(async (documentNumber) => axios.get(`/InsurancePolicy/endorsement/pdf/${documentNumber}?base64=true`)
    .then((response) => (response?.data ? response : { data: { ...response } })), [])

  const getInfosLimitAndRate = useCallback(async (document) => axios.get(`/client/creditAnalysis/${document}`)
    .then((response) => (response?.data ? response : { data: { ...response } })), [])

  const getListLimitAndRate = useCallback(async () => axios.get('/client/myClients')
    .then((response) => (response?.data ? response : { data: { ...response } })), [])

  const sendEndorsementAutomatic = useCallback(async (data) => axios.post('/endorsement-automatic', data).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const sendRERequestPerformance = useCallback(async (data) => axios.post('/opportunity/engineeringRisks', data).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  return useCallback(() => ({
    edit,
    deleteQuote,
    registerBail,
    getDraftQuote,
    getPaymentType,
    sendInfoPayment,
    sendAnalysisRequest,
    getQuotationDetails,
    getQuoteReceiptViewer,
    sendRERequestPerformance,
    getQuoteEndorsementReceiptViewer,
    registerGuaranteeBID,
    registerGuaranteeOther,
    registerEngineeringRisk,
    getQuotationManualDetails,
    getStatusQuotationSimplified,
    registerGuaranteePerformance,
    registerJudicialGuaranteeCivel,
    registerJudicialGuaranteeLabor,
    registerJudicialGuaranteeAppeal,
    sendDocumentsWings,
    downloadDocumentsWings,
    getInfosLimitAndRate,
    getListLimitAndRate,
    sendEndorsementAutomatic,
  }), [
    edit,
    deleteQuote,
    registerBail,
    getDraftQuote,
    getPaymentType,
    sendInfoPayment,
    sendAnalysisRequest,
    getQuotationDetails,
    getQuoteReceiptViewer,
    sendRERequestPerformance,
    getQuoteEndorsementReceiptViewer,
    registerGuaranteeBID,
    registerGuaranteeOther,
    registerEngineeringRisk,
    getQuotationManualDetails,
    getStatusQuotationSimplified,
    registerGuaranteePerformance,
    registerJudicialGuaranteeCivel,
    registerJudicialGuaranteeLabor,
    registerJudicialGuaranteeAppeal,
    sendDocumentsWings,
    downloadDocumentsWings,
    getInfosLimitAndRate,
    getListLimitAndRate,
    sendEndorsementAutomatic,
  ])
}

export default useQuotationClient
