import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'

import { isEmpty } from 'lodash'
import * as Yup from 'yup'
import moment from 'moment'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import FormGroup from '@material-ui/core/FormGroup'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import useUtils from '../../../shared/hooks/useUtils'
import {
  useAlert,
  DateInput,
  ButtonLight,
  GreenCheckbox,
  ButtonGradient,
} from '../../../shared/components'
import { ButtonSquare } from '../../../shared/components/Button'
import DropdownSelectOne from '../../../shared/components/DropdownSelectOne/DropdownSelectOne'
import useQuotationClient from '../../../clients/QuotationClient/useQuotationClient'

import icoTicket from '../../../assets/img/icons/ico-ticket.svg'
import ModalPolicyEmission from '../../price/quotations/payment/fragments/ModalPolicyEmission'
import ModalAnalysisRequest from '../../price/quotations/payment/fragments/ModalAnalysisRequest'
import useStyles from '../styles/payment'
import useObjects from '../utils/useObjects'

const MIN_PARCEL_VALUE_PERFORMANCE = 500

const DEFAULT_CONFIRMATION = {
  paymentType: '',
  installments: '',
  firstInstallmentDueDateDelay: '',
  responsibilityConfirmation: false,
}

const Payment = (props) => {
  const { handlePrevious, endorsement } = props

  const classes = useStyles()
  const history = useHistory()
  const { addMsgSuccess, addMsgDanger } = useAlert()
  const {
    date,
    formatDate,
    formatDateApi,
    ternaryCheck,
    formatCurrency,
    checkStringEmpty,
  } = useUtils()
  const objectsMaker = useObjects()

  const [parcels, setParcels] = useState([''])
  const [checked, setChecked] = useState(false)
  const [isDrafting, setIsDrafting] = useState(false)
  const [dataFormik, setDataFormik] = useState()
  const [selectedParcel, setSelectedParcel] = useState(1)
  const [selectedDaysParcel, setSelectedDaysParcel] = useState('')
  const [datePayment, setDatePayment] = useState('')
  const [selectedPaymentType, setSelectedPaymentType] = useState(1)
  const [openModalConfirmAnalysis, setOpenModalConfirmAnalysis] = useState(false)
  const [openModalConfirmEndorsement, setOpenModalConfirmEndorsement] = useState(false)

  const quotationIdentifier = useMemo(() => endorsement?.identifier, [endorsement])
  const minDatePayment = useMemo(() => new Date(Date.now() + (3600 * 1000 * 24)), [])

  const maxDatePayment = useMemo(() => date().add(30, 'days'), [date])

  const quotationClient = useQuotationClient()

  const getCommercialPremiumFormat = () => (
    formatCurrency(ternaryCheck(
      Number(endorsement?.commercialPremium) < Number(endorsement?.grossPremium),
      endorsement?.grossPremium,
      endorsement?.commercialPremium + endorsement?.IOF,
    ))
  )

  const setParcelsPayment = useCallback((maxParcelValue, maxParcelas) => {
    const parcelas = []

    const parcelsNumber = ternaryCheck(maxParcelValue > maxParcelas, maxParcelas, maxParcelValue)
    const isMaxParcel = parcelsNumber === 0 ? 1 : parcelsNumber

    for (let i = 1; i <= isMaxParcel; i++) {
      parcelas.push({ value: i, text: `${i}x de ${formatCurrency((endorsement?.grossPremium / i))}` })
    }

    setParcels(parcelas)
  }, [endorsement, formatCurrency, setParcels, ternaryCheck])

  const handleHireAnalysis = () => setOpenModalConfirmAnalysis(true)

  const handleConfirmationAnalysis = ({ analysisText }) => {
    quotationClient().sendAnalysisRequest({
      identifier: quotationIdentifier,
      reasonAnalysis: analysisText,
    }).then(() => {
      const dataSuccess = {
        title: 'Recebemos sua solicitação',
        message: 'Sua solicitação estará disponível em breve na consulta de Status da Solicitação.',
        labelButton: 'Acompanhe sua solicitação',
        action: () => history.replace('/status-solicitacao'),
      }

      addMsgSuccess(dataSuccess)
    }, (error) => {
      addMsgDanger(error)
    })
  }

  const handleHireEndorsement = (formikData) => {
    setOpenModalConfirmEndorsement(true)
    setDataFormik(formikData)
  }

  const handleConfirmEndorsement = () => {
    setOpenModalConfirmEndorsement(false)

    quotationClient()
      .sendInfoPayment({ request: { ...dataFormik } }).then(() => {
        const dataSuccess = {
          title: 'Recebemos sua solicitação',
          message: 'Seu endosso estará disponível em breve na consulta de Apólice.',
          labelButton: 'Acompanhe sua solicitação',
          action: () => history.replace('/status-solicitacao'),
        }

        addMsgSuccess(dataSuccess)
      }, (response) => {
        addMsgDanger(response.data)
      })
  }

  const handleParcelasPerformance = useCallback(() => {
    const startDate = moment(endorsement?.newStateDocument?.startDateValidity)
    const endData = moment(endorsement?.newStateDocument?.endDateValidity)

    const vigencia = (endData.diff(startDate, 'months') - 1)
    const maxParcelaTempo = (vigencia === 0 || vigencia === -1) ? 1 : vigencia

    const maxParcelaValor = Math.floor(endorsement?.grossPremium / MIN_PARCEL_VALUE_PERFORMANCE)

    setParcelsPayment(
      ternaryCheck(maxParcelaValor < maxParcelaTempo, maxParcelaValor, maxParcelaTempo),
      5,
    )
  }, [endorsement, setParcelsPayment, ternaryCheck])

  const closeModal = () => {
    setOpenModalConfirmAnalysis(false)
    setOpenModalConfirmEndorsement(false)
  }

  const handleParcelas = useCallback(() => {
    handleParcelasPerformance()
  }, [
    handleParcelasPerformance,
  ])

  const formik = useFormik({
    initialValues: { ...DEFAULT_CONFIRMATION },
    validationSchema: Yup.object({
      paymentType: Yup.string().required(),
      installments: Yup.number().required(),
      firstInstallmentDueDateDelay: Yup.number()
        .test('firstInstallmentDueDateDelay', `A data mínima permitida é ${formatDate(date().add(1, 'days'))}`, (value) => (value && Number(value) > 0))
        .test('firstInstallmentDueDateDelay', `A data máxima permitida é ${formatDate(date().add(30, 'days'))}`, (value) => (value && Number(value) < 31))
        .required(),
      responsibilityConfirmation: Yup.boolean()
        .required()
        .oneOf([true], 'Os termos e condições devem ser lidos e aceitos.'),
    }),
    onSubmit: (data) => {
      // Condicao que previne o submit ao validar o form no Visualizar Minuta
      if (!isDrafting) {
        const values = {
          ...{ identifier: quotationIdentifier },
          ...{ payment: { ...data } },
          ...{
            acceptRequired: false,
          },
        }

        handleHireEndorsement(values)
      }
    },
  })

  const getDiffDate = (dateFinal) => {
    const formatApi = 'YYYY-MM-DD'
    const inicial = date(minDatePayment)?.format(formatApi)
    const final = date(dateFinal)?.format(formatApi)
    const diffIn = new Date(final) - new Date(inicial)
    const diffDays = (diffIn / (1000 * 60 * 60 * 24)) + 1
    return diffDays > 0 ? diffDays : 0
  }

  const handleDate = (e) => {
    const daysDiff = getDiffDate(e.target.value)
    formik.setFieldValue('firstInstallmentDueDateDelay', daysDiff)
    setDatePayment(formatDateApi(date(e.target.value)))
    setSelectedDaysParcel(daysDiff)
  }

  const { setValues } = formik

  const viewDraft = () => {
    setIsDrafting(true)

    formik.validateForm().then((errors) => {
      if (isEmpty(errors)) {
        window.open(`/cotacao/${quotationIdentifier}/minuta`, '_blank')
      }

      formik.submitForm()
    })
  }

  useEffect(() => {
    handleParcelas()
    setValues({
      installments: checkStringEmpty(selectedParcel),
      paymentType: checkStringEmpty(selectedPaymentType),
      responsibilityConfirmation: ternaryCheck(checked, checked, false),
      firstInstallmentDueDateDelay: checkStringEmpty(selectedDaysParcel),
    })
  }, [
    checked,
    setValues,
    ternaryCheck,
    handleParcelas,
    selectedParcel,
    checkStringEmpty,
    selectedDaysParcel,
    selectedPaymentType,
  ])

  return (
    <>
      <Box className={classes.root}>
        <Paper elevation={1}>
          <Grid container>
            <Grid item xs={12} className={classes.header}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" color="initial">
                    Dados de Pagamento
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.labelInfo}>
                    Informe abaixo os dados de pagamento
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.content}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Grid container className={classes.root}>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        className={classes.subheader}
                        data-content="Forma de Pagamento"
                      />

                      <Grid container className={classes.paymentsTypes} spacing={2}>
                        <Grid item xs={12}>
                          <ButtonSquare
                            text="Boleto"
                            svg={icoTicket}
                            className={selectedPaymentType === 1 ? 'selected' : ''}
                            onClick={() => {
                              setSelectedParcel(1)
                              setSelectedPaymentType(1)
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography gutterBottom className={classes.subheader} data-content="Valores" />

                      <Grid container className={classes.confirmationBox} spacing={2}>
                        <Grid item xs={12}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Prêmio Líquido
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {formatCurrency(endorsement?.commercialPremium)}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Prêmio Total
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {getCommercialPremiumFormat()}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography gutterBottom className={classes.subheader} data-content="Parcelas" />

                      <Grid container className={classes.confirmationBox} spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel className={classes.label}>
                              Informe o número de parcelas
                            </InputLabel>

                            <DropdownSelectOne
                              id="installments"
                              options={parcels}
                              placeholder="Selecione"
                              {...formik.getFieldProps('installments')}
                              error={formik.touched.installments && !!formik.errors.installments}
                              onChange={(e) => {
                                const { value } = e.target
                                setSelectedParcel(value)
                              }}
                            />

                            <FormHelperText
                              hidden={!formik.touched.installments || !formik.errors.installments}
                              error={formik.touched.installments && !!formik.errors.installments}
                            >
                              {formik.errors.installments}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography gutterBottom className={classes.subheader} data-content="Pagamento" />

                      <Grid container className={classes.confirmationBox} spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormGroup>
                            <InputLabel className={classes.labelInfo}>
                              Pagamento 1ª parcela
                            </InputLabel>

                            <DateInput
                              minDate={date(minDatePayment)}
                              maxDate={maxDatePayment}
                              value={datePayment}
                              title="Pagamento 1ª parcela"
                              error={formik.touched.firstInstallmentDueDateDelay
                                && !!formik.errors.firstInstallmentDueDateDelay}
                              helperText={formik.touched.firstInstallmentDueDateDelay
                                && formik.errors.firstInstallmentDueDateDelay}
                              onChange={(event) => {
                                handleDate(event)
                              }}
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <FormControlLabel
                      control={(
                        <GreenCheckbox
                          id="responsibilityConfirmation"
                          checked={checked}
                          {...formik.getFieldProps('responsibilityConfirmation')}
                          onChange={() => {
                            setChecked(!checked)
                            formik.values.responsibilityConfirmation = !checked
                          }}
                        />
                      )}
                    />

                    <Typography
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={() => {
                        addMsgSuccess({
                          title: objectsMaker.makeObjectTerm().title,
                          labelButton: 'Fechar',
                          message: objectsMaker.makeObjectTerm().message,
                          action: '',
                          maxWidth: 'md',
                        })
                      }}
                    >
                      Li e Aceito os Termos e Condições
                    </Typography>
                  </Grid>

                  <FormHelperText
                    hidden={
                      !formik.touched.responsibilityConfirmation
                      || !formik.errors.responsibilityConfirmation
                    }
                    error={
                      formik.touched.responsibilityConfirmation
                      && !!formik.errors.responsibilityConfirmation
                    }
                  >
                    {formik.errors.responsibilityConfirmation}
                  </FormHelperText>
                </Grid>

                <Grid item xs={12}>
                  <Box className={classes.buttonsStep}>
                    <Box>
                      <ButtonLight onClick={handlePrevious} title="Voltar">
                        Voltar
                      </ButtonLight>
                    </Box>

                    <>
                      <Box>
                        <ButtonLight onClick={viewDraft}>
                          Visualizar Minuta
                        </ButtonLight>
                      </Box>

                      <Box>
                        <ButtonLight id="policy" onClick={handleHireAnalysis}>
                          Solicitar Análise
                        </ButtonLight>
                      </Box>

                      <ModalAnalysisRequest
                        closeModal={closeModal}
                        openModal={openModalConfirmAnalysis}
                        handleConfirmation={handleConfirmationAnalysis}
                      />

                      <Box>
                        <ButtonGradient onClick={() => {
                          setIsDrafting(false)
                          formik.submitForm()
                        }}
                        >
                          Emitir Endosso
                        </ButtonGradient>
                      </Box>

                      <ModalPolicyEmission
                        isEndorsement
                        closeModal={closeModal}
                        openModal={openModalConfirmEndorsement}
                        handleConfirmation={handleConfirmEndorsement}
                      />
                    </>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  )
}

Payment.propTypes = {
  handlePrevious: PropTypes.func,
  endorsement: PropTypes.object.isRequired,
}

Payment.defaultProps = {
  handlePrevious: () => { },
}

export default Payment
