import React from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import makeStyles from '@material-ui/core/styles/makeStyles'

import { ButtonGradient, ModalInfo } from '../../../shared/components'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& .MuiBox-root': {
      minWidth: 190,
    },
    '@media (max-width: 699px)': {
      justifyContent: 'center',
    },
  },
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 600,
    },
  },
}))

const ModalPolicyEndorsementExpired = (props) => {
  const classes = useStyles()
  const { openModal, closeModal, statusPolicyDueOrCanceled } = props

  return (
    <>
      {openModal && (
        <ModalInfo
          close={false}
          open={openModal}
          className={classes.modal}
        >
          <Box p={2} alignItems="center" justifyContent="center">
            <Box>
              <Box mb={5}>
                <Typography align="center" variant="h4">{statusPolicyDueOrCanceled === 'Vencida' ? 'A apólice/endosso informado está vencido.' : 'A apólice/endosso informado está cancelado.'}</Typography>
              </Box>

              <Grid item xs={12}>
                <Box justifyContent="center" className={classes.buttonsStep}>
                  <Box>
                    <ButtonGradient
                      onClick={closeModal}
                    >
                      Fechar
                    </ButtonGradient>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Box>
        </ModalInfo>
      )}
    </>
  )
}

ModalPolicyEndorsementExpired.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  statusPolicyDueOrCanceled: PropTypes.string,
}
ModalPolicyEndorsementExpired.defaultProps = {
  statusPolicyDueOrCanceled: '',
}

export default ModalPolicyEndorsementExpired
