import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
  container: {
    height: 50,
    backgroundColor: '#F6F6F6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30,
    paddingLeft: 20,
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 10,
  },
  label: {
    color: '#3F4159',
    fontWeight: 900,
  },
  Icon: {
    marginRight: 5,
  },
}))

export default useStyles
