import { useCallback } from 'react'

import {
  STEP_TYPE_FIRST_TEMPLATE,
  STEP_TYPE_SECOND_TEMPLATE,
  STEP_TYPE_FIRST_OTHER_BROKERAGE_TEMPLATE,
  STEP_TYPE_SECOND_OTHER_BROKERAGE_TEMPLATE,
} from '../../constants/EndorsementConstants'

import {
  QUOTE_BID,
  QUOTE_LEASE_BAIL,
  QUOTE_PERFORMANCE,
  QUOTE_GUARANTEE_OTHER,
  QUOTE_JUDICIAL_APPEAL,
  QUOTE_ENGINEERING_RISK,
} from '../../constants'

const useEndorsementPolicyClient = () => {
  const getAll = useCallback(() => new Promise((resolve) => resolve([
    {
      template: { id: 'first', steps: STEP_TYPE_FIRST_TEMPLATE },
      quoteTypes: [
        QUOTE_LEASE_BAIL,
        QUOTE_ENGINEERING_RISK,
      ],
    },
    {
      template: { id: 'second', steps: STEP_TYPE_SECOND_TEMPLATE },
      quoteTypes: [
        QUOTE_BID,
        QUOTE_PERFORMANCE,
        QUOTE_JUDICIAL_APPEAL,
        QUOTE_GUARANTEE_OTHER,
      ],
    },
  ])), [])

  const getAllOtherBrokerage = useCallback(() => new Promise((resolve) => resolve([
    {
      template: { id: 'first', steps: STEP_TYPE_FIRST_OTHER_BROKERAGE_TEMPLATE },
      quoteTypes: [
        QUOTE_LEASE_BAIL,
        QUOTE_ENGINEERING_RISK,
      ],
    },
    {
      template: { id: 'second', steps: STEP_TYPE_SECOND_OTHER_BROKERAGE_TEMPLATE },
      quoteTypes: [
        QUOTE_BID,
        QUOTE_PERFORMANCE,
        QUOTE_JUDICIAL_APPEAL,
        QUOTE_GUARANTEE_OTHER,
      ],
    },
  ])), [])

  const findStepsByType = useCallback((
    quoteType,
    otherBrokerage,
  ) => new Promise((resolve) => {
    if (!otherBrokerage) {
      getAll().then((stepsEndorsement) => {
        const stepsEndorsementsFound = stepsEndorsement.find(
          (stepEndorsement) => stepEndorsement.quoteTypes.includes(quoteType?.product),
        )
        resolve(stepsEndorsementsFound?.template ?? {})
      })
    }
    if (otherBrokerage) {
      // eslint-disable-next-line sonarjs/no-identical-functions
      getAllOtherBrokerage().then((stepsEndorsement) => {
        const stepsEndorsementsFound = stepsEndorsement.find(
          (stepEndorsement) => stepEndorsement.quoteTypes.includes(quoteType?.product),
        )
        resolve(stepsEndorsementsFound?.template ?? {})
      })
    }
  }), [getAll, getAllOtherBrokerage])

  return useCallback(() => ({
    findStepsByType,
  }), [
    findStepsByType,
  ])
}

export default useEndorsementPolicyClient
