import { useCallback } from 'react'

import icoAir from '../../assets/img/icons/ico-air.svg'
import icoKey from '../../assets/img/icons/ico-key.svg'
import icoGas from '../../assets/img/icons/ico-gas.svg'
import icoIptu from '../../assets/img/icons/ico-iptu.svg'
import icoWater from '../../assets/img/icons/ico-water.svg'
import icoLight from '../../assets/img/icons/ico-light.svg'
import icoPromo from '../../assets/img/icons/ico-promo.svg'
import icoBuildings from '../../assets/img/icons/ico-buildings.svg'

const useQuotationRentChargeClient = () => {
  const getAll = useCallback(() => new Promise((resolve) => resolve([
    {
      id: 'valueLease',
      icon: icoKey,
      title: 'Aluguel',
      text: 'Informe o valor do Aluguel',
      value: 0,
      valueMultiple: '',
      idMultiple: 'multipleLease',
    },
    {
      id: 'valueIPTU',
      icon: icoIptu,
      title: 'IPTU',
      text: 'Gasto mensal do IPTU',
      value: 0,
      valueMultiple: '',
      idMultiple: 'multipleIPTU',
    },
    {
      id: 'valueCondominium',
      icon: icoBuildings,
      title: 'Condomínio',
      text: 'Gasto mensal do Condomínio',
      value: 0,
      valueMultiple: '',
      idMultiple: 'multipleCondominium',
    },
    {
      id: 'valueWater',
      icon: icoWater,
      title: 'Água',
      text: 'Gasto mensal do Água',
      value: 0,
      valueMultiple: '',
      idMultiple: 'multipleWater',
    },
    {
      id: 'valueGAS',
      icon: icoGas,
      title: 'Gás',
      text: 'Gasto mensal do Gás',
      value: 0,
      valueMultiple: '',
      idMultiple: 'multipleGAS',
    },
    {
      id: 'valueElectricEnergy',
      icon: icoLight,
      title: 'Energia',
      text: 'Gasto mensal do Energia',
      value: 0,
      valueMultiple: '',
      idMultiple: 'multipleElectricEnergy',
    },
    {
      id: 'valueAir',
      icon: icoAir,
      title: 'Ar-Condicionado',
      text: 'Apenas para contrato por shopping',
      value: 0,
      valueMultiple: '',
      idMultiple: 'multipleAir',
    },
    {
      id: 'valuePromotionalFund',
      icon: icoPromo,
      title: 'Fundo de Promoção',
      text: 'Apenas para contrato por shopping',
      value: 0,
      valueMultiple: '',
      idMultiple: 'multiplePromotionalFund',
    },
  ])), [])

  const getMultipleOne = useCallback(() => ([
    { value: 12, text: '12' },
    { value: 18, text: '18' },
    { value: 24, text: '24' },
    { value: 30, text: '30' },
  ]), [])

  const getMultipleTwo = useCallback(() => ([
    { value: 6, text: '6' },
    { value: 12, text: '12' },
  ]), [])

  return useCallback(() => ({
    getAll,
    getMultipleOne,
    getMultipleTwo,
  }), [
    getAll,
    getMultipleOne,
    getMultipleTwo,
  ])
}

export default useQuotationRentChargeClient
