import React from 'react'
import { useHistory } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import makeStyles from '@material-ui/core/styles/makeStyles'
import bgPortalUpdates from '../../../assets/img/backgrounds/bg-portal-updates.png'

const useStyles = makeStyles((theme) => ({
  linkHelp: {
    cursor: 'pointer',
    marginTop: 8,
    fontSize: 16,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  boxUpdate: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 24,
    justifyContent: 'center',
    color: '#fff',
    backgroundColor: '#2d4282',

    [theme.breakpoints.up('md')]: {
      marginLeft: 85,
    },
  },
  boxTextsUpdate: {
    '& .MuiTypography-root': {
      color: '#fff',
    },

    padding: 16,
    flexGrow: 1,
    height: 230,
    maxWidth: 580,
    background: 'linear-gradient(270deg, #1BD4ED -20.4%, #0F347D 124.8%)',

    [theme.breakpoints.down('lg')]: {
      maxWidth: 440,
    },
    '@media (max-width: 1195px)': {
      maxWidth: 'unset',
    },
    '@media (max-width: 1499px)': {
      height: 185,
    },
    '@media (min-width: 2000px)': {
      height: 300,
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      width: '100%',
      maxWidth: '100%',
    },
  },
  imgUpdates: {
    height: 230,
    backgroundImage: `url(${bgPortalUpdates})`,
    backgroundRepeat: 'no-repeat',

    backgroundSize: 'contain',
    backgroundPosition: 'left',

    '@media (max-width: 1499px)': {
      height: 185,
      backgroundSize: 'cover',
      backgroundPosition: 'left',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      backgroundSize: 'contain',
    },

    '@media (min-width: 2000px)': {
      height: 300,
    },
  },
  boxImg: {
    minWidth: 700,
    flexGrow: 5,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: '100%',
    },
  },
  textHelp: {
    fontWeight: '100',
    lineHeight: '1.2',
    fontSize: 16,

    [theme.breakpoints.down('lg')]: {
      fontSize: 13,
    },
  },
}))

const PortalUpdates = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box className={classes.boxUpdate}>
      <Box display="flex" flexDirection="column" justifyContent="space-evenly" className={classes.boxTextsUpdate}>
        <Box>
          <Typography variant="h6">
            <b>Atualizações do Portal</b>
          </Typography>
        </Box>

        <Box>
          <Typography variant="subtitle2" style={{ fontWeight: '100' }}>
            <i>Tutorial</i>
          </Typography>
        </Box>

        <Box>
          <Typography variant="subtitle1" className={classes.textHelp}>
            Para trazer mais agilidade ao seu dia a dia, a partir
            de agora será possível emitir seus endossos da modalidade
            de Garantia de Performance Pública
            (construção, fornecimento e prestação de serviços)
            diretamente aqui. Experimente agora mesmo a novidade!
          </Typography>
        </Box>

        <Box>
          <Typography
            className={classes.linkHelp}
            variant="h6"
            color="primary"
            onClick={() => history.push('/ajuda')}
          >
            Ir para
            &gt;
          </Typography>
        </Box>
      </Box>

      <Box className={classes.boxImg}>
        <Box className={classes.imgUpdates} />
      </Box>
    </Box>
  )
}

export default PortalUpdates
