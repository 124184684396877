import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import ViewerDocument from '../../../shared/components/ViewerDocument/ViewerDocument'

import usePolicyClient from '../../../clients/PolicyClient/usePolicyClient'

const TicketViewer = () => {
  const policyClient = usePolicyClient()

  const { billingId } = useParams()

  const [draftData, setDocumentData] = useState('')

  useEffect(() => {
    policyClient().getInvoiceDocument({
      billingId,
      base64: true,
    }).then((response) => {
      setDocumentData(response.data)
    })
  }, [billingId, policyClient])

  return (
    <ViewerDocument documentBase64={draftData} />
  )
}

export default TicketViewer
