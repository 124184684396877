import { useCallback } from 'react'

const useInsuredGuaranteeBidClient = () => {
  const getTypes = useCallback(() => [
    'Carta convite',
    'Carta convite internacional',
    'Coleta de preços',
    'Concorrência',
    'Concorrência eletrônica',
    'Concorrência nacional',
    'Concorrência pública',
    'Concorrência pública internacional',
    'Pregão eletrônico',
    'Pregão presencial',
    'Regime diferenciado de contratação',
    'Tomada de preços',
    'Tomada de preços internacional',
  ], [])

  return useCallback(() => ({
    getTypes,
  }), [
    getTypes,
  ])
}

export default useInsuredGuaranteeBidClient
