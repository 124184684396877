import React from 'react'
import clsx from 'clsx'
import { TextField, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import IntlCurrencyInput from 'react-intl-currency-input'

const Config = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        currency: 'BRL',
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}

const CustomIntlCurrencyInput = (props) => {
  const {
    name,
    value,
    inputRef,
    onChange,
    ...other
  } = props

  return (
    <IntlCurrencyInput
      {...other}
      autoSelect
      value={Number(value) ?? 0}
      currency="BRL"
      margin="normal"
      config={Config}
      onChange={(e, valueChange) => {
        onChange({
          target: {
            name,
            value: valueChange,
          },
        })
      }}
    />
  )
}

CustomIntlCurrencyInput.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

CustomIntlCurrencyInput.defaultProps = {
  value: 0,
}

const useStyles = makeStyles(() => ({
  inputDarkMode: {
    '& .MuiInputBase-root': {
      color: '#fff',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #fff',
    },
  },
}))

const CurrencyInput = (props) => {
  const classes = useStyles()
  const { darkMode } = props

  const dark = darkMode
  const inputProps = { ...props }
  delete inputProps.darkMode

  return (
    <TextField
      fullWidth
      {...inputProps}
      margin="normal"
      InputProps={{
        inputComponent: CustomIntlCurrencyInput,
      }}
      className={clsx(dark && classes.inputDarkMode)}
    />
  )
}

CurrencyInput.propTypes = {
  darkMode: PropTypes.bool,
}

CurrencyInput.defaultProps = {
  darkMode: false,
}

export default CurrencyInput
