import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  step: {
    width: 135,
    flexDirection: 'column',
    textAlign: 'center',
    '& .MuiStepLabel-labelContainer': {
      display: 'none',
    },
  },
  helpGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerStepper: {
    width: '100%',
    marginBottom: 0,
    '& .MuiPaper-root': {
      marginLeft: -40,
      padding: theme.spacing(3, 0),
      backgroundColor: 'transparent',
    },
    '& .MuiStepConnector-root': {
      margin: -50,
      maxWidth: 50,
      '&.MuiStepConnector-active': {
        '& .MuiStepConnector-line': {
          borderTopWidth: 4,
          borderImageSlice: 1,
          borderImage: 'linear-gradient(90deg, #8bf6aa 0%, #25a9cf 50%)',
        },
      },
      '&.MuiStepConnector-completed': {
        '& .MuiStepConnector-line': {
          borderColor: '#49ecae',
        },
      },
      '& .MuiStepConnector-line': {
        borderTopWidth: 4,
        borderColor: theme.palette.text.secondary,
      },
    },
  },
  stepper: {
    [theme.breakpoints.down('xs')]: {
      width: '110vw',
    },
  },
}))
