import React, { useContext, useMemo } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ButtonGradient from '../../shared/components/Button/ButtonGradient'

import icoTime from '../../assets/img/icons/ico-time.svg'
import icoError from '../../assets/img/icons/ico-error-status.svg'
import { AuthContext } from '../../security/Auth'
import useUtils from '../../shared/hooks/useUtils'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '90%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  image: {
    maxWidth: '100%',
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  status: {
    top: 86,
    left: 111,
    position: 'relative',
    fontSize: '2.4rem',
    fontWeight: '900',
    color: theme.palette.primary.main,
    fontFamily: 'Arial, Helvetica, sans-serif',
  },
  textError: {
    fontSize: 16,
    fontWeight: 'normal',
    color: theme.palette.text.tertiary,
  },
}))

const Error = () => {
  const classes = useStyles()
  const { status } = useParams()
  const location = useLocation()
  const { formatDate, formatHours } = useUtils()

  const {
    maintenanceValue,
  } = useContext(AuthContext)

  const externalError = useMemo(() => {
    const search = new URLSearchParams(location.search)
    const data = search.get('external')
    return data === ''
  }, [location])

  const errorTime = useMemo(() => {
    const search = new URLSearchParams(location.search)
    const data = search.get('time')
    return data === ''
  }, [location])

  const login = useMemo(() => {
    const search = new URLSearchParams(location.search)
    const data = search.get('login')
    return data === ''
  }, [location])

  return (
    <>
      <Box className={classes.root}>
        {!errorTime && (
          <>
            <Box component="span" className={classes.status}>{status}</Box>

            <img
              src={icoError}
              alt="Under development"
              className={classes.image}
            />
          </>
        )}

        {errorTime && (
          <>
            <img
              alt="Time"
              width={350}
              src={icoTime}
            />
          </>
        )}

        <Typography variant="h1" className={classes.title}>Oops!</Typography>

        <Box my={5}>
          <Typography variant="h5" className={classes.textError}>
            {status !== '403' && (
              <>
                {status === '503' ? (
                  <Box>
                    {
                      maintenanceValue?.Avaliable === false && (
                        <>
                          Olá! No momento o portal está passando por uma manutenção
                          sistêmica com previsão de normalização no dia &nbsp;
                          {formatDate(maintenanceValue?.ExpectedAvailabilityDate)}
                          &nbsp;às&nbsp;
                          {formatHours(maintenanceValue?.ExpectedAvailabilityDate)}
                          . Pedimos desculpas pelo transtorno.
                        </>
                      )
                    }
                    {
                      maintenanceValue === 'maintenance' && (
                        <>
                          No momento o portal está passando por uma manutenção sistêmica.
                          Favor tentar novamente mais tarde. Pedimos desculpas pelo transtorno
                        </>
                      )
                    }
                  </Box>
                ) : (!errorTime && (
                  <Box>Desculpe, algo deu errado. Que tal tentar novamente mais tarde?</Box>
                ))}

                {login && (
                  <>
                    <Box component="span">
                      Tente novamente
                    </Box>

                    <Button
                      to="/"
                      variant="text"
                      color="primary"
                      component={Link}
                      title="clicando aqui"
                      className={classes.button}
                    >
                      clicando aqui
                    </Button>

                    <Box component="span">
                      ou se o erro persistir,
                      entre em contato conosco.
                    </Box>
                  </>
                )}

                {errorTime && (
                  <>
                    <Box>
                      Identificamos que o seu relógio está diferente do servidor.
                    </Box>
                    <Box>
                      Por favor ajuste as configurações de
                      hora do seu dispositivo e tente novamente.
                    </Box>
                  </>
                )}
              </>
            )}

            {status === '403' && (
              <>
                <Box>
                  Você não tem permissão para acessar essa funcionalidade.
                </Box>

                <Box>
                  Entre em contato com o Administrador da Corretora ou retorne
                  para o Portal clicando no botão a baixo.
                </Box>
              </>
            )}
          </Typography>
        </Box>

        {!externalError && (
          <Box my={5}>
            <ButtonGradient
              to="/"
              color="primary"
              variant="contained"
              component={Link}
              title="Voltar a página inicial"
            >
              Voltar a página inicial
            </ButtonGradient>
          </Box>
        )}
      </Box>
    </>
  )
}

export default Error
