import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import reportWebVitals from './reportWebVitals'

import App from './App'
import { store, persist } from './store'
import { AuthProvider } from './security/Auth'
import AppTheme from './shared/layouts/AppTheme'
import ErrorBoundary from './shared/components/ErrorBoundary/ErrorBoundary'

ReactDOM.render(
  <>
    <Provider store={store}>
      <PersistGate persistor={persist}>
        <AuthProvider>
          <ErrorBoundary>
            <AppTheme>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </AppTheme>
          </ErrorBoundary>
        </AuthProvider>
      </PersistGate>
    </Provider>
  </>,
  document.getElementById('root'),
)

reportWebVitals()
