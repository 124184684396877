import React, {
  useEffect, useCallback, useState,
} from 'react'
import PropTypes from 'prop-types'
import {
  head,
  isArray,
  isEmpty,
  isString,
} from 'lodash'
import Alert from '@material-ui/lab/Alert'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'

import MessageBox from '../MessageBox'

const DEFAULT_ID = 'global'

const AlertProvider = (props) => {
  const { id } = props
  const [type, setType] = useState()
  const [text, setText] = useState()
  const [titleValue, setTitleValue] = useState()
  const [opened, setOpened] = useState()
  const [action, setAction] = useState()
  const [label, setLabel] = useState('Fechar')
  const [global, setGlobal] = useState(true)
  const [messages, setMessages] = useState()
  const [maxWidthValue, setMaxWidthValue] = useState('xs')

  const cleanAlert = useCallback(() => setOpened(false), [])

  const addAlert = useCallback((data, alertType) => {
    setAction({ run: () => { } })
    setType(alertType)
    setMessages()
    setText()
    setTitleValue()
    setMaxWidthValue()

    // string
    if (isString(data)) {
      setText(data)

      // array and length one
    } else if (isArray(data) && data.length === 1) {
      const value = head(data)
      setText(value.message)

      if (value.title) {
        setTitleValue(value.title)
      }

      if (value.maxWidth) {
        setMaxWidthValue(value.maxWidth)
      }

      // array and multiples itens
    } else if (isArray(data)) {
      setMessages(data)

      // object
    } else {
      const {
        title,
        message,
        labelButton,
        maxWidth,
        action: handle,
      } = data

      const actionRun = !handle ? () => { } : handle

      setAction({ run: actionRun })
      setText(message)

      if (title) {
        setTitleValue(String(title))
      }

      if (maxWidth) {
        setMaxWidthValue(maxWidth)
      }

      if (labelButton) {
        setLabel(labelButton)
      }
    }

    setOpened(true)
  }, [])

  useEffect(() => setGlobal(id === DEFAULT_ID), [id])

  useEffect(() => {
    const error = (event) => addAlert(event?.detail, 'error')
    window.addEventListener(`${id}.alert.danger`, error)

    const success = (event) => addAlert(event?.detail, 'success')
    window.addEventListener(`${id}.alert.success`, success)

    const warning = (event) => addAlert(event?.detail, 'warning')
    window.addEventListener(`${id}.alert.warning`, warning)

    const clean = () => cleanAlert()
    window.addEventListener(`${id}.alert.clean`, clean)

    return () => {
      window.removeEventListener(`${id}.alert.danger`, error)
      window.removeEventListener(`${id}.alert.success`, success)
      window.removeEventListener(`${id}.alert.warning`, warning)
      window.removeEventListener(`${id}.alert.clean`, clean)
    }
  }, [id, addAlert, cleanAlert])

  const handleClose = () => {
    setOpened(false)
    action.run()
  }

  const messagesRender = () => (
    <Typography variant="body2" component="div">
      <ul>
        {messages.filter((item) => !isEmpty(item.message)).map(
          (item, index) => (
            <li key={index}>
              <span dangerouslySetInnerHTML={{ __html: item.message }} />
            </li>
          ),
        )}
      </ul>
    </Typography>
  )

  return (
    <>
      {global && (
        <MessageBox
          type={type}
          text={text}
          title={titleValue}
          opened={opened}
          labelPrimary={label}
          handleClose={handleClose}
          handlePrimary={handleClose}
          maxWidth={maxWidthValue}
        >
          {messages && messagesRender()}
        </MessageBox>
      )}

      {!global && (
        <Collapse in={opened}>
          <Alert
            severity={type}
            closeText="Fechar"
            onClose={handleClose}
          >
            {messages && messagesRender()}
            {!isEmpty(text) && (
              <span dangerouslySetInnerHTML={{ __html: text }} />)}
          </Alert>
        </Collapse>
      )}
    </>
  )
}

export const useAlert = (id = DEFAULT_ID) => {
  const addMsg = useCallback((detail, event) => {
    if (isEmpty(detail)) {
      return
    }

    const customEvent = new CustomEvent(event, { detail })
    window.dispatchEvent(customEvent)
  }, [])

  const addMsgSuccess = useCallback((data) => {
    addMsg(data, `${id}.alert.success`)
  }, [id, addMsg])

  const addMsgWarning = useCallback((data) => {
    addMsg(data, `${id}.alert.warning`)
  }, [id, addMsg])

  const addMsgDanger = useCallback((data) => {
    addMsg(data, `${id}.alert.danger`)
  }, [id, addMsg])

  const cleanMsg = useCallback(() => {
    const customEvent = new CustomEvent(`${id}.alert.clean`)
    window.dispatchEvent(customEvent)
  }, [id])

  return {
    cleanMsg,
    addMsgDanger,
    addMsgWarning,
    addMsgSuccess,
  }
}

AlertProvider.propTypes = {
  id: PropTypes.string,
}

AlertProvider.defaultProps = {
  id: DEFAULT_ID,
}

export default AlertProvider
