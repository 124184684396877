export const PREENCHIMENTO_OBRIGATORIO = 'Preenchimento obrigatório'
export const VALOR_INVALIDO = 'Valor inválido'
export const EMAIL_INVALIDO = 'E-mail inválido'
export const NAO_DEFINIDO = 'Não definido'
export const NO_OPTIONS_MESSAGE = 'NENHUM REGISTRO ENCONTRADO.'
export const DATA_FINAL_MAIOR_INICIAL = 'A data final deve ser maior que a data inicial.'
export const VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR = 'Verifique os campos em destaque antes de prosseguir.'
export const ERRO_FIREBASE_SENHA = 'A senha é inválida ou o usuário não possui uma senha. Caso deseje redefinir sua senha, '
export const ERRO_SENHA_EXPIRADA = 'Senha expirada. Verifique sua caixa de e-mail para redefinição de senha. Caso deseje redefinir novamente, '
export const ACCESS_EXPIRED = 'Acesso expirado. Para novo acesso contate o Executivo.'
export const ERRO_USUARIO_INEXISTENTE = 'Não há registro de usuário correspondente a este identificador. O usuário pode ter sido excluído.'
export const INCREASE_IS_REDUCTION_GUARANTEE = '* Favor informar novo valor de importância segurada de acordo com tipo de endosso informado.'
export const EQUAL_TO_POLICY_HOLDER = '* O CNPJ do segurado não pode ser igual ao do Tomador.'
export const LIMIT_DATA_SUMMARY = 'Limite máximo de pesquisa 1 ano.'
export const EXPIRED_PASSWORD = 'Senha expirada. Favor verificar sua caixa de e-mail para redefinição de senha.'

export const CAMPO_MAIOR_ZERO = (campo) => {
  const campoConcat = campo ? ` ${campo}` : ''
  return `O campo${campoConcat} deverá ser maior que zero.`
}
export const LIMIT_MAX_CHARACTER = (max) => {
  const limit = max ? ` ${max}` : ''
  return `* O limite máximo é de ${limit} caracteres!`
}
export const DESCRIPTION_TIME_TICKET = (campo) => {
  const campoConcat = campo ? ` ${campo}` : ''
  return `O boleto pode demorar${campoConcat} minutos para ser disponibilizado após a emissão da apólice.`
}

const Global = {
  NAO_DEFINIDO,
  EMAIL_INVALIDO,
  VALOR_INVALIDO,
  CAMPO_MAIOR_ZERO,
  NO_OPTIONS_MESSAGE,
  LIMIT_DATA_SUMMARY,
  LIMIT_MAX_CHARACTER,
  EQUAL_TO_POLICY_HOLDER,
  DATA_FINAL_MAIOR_INICIAL,
  INCREASE_IS_REDUCTION_GUARANTEE,
  PREENCHIMENTO_OBRIGATORIO,
  VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR,
  ERRO_FIREBASE_SENHA,
  ERRO_USUARIO_INEXISTENTE,
  DESCRIPTION_TIME_TICKET,
}

export default Global
