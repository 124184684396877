import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'

import makeStyles from '@material-ui/core/styles/makeStyles'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isEqual, isEmpty } from 'lodash'

import { usePrice } from '../../../../../shared/contexts/PriceContext'

import {
  ButtonLight,
  CurrencyInput,
  ButtonGradient,
} from '../../../../../shared/components'
import DropdownSelectOne from '../../../../../shared/components/DropdownSelectOne/DropdownSelectOne'
import { GlobalMessages } from '../../../../../shared/messages'
import CollapsePrice from '../../../../../shared/components/CollapsePrice/CollapsePrice'
import useUtils from '../../../../../shared/hooks/useUtils'

import {
  JUDICIAL_APPEAL_INTERLOCUTORY,
  DEPOSIT_VALUE_APPEAL_EMBARGOES,
  JUDICIAL_APPEAL_ORDINARY_RESOURCE,
  DEPOSIT_VALUE_APPEAL_INTERLOCUTORY,
} from '../../../../../constants'

import ValuesDeadlineData from '../../../../../models/JudicialGuaranteeAppealModels/ValuesDeadlineData'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
}))

const ValuesDeadlines = forwardRef((props, ref) => {
  const classes = useStyles()
  const {
    quote,
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props

  const {
    onNext,
    actionNext,
    setFormikChanged,
    resourceTypeProduct,
    judicialGuaranteeAppeal,
    setJudicialGuaranteeAppeal,
  } = usePrice()

  const { formatCurrencyBRLToDecimal } = useUtils()

  const initialValuesForm = useMemo(() => (Object.assign(
    ValuesDeadlineData({
      ...quote?.ValuesDeadlines,
      AppealDepositValue: quote?.ValuesDeadlines?.AppealDepositValue
        ? quote?.ValuesDeadlines?.AppealDepositValue
        : undefined,
    } ?? {}),
  )), [quote?.ValuesDeadlines])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesForm,
    validationSchema: Yup.object({
      deadline: Yup.number(),
      percentageHarm: Yup.number(),
      appealDepositValue: Yup.number()
        .test('appealDepositValue', GlobalMessages.CAMPO_MAIOR_ZERO('Valor de Depósito Recursal'), (value) => (value && Number(value) !== 0))
        .required(),
      insuredImportance: Yup.number()
        .test('insuredImportance', GlobalMessages.CAMPO_MAIOR_ZERO('Importância Segurada'), (value) => (value && Number(value) !== 0))
        .required(),
    }),
    onSubmit: (data) => {
      const values = {
        ...judicialGuaranteeAppeal,
        ...{ valuesDeadlines: { ...data } },
      }
      setJudicialGuaranteeAppeal(values)

      if (actionNext) {
        setFormikChanged(!isEqual(initialValuesForm, data))
        onNext(values)
      }
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  const { setValues, handleSubmit } = formik

  const [DEADLINE_DEFAULT] = useState(3)
  const [PERCENTUAL_HARM] = useState(30)
  const [DEADLINE_YEARS] = useState([3, 4, 5])
  const [appealDepositValue, setAppealDepositValue] = useState(0)
  const [insuredImportanceValue, setInsuredImportanceValue] = useState(0)

  const handleAppealDepositValue = useCallback((event) => {
    const { value, valueConst } = event.target
    const fragmentData = quote?.ValuesDeadlines
    const valueDecimal = fragmentData?.AppealDepositValue
      ?? valueConst ?? formatCurrencyBRLToDecimal(value)
    setAppealDepositValue(valueDecimal)

    const deadlineCount = (((valueDecimal / 100) * PERCENTUAL_HARM) + valueDecimal)
    setInsuredImportanceValue(Number(deadlineCount.toFixed(2)))

    const settedValues = setValues({
      deadline: fragmentData?.Deadline ?? DEADLINE_DEFAULT,
      percentageHarm: fragmentData?.PercentageHarm ?? PERCENTUAL_HARM,
      appealDepositValue: Number(valueDecimal) ?? 0,
      insuredImportance: Number(deadlineCount.toFixed(2)) ?? 0,
    })

    if (fragmentData) {
      settedValues.then(handleSubmit)
    }
  }, [
    PERCENTUAL_HARM,
    DEADLINE_DEFAULT,
    quote,
    setValues,
    handleSubmit,
    setInsuredImportanceValue,
    formatCurrencyBRLToDecimal,
  ])

  useEffect(() => {
    if ([JUDICIAL_APPEAL_ORDINARY_RESOURCE, JUDICIAL_APPEAL_INTERLOCUTORY]
      .includes(resourceTypeProduct)) {
      handleAppealDepositValue({ target: { valueConst: DEPOSIT_VALUE_APPEAL_INTERLOCUTORY } })
    } else {
      handleAppealDepositValue({ target: { valueConst: DEPOSIT_VALUE_APPEAL_EMBARGOES } })
    }
  }, [
    resourceTypeProduct,
    handleAppealDepositValue,
  ])

  const deadlineLabel = 'Prazo (anos)'
  const insuredImportanceLabel = 'Importância Segurada'
  const percentageHarmLabel = 'Percentual de Agravo (%)'
  const appealDepositValueLabel = 'Valor de Depósito Recursal'

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={isEmpty(quote?.ValuesDeadlines) && !formik.isSubmitting}
        title="Valores e Prazos"
        subtitle="Informe abaixo os valores e prazos"
      >
        <Grid container spacing={5}>
          <Grid item sm={3} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="appealDepositValueLabel" className={classes.label}>{appealDepositValueLabel}</label>
              <CurrencyInput
                value={appealDepositValue}
                id="appealDepositValueLabel"
                title={appealDepositValueLabel}
                {...formik.getFieldProps('appealDepositValue')}
                error={
                  formik.touched.appealDepositValue
                  && !!formik.errors.appealDepositValue
                }
                helperText={
                  formik.touched.appealDepositValue
                  && formik.errors.appealDepositValue
                }
                onKeyPress={handleAppealDepositValue}
                onBlur={handleAppealDepositValue}
              />
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="percentageHarmLabel" className={classes.label}>{percentageHarmLabel}</label>
              <Input
                disabled
                fullWidth
                color="secondary"
                id="percentageHarm"
                value={PERCENTUAL_HARM}
                {...formik.getFieldProps('percentageHarm')}
                error={formik.touched.percentageHarm && !!formik.errors.percentageHarm}
                helpertext={formik.touched.propertyDeadline && formik.errors.propertyDeadline}
              />
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="insuredImportanceLabel" className={classes.label}>{insuredImportanceLabel}</label>
              <CurrencyInput
                disabled
                id="insuredImportance"
                value={insuredImportanceValue}
                title={insuredImportanceLabel}
                {...formik.getFieldProps('insuredImportance')}
                error={
                  formik.touched.insuredImportance
                  && !!formik.errors.insuredImportance
                }
                helperText={
                  formik.touched.insuredImportance
                  && formik.errors.insuredImportance
                }
                onChange={() => {
                  setInsuredImportanceValue(insuredImportanceValue)
                }}
              />
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="deadlineLabel" className={classes.label}>{deadlineLabel}</label>
              <DropdownSelectOne
                id="deadline"
                options={DEADLINE_YEARS}
                placeholder="Selecione"
                {...formik.getFieldProps('deadline')}
                error={formik.touched.deadline && !!formik.errors.deadline}
                helpertext={formik.touched.deadline && formik.errors.deadline}
                onChange={(e) => {
                  const { value } = e.target
                  formik.setFieldValue('deadline', value)
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.buttonsStep}>
              <Box>
                <ButtonLight
                  onClick={handlePrevious}
                  title="Voltar"
                >
                  Voltar
                </ButtonLight>
              </Box>
              <Box ml={4}>
                <ButtonGradient
                  onClick={handleNext}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
            <ReactTooltip place="top" type="dark" />
          </Grid>
        </Grid>
      </CollapsePrice>
    </>
  )
})

ValuesDeadlines.propTypes = {
  quote: PropTypes.any,
  checked: PropTypes.bool,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

ValuesDeadlines.defaultProps = {
  checked: false,
  quote: undefined,
}

export default ValuesDeadlines
