import React from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Container from '@material-ui/core/Container'

import makeStyles from '@material-ui/core/styles/makeStyles'

import TitlePage from './TitlePage'
import HeaderMenu from './HeaderMenu'

const useStyles = makeStyles((theme) => ({
  root: (props) => {
    const styles = {
      color: theme.palette.primary.contrastText,
      backgroundSize: 'cover',
      backgroundPosition: 'center 25%',
      '& .MuiContainer-root': {
        display: 'flex',
        height: theme.sizes.header,
      },
      '& .MuiTypography-h4': {
        fontSize: '1.9rem',
        color: theme.palette.primary.contrastText,
      },
    }

    if (props.isError) {
      styles.backgroundColor = '#F6F6F6'
    } else {
      styles.animation = 'fadein 0.5s'
      styles.backgroundImage = `linear-gradient(to left, #19BDDDCC, ${theme.palette.primary.main}CC), url(${props.bgImage})`
    }

    return styles
  },
  main: {
    height: '100vh',
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(0, 8),
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 12),
    },
  },
  title: {
    display: 'flex',
    width: 160,
    '& img': {
      maxWidth: '100%',
    },
  },
  containerMenu: (props) => {
    const styles = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row-reverse',

      [theme.breakpoints.down('lg')]: {
        marginLeft: 'auto',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    }

    if (props.isError) {
      styles.zIndex = 1
    }

    return styles
  },
  clientLogo: {
    maxHeight: 50,
    maxWidth: 180,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}))

const Header = (props) => {
  const {
    crumbs,
    logout,
    bgImage,
    isError,
    openModalPortalUpdates,
    closeModalRegistrationUpdate,
  } = props

  const classes = useStyles({ bgImage, isError })

  return (
    <>
      <header
        className={classes.root}
      >
        <Container maxWidth="xl" className={classes.main}>
          <Grid container alignItems="center">
            <Grid
              md
              item
              xs="auto"
              sm="auto"
              className={classes.containerMenu}
            >
              <Hidden smDown>
                <Box className={classes.headerMenu}>
                  <HeaderMenu
                    logout={logout}
                    openModalPortalUpdates={openModalPortalUpdates}
                    closeModalRegistrationUpdate={closeModalRegistrationUpdate}
                  />
                </Box>
              </Hidden>
              <Box
                hidden={isError}
                className={classes.headerMenu}
              >
                <TitlePage crumbs={crumbs} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </header>
    </>
  )
}

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  bgImage: PropTypes.any.isRequired,
  crumbs: PropTypes.array.isRequired,
  isError: PropTypes.bool.isRequired,
  openModalPortalUpdates: PropTypes.bool.isRequired,
  closeModalRegistrationUpdate: PropTypes.func.isRequired,
}

export default Header
