import React from 'react'
import { isEmpty } from 'lodash'
import { PropTypes } from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { Error } from '@material-ui/icons'

import useQuotationClient from '../../../../clients/QuotationClient/useQuotationClient'
import { ReactComponent as DownloadIcon } from '../../../../assets/img/icons/ico-download.svg'

import useDownload from '../../../../shared/hooks/useDownload'
import { useAlert } from '../../../../shared/components'
import useUtils from '../../../../shared/hooks/useUtils'

import {
  STATUS_PROPOSAL_DONE,
  STATUS_PROPOSAL_EXPIRED,
  STATUS_PROPOSAL_ACCEPTED,
  STATUS_PROPOSAL_REQUESTED,
  STATUS_QUOTE_DENIED,
  STATUS_QUOTE_EXPIRED,
  STATUS_QUOTE_APPROVED,
  COLOR_ERROR,
} from '../../../../constants'

import useStyles from './styles/containerFields'

const productLabel = 'Produto:'
const modalityLabel = 'Modalidade:'

const policyHolderLabel = 'Tomador:'
const cpfcnpjInsuredLabel = 'CPF/CNPJ Segurado (Tribunal):'
const nameInsuredLabel = 'Segurado (Tribunal):'

const cpfcnpjObjectPropertyLabel = 'CPF/CNPJ do Reclamante:'
const nameObjectPropertyLabel = 'Reclamante:'

const stickLabel = 'Vara Cível:'
const deadlineLabel = 'Prazo (Anos):'
const nameProposalLabel = 'Ação Proposta:'

const processNumberLabel = 'Número do Processo:'
const endDateValidityVigLabel = 'Fim de Vigência:'
const startDateValidityLabel = 'Início de Vigência:'
const insuredGuaranteeValueLabel = 'Valor da Garantia:'

const objectGuaranteeLabel = 'Obrigação Garantia (Objeto):'
const courtRegionalLaborLabel = 'Tribunal Cível:'

const noteLabel = 'Observações'
const requesterLabel = 'Nome do Solicitante'
const requesterEmailLabel = 'E-mail do Solicitante'
const requesterPhoneLabel = 'Telefone do Solicitante'
const idClientControlLabel = 'Identificador do Controle do Cliente'

const LabelDataExpiracao = 'Data de Expiração'
const LabelDataLiberacao = 'Data de Liberação'
const LabelDataNegacao = 'Data Negação'

const formaDePagamento = 'Forma de Pagamento'
const premioLiquido = 'Prêmio Líquido'
const premioTotal = 'Prêmio Total'
const parcelas = 'Parcelas'
const pagamentoPrimeiraParcela = 'Pagamento 1ª Parcela'

const textQuoteDenied = 'Prezado cliente, após análise técnica e politicas de subscrição da seguradora, não será possível prosseguir com a emissão desta solicitação. Para mais detalhes, entre em contato com seu executivo comercial.'

const ContainerFields = (props) => {
  const classes = useStyles()
  const { quotation, quotationStatus, proposal } = props

  const quotationClient = useQuotationClient()

  const { addMsgDanger } = useAlert()
  const { downloadPDF } = useDownload()

  const {
    formatDate,
    formatCurrency,
    formatCPFOrCNPJ,
    formatPhoneOrCellphone,
  } = useUtils()

  const isProposal = () => !isEmpty(quotationStatus) && ([
    STATUS_PROPOSAL_REQUESTED,
    STATUS_PROPOSAL_DONE,
    STATUS_PROPOSAL_ACCEPTED,
    STATUS_PROPOSAL_EXPIRED,
  ].includes(quotationStatus))

  const downloadDocuments = (document) => {
    quotationClient().downloadDocumentsWings(document).then((response) => {
      downloadPDF(response?.data, document)
    }, () => {
      addMsgDanger('Não foi possível baixar o arquivo, tente mais tarde ou contate o Administrador.')
    })
  }

  return (
    <>
      {
        quotationStatus === STATUS_QUOTE_DENIED && (
          <Grid container>
            <Grid item xs={12} className={classes.text}>
              <Box display="flex">
                <Box mr={1}>
                  <Error style={{ color: COLOR_ERROR }} />
                </Box>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {textQuoteDenied}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                className={classes.header}
                data-content="Cotação Negada"
              />
              <Grid container className={classes.confirmationBox} spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {LabelDataNegacao}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatDate(proposal?.quoteDenied?.DeniedAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
      {
        quotationStatus === STATUS_QUOTE_EXPIRED && (
          <Grid container className={classes.root}>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                className={classes.header}
                data-content="Cotação Expirada"
              />
              <Grid container className={classes.confirmationBox} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {LabelDataExpiracao}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatDate(proposal?.quote.ExpiredAt)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {LabelDataLiberacao}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatDate(proposal?.quote.CreatedAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Detalhes do Produto"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {productLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Product?.Name ?? 'Garantia Judicial'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {modalityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                Judicial Cível
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Dados do Segurado"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {policyHolderLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.PolicyHolder.Name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {cpfcnpjInsuredLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCPFOrCNPJ(quotation?.Insured.Document)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {nameInsuredLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Insured.Name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Objeto Segurado"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {cpfcnpjObjectPropertyLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCPFOrCNPJ(quotation?.Claimant.Document)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {nameObjectPropertyLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Claimant.Name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {processNumberLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.ProcessNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {courtRegionalLaborLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty?.CourtRegionalLabor?.descricao}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {stickLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty?.CourtSection?.descricao}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {nameProposalLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.ProposedLegalAction}
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {insuredGuaranteeValueLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.ObjectProperty.GuaranteeValue)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {startDateValidityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatDate(quotation?.ObjectProperty.StartDateValidity)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {endDateValidityVigLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatDate(quotation?.ObjectProperty.EndDateValidity)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {deadlineLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.Deadline}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {objectGuaranteeLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.ObjectText}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Solicitante"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {requesterLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.Name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {requesterPhoneLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatPhoneOrCellphone(quotation?.Requester.Phone)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {requesterEmailLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.Email}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} hidden={!quotation?.Requester.CustomerControlIdentifier}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {idClientControlLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.CustomerControlIdentifier}
              </Typography>
            </Grid>
            <Grid item xs={12} hidden={!quotation?.Requester.Note}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {noteLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.Note}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.containerFileUpload}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Arquivos"
          />
          <Grid className={classes.fileUpload}>
            <Grid item xs={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                Nome do Arquivo:
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                Download:
              </Typography>
            </Grid>
          </Grid>
          {(quotation?.FileUpload?.Documents ?? quotation?.FileUpload)
            ?.map((item, index) => (
              <Grid key={index} item xs={12} className={classes.fileUpload}>
                <Grid item xs={6}>
                  <Typography className={classes.confirmationValue}>
                    {item?.DocumentName}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Box>
                    <Button
                      variant="text"
                      onClick={() => downloadDocuments(item?.DocumentID)}
                    >
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <Box mr={1}>
                          <DownloadIcon />
                        </Box>

                        <Box sx={{ textDecoration: 'underline', fontSize: 17 }}>
                          <b>Download</b>
                        </Box>
                      </Box>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            ))}
        </Grid>
        {
          isProposal() && (
            <Grid item xs={12}>
              <Typography
                gutterBottom
                className={classes.header}
                data-content="Forma de Pagamento"
              />
              <Grid container className={classes.confirmationBox} spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {formaDePagamento}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    Boleto
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {premioLiquido}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatCurrency(proposal?.quote?.CommercialPremium)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {premioTotal}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatCurrency(proposal?.quote?.GrossPremium)}
                  </Typography>
                </Grid>

                {
                  quotationStatus !== STATUS_QUOTE_APPROVED && (
                    <>
                      <Grid item xs={12} md={4}>
                        <Typography gutterBottom className={classes.confirmationLabel}>
                          {parcelas}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.confirmationValue}>
                          {proposal?.proposal?.Request?.Payment.Installments}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography gutterBottom className={classes.confirmationLabel}>
                          {pagamentoPrimeiraParcela}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.confirmationValue}>
                          {proposal?.proposal?.Request?.Payment.FirstInstallmentDueDateDelay}
                        </Typography>
                      </Grid>
                    </>
                  )
                }
              </Grid>
            </Grid>
          )
        }
      </Grid>
    </>
  )
}

ContainerFields.propTypes = {
  quotation: PropTypes.any.isRequired,
  proposal: PropTypes.any.isRequired,
  quotationStatus: PropTypes.any,
}
ContainerFields.defaultProps = {
  quotationStatus: undefined,
}

export default ContainerFields
