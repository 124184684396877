import React from 'react'
import PropTypes from 'prop-types'
import { isObject } from 'lodash'
import { Select, FormHelperText, MenuItem } from '@material-ui/core'

import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  customSelect: {
    '& select': {
      [theme.breakpoints.down('xs')]: {
        WebkitAppearance: 'none',
      },
    },
  },
  item: {
    [theme.breakpoints.down('xs')]: {
      whiteSpace: 'normal',
      maxWidth: '100vw',
      marginLeft: 10,
      marginBottom: 5,
      borderBottom: '1px solid #8080802e',
      '& li': {
        WebkitAppearance: 'none',
      },
    },
  },
}))
const DropdownSelectOne = (props) => {
  const {
    id, options, color, error, helpertext,
  } = props

  const classes = useStyles()

  return (
    <>
      <Select
        {...props}
        htmlFor={id}
        color={color}
        error={error}
        className={classes.customSelect}
        MenuProps={{
        }}
      >
        {
          options.map((option, index) => (
            <MenuItem
              key={index}
              style={{ whiteSpace: 'normal' }}
              className={classes.item}
              value={!isObject(option) ? option : option?.value ?? option?.text}
            >
              {!isObject(option) ? option : option.text}
            </MenuItem>
          ))
        }
      </Select>
      <FormHelperText hidden={!error} error={error}>{helpertext}</FormHelperText>
    </>
  )
}

DropdownSelectOne.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  error: PropTypes.bool,
  options: PropTypes.array,
  helpertext: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
}

DropdownSelectOne.defaultProps = {
  color: 'secondary',
  error: false,
  options: [''],
  helpertext: '',
  label: '',
  name: undefined,
  onChange: () => { },
}

export default DropdownSelectOne
