import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  btn: {
    border: 'none',
    width: '100%',
    height: 55,
    borderRadius: 33,
    fontSize: 16,
    fontWeight: 'bold',
    cursor: 'pointer',
    backgroundSize: '200%',
    transition: '0.4s',
    '&:hover': {
      backgroundPosition: 'right',
    },
  },
}))

export default useStyles
