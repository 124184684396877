import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  root: {
    '& .MuiCollapse-root': {
      borderRadius: 14,
    },
  },
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  header: {
    height: 120,
    padding: 40,
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: theme.spacing(4, 5),
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  labelObject: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  subLabel: {
    display: 'flex',
    marginTop: '10px',
  },
  previewChip: {
    minWidth: 200,
    maxWidth: 400,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    cursor: 'pointer',
  },
  noOptionsLabel: {
    fontSize: 14,
    color: '#c93952',
    fontWeight: 'bold',
  },
  autocompleteStyles: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}))
