import React, { useContext } from 'react'

const BreadcrumbContext = React.createContext()

export const useBreadcrumb = () => {
  const {
    titlePage,
    setTitlePage,
  } = useContext(BreadcrumbContext)

  return {
    titlePage,
    setTitlePage,
  }
}

export default BreadcrumbContext
