const InsuredObjectPropertyData = ({
  CEP,
  City,
  Place,
  State,
  Number: NumberData,
  Document,
  District,
  Claimant,
  Complement,
  CourtSection,
  ProcessNumber,
  CourtRegionalLabor,
  ObjectText,
}) => ({
  cep: CEP ?? '',
  city: City ?? '',
  place: Place ?? '',
  state: State ?? '',
  number: NumberData ?? '',
  document: Document ?? '',
  district: District ?? '',
  claimant: Claimant ?? '',
  complement: Complement ?? '',
  objectText: ObjectText ?? '',
  courtSection: CourtSection ?? '',
  processNumber: ProcessNumber ?? '',
  courtRegionalLabor: CourtRegionalLabor ?? '',
})

export default InsuredObjectPropertyData
