import axios from 'axios'
import { useCallback } from 'react'

const useCourtRegionalLaborClient = () => {
  const getAll = useCallback(async (courtType) => axios.get(`Tribunal/all${courtType ?? ''}`).then((res) => res?.data), [])
  return useCallback(() => ({
    getAll,
  }), [
    getAll,
  ])
}

export default useCourtRegionalLaborClient
