export const SEGURO_GARANTE_INDENIZACAO_NUMERO_EDITAL = (numeroEdital) => `Este seguro garante a indenização, até o valor da garantia fixado na apólice, se o Tomador adjudicatário se recusar a assinar o Contrato Principal, nas condições propostas, dentro do prazo estabelecido no Edital número: ${numeroEdital}`
export const SEGURO_GARANTE_INDENIZACAO_NUMERO_CONTRATO_COMPLEMENTO = (complementoContrato) => `Este seguro garante a indenização, até o valor da garantia fixado na apólice, pelos prejuízos causados pelo Tomador ao Segurado, em razão de inadimplemento das obrigações previstas no ${complementoContrato}`
export const GARANTIA_JUDICIAL_CDA_COMPLEMENTO = (otherGuarantee, complementoContrato) => `Garantia Judicial destinada ao Processo nº ${otherGuarantee.processNumber ?? '[nº do processo]'}, Auto Infração nº ${otherGuarantee.infractionNoticeNumber ?? '[nº do auto de infração]'}, CDA nº ${otherGuarantee.CDANumber ?? '[nº do CDA]'}. Trata-se Ação nº ${otherGuarantee.proposalNumber ?? '[nº da ação proposta]'}, proposta por ${otherGuarantee.policyHolderName.replace('.', '') ?? '[nome do tomador]'}, perante o Juízo nº ${otherGuarantee.nameJudgment ?? '[nº do juízo]'}.\n${complementoContrato}`
export const GARANTIA_JUDICIAL_LABOR_CDA_COMPLEMENTO = (otherGuarantee, complementoContrato) => `Garantia Judicial Trabalhista destinada ao Processo nº ${otherGuarantee.processNumber ?? '[nº do processo]'}. Trata-se de ação trabalhista movida por ${otherGuarantee.claimantName.replace('.', '') ?? '[nome]'}, CPF/CNPJ ${otherGuarantee.claimantCnpj ?? '[CPF/CNPJ]'}, perante o tribunal ${otherGuarantee.courtRegionalLabor ?? '[tribunal]'} e vara ${otherGuarantee.courtSection ?? '[vara]'}.\n${complementoContrato}`
const GuaranteedBIDQuotation = {
  GARANTIA_JUDICIAL_CDA_COMPLEMENTO,
  GARANTIA_JUDICIAL_LABOR_CDA_COMPLEMENTO,
  SEGURO_GARANTE_INDENIZACAO_NUMERO_EDITAL,
  SEGURO_GARANTE_INDENIZACAO_NUMERO_CONTRATO_COMPLEMENTO,
}

export default GuaranteedBIDQuotation
