import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import makeStyles from '@material-ui/core/styles/makeStyles'

import icoError from '../../../assets/img/icons/ico-alert-danger.svg'

import { ButtonGradient, ModalInfo } from '../../../shared/components'

const useStyles = makeStyles(() => ({
  modal: {
    '& .MuiPaper-root': {
      height: 400,
      width: 600,
    },
    '& img': {
      width: 174,
    },
  },
  text: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: '160%',
    fontWeight: 500,
    textAlign: 'center',
    color: '#6E7191',
  },
  textClickHere: {
    fontWeight: 600,
    color: '#0E347D',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
  },
}))

const ModalPasswordResetMessage = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const {
    openModal,
    closeModal,
    message,
  } = props

  const handleOnClick = () => {
    closeModal()
    history.push('/recover-password')
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleOnClick()
    }
  }

  return (
    <>
      {openModal && (
        <ModalInfo
          close
          open={openModal}
          onClose={() => closeModal()}
          className={classes.modal}
        >
          <Box mt={2} mb={3} align="center">
            <img src={icoError} alt="thumb" />
          </Box>
          <Box mt={2} mb={3} align="center">
            <Typography className={classes.text}>
              {message}
              {' '}
              <span
                role="button"
                tabIndex={0}
                className={classes.textClickHere}
                onClick={handleOnClick}
                onKeyDown={handleKeyDown}
              >
                clique aqui
              </span>
              .
            </Typography>
            <Box width={220} mt={3}>
              <ButtonGradient
                onClick={() => {
                  closeModal()
                }}
              >
                Fechar
              </ButtonGradient>
            </Box>
          </Box>
        </ModalInfo>
      )}
    </>
  )
}

ModalPasswordResetMessage.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
}

export default ModalPasswordResetMessage
