import React from 'react'

import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import {
  Grid,
  Box,
  Typography,
} from '@material-ui/core'

import makeStyles from '@material-ui/core/styles/makeStyles'

import ModalInfo from '../../components/ModalInfo'

import bgPortalUpdates from '../../../assets/img/backgrounds/bg-portal-updates.webp'

const useStyles = makeStyles((theme) => ({
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 640,
      height: 480,
      borderRadius: 24,
      background: 'linear-gradient(130.64deg, #1F2A50 -2.17%, #144776 102.41%)',
      display: 'flex',
      alignItems: 'flex-start',
    },
    '& .material-icons': {
      color: '#D5D7DC',
    },
  },
  description: {
    width: 560,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '160%',
    textAlign: 'center',
    color: '#F7F7F7',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      fontSize: 1,
    },
  },
  imgUpdates: {
    minWidth: '543px',
    minHeight: '111px',
    marginTop: theme.spacing(2),
    backgroundSize: 'cover',
    animation: 'fadein 0.5s',
    backgroundPosition: 'center',
    backgroundImage: `url(${bgPortalUpdates})`,
    [theme.breakpoints.down('xs')]: {
      minWidth: 287,
      minHeight: 60,
    },
  },
  linkHelp: {
    cursor: 'pointer',
    width: 100,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '160%',
    textAlign: 'center',
    textDecorationLine: 'underline',
    color: '#F7F7F7',
  },
  subLinkHelp: {
    width: 90,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: '160%',
    textAlign: 'center',
    color: '#D5D7DC',
  },
}))

const PortalUpdatesModal = ({ openModal, closeModal }) => {
  const classes = useStyles()
  const history = useHistory()

  const description = 'Pensando sempre em novas formas de melhorar a Área do Cliente, elaboramos tutoriais explicativos para facilitar ainda mais a experiência do usuário. Acompanhe os vídeos sobre a usabilidade dos principais menus e descubra novas funcionalidades!'

  return (
    <ModalInfo
      open={openModal}
      onClose={closeModal}
      className={classes.modal}
    >
      <Grid
        container
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        style={{
          paddingTop: 20,
          height: '100%',
          width: '100%',
        }}
      >
        <Grid item className={classes.description}>
          <Typography>
            {description}
          </Typography>
        </Grid>
        <Grid item>
          <Box className={classes.imgUpdates} />
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            width: 'inherit',
            justifyContent: 'center',
          }}
        >
          <Typography
            className={classes.linkHelp}
            variant="h6"
            color="primary"
            onClick={() => {
              closeModal(false)
              history.push('/ajuda')
            }}
          >
            Clique aqui
          </Typography>
          <Typography className={classes.subLinkHelp}>
            e conheça!
          </Typography>
        </Grid>
      </Grid>
    </ModalInfo>
  )
}

PortalUpdatesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
}

PortalUpdatesModal.defaultProps = {

}

export default PortalUpdatesModal
