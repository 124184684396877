import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import Icon from '@material-ui/core/Icon'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import Popover from '@material-ui/core/Popover'

import makeStyles from '@material-ui/core/styles/makeStyles'

import { Tooltip, styled } from '@material-ui/core'

import useSecurity from '../../../security/useSecurity'

import UserOptionMenu from './UserOptionMenu'

import iconWarning from '../../../assets/img/icons/ico-warning.svg'

const useStyles = makeStyles((theme) => ({
  menu: {
    '& .MuiPopover-paper': {
      width: '300px',
      borderRadius: '14px',
    },
  },
  btn: {
    color: theme.palette.text.secondary,
  },
  name: {
    fontWeight: 600,
    fontSize: '1.05rem',
  },
  iconArrow: {
    fontSize: '12px',
  },
  labelInfo: {
    color: '#9c9898',
    fontSize: '12px',
  },
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 600,
    },
  },
  tooltip: {
    minWidth: 249,
    minHeight: 30,
    fontSize: 14,
    color: '#fff',
    backgroundColor: '#292D32',
  },
}))

const SmallAvatar = styled(Avatar)(() => ({
  width: 22,
  height: 22,
}))

const UserMenu = ({ logout, closeModalRegistrationUpdate }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const location = useLocation()
  const {
    getName,
    avatarURL,
    getDataIsOutdated,
  } = useSecurity()

  const isOutdated = getDataIsOutdated()

  const handleRegistrationUpdate = () => {
    if (location?.pathname !== '/atualizacao-cadastral') {
      closeModalRegistrationUpdate(true)
    }

    setAnchorEl(null)
  }

  // POPOVER
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const classes = useStyles()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const registrationUpdateVerify = localStorage.getItem('RegistrationUpdate')

  const IconWarningComponent = () => (
    <Tooltip
      placement="top-end"
      title="Seus dados estão desatualizados"
      classes={{ tooltip: classes.tooltip }}
    >
      <SmallAvatar onClick={() => handleRegistrationUpdate()} alt="Seus dados estão desatualizado" src={iconWarning} />
    </Tooltip>
  )

  return (
    <>
      <Button
        className={classes.btn}
        onClick={handleClick}
        aria-controls="simple-menu"
        title={getName()}
      >
        {isOutdated && registrationUpdateVerify !== null
          && (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                <IconWarningComponent />
              }
            >
              <Avatar alt={getName()} src={avatarURL} />
            </Badge>
          )}
        {(!isOutdated || registrationUpdateVerify === null)
          && (
            <Avatar alt={getName()} src={avatarURL} />
          )}
        <Icon className={classes.iconArrow}>keyboard_arrow_down</Icon>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.menu}
        anchorOrigin={{
          vertical: 70,
          horizontal: 58,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <UserOptionMenu logout={logout} />
      </Popover>
    </>
  )
}

UserMenu.propTypes = {
  logout: PropTypes.func.isRequired,
  closeModalRegistrationUpdate: PropTypes.func.isRequired,
}

export default UserMenu
