import { useCallback } from 'react'
import axios from 'axios'

const useViaCepClient = () => {
  const getInfoByCep = useCallback(async (cep) => axios.get([process.env.REACT_APP_URL_API_VIACEP, `${cep}/json/`].join('/')).then((res) => res?.data), [])

  return useCallback(() => ({
    getInfoByCep,
  }), [
    getInfoByCep,
  ])
}

export default useViaCepClient
