import React from 'react'
import SVG from 'react-inlinesvg'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Card,
  makeStyles,
} from '@material-ui/core'

const useCustomStyle = makeStyles((theme) => ({
  img: {
    textAlign: 'center',
  },
  equipment: {
    color: theme.palette.text.primary,
    alignItems: 'center',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 15,
    marginTop: 2,
  },
  button: {
    width: 164,
    height: 169,
    borderRadius: 20,
    boxShadow: 'none',
    padding: 'initial',
    '&:hover': {
      boxShadow: '0px 10px 15px -3px rgba(15, 23, 42, 0.1), 0px 4px 6px -2px rgba(15, 23, 42, 0.05)',
      filter: 'drop-shadow(0px 0px 1px rgba(15, 23, 42, 0.06))',
      '& .MuiCard-root': {
        transition: 'all 300ms linear',
        backgroundColor: '#ECF8FF',
      },
    },
  },
  card: {
    borderRadius: 20,
    border: 2000,
    width: '100%',
    height: 186,
    left: '217px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '8px',
  },
}))

const CardIconTicketAndInvoice = (props) => {
  const {
    text,
    svg,
    styles,
  } = props
  const classesCustom = useCustomStyle()

  return (
    <>
      <Button
        className={classesCustom.button}
        {...props}
      >
        <Card className={classesCustom.card}>
          <Box className={classesCustom.img}>
            {svg && <SVG src={svg} style={styles} alt={`icone ${text}`} />}
          </Box>
          <Box className={classesCustom.equipment}>
            {text}
          </Box>
        </Card>
      </Button>
    </>
  )
}

CardIconTicketAndInvoice.propTypes = {
  svg: PropTypes.string,
  styles: PropTypes.object,
  text: PropTypes.string.isRequired,
}

CardIconTicketAndInvoice.defaultProps = {
  svg: null,
  styles: {},
}

export default CardIconTicketAndInvoice
