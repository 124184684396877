const EndorsementNewStateDocument = ({
  name,
  document,
  deadline,
  startDateValidity,
  endDateValidity,
  contractValue,
  guaranteeValue,
  objectText,
  civilWork,
  coverageFine,
  coverageLabor,
}) => ({
  name: name ?? '',
  document: document ?? '',
  deadline: deadline ?? '',
  startDateValidity: startDateValidity ?? '',
  endDateValidity: endDateValidity ?? '',
  contractValue: contractValue ?? 0,
  guaranteeValue: guaranteeValue ?? 0,
  objectText: objectText ?? '',
  civilWork: civilWork ?? 'false',
  coverageFine: coverageFine ?? 'false',
  coverageLabor: coverageLabor ?? 'false',
})

export default EndorsementNewStateDocument
