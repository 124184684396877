import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'

import 'react-toastify/dist/ReactToastify.css'

import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { useParams, useLocation } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'

import SearchIcon from '@material-ui/icons/Search'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useEndorsement } from '../../../shared/contexts/EndorsementContext'

import { GlobalMessages } from '../../../shared/messages'

import useUtils from '../../../shared/hooks/useUtils'

import {
  useAlert,
  ButtonGradient,
  ButtonLight,
} from '../../../shared/components'

import CollapsePrice from '../../../shared/components/CollapsePrice/CollapsePrice'
import DropdownSelectOne from '../../../shared/components/DropdownSelectOne/DropdownSelectOne'

import {
  QUOTE_LEASE_BAIL,
  QUOTE_JUDICIAL_APPEAL,
  QUOTE_ENGINEERING_RISK,
  PRODUCT_OTHER,
  PREFIX_RETURN_QUOTE,
  MODALITY_RETURN_PREFIX,
  MODALITY_AND_PRODUCT_RETURN_PRODUCT_CODE,
  ENDORSEMENT_URL_EXECUTIVE_REQUEST,
} from '../../../constants'

import {
  ENDORSEMENT_TYPE_CANCELLATION,
  ENDORSEMENT_TYPE_DEADLINE_EXTENSION,
  ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT,
  ENDORSEMENT_TYPE_EXTENSION_REDUCTION_INSURED_AMOUNT,
} from '../../../constants/EndorsementConstants'

import usePolicyClient from '../../../clients/PolicyClient/usePolicyClient'

import EndorsementRequestData from '../../../models/PolicyModels/EndorsementRequestData'

import ModalPolicyIsUpload from './ModalPolicyIsUpload'
import ModalPolicyEndorsementExpired from './ModalPolicyEndorsementExpired'
import ModalIssuedByAnotherBrokerage from './ModalIssuedByAnotherBrokerage'
import useProductQuotationClient from '../../../clients/ProductClient/useProductQuotationClient'

import useStyles from './styles/endorsementRequest'
import useSecurity from '../../../security/useSecurity'
import useUserClient from '../../../clients/UserClient/useUserClient'

const LEASE_BAIL = 'fianca-locaticia'
const RISK_ENGINEERING = 'risco-engenharia'
const NOT_LINKED_USER_MESSAGE = 'O tomador da ap�lice n�o est� vinculado ao usu�rio.'

const EndorsementRequest = forwardRef((props, ref) => {
  const classes = useStyles()
  const { policyIdentifier } = useParams()

  const { date } = useUtils()
  const { addMsgDanger, addMsgWarning } = useAlert()
  const { getEmail } = useSecurity()
  const userClient = useUserClient()
  const productQuotationClient = useProductQuotationClient()

  const {
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props

  const {
    onNext,
    actionNext,
    endorsement,
    setEndorsement,
    endorsementData,
    setEndorsementData,
    setPolicyCurrent,
    policySelected,
    setPolicySelected,
    endorsementTypeStateSelected,
    setEndorsementTypeStateSelected,
    productPolicy,
    setProductPolicy,
    policy,
    setPolicy,
    policyMain,
    setPolicyMain,
    lastPolicy,
    setLastPolicy,
    otherBrokerageDocument,
    setOtherBrokerageDocument,
  } = useEndorsement()

  const policyClient = usePolicyClient()

  const [mainCodePolicies, setMainCodePolicies] = useState([])
  const [policies, setPolicies] = useState([policyIdentifier ?? ''])
  const [endorsementTypeOptions, setEndorsementTypeOptions] = useState([''])
  const [numberDocumentNotFound, setNumberDocumentNotFound] = useState('')
  const [endorsementWithoutPolicy, setEndorsementWithoutPolicy] = useState('')

  const [verifySelectedProduct, setVerifySelectedProduct] = useState(false)
  const [openModalIsUpload, setOpenModalIsUpload] = useState(false)
  const [documentIsUpload, setDocumentIsUpload] = useState(false)
  const [openModalExpiredStatusConfirm, setOpenModalExpiredStatusConfirm] = useState(false)
  const [openModalIssuedByAnotherBrokerage, setOpenModalIssuedByAnotherBrokerage] = useState(false)
  const [products, setProducts] = useState([''])
  const [modalities, setModalities] = useState([''])
  const [selectedProduct, setSelectedProduct] = useState('')
  const [selectedPrefix, setSelectedPrefix] = useState('')

  const location = useLocation()

  const getUrl = location?.pathname?.split(':')
  const routeExecutiveRequest = getUrl[0]

  useEffect(() => {
    setProducts(productQuotationClient().getProducts().sort())
  }, [productQuotationClient])

  useEffect(() => {
    if (selectedProduct) {
      setModalities(productQuotationClient().getModalitiesByProduct(selectedProduct).sort())
    }
  }, [productQuotationClient, selectedProduct])

  const modalitiesIsEmpty = () => modalities.filter((m) => m).length === 0

  const filterSelectedOptionObjectProducts = (e, arrayOptions) => {
    const selectedOptionObject = e.target.value
    if (!selectedOptionObject) {
      return ''
    }

    return arrayOptions.find((option) => option.value === selectedOptionObject) ?? undefined
  }

  const initialValuesForm = useMemo(() => (Object.assign(
    EndorsementRequestData({
      prefix: policy?.modalidade?.prefixo ?? '',
      policyNumber: policySelected ?? '',
      policy: !isEmpty(lastPolicy) || !isEmpty(policy) ? {
        id: lastPolicy?.codigo ?? policy?.codigo ?? '',
        mainPolicyId: policy?.codigo ?? '',
        endDateValidity: policy?.terminoVigencia ?? '',
        startDateValidity: policy?.inicioVigencia ?? '',
        policyHolderName: lastPolicy?.tomador?.clienteNome ?? policy?.tomador?.clienteNome ?? '',
        policyHolderDocument: lastPolicy?.tomador?.clienteCpfCnpj ?? policy?.tomador?.clienteCpfCnpj ?? '',
        productModality: lastPolicy?.modalidade?.descricaoModalidade ?? policy?.modalidade?.descricaoModalidade ?? '',
      } : undefined,
      endorsementType: endorsementTypeStateSelected?.text ?? '',
      endorsementTypeId: endorsementTypeStateSelected?.id ?? '',
    }),
  )), [policy, lastPolicy, policySelected, endorsementTypeStateSelected])

  const insuredData = useMemo(() => (
    {
      cep: lastPolicy?.segurado?.cepBeneficiario ?? '',
      state: lastPolicy?.segurado?.ufBeneficiario ?? '',
      name: lastPolicy?.segurado?.nomeBeneficiario ?? '',
      email: lastPolicy?.segurado?.emailBeneficiario ?? '',
      city: lastPolicy?.segurado?.cidadeBeneficiario ?? '',
      number: lastPolicy?.segurado?.numeroBeneficiario ?? '',
      document: lastPolicy?.segurado?.cnpjBeneficiario ?? '',
      district: lastPolicy?.segurado?.bairroBeneficiario ?? '',
      place: lastPolicy?.segurado?.logradouroBeneficiario ?? '',
      complement: lastPolicy?.segurado?.complementoBeneficiario ?? '',
    }
  ), [lastPolicy?.segurado])

  const getDeadlineValue = useCallback((policyData) => {
    let deadlineCount = ''
    if (isEmpty(policyData?.prazo) && policyData?.inicioVigencia && policyData?.terminoVigencia) {
      deadlineCount = date(policyData?.terminoVigencia)
        .diff(date(policyData?.inicioVigencia), 'days')

      if (endorsementData?.product === QUOTE_JUDICIAL_APPEAL) {
        deadlineCount = date(policyData?.terminoVigencia)
          .diff(date(policyData?.inicioVigencia), 'years')
      }
    }

    return deadlineCount
  }, [date, endorsementData])

  const orderEndorsements = (array) => array?.sort((a, b) => {
    const strA = a?.numeroDocumento.toString()
    const strB = b?.numeroDocumento.toString()

    const lastThreDigitsA = strA?.substr(-3)
    const lastThreDigitsB = strB?.substr(-3)

    return lastThreDigitsA - lastThreDigitsB
  })

  const handlePolicyValidaty = useCallback(() => {
    const arrayOrderEndorsements = orderEndorsements(lastPolicy?.endossos)

    if (arrayOrderEndorsements) {
      if (policyMain?.numeroDocumento
        === policyMain?.numeroDocumentoUltimoEndosso) {
        return [...arrayOrderEndorsements]
      }
      return [{ ...policyMain }, ...arrayOrderEndorsements]
    }
    return []
  }, [lastPolicy, policyMain])

  const endorsementDataObject = useMemo(() => (
    {
      name: lastPolicy?.segurado?.nomeBeneficiario ?? '',
      document: lastPolicy?.segurado?.cnpjBeneficiario ?? '',
      deadline: getDeadlineValue(lastPolicy),
      startDateValidity: lastPolicy?.inicioVigencia ?? '',
      endDateValidity: lastPolicy?.terminoVigencia ?? '',
      contractValue: lastPolicy?.valorContrato ?? 0,
      guaranteeValue: lastPolicy?.importanciaSegurada ?? 0,
      objectText: lastPolicy?.obrigacaoGarantia ?? '',
      historyDocuments: orderEndorsements(lastPolicy?.endossos)
        ? handlePolicyValidaty() : [],
    }
  ), [lastPolicy, getDeadlineValue, handlePolicyValidaty])

  const isShowDescriptionByQuoteType = (product) => (
    endorsementTypeStateSelected?.id === ENDORSEMENT_TYPE_CANCELLATION || [
      QUOTE_LEASE_BAIL,
      QUOTE_ENGINEERING_RISK,
    ].includes(product))

  const clearData = useCallback(() => {
    setPolicies([])
    setEndorsement({})
    setPolicyCurrent({})
    setProductPolicy('')
    setPolicy({})
    setPolicyMain({})
    setLastPolicy({})
    setEndorsementData({})
    setMainCodePolicies([])
    setEndorsementWithoutPolicy([])
    setEndorsementTypeStateSelected()
  }, [
    setPolicies,
    setEndorsement,
    setPolicyCurrent,
    setProductPolicy,
    setPolicy,
    setPolicyMain,
    setLastPolicy,
    setEndorsementData,
    setMainCodePolicies,
    setEndorsementWithoutPolicy,
    setEndorsementTypeStateSelected,
  ])

  const endorsementDetail = (value) => {
    policyClient().getEndorsementProcess(value).then((response) => {
      if (response?.Message === 'Já existe uma solicitação de endosso em andamento para esta apólice.' && ENDORSEMENT_URL_EXECUTIVE_REQUEST !== routeExecutiveRequest) {
        addMsgWarning({
          labelButton: 'Fechar',
          message: `${response?.Message} Entre em contato com o executivo.`,
          action: () => {
            clearData()
          },
        })
      }
      if (response.Status) {
        (
          addMsgDanger({
            title: 'Endosso em processamento',
            message: `${response?.Message}. Aguarde o fim do processo para solicitar um novo endosso.`,
            action: '',
            maxWidth: 'md',
          })
        )
        setPolicySelected('')
      }
    })
  }
  const verifyFinlandiaIsBroker = (value) => {
    if (value) {
      return false
    }
    return true
  }

  const setPrefixParam = useCallback((modalityIdentifier) => MODALITY_RETURN_PREFIX[modalityIdentifier] || 'OG', [])

  const formik = useFormik({
    initialValues: initialValuesForm,
    enableReinitialize: true,
    validationSchema: Yup.object({
      policyNumber: Yup.string(),
      endorsementType: Yup.string().required(),
      endorsementDescription: Yup.string().when(() => (
        isShowDescriptionByQuoteType(endorsementData?.product)
          ? Yup.string().required()
          : Yup.string()
      )),
    }),
    onSubmit: (data) => {
      const values = !otherBrokerageDocument ? {
        notFoundDocument: !verifyFinlandiaIsBroker(otherBrokerageDocument),
        isPolicyUpload: documentIsUpload,
        ...endorsement,
        ...{ insured: insuredData },
        ...{
          endorsementRequest: {
            ...data,
            endorsementTypeId: endorsementTypeStateSelected?.id ?? '',
          },
        },
        ...{
          currentStateDocument: endorsementDataObject,
        },
      } : {}

      const valuesDocumentOtherBrokerage = {
        notFoundDocument: !verifyFinlandiaIsBroker(otherBrokerageDocument),
        isPolicyUpload: verifyFinlandiaIsBroker(otherBrokerageDocument),
        finlandiaIsBroker: verifyFinlandiaIsBroker(otherBrokerageDocument),
        ...{
          endorsementRequest: {
            ...data,
            prefix: setPrefixParam(selectedPrefix),
            endorsementTypeId: endorsementTypeStateSelected?.id ?? '',
          },
          ...{ insured: insuredData },
        },
      }
      if (otherBrokerageDocument) {
        delete valuesDocumentOtherBrokerage?.currentStateDocument
        if (
          valuesDocumentOtherBrokerage?.endorsementRequest?.prefix !== 'FL'
          || valuesDocumentOtherBrokerage?.endorsementRequest?.prefix !== 'RE'
        ) {
          delete valuesDocumentOtherBrokerage?.insured
        }
      }

      const today = date()
      const dateLimit = date(today).add(1, 'days')
      const dateTwoYears = date(today).add(-2, 'years')
      const dateEnd = date(values?.currentStateDocument?.endDateValidity, 'DD/MM/YYY')

      const verifyTypeEndorsement = () => (![
        ENDORSEMENT_TYPE_DEADLINE_EXTENSION,
        ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT,
        ENDORSEMENT_TYPE_EXTENSION_REDUCTION_INSURED_AMOUNT,
      ].includes(values?.endorsementRequest?.endorsementTypeId))

      if (dateEnd?.isBefore(dateLimit) && verifyTypeEndorsement() && !otherBrokerageDocument) {
        addMsgDanger({
          title: 'A apólice ou endosso informado está vencido.',
          message: 'Somente endossos de prorrogação podem ser solicitados.',
        })
      } else if (dateEnd?.isBefore(dateTwoYears) && !otherBrokerageDocument) {
        addMsgDanger({
          title: 'Essa apólice não pode ser prorrogada, pois seu vencimento foi há mais de 2 anos.',
          message: 'Para mais informações, entre em contato com o seu executivo.',
        })
      } else {
        if (!otherBrokerageDocument) {
          userClient().getClientByUserLogged(getEmail()).then((response) => {
            const filterNotLead = response?.data?.filter((item) => item?.lead === false)
            const filterNomination = filterNotLead
              // eslint-disable-next-line max-len
              ?.filter((item) => item?.cpfcnpj === values.endorsementRequest.policy.policyHolderDocument)

            if (filterNomination?.length > 0) {
              setEndorsement({ ...values, ClientIsNominated: true })
            } else {
              setEndorsement({ ...values, ClientIsNominated: false })
            }
          }, () => {
            setEndorsement({ ...values })
          })
        }
        if (otherBrokerageDocument) {
          setEndorsement(valuesDocumentOtherBrokerage)
        }

        setPolicyCurrent(!otherBrokerageDocument ? policy : {})
        if (actionNext) {
          onNext(values)
        }
      }
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  const loadPolicies = useCallback((value) => {
    const data = {
      take: 5,
      limit: 5,
      documentNumber: value !== undefined ? value : '',
    }
    if (value?.length >= 11 || value === '' || value === undefined) {
      policyClient().getPoliciesAndEndorsements(data).then((policiesResponse) => {
        const numberDocuments = policiesResponse?.data
          .map(({ numeroDocumento }) => numeroDocumento)
        const numberDocumentsMainCode = policiesResponse?.data
          .map(({ codigo, numeroDocumento, codigoPrincipal }) => (
            { codigo, numeroDocumento, codigoPrincipal }
          ))
        const codeLastEndorsement = policiesResponse?.data
          .map(({ numeroDocumentoUltimoEndosso }) => (
            { numeroDocumentoUltimoEndosso }
          ))
        const lastEndorsement = codeLastEndorsement
          ?.slice(-1)[0]?.numeroDocumentoUltimoEndosso
        setEndorsementWithoutPolicy(lastEndorsement)
        setMainCodePolicies(numberDocumentsMainCode)
        setPolicies(numberDocuments?.sort() ?? [''])
        if (policiesResponse?.data?.length === 1) {
          setOtherBrokerageDocument(false)
        }
      }).catch((error) => {
        clearData()
        if (error?.data?.error === NOT_LINKED_USER_MESSAGE) {
          addMsgWarning({
            labelButton: 'Fechar',
            message: 'O tomador da apólice não está vinculado ao usuário. Procure seu executivo.',
            action: () => {
              setPolicySelected('')
            },
          })
        } else if (data?.documentNumber !== '') {
          setNumberDocumentNotFound(data?.documentNumber)

          if (ENDORSEMENT_URL_EXECUTIVE_REQUEST !== routeExecutiveRequest) {
            setOpenModalIssuedByAnotherBrokerage(true)
          }
          if (ENDORSEMENT_URL_EXECUTIVE_REQUEST === routeExecutiveRequest && error?.data?.error === 'Nenhum registro encontrado') {
            addMsgWarning({
              labelButton: 'Fechar',
              message: 'Não foi possível localizar a apólice/endosso solicitado, ou o produto não está disponível para solicitação automática.',
              action: () => {
                setPolicySelected('')
              },
            })
          }
        }
      })
    }
  }, [
    clearData,
    policyClient,
    addMsgWarning,
    routeExecutiveRequest,
    setPolicySelected,
    setOtherBrokerageDocument,
  ])

  const requestPolicyDetails = useCallback((policyValue) => new Promise((resolve) => {
    policyClient().getPolicyDetails(policyValue)
      .then((response) => {
        resolve(response.data)
        if (response?.data?.isPolicyUpload) {
          setOpenModalIsUpload(true)
        }
      }, (error) => {
        if (error.status === 404) {
          policyClient().getPolicyDetails(endorsementWithoutPolicy)
            .then((response) => {
              resolve(response.data)
            })
        }
      })
  }), [policyClient, endorsementWithoutPolicy])

  const timeoutRequestEndorsement = useRef(0)

  useEffect(() => {
    let active = true
    clearTimeout(timeoutRequestEndorsement.current)

    if (active) {
      loadPolicies()

      if (!!policyIdentifier) {
        timeoutRequestEndorsement.current = setTimeout(() => {
          setPolicySelected(policyIdentifier)
          loadPolicies(policyIdentifier)
        }, 500)
      }
    }

    active = false
  }, [loadPolicies, policyIdentifier, setPolicySelected])

  const getPrefixParam = useCallback((identifier) => PREFIX_RETURN_QUOTE[identifier] || 6, [])

  const setProductCode = useCallback((path) => MODALITY_AND_PRODUCT_RETURN_PRODUCT_CODE[path]
    || PRODUCT_OTHER, [])

  const checkStatusPolicy = (data) => {
    const { endossos } = data
    if (data?.status === 'Cancelado') {
      addMsgDanger({
        labelButton: 'Fechar',
        title: 'A apólice/endosso informado está cancelado.',
        message: 'Não é possível realizar a solicitação de endosso, procure seu executivo.',
        action: () => {
          clearData()
          setPolicySelected('')
        },
      })
    } else if (endossos?.length !== 0) {
      const lastCurrentPolicy = endossos?.reverse().find((item) => item?.status !== 'Cancelado')

      if (lastCurrentPolicy) {
        return { ...lastCurrentPolicy, endossos }
      }
      return data
    }
    return data
  }

  const setEndorsementTypeByProduct = (value) => {
    const mainPolicy = mainCodePolicies.find(
      ({ numeroDocumento }) => numeroDocumento === policySelected,
    )

    if (!!mainPolicy) {
      requestPolicyDetails(mainPolicy?.codigoPrincipal ?? mainPolicy?.codigo)
        .then((policyData) => {
          setProductPolicy(policyData?.modalidade?.descricaoModalidade)
          setPolicy(policyData)
          setPolicyMain(policyData)
          const lastPolicyData = checkStatusPolicy(policyData)
          setLastPolicy(lastPolicyData)
          setEndorsementData({
            id: value,
            numberPolicy: lastPolicyData?.numeroDocumento,
            product: getPrefixParam(lastPolicyData?.modalidade?.prefixo) ?? getPrefixParam(policyData?.modalidade?.prefixo) ?? '',
            productKey: lastPolicyData?.modalidade?.productKey ?? policyData?.modalidade?.productKey ?? '',
            modality: lastPolicyData?.modalidade?.numeroModalidade ?? policyData?.modalidade?.numeroModalidade ?? '',
          })
        })
    }
  }

  const handleProductsOtherBrokerage = (brokerProduct) => {
    if (brokerProduct?.path === RISK_ENGINEERING || brokerProduct?.path === LEASE_BAIL) {
      setSelectedPrefix(brokerProduct?.path)
      setEndorsementData({
        ...endorsementData,
        numberPolicy: policySelected,
        product: getPrefixParam(brokerProduct?.path),
        modality: brokerProduct?.path,
        productKey: brokerProduct?.path,
        productCode: setProductCode(brokerProduct?.path),
      })
    } else {
      setEndorsementData({
        ...endorsementData,
        numberPolicy: policySelected,
      })
    }
    setVerifySelectedProduct(true)
  }

  const handleModalitiesOtherBrokerage = (brokerModality) => {
    setSelectedPrefix(brokerModality?.path)
    setEndorsementData({
      ...endorsementData,
      numberPolicy: policySelected,
      product: getPrefixParam(brokerModality?.path),
      modality: brokerModality?.path,
      productKey: brokerModality?.path,
      productModality: brokerModality?.text,
      productCode: setProductCode(brokerModality?.path),
    })
  }

  const setEndorsementTypeForDescription = (endorsementTypeSelected) => {
    setEndorsement({ ...endorsement, isEditing: false })
    setEndorsementTypeStateSelected(endorsementTypeSelected)
    setEndorsementTypeByProduct(endorsementTypeSelected?.id)
  }

  useEffect(() => {
    if (ENDORSEMENT_URL_EXECUTIVE_REQUEST === routeExecutiveRequest) {
      setEndorsementTypeOptions(
        policyClient().getAutomaticTypesEndorsementExecutiveRequest().sort(),
      )
    } else {
      setEndorsementTypeOptions(
        policyClient().getTypesEndorsement().sort(),
      )
    }
  }, [policyClient, routeExecutiveRequest, otherBrokerageDocument])

  const filterSelectedOptionObject = (e, arrayOptions) => {
    const selectedOptionObject = e.target.value
    if (!selectedOptionObject) {
      return ''
    }

    return arrayOptions.find((option) => option.text === selectedOptionObject) ?? undefined
  }

  const closeModalExpiredStatus = () => {
    setOpenModalExpiredStatusConfirm(false)
  }

  const { setValues } = formik

  const incorrectNumber = () => {
    setValues({ policyNumber: '' })
    setPolicySelected('')
    loadPolicies()
    setOtherBrokerageDocument(false)
  }

  const closeModalIssuedByAnotherBrokerage = () => {
    setOpenModalIssuedByAnotherBrokerage(false)
  }

  const resetFieldsDocument = () => {
    setValues({ policyNumber: '' })
    setPolicySelected('')
    loadPolicies()
    clearData()
    setProductPolicy('')
  }

  const closeModalIsUpload = () => {
    setOpenModalIsUpload(false)
  }

  const typeLabel = 'Tipo de Endosso'
  const modalityLabel = 'Modalidade'
  const productModalityLabel = 'Produto'
  const descriptionLabel = 'Descrição do Endosso'
  const policyNumberLabel = 'Número da Apólice / do Endosso'

  const noOptionsMessage = (
    <Typography className={classes.noOptionsLabel}>
      {GlobalMessages.NO_OPTIONS_MESSAGE}
    </Typography>
  )

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={!formik.isSubmitting}
        title="Dados do Endosso"
        subtitle="Informe abaixo os dados da solicitação de endosso"
      >
        <Grid container spacing={5}>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="policyNumberLabel" className={classes.label}>{policyNumberLabel}</label>
              <Autocomplete
                className={classes.textField}
                id="policyNumber"
                classes={{ option: classes.autocompleteStyles }}
                multiple={false}
                options={policies}
                noOptionsText={noOptionsMessage}
                getOptionLabel={(option) => option}
                onClose={(e, reason) => {
                  const value = e?.target?.innerText === ''
                    ? e?.target?.value
                    : e?.target?.innerText

                  if (['select-option', 'blur'].includes(reason)) {
                    setPolicySelected(value)
                    endorsementDetail(value)
                    loadPolicies(value)
                    if (reason === 'blur' && !policy) {
                      clearData()
                      setEndorsementData()
                    }
                  }
                }}
                onInputChange={(e, value) => {
                  if (e) {
                    clearTimeout(timeoutRequestEndorsement.current)
                    setPolicy()
                    if (value !== policySelected) {
                      timeoutRequestEndorsement.current = setTimeout(() => {
                        clearData()
                        setProductPolicy()
                        setEndorsementData()
                        setEndorsementTypeStateSelected()
                        setOtherBrokerageDocument(false)
                        setVerifySelectedProduct(false)
                        setPolicySelected(value)
                        setSelectedProduct('')
                        loadPolicies(value)
                      }, 500)
                    }
                  }
                }}
                getOptionSelected={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete="none"
                    placeholder="Procurar"
                    margin="normal"
                    error={formik.touched.policyNumber && !!formik.errors.policyNumber}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {...formik.getFieldProps('policyNumber')}
                disabled={!isEmpty(policyIdentifier)}
              />
              <FormHelperText
                hidden={!formik.touched.policyNumber || !formik.errors.policyNumber}
                error={formik.touched.policyNumber && !!formik.errors.policyNumber}
              >
                {formik.errors.policyNumber}
              </FormHelperText>
              <ModalPolicyEndorsementExpired
                closeModal={closeModalExpiredStatus}
                openModal={openModalExpiredStatusConfirm}
                statusPolicyDueOrCanceled={policy?.situacao?.status}
              />
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="typeLabel" className={classes.label}>{typeLabel}</label>
              <DropdownSelectOne
                id="endorsementType"
                color="secondary"
                disabled={isEmpty(policySelected)}
                options={endorsementTypeOptions}
                {...formik.getFieldProps('endorsementType')}
                error={formik.touched.endorsementType && !!formik.errors.endorsementType}
                onChange={(e) => {
                  const optionSelected = filterSelectedOptionObject(e, endorsementTypeOptions)
                  setEndorsementTypeForDescription(optionSelected)
                  if (otherBrokerageDocument) {
                    setEndorsementData({ ...endorsementData, id: optionSelected?.id })
                  }
                  formik.values.endorsementType = optionSelected?.text
                }}
              />
              <FormHelperText
                hidden={!formik.touched.endorsementType || !formik.errors.endorsementType}
                error={formik.touched.endorsementType && !!formik.errors.endorsementType}
              >
                {formik.errors.endorsementType}
              </FormHelperText>
            </FormControl>
          </Grid>
          {!otherBrokerageDocument && (
            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <label htmlFor="productModalityLabel" className={classes.label}>{productModalityLabel}</label>
                <Input
                  disabled
                  fullWidth
                  color="secondary"
                  id="productModality"
                  value={productPolicy ?? ''}
                />
              </FormControl>
            </Grid>
          )}
          {otherBrokerageDocument && (
            <>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="productLabel" className={classes.label}>{productModalityLabel}</label>
                  <DropdownSelectOne
                    id="brokerProduct"
                    options={products}
                    onClick={(e) => {
                      const optionSelected = filterSelectedOptionObjectProducts(e, products)
                      setSelectedProduct(optionSelected)
                      handleProductsOtherBrokerage(optionSelected)
                    }}
                  />
                </FormControl>
              </Grid>
              {verifySelectedProduct && (
                <Grid item sm={6} xs={12} hidden={modalitiesIsEmpty()}>
                  <FormControl fullWidth>
                    <label htmlFor="modalityLabel" className={classes.label}>{modalityLabel}</label>
                    <DropdownSelectOne
                      id="brokerModality"
                      options={modalities}
                      placeholder="Produto sem modalidade"
                      disabled={modalitiesIsEmpty()}
                      onClick={(e) => {
                        const optionSelected = filterSelectedOptionObjectProducts(e, modalities)
                        handleModalitiesOtherBrokerage(optionSelected)
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
            </>
          )}
          {
            isShowDescriptionByQuoteType(
              endorsementData?.product,
            ) && (
              <Grid item sm={12} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="descriptionLabel" className={classes.labelObject}>{descriptionLabel}</label>
                  <TextField
                    id="endorsementDescription"
                    multiline
                    variant="outlined"
                    placeholder="Descreva o que deseja no endosso..."
                    fullWidth
                    minRows={6}
                    {...formik.getFieldProps('endorsementDescription')}
                    error={
                      formik.touched.endorsementDescription
                      && !!formik.errors.endorsementDescription
                    }
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.endorsementDescription
                      || !formik.errors.endorsementDescription
                    }
                    error={
                      formik.touched.endorsementDescription
                      && !!formik.errors.endorsementDescription
                    }
                  >
                    {formik.errors.endorsementDescription}
                  </FormHelperText>
                </FormControl>
              </Grid>
            )
          }

          <ModalIssuedByAnotherBrokerage
            incorrectNumber={incorrectNumber}
            closeModal={closeModalIssuedByAnotherBrokerage}
            openModal={openModalIssuedByAnotherBrokerage}
            documentNumber={numberDocumentNotFound}
            setOtherBrokerageDocument={setOtherBrokerageDocument}
          />

          <ModalPolicyIsUpload
            resetFieldsDocument={resetFieldsDocument}
            closeModal={closeModalIsUpload}
            openModal={openModalIsUpload}
            documentNumber={policySelected}
            setDocumentIsUpload={setDocumentIsUpload}
          />

          <Grid item xs={12}>
            <Box className={classes.buttonsStep}>
              <Box>
                <ButtonLight
                  onClick={handlePrevious}
                  title="Voltar"
                >
                  Voltar
                </ButtonLight>
              </Box>
              <Box ml={4}>
                <ButtonGradient
                  onClick={() => {
                    handleNext()
                    setEndorsement({ ...endorsement, finlandiaIsBroker: !documentIsUpload })
                  }}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
            <ReactTooltip place="top" type="dark" />
          </Grid>
        </Grid>
      </CollapsePrice>
    </>
  )
})

EndorsementRequest.propTypes = {
  checked: PropTypes.bool,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

EndorsementRequest.defaultProps = {
  checked: false,
}

export default EndorsementRequest
