import React, { useContext } from 'react'

const EndorsementContext = React.createContext()

export const useEndorsement = () => {
  const {
    actionNext,
    actionPrevious,
    onNext,
    onRegister,
    endorsement,
    setEndorsement,
    endorsementData,
    setEndorsementData,
    policyCurrent,
    setPolicyCurrent,
    addMsgError,
    isFormikChanged,
    setFormikChanged,
    policySelected,
    setPolicySelected,
    endorsementTypeStateSelected,
    setEndorsementTypeStateSelected,
    productPolicy,
    setProductPolicy,
    policy,
    setPolicy,
    policyMain,
    setPolicyMain,
    lastPolicy,
    setLastPolicy,
    endorsementDocument,
    setEndorsementDocument,
    otherBrokerageDocument,
    setOtherBrokerageDocument,
  } = useContext(EndorsementContext)

  return {
    actionNext,
    actionPrevious,
    onNext,
    onRegister,
    endorsement,
    setEndorsement,
    endorsementData,
    setEndorsementData,
    policyCurrent,
    setPolicyCurrent,
    addMsgError,
    isFormikChanged,
    setFormikChanged,
    policySelected,
    setPolicySelected,
    endorsementTypeStateSelected,
    setEndorsementTypeStateSelected,
    productPolicy,
    setProductPolicy,
    policy,
    setPolicy,
    policyMain,
    setPolicyMain,
    lastPolicy,
    setLastPolicy,
    endorsementDocument,
    setEndorsementDocument,
    otherBrokerageDocument,
    setOtherBrokerageDocument,
  }
}

export default EndorsementContext
