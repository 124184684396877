import React from 'react'
import clsx from 'clsx'
import { PropTypes } from 'prop-types'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useStyles from './useStyles'

import vectEclipse from '../../../assets/img/vectors/vect-eclipse-primary.svg'

const useCustomStyles = makeStyles((theme) => ({
  root: (props) => ({
    color: '#FFFFFF',
    width: 'auto',
    minHeight: 60,
    marginBottom: theme.spacing(4),
    backgroundImage: `url(${props.bgState})`,
    [theme.breakpoints.down('xs')]: {
      width: 50,
      minHeight: 50,
    },
  }),
}))

const EclipseNumber = (props) => {
  const { step, number, bgState } = props
  const classes = useStyles()
  const classesCustom = useCustomStyles({ bgState })
  const downXS = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  return (
    <div className={clsx(
      'EclipseNumber-root',
      classes.root,
      classesCustom.root,
      !step && classes.active,
    )}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Typography variant={downXS ? 'subtitle1' : 'h5'}>
          {number}
        </Typography>
      </Grid>
    </div>
  )
}

EclipseNumber.propTypes = {
  step: PropTypes.any,
  number: PropTypes.number,
  bgState: PropTypes.string,
}

EclipseNumber.defaultProps = {
  number: null,
  step: false,
  bgState: vectEclipse,
}

export default EclipseNumber
