const InsuredData = ({
  Name,
  Document,

}) => ({
  name: Name ?? '',
  document: Document ?? '',
})

export default InsuredData
