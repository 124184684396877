export const GUARANTEE_ADVANCE_PAYMENTS_PUBLIC = 1
export const GUARANTEE_ADMINISTRATIVE_TAX_CREDITS_PUBLIC = 2
export const GUARANTEE_CUSTOMS_PUBLIC = 3
export const GUARANTEE_PERFORMING_NAT_PETROLEUM_AGENCY_PUBLIC = 4
export const GUARANTEE_BIDDING_NAT_PETROLEUM_AGENCY_PUBLIC = 5
export const GUARANTEE_COMPLETION_BOND_PUBLIC = 6
export const GUARANTEE_PROVISION_SERVICES_ANATEL_PUBLIC = 7
export const GUARANTEE_PROVISION_SERVICES_ANEEL_PUBLIC = 8
export const GUARANTEE_DEALERSHIP_PERFORMER_PUBLIC = 9
export const GUARANTEE_ENERGY_PURCHASE_SALE_PUBLIC = 10
export const GUARANTEE_WORK_COMPLETION_PUBLIC = 11
export const GUARANTEE_BIDDER_CCEE_PUBLIC = 12
export const GUARANTEE_JUDICIAL_CIVIL_PUBLIC = 13
export const GUARANTEE_JUDICIAL_LABOR_PUBLIC = 14
export const GUARANTEE_JUDICIAL_TAX_ENFORCEMENT_PUBLIC = 15
export const GUARANTEE_CORRECTIVE_MAINTENANCE_PUBLIC = 16
export const GUARANTEE_ADMINISTRATIVE_TAX_PORTION_PUBLIC = 17
export const GUARANTEE_PAYMENT_WITHHOLDING_PUBLIC = 18
export const GUARANTEE_COMPLETION_WORKS_BANCO_BRASIL_PUBLIC = 19
export const GUARANTEE_COMPLETION_WORKS_CAVA_PUBLIC = 20
export const GUARANTEE_COMPLETION_WORKS_INFRASTRUCTURE_PUBLIC = 21
export const GUARANTEE_COMPLET_WORKS_CORRECT_MAINTENANCE_PUBLIC = 22

export const GUARANTEE_ADVANCE_PAYMENTS_PRIVATE = 23
export const GUARANTEE_DEALERSHIP_PERFORMER_PRIVATE = 24
export const GUARANTEE_ENERGY_PURCHASE_SALE_PRIVATE = 25
export const GUARANTEE_ENERGY_PURCHASE_SALE_FINES_PRIVATE = 26
export const GUARANTEE_PAYMENTS_PRIVATE = 27
export const GUARANTEE_FINANCIAL_FINEP_PRIVATE = 28
export const GUARANTEE_BIDDER_PRIVATE = 29
export const GUARANTEE_REAL_ESTATE_OFFICE_PRIVATE = 30
export const GUARANTEE_CORRECTIVE_MAINTENANCE_PRIVATE = 31
export const GUARANTEE_PAYMENT_WITHHOLDING_PRIVATE = 32
export const GUARANTEE_JUDICIAL_CIVIL_PUBLIC_PATH = 'judicial-civel'

export const STEP_TYPE_FIRST_TEMPLATE = {
  DETAILS_PRODUCT: 0,
  INSURED: 1,
  OBJECT_PROPERTY: 2,
  REQUESTER: 3,
  FILE_UPLOAD: 4,
}

export const STEP_TYPE_SECOND_TEMPLATE = {
  DETAILS_PRODUCT: 0,
  INSURED: 1,
  OBJECT_PROPERTY: 2,
  REQUESTER: 3,
  FILE_UPLOAD: 4,
}

export const STEP_TYPE_THIRD_TEMPLATE = {
  DETAILS_PRODUCT: 0,
  INSURED: 1,
  CLAIMANT: 2,
  OBJECT_PROPERTY: 3,
  REQUESTER: 4,
  FILE_UPLOAD: 5,
}
