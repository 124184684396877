import React from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import Icon from '@material-ui/core/Icon'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import Backdrop from '@material-ui/core/Backdrop'

import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1),
    height: `calc(100% - ${theme.spacing(2)}px)`,
  },
  paper: {
    width: '66%',
    maxHeight: '100%',
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '96%',
    },
  },

  close: {
    cursor: 'pointer',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

const ModalInfo = ({
  children, open, onClose, close, ...props
}) => {
  const classes = useStyles()
  return (
    <Modal
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      open={open}
      {...props}
    >
      <Fade in={open}>
        <Box className={classes.modal}>
          <Paper className={classes.paper}>
            {close && (
              <Icon
                title="Fechar"
                className={classes.close}
                onClick={onClose}
              >
                close
              </Icon>
            )}
            {children}
          </Paper>
        </Box>
      </Fade>
    </Modal>
  )
}

ModalInfo.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
}

ModalInfo.defaultProps = {
  open: false,
  close: true,
  onClose: () => { },
}

export default ModalInfo
