import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'

import makeStyles from '@material-ui/core/styles/makeStyles'

import Autocomplete from '@material-ui/lab/Autocomplete'
import SearchIcon from '@material-ui/icons/Search'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isEqual, isEmpty } from 'lodash'
import ReactTooltip from 'react-tooltip'

import { usePrice } from '../../../../../shared/contexts/PriceContext'
import useYup from '../../../../../shared/hooks/useYup'
import useUtils from '../../../../../shared/hooks/useUtils'
import useSecurity from '../../../../../security/useSecurity'

import {
  ButtonLight,
  ButtonGradient,
  CPFOrCNPJInput,
} from '../../../../../shared/components'

import { GlobalMessages } from '../../../../../shared/messages'
import CollapsePrice from '../../../../../shared/components/CollapsePrice/CollapsePrice'

import useUserClient from '../../../../../clients/UserClient/useUserClient'
import PolicyHolderClaimantData from '../../../../../models/JudicialGuaranteeAppealModels/PolicyHolderClaimantData'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  help: {
    color: theme.palette.text.quaternary,
  },
  noOptionsLabel: {
    fontSize: 14,
    color: '#c93952',
    fontWeight: 'bold',
  },
}))

const PolicyHolderClaimant = forwardRef((props, ref) => {
  const classes = useStyles()
  const userClient = useUserClient()

  const { cpfOrCnpj } = useYup()
  const { formatCPFOrCNPJ } = useUtils()

  const {
    getEmail,
  } = useSecurity()

  const {
    quote,
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props

  const {
    onNext,
    actionNext,
    setFormikChanged,
    judicialGuaranteeAppeal,
    setJudicialGuaranteeAppeal,
  } = usePrice()

  const [hasNoneClient, setHasNoneClient] = useState(false)

  const initialValuesForm = useMemo(() => (Object.assign(
    PolicyHolderClaimantData(quote?.PolicyHolderClaimant ?? {}),
  )), [quote?.PolicyHolderClaimant])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesForm,
    validationSchema: Yup.object({
      document: cpfOrCnpj
        .when(() => (hasNoneClient ? cpfOrCnpj : cpfOrCnpj.required())),
      policyHolder: Yup.string().max(200)
        .when(() => (hasNoneClient ? Yup.string() : Yup.string().required())),
    }),
    onSubmit: (data) => {
      const values = {
        ...judicialGuaranteeAppeal,
        ...{ policyHolderClaimant: { ...data } },
      }

      userClient().getClientByUserLogged(getEmail()).then((response) => {
        const filterNotLead = response?.data?.filter((item) => item?.lead === false)
        const filterNomination = filterNotLead
          ?.filter((item) => item?.cpfcnpj === values.policyHolderClaimant.document)
        if (filterNomination?.length > 0) {
          setJudicialGuaranteeAppeal({ ...values, ClientIsNominated: true })
        } else {
          setJudicialGuaranteeAppeal({ ...values, ClientIsNominated: false })
        }
      }, () => {
        setJudicialGuaranteeAppeal({ ...values })
      })

      if (actionNext) {
        setFormikChanged(!isEqual(initialValuesForm, data))
        onNext(values)
      }
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  const { setValues } = formik

  const [policiesHolder, setPoliciesHolder] = useState([{}])
  const [policyHolderOptions, setPolicyHolderOptions] = useState([''])
  const [hasUniqueClient, setHasUniqueClient] = useState(true)

  const formatClients = useCallback((clientsUser) => clientsUser.map(({ name, cpfcnpj }) => ({
    id: cpfcnpj,
    text: `${cpfcnpj} ${name}`,
  })), [])

  const handlePolicyHolder = useCallback((value) => {
    const policiesHolderFound = policiesHolder.find((item) => item?.text === value)
    if (!isEmpty(policiesHolderFound)) {
      formik.values.document = policiesHolderFound?.id
    }
  }, [formik, policiesHolder])

  useEffect(() => {
    userClient().getClientByUserLogged(getEmail(), true).then((response) => {
      const filterNotLead = response?.data?.filter((item) => item?.lead === false)
      const clientsData = formatClients(filterNotLead
        ?.filter(({ cpfcnpj }) => cpfcnpj?.length === 14))

      setHasNoneClient(clientsData.length === 0)

      if (clientsData.length !== 0) {
        setHasUniqueClient(false)
        setPoliciesHolder(clientsData)
        setPolicyHolderOptions(clientsData.map(({ text }) => text))

        if (clientsData.length === 1) {
          const { text: nameClient, id: cpfCnpj } = clientsData[0]

          setValues({
            policyHolder: nameClient,
            document: cpfCnpj ?? '',
          })

          setHasUniqueClient(true)
        }
      }
    })
  }, [
    getEmail,
    setValues,
    userClient,
    formatClients,
    formatCPFOrCNPJ,
    setHasUniqueClient,
  ])

  const placeholderCPFCNPJ = '000.000.000-00 / 00.000.000/0000-00'

  const policyHolderLabel = 'Tomador'
  const cpfCnpjLabel = 'CPF/CNPJ'

  const noOptionsMessage = (
    <Typography className={classes.noOptionsLabel}>
      {GlobalMessages.NO_OPTIONS_MESSAGE}
    </Typography>
  )

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={isEmpty(quote?.PolicyHolderClaimant) && !formik.isSubmitting}
        title="Tomador"
        subtitle="Informe abaixo os dados do tomador"
      >
        <Grid container spacing={5}>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="policyHolderLabel" className={classes.label}>{policyHolderLabel}</label>
              <Autocomplete
                id="policyHolder"
                options={policyHolderOptions}
                disabled={hasUniqueClient}
                noOptionsText={noOptionsMessage}
                getOptionLabel={(option) => option ?? ''}
                onInputChange={(e, value) => {
                  formik.values.policyHolder = value ?? ''
                  handlePolicyHolder(value)
                }}
                getOptionSelected={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onFocus={(event) => event.target.setAttribute('autocomplete', 'none')}
                    placeholder="Procurar"
                    margin="normal"
                    error={formik.touched.policyHolder && !!formik.errors.policyHolder}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {...formik.getFieldProps('policyHolder')}
              />
              <FormHelperText
                hidden={!formik.touched.policyHolder || !formik.errors.policyHolder}
                error={formik.touched.policyHolder && !!formik.errors.policyHolder}
              >
                {formik.errors.policyHolder}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="cpfCnpjLabel" className={classes.label}>{cpfCnpjLabel}</label>
              <CPFOrCNPJInput
                fullWidth
                id="document"
                color="secondary"
                disabled={hasUniqueClient}
                placeholder={placeholderCPFCNPJ}
                {...formik.getFieldProps('document')}
                error={formik.touched.document && !!formik.errors.document}
              />
              <FormHelperText
                hidden={!formik.touched.document || !formik.errors.document}
                error={formik.touched.document && !!formik.errors.document}
              >
                {formik.errors.document}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.buttonsStep}>
              <Box>
                <ButtonLight
                  onClick={handlePrevious}
                  title="Voltar"
                >
                  Voltar
                </ButtonLight>
              </Box>
              <Box ml={4}>
                <ButtonGradient
                  onClick={handleNext}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
            <ReactTooltip place="top" type="dark" />
          </Grid>
        </Grid>
      </CollapsePrice>
    </>
  )
})

PolicyHolderClaimant.propTypes = {
  quote: PropTypes.any,
  checked: PropTypes.bool,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

PolicyHolderClaimant.defaultProps = {
  checked: false,
  quote: undefined,
}

export default PolicyHolderClaimant
