import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useHistory, useLocation } from 'react-router-dom'

import useSecurity from '../security/useSecurity'
import { useAlert } from '../shared/components'
import { ROTAS_DE_FORMULARIOS, ROUTES_EXECUTIVE_REQUEST } from '../constants'

axios.timeout = 60000
axios.defaults.baseURL = process.env.REACT_APP_BFF_URL

const AxiosSetting = ({
  handleError,
  handleShowLoading,
  onStopRequest: stopRequest,
  onStartRequest: startRequest,
}) => {
  const {
    verifyToken,
    getUserFirebase,
  } = useSecurity()

  const history = useHistory()
  const location = useLocation()

  const { addMsgDanger } = useAlert()
  const [count, setCount] = useState(0)
  const [responseError, setResponseError] = useState()

  const getTokenUrl = location?.pathname?.split(':')
  const tokenParams = getTokenUrl[1]
  const routeSolicitation = getTokenUrl[0]

  const catchResponse = (response) => {
    setResponseError(response)
    setCount((current) => current - 1)
    return Promise.reject(response)
  }

  useEffect(() => {
    const requestId = axios.interceptors.request.use((config) => {
      handleShowLoading(!config?.noLoading)
      setCount((current) => current + 1)

      const newConfig = { ...config }

      const { headers } = newConfig

      if (ROUTES_EXECUTIVE_REQUEST.includes(routeSolicitation)) {
        headers.Authorization = `Bearer ${tokenParams}`
      }

      if (!ROUTES_EXECUTIVE_REQUEST.includes(routeSolicitation)) {
        const currentUser = getUserFirebase()
        if (currentUser && !config.url.substring(0, 6).includes('http')) {
          if (ROTAS_DE_FORMULARIOS.includes(location?.pathname)) {
            verifyToken(currentUser)
            currentUser.getIdToken().then((idToken) => {
              headers.Authorization = `Bearer ${idToken}`
            })
          }
          currentUser.getIdToken().then((idToken) => {
            headers.Authorization = `Bearer ${idToken}`
          })
        }
      }

      return newConfig
    }, (error) => {
      setCount((current) => current - 1)
      return Promise.reject(error)
    })

    const responseId = axios.interceptors.response.use((response) => {
      setCount((current) => current - 1)
      if (response?.data) {
        if (response?.data?.StatusCode
          && response?.data?.StatusCode !== null
          && response?.data?.StatusCode !== 200
        ) {
          const data = new Error(response?.data?.Message)
          data.status = response?.data?.StatusCode
          return catchResponse({ data })
        }

        if (response?.data?.Data
          && response?.data?.Data !== null
        ) {
          response.data = response?.data?.Data
        }
      }

      return response
    }, (error) => {
      let { response = {} } = error

      if (response.status === 401) {
        addMsgDanger({
          title: 'Sessão expirada!',
          message: 'Faça o login novamente!',
          labelButton: 'Fechar',
          action: () => window.location.reload(),
        })
      }

      if (response.status === 403) {
        response = { ...response, data: '' }
      }

      if (response.status === 404) {
        response = { ...response, data: { title: response.status, message: 'Serviço Indisponível!' } }
      }

      if (response.status === 500) {
        response = {
          ...response,
          data: {
            title: `Erro Interno - ${response.status}`,
            message: 'Contate o administrador do sistema!',
          },
        }
      }

      return catchResponse(response)
    })

    return () => {
      axios.interceptors.request.eject(requestId)
      axios.interceptors.response.eject(responseId)
    }
  }, [
    history,
    verifyToken,
    addMsgDanger,
    getUserFirebase,
    handleShowLoading,
    location,
    tokenParams,
    routeSolicitation,
  ])

  useEffect(() => {
    if (count === 1) {
      startRequest()
    }

    if (count === 0) {
      stopRequest()
    }
  }, [count, startRequest, stopRequest])

  useEffect(() => {
    if (responseError) {
      const handle = handleError[responseError.status]

      if (handle) {
        handle(responseError)
      }
    }
  }, [responseError, handleError])

  return <></>
}

AxiosSetting.propTypes = {
  handleError: PropTypes.object,
  handleShowLoading: PropTypes.func.isRequired,
  onStartRequest: PropTypes.func.isRequired,
  onStopRequest: PropTypes.func.isRequired,
}

AxiosSetting.defaultProps = {
  handleError: {},
}

export default AxiosSetting
