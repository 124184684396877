/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import CircularProgress from '@material-ui/core/CircularProgress'

import { useHistory, useLocation } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { ReactComponent as DownloadIcon } from '../../../assets/img/icons/ico-download.svg'

import {
  useAlert,
  useLoader,
  ButtonLight,
  ButtonGradient,
} from '../../../shared/components'
import { useEndorsement } from '../../../shared/contexts/EndorsementContext'
import useUtils from '../../../shared/hooks/useUtils'
import useDownload from '../../../shared/hooks/useDownload'

import useQuotationClient from '../../../clients/QuotationClient/useQuotationClient'
import useObjects from '../utils/useObjects'

import {
  STATUS_QUOTE_APPROVED,
  ENDORSEMENT_URL_EXECUTIVE_REQUEST,
} from '../../../constants'
import ExecutiveRedirectModal from '../../executive-portal-request/ExecutiveRedirectModal'

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    gap: 16,
  },
  header: {
    border: 0,
    outline: 0,
    fontSize: 14,
    fontWeight: 'bold',
    position: 'relative',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    '&:before': {
      left: 0,
      top: '50%',
      height: 0.5,
      width: '100%',
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.text.light,
    },
    '&:after': {
      paddingRight: '1em',
      lineHeight: '1.5em',
      position: 'relative',
      display: 'inline-block',
      backgroundColor: '#FFFFFF',
      content: 'attr(data-content)',
      color: theme.palette.text.primary,
    },
  },
  confirmationBox: {
    display: 'flex',
    borderLeft: '0px',
    borderRight: '0px',
    marginBlock: theme.spacing(1),
    '& .MuiGrid-item': {
      marginBottom: theme.spacing(1),
    },
  },
  confirmationLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.text.quaternary,
  },
  confirmationValue: {
    color: theme.palette.text.quaternary,
  },
  containerFileUpload: {
    marginBottom: '20px',
  },
  fileUpload: {
    display: 'flex',
    marginTop: '20px',
  },
  buttonsStep: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& .MuiBox-root': {
      minWidth: 190,
    },
    '@media (max-width: 699px)': {
      justifyContent: 'center',
    },
  },
}))

const SECONDS_WAITING = 6000
const MAX_SECONDS_WAITING = 90000

const ConfirmationDetails = ({ handlePrevious, handleNext }) => {
  const classes = useStyles()
  const { endorsement, setEndorsement, policy } = useEndorsement()
  const { downloadPDF } = useDownload()
  const { showLoader, hideLoader } = useLoader()
  const { addMsgSuccess, addMsgDanger } = useAlert()
  const { formatDate, formatCurrency, formatCPFOrCNPJ } = useUtils()
  const history = useHistory()
  const location = useLocation()
  const client = useQuotationClient()
  const objectsMaker = useObjects()

  const [buttonDisabled, setButtonDisabled] = useState(false)

  const [identifierForExecutiveRedirect, setIdentifierForExecutiveRedirect] = useState('')
  const [openExecutiveRedirectModal, setExecutiveRedirectModal] = useState(false)

  const getTokenUrl = location?.pathname?.split(':')
  const routeSolicitation = getTokenUrl[0]
  const tokenParams = getTokenUrl[1]

  const trueORFalse = (value) => {
    switch (value) {
      case 'true':
        return 'Sim'
      case 'false':
        return 'Não'
      default:
        return ''
    }
  }

  const handleExecutiveRedirectModal = (executiveSolicitationIdentifier) => {
    setIdentifierForExecutiveRedirect(executiveSolicitationIdentifier)
    setExecutiveRedirectModal(true)
  }

  const sendTryGetEndorsement = (identifier) => {
    let promiseRequest = null
    let countSecondsWaiting = 1

    showLoader({
      title: 'Aguarde,',
      subtitle: 'estamos processando sua solicitação...',
    })

    const intervalToken = setInterval(() => {
      if (countSecondsWaiting * SECONDS_WAITING >= MAX_SECONDS_WAITING
        || ENDORSEMENT_URL_EXECUTIVE_REQUEST === routeSolicitation) {
        hideLoader()

        const dataSuccess = {
          title: 'Sua solicitação ainda está sendo processada.',
          message: 'Estamos processando sua solicitação. Enquanto isso você pode acompanhar o status da sua solicitação.',
          labelButton: 'Acompanhe sua solicitação',
          action: () => history.replace('/status-solicitacao'),
        }

        if (ENDORSEMENT_URL_EXECUTIVE_REQUEST === routeSolicitation) {
          handleExecutiveRedirectModal(identifier)
        } else {
          addMsgSuccess(dataSuccess)
        }

        clearInterval(intervalToken)

        return
      }

      if (!promiseRequest) {
        promiseRequest = null

        client().getQuotationDetails(identifier).then((response) => {
          const {
            Status, Quote, QuoteDenied,
          } = response?.data ?? {}

          if (Status === STATUS_QUOTE_APPROVED && ENDORSEMENT_URL_EXECUTIVE_REQUEST !== routeSolicitation) {
            promiseRequest = 'success'

            setEndorsement({
              ...endorsement,
              grossPremium: Quote?.GrossPremium ?? 0,
              commercialPremium: Quote?.CommercialPremium ?? 0,
              IOF: Quote?.IOF ?? 0,
              identifier,
              quoteCreated: true,
            })

            hideLoader()
            clearInterval(intervalToken)
            handleNext()
            return
          }

          if (QuoteDenied && ENDORSEMENT_URL_EXECUTIVE_REQUEST !== routeSolicitation) {
            promiseRequest = 'success'

            const dataDenied = {
              title: 'Sua solicitação está em análise.',
              message: 'Estamos analisando sua solicitação. Enquanto isso você pode acompanhar o status da sua solicitação.',
              labelButton: 'Acompanhe sua solicitação',
              action: () => history.replace('/status-solicitacao'),
            }

            addMsgSuccess(dataDenied)
            hideLoader()
            clearInterval(intervalToken)
          }
        }, () => {
          promiseRequest = null
        })
      }

      countSecondsWaiting++
    }, SECONDS_WAITING)
  }

  const handleHireEndorsement = () => {
    setButtonDisabled(true)
    const { policyNumber } = endorsement?.endorsementRequest ?? {}

    const payload = objectsMaker.makePayloadCreateEdit(endorsement, policy, policyNumber)

    if (endorsement?.quoteCreated) {
      const payloadEdit = {
        identifier: endorsement?.identifier,
        ...payload,
      }

      client().edit(payloadEdit).then((response) => {
        const { Identifier } = response?.data ?? {}
        sendTryGetEndorsement(Identifier)
      }, () => {
        addMsgDanger('Algo deu errado ao atualizar sua solicitação. Verifique os dados e tente novamente.')
      })
    } else {
      client().sendEndorsementAutomatic(payload).then((response) => {
        const { Identifier } = response?.data ?? {}
        sendTryGetEndorsement(Identifier)
      }, () => {
        addMsgDanger('Algo deu errado com sua solicitação. Verifique os dados e tente novamente.')
      })
    }
  }

  const downloadDocuments = (document) => {
    client().downloadDocumentsWings(document).then((response) => {
      downloadPDF(response?.data, document)
    }, () => {
      addMsgDanger('Não foi possível baixar o arquivo, tente mais tarde ou contate o Administrador.')
    })
  }

  return (
    <Box className={classes.root}>
      <Paper elevation={1}>
        <Box p={4}>
          <Grid container className={classes.mainGrid}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" color="initial">
                    Confirme os dados da sua cotação
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.labelInfo}>
                    Confira abaixo as informações da sua cotação
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.content}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Grid container className={classes.root}>
                    <Grid item xs={12}>
                      <Typography gutterBottom className={classes.header} data-content="Detalhes do Produto" />

                      <Grid container className={classes.confirmationBox} spacing={2}>
                        <Grid item xs={12} md={3}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Produto:
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {endorsement?.Product?.Name ?? 'Seguro Garantia'}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={3}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Modalidade:
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            Performance
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Tipo:
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {endorsement?.endorsementRequest?.endorsementType}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={3}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Número do documento:
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {endorsement?.endorsementRequest?.policyNumber}
                          </Typography>
                        </Grid>
                        {endorsement?.newStateDocument?.civilWork && (
                          <Grid item xs={12} md={6}>
                            <Typography gutterBottom className={classes.confirmationLabel}>
                              Obra Civil:
                            </Typography>

                            <Typography variant="subtitle1" className={classes.confirmationValue}>
                              {trueORFalse(endorsement?.newStateDocument?.civilWork)}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography gutterBottom className={classes.header} data-content="Dados do Segurado" />

                      <Grid container className={classes.confirmationBox} spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Tomador:
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {endorsement?.endorsementRequest?.policy?.policyHolderName}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={3}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            CPF/CNPJ Segurado:
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {formatCPFOrCNPJ(endorsement?.newStateDocument?.document)}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Nome do Segurado:
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {endorsement?.newStateDocument?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography gutterBottom className={classes.header} data-content="Objeto Segurado" />

                      <Grid container className={classes.confirmationBox} spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Obrigação Garantia (Objeto):
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {endorsement?.newStateDocument?.objectText}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Valor Contrato:
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {formatCurrency(endorsement?.newStateDocument?.contractValue)}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={8}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Valor Garantia:
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {formatCurrency(endorsement?.newStateDocument?.guaranteeValue)}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Início de Vigência:
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {formatDate(endorsement?.newStateDocument?.startDateValidity)}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Fim de Vigência:
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {formatDate(endorsement?.newStateDocument?.endDateValidity)}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Prazo (Dias):
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {endorsement?.newStateDocument?.deadline}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography gutterBottom className={classes.header} data-content="Solicitante" />

                      <Grid container className={classes.confirmationBox} spacing={2}>
                        <Grid item xs={12} md={4}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Nome do Solicitante:
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {endorsement?.requester?.name}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Telefone do Solicitante:
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {endorsement?.requester?.phone}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            E-mail do Solicitante:
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {endorsement?.requester?.email}
                          </Typography>
                        </Grid>

                        {endorsement?.requester?.customerControlIdentifier && (
                          <Grid item xs={12} md={4}>
                            <Typography gutterBottom className={classes.confirmationLabel}>
                              Identificador do Controle do Cliente
                            </Typography>

                            <Typography variant="subtitle1" className={classes.confirmationValue}>
                              {endorsement?.requester?.customerControlIdentifier}
                            </Typography>
                          </Grid>
                        )}

                        <Grid item xs={12} md={8}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Observações:
                          </Typography>

                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {endorsement?.requester?.note}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {endorsement?.endorsementDocuments && (
                      <Grid item xs={12} className={classes.containerFileUpload}>
                        <Typography
                          gutterBottom
                          className={classes.header}
                          data-content="Arquivos"
                        />
                        <Grid className={classes.fileUpload}>
                          <Grid item xs={4}>
                            <Typography gutterBottom className={classes.confirmationLabel}>
                              Nome do Arquivo:
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography gutterBottom className={classes.confirmationLabel}>
                              Download:
                            </Typography>
                          </Grid>
                        </Grid>
                        {(endorsement?.endorsementDocuments?.documents
                          ?? endorsement?.endorsementDocuments)
                          ?.map((item, index) => (
                            <Grid key={index} item xs={12} className={classes.fileUpload}>
                              <Grid item xs={4}>
                                <Typography className={classes.confirmationValue}>
                                  {item?.DocumentName ?? item?.documentName}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Box>
                                  <Button
                                    variant="text"
                                    onClick={() => {
                                      downloadDocuments(item?.DocumentID ?? item?.documentID)
                                    }}
                                  >
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                      <Box mr={1}>
                                        <DownloadIcon />
                                      </Box>

                                      <Box sx={{ textDecoration: 'underline', fontSize: 17 }}>
                                        <b>Download</b>
                                      </Box>
                                    </Box>
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box className={classes.buttonsStep}>
                    <Box>
                      <ButtonLight
                        onClick={() => {
                          setEndorsement({ ...endorsement, isEditing: true })
                          handlePrevious()
                        }}
                        title="Editar"
                      >
                        Editar
                      </ButtonLight>
                    </Box>

                    <Box>
                      <ButtonGradient
                        title="Continuar"
                        disabled={buttonDisabled}
                        onClick={handleHireEndorsement}
                      >
                        {endorsement?.quoteCreated ? 'Seguir com Emissão' : 'Continuar'}
                      </ButtonGradient>
                    </Box>
                    {buttonDisabled && (
                      <Box
                        mt={1.5}
                        ml={1}
                      >
                        <CircularProgress style={{ width: 30, height: 30 }} />
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      {openExecutiveRedirectModal && (
        <ExecutiveRedirectModal
          identifier={identifierForExecutiveRedirect}
          tokenParams={tokenParams}
        />
      )}
    </Box>
  )
}

ConfirmationDetails.propTypes = {
  handlePrevious: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
}

export default ConfirmationDetails
