import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    animation: 'fadein 0.5s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 0',
    transition: 'background-image 1s linear',
    '& .MuiGrid-container': {
      left: 1,
      height: '100%',
      position: 'relative',
      '& .MuiTypography-root': {
        color: theme.palette.text.secondary,
      },
    },
  },
  success: {
    '& .MuiTypography-root': {
      color: `${theme.palette.text.quaternary} !important`,
    },
  },
  active: {
    '& .MuiTypography-root': {
      MozBackgroundClip: 'text',
      MozTextFillColor: 'transparent',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      backgroundImage: `linear-gradient(to right, #19BDDDCC, ${theme.palette.primary.main}CC)`,
    },
  },
}))

export default useStyles
