import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'

import SVG from 'react-inlinesvg'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import makeStyles from '@material-ui/core/styles/makeStyles'

import {
  ButtonGradient,
  ModalInfo,
  useAlert,
  SlashScreen,
} from '../../../../../../shared/components'
import { useQuotationClient } from '../../../../../../clients/QuotationClient'

import ModalQuestionOne from './ModalQuestionOne'

import iconPapel from '../../../../../../assets/img/icons/ico-papel.svg'
import iconCheck from '../../../../../../assets/img/icons/icon-check.svg'

const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  textLoading: {
    marginTop: 10,
    fontSize: 15,
    color: 'white',
    marginBottom: 60,
  },
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 562,
      maxHeight: 501,
    },
  },
}))

const GUARANTEE_PERFORMANCE = 'garantia-performance'

const QuestionModal = ({
  quoteDetails, onClickSubmit, identifier, isConstruction,
}) => {
  const classes = useStyles()
  const { addMsgDanger } = useAlert()
  const quotationClient = useQuotationClient()
  const [isShowModalSelected, setIsShowModalSelected] = useState(0)
  const [openModalFinished, setOpenModalFinished] = useState(false)
  const [openModalThanksAnswer, setOpenModalThanksAnswer] = useState(false)

  const nextModal = useCallback((value) => {
    setIsShowModalSelected(value)
  }, [])

  const closeModal = (value) => {
    if (value === true) {
      setIsShowModalSelected(3)
      onClickSubmit(true)
    }
  }

  const fileIdList = quoteDetails?.Attachments?.Documents?.map((document) => document?.documentID)

  const payload = {
    tomador: quoteDetails?.Insured?.PolicyHolder,
    cpfCnpjTomador: quoteDetails?.Insured?.DocumentNumber,
    produto: GUARANTEE_PERFORMANCE,
    cpfCnpjSegurado: quoteDetails?.Insured?.Document,
    segurado: quoteDetails?.Insured?.Name,
    valorContrato: quoteDetails?.ObjectProperty?.ContractValue,
    numeroDoEdital: quoteDetails?.ObjectProperty?.NoticeNumber,
    numeroDoContrato: quoteDetails?.ObjectProperty?.ContractNumber,
    numeroDoAditivo: quoteDetails?.ObjectProperty?.AdditiveNumber,
    numeroDoProcesso: quoteDetails?.ObjectProperty?.ProcessNumber,
    emailSolicitante: quoteDetails?.Requester?.Email,
    telefoneSolicitante: quoteDetails?.Requester?.Phone?.replace(/[^0-9]/g, ''),
    solicitante: quoteDetails?.Requester?.Name,
    identificador: identifier,
    coberturaMulta: quoteDetails?.Rule?.CoverageFine,
    coberturaClausulaTrabalhista: quoteDetails?.Rule?.CoverageLabor,
    importanciaSegurada: quoteDetails?.ObjectProperty?.GuaranteeAmount,
    finalDaVigencia: quoteDetails?.ObjectProperty?.EndDateValidity,
    inicioDeVigencia: quoteDetails?.ObjectProperty?.StartDateValidity,
    objetoTexto: quoteDetails?.ObjectProperty?.ObjectText,
    percentualAgravo: quoteDetails?.ObjectProperty?.PercentageGuarantee,
    prazoEmDias: quoteDetails?.ObjectProperty?.Deadline,
    valorGarantia: quoteDetails?.ObjectProperty?.GuaranteeAmount,
    IdsAnexosWings: fileIdList,
  }

  const errorMessage = (err) => {
    addMsgDanger({
      title: err?.data,
      message: 'Contate o administrador do sistema!',
      labelButton: 'Fechar',
    })
    setTimeout(() => {
      onClickSubmit(true)
      setIsShowModalSelected(3)
    }, 1000)
  }

  const questionSubmit = () => {
    quotationClient().sendRERequestPerformance({
      ...payload,
      ExigeSeguro: true,
    }).then(() => {
      setOpenModalFinished(true)
      setTimeout(() => {
        nextModal(2)
      }, 1000)
    }, (err) => {
      errorMessage(err)
    })
  }

  const thanksAnswerModal = (ExigeSeguro) => {
    quotationClient().sendRERequestPerformance({
      ...payload,
      ExigeSeguro,
    }).then(() => {
      setOpenModalThanksAnswer(true)
      setTimeout(() => {
        nextModal(4)
      }, 1000)
    }, (err) => {
      errorMessage(err)
    })
  }

  const resetTimeout = useRef(null)
  const runTimer = useCallback(() => {
    resetTimeout.current = setTimeout(() => {
      nextModal(3)
      onClickSubmit(true)
    }, 220000)
  }, [nextModal, onClickSubmit])

  useEffect(() => {
    if (isShowModalSelected === 0) {
      runTimer()
    }
    if (isShowModalSelected === 1) {
      clearInterval(resetTimeout.current)
      runTimer()
    }
    if (isShowModalSelected === 4) {
      setOpenModalThanksAnswer(true)
    }
  }, [isShowModalSelected, runTimer, resetTimeout])

  return (
    <SlashScreen>
      <Box className={classes.container}>
        <Typography align="center" variant="h6">
          <div className={classes.textLoading}>
            Aguarde,
            <br />
            estamos processando sua solicitação...
            <br />
          </div>
        </Typography>
        {isShowModalSelected === 0 && (
          <ModalQuestionOne
            text={isConstruction
              ? 'Sendo um contrato de obras civis, instalação e montagem, deseja receber uma cotação de seguro de risco de engenharia e/ou seguro de responsabilidade civil?'
              : 'Sendo um contrato de prestação de serviços, deseja receber uma cotação do seguro responsabilidade civil geral?'}
            nextModal={nextModal}
            closeModal={closeModal}
            questionSubmit={questionSubmit}
            thanksAnswerModal={thanksAnswerModal}
            payload={payload}
            onClickSubmit={onClickSubmit}
            setIsShowModalSelected={setIsShowModalSelected}
            setOpenModalThanksAnswer={setOpenModalThanksAnswer}
          />
        )}

        {(isShowModalSelected === 2) && (
          <ModalInfo
            close={false}
            open={openModalFinished}
            className={classes.modal}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box>
                <SVG src={iconPapel} />
              </Box>
              <Box mt={5} textAlign="center">
                <Typography style={{ fontSize: 20, color: '#3F4159', fontWeight: 600 }}>
                  Obrigado, o executivo responsável entrará em contato o mais breve possível.
                </Typography>
              </Box>
              <Box mt={5} mb={5} width={200}>
                <ButtonGradient
                  onClick={() => {
                    onClickSubmit(true)
                    setIsShowModalSelected(3)
                  }}
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
          </ModalInfo>
        )}
        {(isShowModalSelected === 3) && (
          <></>
        )}
        {(isShowModalSelected === 4) && (
          <ModalInfo
            close={false}
            open={openModalThanksAnswer}
            className={classes.modal}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box>
                <SVG src={iconCheck} />
              </Box>
              <Box mt={5} textAlign="center">
                <Typography style={{ fontSize: 20, color: '#3F4159', fontWeight: 600 }}>
                  Obrigado pela resposta.
                </Typography>
              </Box>
              <Box mt={5} mb={5} width={200}>
                <ButtonGradient
                  onClick={() => {
                    onClickSubmit(true)
                    setIsShowModalSelected(3)
                  }}
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
          </ModalInfo>
        )}
      </Box>
    </SlashScreen>
  )
}

QuestionModal.propTypes = {
  identifier: PropTypes.string,
  onClickSubmit: PropTypes.func,
  quoteDetails: PropTypes.object,
  isConstruction: PropTypes.bool,
}

QuestionModal.defaultProps = {
  quoteDetails: {},
  onClickSubmit: () => { },
  identifier: '',
  isConstruction: true,
}

export default QuestionModal
