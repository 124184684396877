const InsuredObjectPropertyData = ({
  Deadline,
  GuaranteeValue,
  ObjectText,
  NoticeNumber,
  ContractValue,
  EndDateValidity,
  StartDateValidity,
  ComplementObjectText,
}) => ({
  deadline: Deadline ?? '',
  guaranteeValue: GuaranteeValue ?? 0,
  objectText: ObjectText ?? '',
  noticeNumber: NoticeNumber ?? '',
  contractValue: ContractValue ?? 0,
  endDateValidity: EndDateValidity ?? '',
  startDateValidity: StartDateValidity ?? '',
  complementObjectText: ComplementObjectText ?? '',
})

export default InsuredObjectPropertyData
