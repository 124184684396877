import React from 'react'

import BackgroundCotacao from '../../assets/img/backgrounds/bg-solicitar-cotacao.webp'
import BackgroundStatusCotacao from '../../assets/img/backgrounds/bg-status-cotacao.webp'

const LeaseBail = React.lazy(() => import('./quotations/lease-bail/Quotation'))
const GuaranteeBID = React.lazy(() => import('./quotations/guarantee-bid/Quotation'))
const EngineeringRisk = React.lazy(() => import('./quotations/engineering-risk/Quotation'))
const GuaranteePerformance = React.lazy(() => import('./quotations/guarantee-performance/Quotation'))
const JudicialGuaranteeCivel = React.lazy(() => import('./quotations/judicial-guarantee-civel/Quotation'))
const JudicialGuaranteeAppeal = React.lazy(() => import('./quotations/judicial-guarantee-appeal/Quotation'))
const JudicialGuaranteeLabor = React.lazy(() => import('./quotations/judicial-guarantee-labor/Quotation'))
const OtherGuarantee = React.lazy(() => import('./quotations/guarantee-other/Quotation'))

const QuotationDetails = React.lazy(() => import('./quotations/QuotationDetails'))

const PriceRoutes = [
  {
    path: '/:quotationIdentifier/status',
    Component: QuotationDetails,
    bgImage: BackgroundStatusCotacao,
  },
  {
    path: '/confirmacao',
    Component: QuotationDetails,
    bgImage: BackgroundStatusCotacao,
  },
  {
    path: '/fianca-locaticia',
    Component: LeaseBail,
    bgImage: BackgroundCotacao,
  },
  {
    path: '/judicial-deposito-recursal',
    Component: JudicialGuaranteeAppeal,
    bgImage: BackgroundCotacao,
  },
  {
    path: '/judicial-trabalhista',
    Component: JudicialGuaranteeLabor,
    bgImage: BackgroundCotacao,
  },
  {
    path: '/judicial-civel',
    Component: JudicialGuaranteeCivel,
    bgImage: BackgroundCotacao,
  },
  {
    path: '/garantia/licitante',
    Component: GuaranteeBID,
    bgImage: BackgroundCotacao,
  },
  {
    path: '/garantia/performance',
    Component: GuaranteePerformance,
    bgImage: BackgroundCotacao,
  },
  {
    path: '/risco-engenharia',
    Component: EngineeringRisk,
    bgImage: BackgroundCotacao,
  },
  {
    path: '/garantia',
    Component: OtherGuarantee,
    bgImage: BackgroundCotacao,
  },
]

export default PriceRoutes
