import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { PropTypes } from 'prop-types'

import Box from '@material-ui/core/Box'

import makeStyles from '@material-ui/core/styles/makeStyles'

import useStyles from '../EclipseNumber/useStyles'
import EclipseNumber from '../EclipseNumber/EclipseNumber'

import vectEclipsePrimary from '../../../assets/img/vectors/vect-eclipse-primary.svg'
import vectEclipseSecondary from '../../../assets/img/vectors/vect-eclipse-secondary.svg'
import vectEclipseSuccess from '../../../assets/img/vectors/vect-eclipse-success.svg'

const useCustomStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    width: 60,
    height: 60,
    borderRadius: '50%',
    boxShadow: '0px 4px 10px #00000029',
    '& .EclipseNumber-root': {
      margin: 0,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      width: 50,
      height: 50,
    },
  },
}))

const EclipseStep = (props) => {
  const { active, completed, icon } = props

  const classes = useStyles()
  const classesCustom = useCustomStyles()

  const [bgState, setBgState] = useState(vectEclipseSecondary)

  useEffect(() => {
    if (active) {
      setBgState(vectEclipsePrimary)
    }

    if (completed) {
      setBgState(vectEclipseSuccess)
    }

    if (!active && !completed) {
      setBgState(vectEclipseSecondary)
    }
  }, [active, completed, setBgState])

  return (
    <Box className={clsx(
      classes.root,
      classesCustom.root,
      active && classes.active,
      completed && classes.success,
    )}
    >
      <EclipseNumber step number={icon} bgState={bgState} />
    </Box>
  )
}

EclipseStep.propTypes = {
  icon: PropTypes.number,
  active: PropTypes.any,
  completed: PropTypes.any,
}

EclipseStep.defaultProps = {
  icon: 1,
  active: false,
  completed: false,
}

export default EclipseStep
