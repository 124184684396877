const InsuredObjectPropertyData = ({
  Deadline,
  ObjectText,
  NoticeNumber,
  ProcessNumber,
  ContractValue,
  ContractNumber,
  AdditiveNumber,
  GuaranteeAmount,
  EndDateValidity,
  StartDateValidity,
  PercentageGuarantee,
  ComplementObjectText,
}) => ({
  deadline: Deadline ?? '',
  objectText: ObjectText ?? '',
  noticeNumber: NoticeNumber ?? '',
  processNumber: ProcessNumber ?? '',
  contractValue: ContractValue ?? '',
  contractNumber: ContractNumber ?? '',
  additiveNumber: AdditiveNumber ?? '',
  guaranteeAmount: GuaranteeAmount ?? 0,
  endDateValidity: EndDateValidity ?? '',
  startDateValidity: StartDateValidity ?? '',
  percentageGuarantee: PercentageGuarantee ?? '',
  complementObjectText: ComplementObjectText ?? '',
})

export default InsuredObjectPropertyData
