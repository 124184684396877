/* eslint-disable no-dupe-keys */
import React from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ArrowForwardIos } from '@material-ui/icons'

import bgImage from '../../../assets/img/vectors/vect-waves.svg'

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: 'flex',
    minHeight: 180,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: `${props.backgroundColor}`,
    ...(props.type === 'approved' && ({
      background: 'rgb(51,136,90)',
      background: 'linear-gradient(90deg, rgba(51,136,90,1) 0%, rgba(39,150,90,1) 19%, rgba(35,173,99,1) 100%)',
    })),
    ...(props.type === 'analysing' && ({
      background: 'rgb(255,198,84)',
      background: 'linear-gradient(90deg, rgba(255,198,84,1) 0%, rgba(240,178,55,1) 84%, rgba(242,179,52,1) 100%)',
    })),
    flexDirection: 'column',
    position: 'relative',
    borderRadius: 24,
    '&:before': {
      zIndex: 1,
      content: '""',
      width: '100%',
      height: '100%',
      borderRadius: 14,
      position: 'absolute',
      backgroundPosition: '0px -10px',
      filter: `${props.type === 'approved' ? 'brightness(1.2)' : 'sepia(1)'}`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${props.bgImage})`,
    },
    '& .MuiCardContent-root': {
      display: 'flex',
      flex: '1 0 auto',
      zIndex: 2,
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  }),
  contentBox: {
    '& .MuiTypography-root': {
      color: theme.palette.text.primary.contrasText,
    },
  },
}))

const StatusQuotationPanel = (props) => {
  const {
    type,
    Icon,
    title,
    color,
    amount,
    onClick,
    backgroundColor,
  } = props

  const classes = useStyles({ backgroundColor, bgImage, type })

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" width={1}>
            <Box mb={2} hidden={!Icon} height={38}>
              <Icon fontSize="medium" style={{ color: '#FFFFFF' }} />
            </Box>

            <Box display="flex" flexDirection="column" textAlign="end">
              <Box>
                <Typography variant="h3" style={{ color }}>
                  {amount}
                </Typography>
              </Box>

              <Box mb={1}>
                <Box className={classes.contentBox}>
                  <Typography variant="h6" style={{ color, fontWeight: 500 }}>
                    {title}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Typography
                  variant="h6"
                  color="secondary"
                  style={{
                    fontSize: 16, cursor: 'pointer', display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'end',
                  }}
                  onClick={onClick}
                >
                  Ver todas

                  <ArrowForwardIos fontSize="small" style={{ color: '#FFFFFF' }} />
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  )
}

StatusQuotationPanel.propTypes = {
  Icon: PropTypes.any,
  type: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
  amount: PropTypes.number,
  title: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
}

StatusQuotationPanel.defaultProps = {
  amount: 0,
  Icon: undefined,
  color: '#000000',
  onClick: () => { },
}

export default StatusQuotationPanel
