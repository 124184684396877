import { useCallback } from 'react'
import axios from 'axios'

const useBrokerClient = () => {
  const getStatesIbge = useCallback(async () => axios.get([process.env.REACT_APP_URL_API_IBGE, 'localidades/estados'].join('/')).then((res) => res?.data), [])

  const getCitiesIbge = useCallback(async (selectedState) => axios.get(
    [process.env.REACT_APP_URL_API_IBGE, 'localidades/estados', selectedState, 'municipios'].join('/'),
  ).then((res) => res?.data), [])

  return useCallback(() => ({
    getStatesIbge,
    getCitiesIbge,
  }), [
    getStatesIbge,
    getCitiesIbge,
  ])
}

export default useBrokerClient
