import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'

import makeStyles from '@material-ui/core/styles/makeStyles'

import { VpnKey, AccountCircle, PowerSettingsNewOutlined } from '@material-ui/icons'

import useUtils from '../../hooks/useUtils'
import useSecurity from '../../../security/useSecurity'

import useUserClient from '../../../clients/UserClient/useUserClient'

import {
  ButtonLight,
  ButtonGradient,
  ModalInfo,
  useAlert,
} from '../../components'

const useStyles = makeStyles((theme) => ({
  profilesMenu: {
    padding: 0,
    borderBottom: '1px solid #8080805e',
    '& .MuiList-root': {
      padding: 0,
      '& .MuiListItem-root': {
        paddingBlock: 8,
        paddingInline: 30,
      },
    },
    '& .MuiListItemAvatar-root': {
      minWidth: 'inherit',
      '& .MuiAvatar-root': {
        width: '45px',
        height: '45px',
      },
    },
    '& .MuiTypography-subtitle1': {
      fontSize: 14,
      display: 'flex',
    },
    '& .MuiTypography-subtitle2': {
      fontWeight: 'bold',
    },
    '& .MuiListItemText-multiline': {
      margin: 0,
    },
  },
  buttonsStep: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& .MuiBox-root': {
      minWidth: 190,
    },
    '@media (max-width: 699px)': {
      justifyContent: 'center',
    },
  },
  buttonsBox: {
    '& > .MuiBox-root': {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: '1 1 33.3%',
        justifyContent: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        flex: '1 1 100%',
      },
    },
  },
  buttonMenu: {
    border: 'none',
    width: '100%',
    borderRadius: 0,
    fontWeight: 600,
    '& .MuiButton-label': {
      justifyContent: 'left',
    },
  },
  buttonPwd: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  iconForm: {
    color: theme.palette.text.secondary,
    fontSize: '22px',
  },
}))

const UserOptionMenu = ({ logout }) => {
  const classes = useStyles()
  const { addMsgSuccess, addMsgDanger } = useAlert()
  const {
    isBlank,
  } = useUtils()

  const userClient = useUserClient()

  const {
    getName,
    avatarURL,
    getEmail,
    isUserResponsibleClient,
  } = useSecurity()

  const [openModalChangePassword, setOpenModalChangePassword] = useState(false)

  return (
    <>
      {openModalChangePassword && (
        <ModalInfo
          close={false}
          open={openModalChangePassword}
          className={classes.modal}
        >
          <Box p={2} alignItems="center" justifyContent="center">
            <Box>
              <Box mt={2} mb={7}>
                <Typography align="center" variant="h5">Alterar Senha</Typography>
              </Box>

              <Box mb={7}>
                <Typography color="primary" align="center">
                  Tem certeza que deseja alterar sua senha?
                </Typography>
              </Box>

              <Grid item xs={12}>
                <Box justifyContent="center" className={classes.buttonsStep}>
                  <Box>
                    <ButtonGradient onClick={() => {
                      userClient().requestPassword(getEmail()).then((response) => {
                        const { message } = response
                        addMsgSuccess({
                          message,
                        })
                        setOpenModalChangePassword(false)
                      }, (error) => {
                        if (error) {
                          const { data: err } = error
                          addMsgDanger({ ...err })
                        }
                      })
                    }}
                    >
                      Sim
                    </ButtonGradient>
                  </Box>
                  <Box>
                    <ButtonLight
                      onClick={() => {
                        setOpenModalChangePassword(false)
                      }}
                    >
                      Não
                    </ButtonLight>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Box>
        </ModalInfo>
      )}
      <Paper>
        <Box>
          <Box className={classes.profilesMenu}>
            <List>
              <ListItem button>
                <ListItemText
                  id="checkbox-list-secondary-label-1"
                  primary={(
                    <>
                      <Typography
                        component="span"
                        variant="subtitle2"
                      >
                        {!isBlank(getName()) ? `${getName()}` : ' 1'}
                      </Typography>
                    </>
                  )}
                />
                <ListItemAvatar>
                  <Avatar alt={getName()} src={avatarURL} />
                </ListItemAvatar>
              </ListItem>
            </List>
          </Box>
        </Box>

        <Box className={classes.buttonsBox}>
          <Box>
            <Button
              className={`${classes.buttonMenu} ${classes.buttonPwd}`}
              title="Alterar Senha"
              variant="outlined"
              onClick={() => {
                setOpenModalChangePassword(true)
              }}
            >
              <Box mr={1}>
                <VpnKey className={classes.iconForm} />
              </Box>
              Alterar Senha
            </Button>
          </Box>

          <Box hidden={!isUserResponsibleClient()}>
            <Button
              title="Gerenciar Usuários"
              href="/gerenciar-usuarios"
              className={`${classes.buttonMenu} ${classes.buttonPwd}`}
              variant="outlined"
            >
              <Box mr={1}>
                <AccountCircle className={classes.iconForm} />
              </Box>
              Gerenciar Usuários
            </Button>
          </Box>

          <Box>
            <Button
              title="Sair"
              onClick={logout}
              variant="outlined"
              className={`${classes.buttonMenu} ${classes.buttonPwd}`}
            >
              <Box mr={1}>
                <PowerSettingsNewOutlined className={classes.iconForm} />
              </Box>
              Sair da conta
            </Button>
          </Box>
        </Box>
      </Paper>
    </>
  )
}

UserOptionMenu.propTypes = {
  logout: PropTypes.func.isRequired,
}

export default UserOptionMenu
