import React, {
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import makeStyles from '@material-ui/core/styles/makeStyles'

import useSecurity from '../../../security/useSecurity'

import { useBreadcrumb } from '../../contexts/BreadcrumbContext'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    gap: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      marginLeft: -65,
      width: '85vw',
      alignItems: 'start',
      textAlign: 'start',
      justifyContent: 'start',
    },
  },
  breadcrumb: {
    maxWidth: 500,
  },
  nameSocial: {
    [theme.breakpoints.down('xs')]: {
      width: 400,
      marginLeft: -80,
    },
  },
  margin: {
    [theme.breakpoints.down('xs')]: {
      marginTop: -15,
    },
  },
}))

const TitlePage = ({ crumbs }) => {
  const classes = useStyles()
  const location = useLocation()
  const { titlePage } = useBreadcrumb()
  const downXS = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const {
    getName,
  } = useSecurity()

  const goodNews = () => {
    const goodMessage = 'Boas-vindas'
    return `${getName() ? goodMessage.concat(', ', getName()) : goodMessage}`
  }

  const home = useMemo(() => {
    const { pathname } = location
    return pathname === '/inicio'
  }, [location])

  return (
    <>
      {!home && (
        <div className={classes.root}>
          <Grid item className={classes.margin}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="none" color="inherit" href="/">
                Página Inicial
              </Link>
              {crumbs.map(({ name, path }, key) => (name && (
                key + 1 === crumbs.length
                  ? (
                    <Typography key={key}>
                      {name}
                    </Typography>
                  ) : (
                    <Link
                      href={path}
                      key={key}
                      underline="none"
                      variant="body1"
                      color="textSecondary"
                    >
                      {name}
                    </Link>
                  )
              )))}
            </Breadcrumbs>
          </Grid>
          <Grid item className={classes.breadcrumb}>
            {titlePage && (
              <Typography
                component="span"
                variant="h4"
                style={{
                  fontSize: downXS ? 20 : 30,
                }}
              >
                {titlePage}
              </Typography>
            )}
          </Grid>
        </div>
      )}

      {home && (
        <>
          <Grid item className={classes.nameSocial}>
            <Typography
              component="span"
              variant="h4"
            >
              {goodNews()}
            </Typography>
          </Grid>
        </>
      )}
    </>
  )
}

TitlePage.propTypes = {
  crumbs: PropTypes.array.isRequired,
}

export default TitlePage
