import { useCallback } from 'react'
import axios from 'axios'
import { isEmpty } from 'lodash'

const useUserClient = () => {
  const URI = '/user'

  const createUser = useCallback((data) => axios.post(URI, data)
    .then((response) => (
      response?.data ? response : { data: { ...response } })), [])

  const editUser = useCallback(async (data) => axios.put(URI, data)
    .then((response) => (
      response?.data ? response : { data: { ...response } })), [])

  const deleteUser = useCallback((data) => axios.delete(URI, {
    data,
  })
    .then((response) => response?.data), [])

  const createClient = useCallback((data) => axios.post('/client', data)
    .then((response) => response?.data), [])

  const loginUser = useCallback((notificationToken) => axios.post([URI, 'login'].join('/'), {
    notificationToken,
  }).then((response) => (response?.data ? response : { data: { ...response } })), [])

  const logoutUser = useCallback((data) => new Promise((resolve) => {
    if (!isEmpty(data)) {
      axios.get([URI, 'logout'].join('/'), {
        params: { ...data },
      }).then((response) => (response?.data ? response : { data: { ...response } }))
    }
    resolve()
  }), [])

  const getInfoUserLogged = useCallback((Email) => axios.get('/user/find', {
    params: { Email },
  }).then((response) => response?.data), [])

  const getClientByUser = useCallback((Email, FindAll) => axios.get(`/client/findByUser?Email=${Email}&FindAll=${FindAll ?? false}`)
    .then((response) => response?.data), [])

  const getClientByDocument = useCallback((CPFCNPJ) => axios.get('/user/findByDocumentClient', {
    params: { CPFCNPJ },
  }).then((response) => response?.data), [])

  const getClientByUserLogged = useCallback((Email, FindAll) => getClientByUser(Email, FindAll)
    .then((response) => (FindAll ? response?.data : response)), [getClientByUser])

  const getAllClients = useCallback((Email) => axios.get(`/user/findByEmail/${Email}/all`)
    .then((response) => response?.data), [])

  const changePassword = useCallback((Token, data) => axios.post('/auth/change-password', { Token, NewPassword: data.password }).then((response) => response.data), [])

  const requestPassword = useCallback((Email) => axios.post('/auth/require-change-password', { Email }).then((response) => response.data), [])

  const passwordResetStatus = useCallback((Email) => axios.get(`/auth/password-reset-status?email=${Email}`)
    .then((response) => response?.data), [])

  const getClientInsuredObjectProperty = useCallback((CPFCNPJ) => axios.get('/people/findByCpf', {
    params: { CPFCNPJ },
  }).then((response) => response?.data), [])

  const createUpdateInsuredObjectProperty = useCallback(async (data) => axios.post('/people/createOrUpdate', data)
    .then((response) => (response?.data ? response : { data: { ...response } })), [])

  const subscribeNotifications = useCallback(async (data) => axios.post('/firebase/subscribe', data)
    .then((response) => (response?.data ? response : { data: { ...response } })), [])

  const maintenanceWings = useCallback(() => axios.get('/wings/platform-status')
    .then((response) => response?.data, () => 'maintenance')
    .catch((err) => err?.response), [])

  const sendUserNewExecutiveVisualized = useCallback(async () => axios.post('user/NewExecutiveVisualized', { noLoading: true }).then((res) => res?.data), [])

  const getIPAddress = useCallback(async () => axios.get('https://api.ipify.org/?format=json').then((res) => res?.data), [])

  const sendIPAddress = useCallback((email, ipAddress, userType) => axios.put(`/user/SendIpLog?email=${email}&ipAddress=${ipAddress}&userType=${userType}`), [])

  const getExecutiveEmail = useCallback(async () => axios.get('client/ExecutiveEmail').then((res) => res?.data), [])

  const findUserEmailsByClient = useCallback((clientDocument) => axios.get(`/client/findUserEmailsByClient?clientDocument=${clientDocument}`)
    .then((response) => response?.data), [])

  const mainActivityIsConstruction = useCallback((clientDocument) => axios.get(`/client/mainActivityIsConstruction?clientDocument=${clientDocument}`)
    .then((response) => response?.data), [])

  return useCallback(() => ({
    editUser,
    loginUser,
    logoutUser,
    createUser,
    deleteUser,
    getIPAddress,
    sendIPAddress,
    createClient,
    getAllClients,
    changePassword,
    getClientByUser,
    requestPassword,
    maintenanceWings,
    getInfoUserLogged,
    getExecutiveEmail,
    passwordResetStatus,
    getClientByDocument,
    getClientByUserLogged,
    subscribeNotifications,
    sendUserNewExecutiveVisualized,
    getClientInsuredObjectProperty,
    createUpdateInsuredObjectProperty,
    findUserEmailsByClient,
    mainActivityIsConstruction,
  }), [
    editUser,
    loginUser,
    logoutUser,
    createUser,
    deleteUser,
    getIPAddress,
    sendIPAddress,
    createClient,
    getAllClients,
    changePassword,
    requestPassword,
    maintenanceWings,
    getClientByUser,
    getInfoUserLogged,
    getExecutiveEmail,
    passwordResetStatus,
    getClientByDocument,
    getClientByUserLogged,
    subscribeNotifications,
    sendUserNewExecutiveVisualized,
    getClientInsuredObjectProperty,
    createUpdateInsuredObjectProperty,
    findUserEmailsByClient,
    mainActivityIsConstruction,
  ])
}

export default useUserClient
