import React, { useMemo } from 'react'
import { isEmpty } from 'lodash'
import { PropTypes } from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { Error } from '@material-ui/icons'
import useQuotationClient from '../../../../clients/QuotationClient/useQuotationClient'
import { ReactComponent as DownloadIcon } from '../../../../assets/img/icons/ico-download.svg'

import useDownload from '../../../../shared/hooks/useDownload'
import { useAlert } from '../../../../shared/components'
import useUtils from '../../../../shared/hooks/useUtils'
import {
  STATUS_PROPOSAL_DONE,
  STATUS_PROPOSAL_EXPIRED,
  STATUS_PROPOSAL_ACCEPTED,
  STATUS_PROPOSAL_REQUESTED,
  STATUS_QUOTE_DENIED,
  STATUS_QUOTE_EXPIRED,
  STATUS_QUOTE_APPROVED,
  COLOR_ERROR,
} from '../../../../constants'

import { JudicialGuaranteeeAppealMessages } from '../../../../shared/messages'
import useProductQuotationClient from '../../../../clients/ProductClient/useProductQuotationClient'
import useStyles from './styles/containerFields'

const cepLabel = 'CEP:'
const stickLabel = 'Vara:'
const cityLabel = 'Cidade:'
const stateLabel = 'Estado:'
const objectLabel = 'Objeto:'
const numberLabel = 'Número:'
const districtLabel = 'Bairro:'
const placeLabel = 'Logradouro:'
const cpfCnpjLabel = 'CPF/CNPJ:'
const claimantLabel = 'Reclamante:'
const modalityLabel = 'Modalidade:'
const deadlineLabel = 'Prazo (anos):'
const complementLabel = 'Complemento:'
const appealLabel = 'Tipo de Recurso:'
const noteLabel = 'Observações'
const emailLabel = 'E-mail do Solicitante:'
const phoneLabel = 'Telefone do Solicitante:'
const requesterLabel = 'Nome do Solicitante:'
const processNumberLabel = 'Número do Processo:'
const insuredImportanceLabel = 'Importância Segurada:'
const percentageHarmLabel = 'Percentual de Agravo (%):'
const appealDepositValueLabel = 'Valor de Depósito Recursal:'
const idClientControlLabel = 'Identificador do Controle do Cliente:'
const courtRegionalLaborLabel = 'Tribunal e Região da Justiça do Trabalho:'
const insuranceCompanyName = 'Nome da Seguradora:'

const LabelDataExpiracao = 'Data de Expiração'
const LabelDataLiberacao = 'Data de Liberação'
const LabelDataNegacao = 'Data Negação'

const formaDePagamento = 'Forma de Pagamento'
const premioLiquido = 'Prêmio Líquido'
const premioTotal = 'Prêmio Total'
const parcelas = 'Parcelas'
const pagamentoPrimeiraParcela = 'Pagamento 1ª Parcela'

const textQuoteDenied = 'Prezado cliente, após análise técnica e politicas de subscrição da seguradora, não será possível prosseguir com a emissão desta solicitação. Para mais detalhes, entre em contato com seu executivo comercial.'

const ContainerFields = (props) => {
  const classes = useStyles()
  const productQuotationClient = useProductQuotationClient()
  const { downloadPDF } = useDownload()
  const { addMsgDanger } = useAlert()
  const quotationClient = useQuotationClient()

  const { quotation, quotationStatus, proposal } = props
  const { formatCurrency, formatCPFOrCNPJ, formatDate } = useUtils()

  const appealTypeFormat = useMemo(() => {
    const productQuotation = productQuotationClient()
      .getProducts()
      .find((item) => item.value === 2)
      .appeals
      .find((item) => item.value === quotation?.DetailsProduct?.AppealType)

    return productQuotation?.text
  }, [quotation?.DetailsProduct?.AppealType, productQuotationClient])

  const objectMessage = JudicialGuaranteeeAppealMessages.FORMA_ARTIGO_PRESENTE_GARANTIA_AGRAVO(
    quotation?.InsuredObjectProperty?.ProcessNumber,
    quotation?.InsuredObjectProperty?.Claimant,
    formatCPFOrCNPJ(quotation?.InsuredObjectProperty?.Document),
    quotation?.InsuredObjectProperty?.CourtRegionalLabor?.descricao,
    quotation?.InsuredObjectProperty?.CourtSection?.descricao,
    appealTypeFormat,
  )

  const isProposal = () => !isEmpty(quotationStatus) && ([
    STATUS_PROPOSAL_REQUESTED,
    STATUS_PROPOSAL_DONE,
    STATUS_PROPOSAL_ACCEPTED,
    STATUS_PROPOSAL_EXPIRED,
  ].includes(quotationStatus))

  const downloadDocuments = (document) => {
    quotationClient().downloadDocumentsWings(document).then((response) => {
      downloadPDF(response?.data, document)
    }, () => {
      addMsgDanger('Não foi possível baixar o arquivo, tente mais tarde ou contate o Administrador.')
    })
  }

  return (
    <>
      {
        quotationStatus === STATUS_QUOTE_DENIED && (
          <Grid container className={classes.root}>
            <Grid item xs={12}>
              <Grid container className={classes.text}>
                <Box display="flex">
                  <Box mr={1}>
                    <Error style={{ color: COLOR_ERROR }} />
                  </Box>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {textQuoteDenied}
                  </Typography>
                </Box>
              </Grid>
              <Typography
                gutterBottom
                className={classes.header}
                data-content="Cotação Negada"
              />
              <Grid container className={classes.confirmationBox} spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {LabelDataNegacao}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatDate(proposal?.quoteDenied?.DeniedAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
      {
        quotationStatus === STATUS_QUOTE_EXPIRED && (
          <Grid container className={classes.root}>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                className={classes.header}
                data-content="Cotação Expirada"
              />
              <Grid container className={classes.confirmationBox} spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {LabelDataExpiracao}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatDate(proposal?.quote.ExpiredAt)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {LabelDataLiberacao}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatDate(proposal?.quote.CreatedAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Detalhes do Produto"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {modalityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.DetailsProduct.Modality}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {appealLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {appealTypeFormat}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Tomador"
          />

          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {quotation?.PolicyHolderClaimant.PolicyHolder}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCPFOrCNPJ(quotation?.PolicyHolderClaimant.Document)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Valores e prazos"
          />

          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {appealDepositValueLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.ValuesDeadlines.AppealDepositValue)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {percentageHarmLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {Number(quotation?.ValuesDeadlines.PercentageHarm)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {insuredImportanceLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.ValuesDeadlines.InsuredImportance)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {deadlineLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ValuesDeadlines.Deadline}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Segurado e Objeto do Seguro"
          />

          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} sm={3} md={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {cpfCnpjLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCPFOrCNPJ(quotation?.InsuredObjectProperty.Document)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {claimantLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.InsuredObjectProperty.Claimant}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} sm={3} md={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {cepLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.InsuredObjectProperty.CEP}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {placeLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.InsuredObjectProperty.Place}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {numberLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.InsuredObjectProperty.Number}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {complementLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.InsuredObjectProperty.Complement}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {districtLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.InsuredObjectProperty.District}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {stateLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.InsuredObjectProperty.State}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {cityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.InsuredObjectProperty.City}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} sm={3} md={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {processNumberLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.InsuredObjectProperty.ProcessNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {courtRegionalLaborLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.InsuredObjectProperty?.CourtRegionalLabor?.descricao}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {stickLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.InsuredObjectProperty?.CourtSection?.descricao}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {objectLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {objectMessage}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Solicitante"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {requesterLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.Name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {phoneLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.Phone}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {emailLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.Email}
              </Typography>
            </Grid>
            <Grid item xs={6} hidden={!quotation?.Requester.CustomerControlIdentifier}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {idClientControlLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.CustomerControlIdentifier}
              </Typography>
            </Grid>
            <Grid item xs={12} hidden={!quotation?.Requester.Note}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {noteLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.Note}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.containerFileUpload}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Arquivos"
          />
          <Grid className={classes.fileUpload}>
            <Grid item xs={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                Nome do Arquivo:
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                Download:
              </Typography>
            </Grid>
          </Grid>
          {(quotation?.FileUpload?.Documents ?? quotation?.FileUpload)
            ?.map((item, index) => (
              <Grid key={index} item xs={12} className={classes.fileUpload}>
                <Grid item xs={6}>
                  <Typography className={classes.confirmationValue}>
                    {item?.DocumentName}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Box>
                    <Button
                      variant="text"
                      onClick={() => downloadDocuments(item?.DocumentID)}
                    >
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <Box mr={1}>
                          <DownloadIcon />
                        </Box>

                        <Box sx={{ textDecoration: 'underline', fontSize: 17 }}>
                          <b>Download</b>
                        </Box>
                      </Box>
                    </Button>
                  </Box>
                </Grid>
              </Grid>

            ))}
        </Grid>
        {
          isProposal() && (
            <>
              {
                (proposal?.quote?.InsurerName !== null || !isEmpty(proposal?.quote?.InsurerName))
                && (
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      className={classes.header}
                      data-content="Seguradora"
                    />
                    <Grid container className={classes.confirmationBox} spacing={2}>
                      <Grid item xs={12} md={4}>
                        <Typography gutterBottom className={classes.confirmationLabel}>
                          {insuranceCompanyName}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.confirmationValue}>
                          {proposal?.quote?.InsurerName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  className={classes.header}
                  data-content="Forma de Pagamento"
                />
                <Grid container className={classes.confirmationBox} spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Typography gutterBottom className={classes.confirmationLabel}>
                      {formaDePagamento}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.confirmationValue}>
                      Boleto
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography gutterBottom className={classes.confirmationLabel}>
                      {premioLiquido}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.confirmationValue}>
                      {formatCurrency(proposal?.quote?.CommercialPremium)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography gutterBottom className={classes.confirmationLabel}>
                      {premioTotal}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.confirmationValue}>
                      {formatCurrency(proposal?.quote?.GrossPremium)}
                    </Typography>
                  </Grid>
                  {
                    quotationStatus !== STATUS_QUOTE_APPROVED && (
                      <>
                        <Grid item xs={12} md={4}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            {parcelas}
                          </Typography>
                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {proposal?.proposal?.Request?.Payment.Installments}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            {pagamentoPrimeiraParcela}
                          </Typography>
                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {proposal?.proposal?.Request?.Payment.FirstInstallmentDueDateDelay}
                          </Typography>
                        </Grid>
                      </>
                    )
                  }
                </Grid>
              </Grid>
            </>
          )
        }
      </Grid>
    </>
  )
}

ContainerFields.propTypes = {
  quotation: PropTypes.any.isRequired,
  proposal: PropTypes.any.isRequired,
  quotationStatus: PropTypes.any,
}

ContainerFields.defaultProps = {
  quotationStatus: undefined,
}

export default ContainerFields
