import React from 'react'
import { PropTypes } from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Typography from '@material-ui/core/Typography'
import TableContainer from '@material-ui/core/TableContainer'

import useUtils from '../../../shared/hooks/useUtils'

const ListDocuments = (props) => {
  const { formatDate, formatCurrency } = useUtils()

  const { document } = props
  return (
    <>
      <Grid
        item
        sm={12}
        xs={12}
      >
        <Box mb={2}>
          <Typography gutterBottom variant="h6">
            Histórico do Documento
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Documento
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    Proposta
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    Importância Segurada
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    Início de Vigência
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    Fim de Vigência
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    Data da Emissão
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    Tipo de Documento
                  </TableCell>
                </TableRow>
              </TableHead>
              {
                document.map((documentItem, key) => (
                  <TableBody
                    key={key}
                    xs={12}
                  >
                    <TableRow>
                      <TableCell>
                        {documentItem?.numeroDocumento}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {documentItem?.numeroProposta}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {formatCurrency(documentItem?.totalImportanciaSegurada)}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {formatDate(documentItem?.inicioVigencia)}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {formatDate(documentItem?.terminoVigencia)}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {formatDate(documentItem?.dataEmissao)}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {documentItem?.tipoEndosso?.descricaoEndosso
                          ? documentItem?.tipoEndosso?.descricaoEndosso
                          : documentItem?.tipoDocumento}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))
              }
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </>
  )
}

ListDocuments.propTypes = {
  document: PropTypes.any.isRequired,
}

export default ListDocuments
