import { useCallback } from 'react'

const usePaymentQuotationClient = () => {
  const getDaysParcel = useCallback(() => [
    { value: 7, text: '7 dias' },
    { value: 10, text: '10 dias' },
    { value: 15, text: '15 dias' },
    { value: 30, text: '30 dias' },
  ], [])

  return useCallback(() => ({
    getDaysParcel,
  }), [
    getDaysParcel,
  ])
}

export default usePaymentQuotationClient
