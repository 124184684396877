/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import clsx from 'clsx'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import makeStyles from '@material-ui/core/styles/makeStyles'
import FormHelperText from '@material-ui/core/FormHelperText'

import {
  useAlert,
  ModalInfo,
  ButtonLight,
  ButtonGradient,
} from '../../components'

import useSecurity from '../../../security/useSecurity'
import useRatingClient from '../../../clients/RatingClient'

const useStyles = makeStyles((theme) => ({
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 880,
      height: 500,
    },
    '& .MuiRating-root': {
      minHeight: 110,
    },
  },
  action: {
    border: '2px solid #222',
  },
  colNps: {
    width: 56,
    height: 56,
    borderRadius: 5,
    color: '#ffffff',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
  },
  npsScore: {
    width: 50,
    height: 50,
    display: 'flex',
    fontSize: 14,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      opacity: 0.8,
    },
  },
  npsScore0: {
    background: '#B72025',
  },
  npsScore1: {
    background: '#D62027',
  },
  npsScore2: {
    background: '#F05223',
  },
  npsScore3: {
    background: '#F36F21',
  },
  npsScore4: {
    background: '#FAA823',
  },
  npsScore5: {
    background: '#FFCA27',
  },
  npsScore6: {
    background: '#ECDB12',
  },
  npsScore7: {
    background: '#E8E73D',
  },
  npsScore8: {
    background: '#C5D92D',
  },
  npsScore9: {
    background: '#AFD136',
  },
  npsScore10: {
    background: '#64B64D',
  },
  label: {
    color: theme.palette.text.primary,
  },
  text: {
    fontSize: 15,
    color: theme.palette.text.quaternary,
  },
  info: {
    fontSize: 13,
    color: theme.palette.text.tertiary,
  },
}))

const Rating = ({ value, onChange: change }) => {
  const classes = useStyles()

  const RATINGS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  const handleSelectScore = (newValue) => {
    const data = value !== newValue ? newValue : ''
    change(data)
  }

  return (
    <Box display="flex" justifyContent="center">
      {RATINGS.map((rating) => (
        <Box
          key={rating}
          onClick={() => handleSelectScore(rating)}
          className={clsx({ [classes.action]: value === rating }, classes.colNps)}
        >
          <Box component="span" className={clsx(classes.npsScore, classes[`npsScore${rating}`])}>{rating}</Box>
        </Box>
      ))}
    </Box>
  )
}

Rating.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
}

const FeedBackModal = ({ open, setOpen }) => {
  const classes = useStyles()
  const ratingClient = useRatingClient()
  const { addMsgDanger, addMsgSuccess } = useAlert()
  const {
    getUser,
    getTrackSaleSchedule,
  } = useSecurity()

  const user = getUser()
  const trackSaleSchedule = getTrackSaleSchedule()

  const DEFAULT_RATING = {
    score: 0,
    phone: '',
    justification: '',
    identification: '',
    name: user?.name ?? '',
    scheduleId: trackSaleSchedule?.id ?? '',
    email: trackSaleSchedule?.userEmail ?? '',
    campaignId: trackSaleSchedule?.campaignId ?? '',
    tags: [
      {
        name: 'numero-documento',
        value: trackSaleSchedule?.documentNumber ?? '',
      },
    ],
  }
  const formik = useFormik({
    initialValues: { ...DEFAULT_RATING },
    validationSchema: Yup.object({
      score: Yup.number().required(),
      justification: Yup.string(),
    }),
    onSubmit: (data) => {
      const values = { ...data }

      ratingClient().sendFeedBack(values).then(() => {
        setOpen(false)
        addMsgSuccess('Agradecemos por dedicar seu tempo e avaliar a Finlândia.')
      }, (response) => {
        setOpen(false)
        addMsgDanger(response.data)
      })
    },
  })
  const handleCancel = () => {
    if (trackSaleSchedule) {
      const data = {
        score: -1,
        phone: user?.phone ?? '',
        scheduleId: trackSaleSchedule?.id ?? '',
        justification: 'O cliente optou por não responder a pesquisa.',
        identification: trackSaleSchedule?.id ?? '',
        name: user?.name ?? '',
        email: trackSaleSchedule?.userEmail ?? '',
        campaignId: trackSaleSchedule?.campaignId ?? '',
        tags: [
          {
            name: 'numero-documento',
            value: trackSaleSchedule?.documentNumber ?? '',
          },
        ],
      }
      setOpen(false)
      ratingClient().sendFeedBack(data).then(() => {
        setOpen(false)
      }, (response) => {
        setOpen(false)
        addMsgDanger(response.data)
      })
    } else {
      setOpen(false)
    }
  }

  return (
    <>
      <ModalInfo
        open={open}
        close={false}
        className={classes.modal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <>
          <Box p={3}>
            <Box mb={5}>
              <Typography align="center" variant="h6" className={classes.label}>
                Em uma escala de 0 a 10, como você avalia a sua experiência
                com a Área do Cliente Finlândia?
              </Typography>
            </Box>

            <Box component="fieldset" mb={3} textAlign="center" borderColor="transparent">
              <Rating
                value={formik.values.score}
                onChange={(value) => formik.setFieldValue('score', value)}
              />

              <FormHelperText
                style={{ textAlign: 'center' }}
                hidden={!formik.touched.score || !formik.errors.score}
                error={formik.touched.score && !!formik.errors.score}
              >
                {formik.errors.score}
              </FormHelperText>
            </Box>

            <Box mb={2}>
              <Typography className={classes.text}>
                Descreva o que motivou a sua nota (opcional):
              </Typography>
              <FormControl fullWidth>
                <TextField
                  {...formik.getFieldProps('justification')}
                  variant="outlined"
                  multiline
                  fullWidth
                  minRows={4}
                />
              </FormControl>
              <Typography className={classes.info}>
                Ao responder a pesquisa, eu aceito que a Finlândia entre em contato
                comigo para conversar sobre minha experiência.
              </Typography>
            </Box>

            <Grid container justifyContent="center" spacing={2} className={classes.buttonsPad}>
              <Grid item xs={12} sm={3}>
                <ButtonGradient
                  fullWidth
                  onClick={() => formik.submitForm()}
                >
                  Enviar
                </ButtonGradient>
              </Grid>
              <Grid item xs={12} sm={3}>
                <ButtonLight
                  fullWidth
                  onClick={() => handleCancel()}
                >
                  Fechar
                </ButtonLight>
              </Grid>

            </Grid>
          </Box>
        </>
      </ModalInfo>
    </>
  )
}

FeedBackModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default FeedBackModal
