import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircularProgress from '@material-ui/core/CircularProgress'

import { DEFAULT_THEME } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: DEFAULT_THEME.primary,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1000002,
  },
  logo: {
    width: 200,
    maxWidth: '100%',
  },
  circularProgress: {
    color: '#fff',
  },
}))

const SlashScreen = (props) => {
  const classes = useStyles()

  const { children } = props

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.circularProgress} />
      {children}
    </div>
  )
}

SlashScreen.propTypes = {
  children: PropTypes.element,
}

SlashScreen.defaultProps = {
  children: null,
}

export default SlashScreen
