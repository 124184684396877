const RequesterData = ({
  Name,
  Phone,
  Email,
  Note,
  CustomerControlIdentifier,
}) => ({
  name: Name ?? '',
  phone: Phone ?? '',
  email: Email ?? '',
  note: Note ?? '',
  customerControlIdentifier: CustomerControlIdentifier ?? '',
})

export default RequesterData
