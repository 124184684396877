import React from 'react'
import BackgroundSlider from 'react-background-slider'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import makeStyles from '@material-ui/core/styles/makeStyles'

import WhatsBtn from '../components/WhatsButton/WhatsBtn'

import bgFormLogin from '../../assets/img/backgrounds/bg-form-login.svg'
import vectCarouselLogin from '../../assets/img/vectors/vect-carousel-login.svg'

import bgLogin from '../../assets/img/backgrounds/bg-login.webp'
import bgLogin2 from '../../assets/img/backgrounds/bg-login2.webp'
import bgLogin3 from '../../assets/img/backgrounds/bg-login3.webp'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    '& > .fa': {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  bgContainer: {
    '& #ReactBackgroundSlider': {
      height: '100%',
      '& figure': {
        top: 'auto',
        left: 'auto',
        width: '50%',
        backgroundSize: 'cover',
        backgroundPositionY: 'top',
        backgroundPositionX: 'center',
        '@media (max-width: 599px)': {
          width: '100%',
          height: '255px',
          backgroundPositionX: 'unset',
        },
      },
    },
  },
  formContainer: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    maxWidth: '26em',
    color: '#6E7191',
    minHeight: '50em',
    flexDirection: 'column',
    padding: theme.spacing(2),
    '@media (max-width: 599px)': {
      minHeight: '30em',
    },
  },
  formCenterContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1),
    backgroundImage: `url(${bgFormLogin})`,
    backgroundSize: '85%',
    backgroundPositionX: 'left',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'bottom',
    '@media (max-width: 1199px)': {
      backgroundSize: '100%',
    },
    '@media (max-width: 499px)': {
      background: 'none',
    },
  },
  onda: {
    right: 0,
    bottom: 0,
    width: '28vw',
    position: 'absolute',
  },
}))

const Login = ({ children }) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <Grid item sm={6} className={classes.formCenterContainer}>
        <Box className={classes.formContainer}>
          {children}
        </Box>
      </Grid>
      <Grid item className={classes.bgContainer} sm={6}>
        <BackgroundSlider
          images={[bgLogin2, bgLogin3, bgLogin]}
          duration={3}
          transition={1}
        />
        <Hidden smDown>
          <img
            className={classes.onda}
            src={vectCarouselLogin}
            alt="Vetor em formato de onda azul"
          />
        </Hidden>
        <WhatsBtn />
      </Grid>
    </Grid>
  )
}

Login.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Login
