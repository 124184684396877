import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import ViewerDocument from '../../../shared/components/ViewerDocument/ViewerDocument'
import { useAlert } from '../../../shared/components/Alert'
import usePolicyClient from '../../../clients/PolicyClient/usePolicyClient'

const PolicyViewer = () => {
  const policyClient = usePolicyClient()

  const { policyCode } = useParams()
  const { addMsgDanger } = useAlert()
  const history = useHistory()
  const [draftData, setDocumentData] = useState('')

  useEffect(() => {
    policyClient().getDocumentPolicy({
      base64: true,
      codigo: policyCode,
    }).then((response) => {
      setDocumentData(response.data)
    }, (error) => {
      let msg = 'Nenhum documento encontrado.'

      if (error.status !== 404) {
        msg = 'Não foi possível buscar o documento no momento.'
      }

      addMsgDanger({
        message: msg,
        action: () => {
          history.push('/financeiro')
        },
      })
    })
  }, [policyCode, policyClient, addMsgDanger, history])

  return (
    <>
      {draftData !== '' && (
        <ViewerDocument documentBase64={draftData} />
      )}
    </>
  )
}

export default PolicyViewer
