const InsuredData = ({
  Name,
  Document,
  DocumentNumber,
  PolicyHolder,
}) => ({
  name: Name ?? '',
  document: Document ?? '',
  documentNumber: DocumentNumber ?? '',
  policyHolder: PolicyHolder ?? '',
})

export default InsuredData
