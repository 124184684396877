import { useCallback } from 'react'
import axios from 'axios'

const useMissedAppointmentClient = () => {
  const getCheckNomination = useCallback(() => axios.get('/user/checkNomination')
    .then((response) => response?.data), [])

  return useCallback(() => ({
    getCheckNomination,
  }), [
    getCheckNomination,
  ])
}

export default useMissedAppointmentClient
