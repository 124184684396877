import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  Button,
} from '@material-ui/core'

import useStyles from './useStyles'

const useCustomStyle = makeStyles((theme) => ({
  btn: {
    color: '#fff',
    paddingInline: 20,
    textTransform: 'initial',
    backgroundImage: `linear-gradient(90deg, #19B8DA, ${theme.palette.primary.main} 50%)`,
    '&:hover': {
      textDecoration: 'unset',
    },
  },
}))

const ButtonGradient = (props) => {
  const { children } = props
  const classes = useStyles()
  const classesCustom = useCustomStyle()

  return (
    <>
      <Button
        className={clsx(classes.btn, classesCustom.btn)}
        {...props}
      >
        {children}
      </Button>
    </>
  )
}

ButtonGradient.propTypes = {
  children: PropTypes.any,
}

ButtonGradient.defaultProps = {
  children: undefined,
}

export default ButtonGradient
