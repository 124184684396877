import { merge } from 'lodash'
import { createTheme as createMuiTheme, responsiveFontSizes } from '@material-ui/core'

import MuiBaseConfig from './MuiBaseConfig'
import { DEFAULT_THEME } from '../../constants'

export const createTheme = (color = DEFAULT_THEME) => {
  const configs = {
    name: color.id,
    palette: {
      primary: {
        main: color.primary,
        footer: '#0B2049',
      },
      secondary: {
        main: '#EFEFEF',
        contrastText: '#8B7676',
        button: '#5785DC',
      },
      info: {
        light: '#ECF8FF',
        main: '#A2EAF3',
        secondary: '#6EDFEE',
        dark: '#1BD4ED',
      },
      text: {
        main: '#89A8E3',
        light: '#E5E5E5',
        primary: '#0E347D',
        secondary: '#B1B3C9',
        tertiary: '#6E7191',
        quaternary: '#3F4159',
        quintenary: '#4368AD',
      },
      danger: {
        main: '#FFC654',
      },
      error: {
        main: '#C3284C',
      },
      active: {
        main: '#19BDDD',
      },
      success: {
        main: '#34C575',
      },
    },
  }

  const options = merge({}, MuiBaseConfig, configs)
  const theme = createMuiTheme(options, [{ size: 10 }])
  return responsiveFontSizes(theme)
}

export default createTheme
