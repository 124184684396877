/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react'
import SVG from 'react-inlinesvg'
import { PropTypes } from 'prop-types'
import clsx from 'clsx'
import {
  Link,
  useLocation,
} from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import List from '@material-ui/core/List'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import Collapse from '@material-ui/core/Collapse'
import ListItem from '@material-ui/core/ListItem'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import makeStyles from '@material-ui/core/styles/makeStyles'

import HelpIcon from '@material-ui/icons/Help'

import { useScreenLoader } from '../contexts/ScreenLoaderContext'
import { useSecurityAction } from '../../store/ducks/security'
import useSecurity from '../../security/useSecurity'

import UserOptionMenu from './header/UserOptionMenu'

import logo from '../../assets/img/logo-white.svg'
import logoSmall from '../../assets/img/logo-small.svg'
import icoWallet from '../../assets/img/icons/nav/ico-wallet.svg'
import icoChartPie from '../../assets/img/icons/nav/ico-chart-pie.svg'
import icoUmbrellaFill from '../../assets/img/icons/nav/ico-umbrella-fill.svg'
import icoOpportunities from '../../assets/img/icons/nav/ico-opportunities.svg'
import icoPolicy from '../../assets/img/icons/nav/ico-policy.svg'
import icoFinance from '../../assets/img/icons/nav/ico-finance.svg'
import icoNote from '../../assets/img/icons/nav/ico-note.svg'
import icoTermsOfUse from '../../assets/img/icons/nav/ico-termos-de-uso.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 85,
    transition: 'all 300ms ease-out',
    '& .MuiBox-root': {
      display: 'flex',
      flexDirection: 'row',
    },
    '&:hover': {
      animation: 'fadein 1000ms',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  expandNav: {
    '&:hover': {
      width: 240,
    },
  },
  title: {
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.sizes.header - 140,
    '& > img': {
      padding: theme.spacing(2),
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      flexShrink: 0,
    },
  },
  drawerPaper: (props) => {
    const style = {
      border: 0,
      width: theme.sizes.nav,
    }

    if (props.mobileOpen) {
      style.position = 'relative'
    }

    return style
  },
  userContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  listContainer: {
    width: '100%',
    background: theme.palette.primary.main,
  },
  listIcon: {
    minWidth: 30,
    color: 'initial',
    fontSize: '1.5rem',
    justifyContent: 'center',
  },
  iconSmall: {
    maxWidth: 16,
  },
  icon: {
    maxWidth: 20,
  },
  iconBig: {
    maxWidth: 25,
  },
  iconInicio: {
    maxWidth: 30,
  },
  active: {
    backgroundColor: `${theme.palette.secondary.main}1A`,
    '&::after': {
      top: 0,
      left: 0,
      height: '100%',
      content: "''",
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '0px 0px 0px 7px',
      borderImage: `linear-gradient(to bottom, ${theme.palette.active.main}, transparent) 1 100%`,
    },
  },
  iconActive: {
    '& path': {
      fill: theme.palette.primary.contrastText,
    },
  },
  iconFullActive: {
    '& path': {
      stroke: '#000000',
    },
  },
  textActive: {
    color: `${theme.palette.primary.contrastText} !important`,
  },

  listItem: {
    maxHeight: 56,
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  textItem: {
    marginLeft: 10,
    color: theme.palette.text.secondary,
    '& span': {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
  textItemArrow: {
    color: theme.palette.text.secondary,
    '& span': {
      fontSize: '.9rem',
      fontWeight: '500',
    },
  },
  subMenu: {
    '& .MuiListItem-root': {
      padding: '5px 40px',
    },
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
  },
  subMenuArrow: {
    color: theme.palette.text.secondary,
    fontSize: '1.2rem',
  },
  navHorizontal: (props) => {
    const styles = {
      [theme.breakpoints.down('sm')]: {
        height: 100,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.main.contrastText,
        '& img': {
          marginLeft: theme.spacing(2.5),
        },
      },
    }

    if (props.isError) {
      styles.zIndex = 1
    }

    return styles
  },
  menuButton: {
    padding: theme.spacing(2.5),
    '& .MuiIcon-root': {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  hiddenDiv: {
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      display: 'flex',
    },
  },
}))

const INICIO = ['/inicio']

const ENDOSSOS = ['/endossos']

const APOLICES = ['/apolices']

const IMPORTACAO_DE_APOLICES = ['/importacao-de-apolices']

const FINANCEIRO = ['/financeiro']

const AJUDA = ['/ajuda']

const TERMS_OF_USE = ['/termos-de-uso']

const RECLAMACOES_SUGESTOES = ['/reclamacoes-sugestoes']

const TODOS_PRODUTOS = [
  '/garantia',
  '/beneficios',
  '/patrimonial',
  '/fianca-locaticia',
  '/risco-engenharia',
  '/risco-equipamento',
  '/responsabilidades',
  '/judicial-deposito-recursal',
]

const GARANTIA = ['/garantia']

const FAZER_COTACAO = ['/cotacao']

const STATUS_SOLICITACAO = ['/status-solicitacao']

const BENEFICIOS = ['/beneficios']

const PATRIMONIAL = ['/patrimonial']

const FIANCA_LOCATICIA = ['/fianca-locaticia']

const RISCO_ENGENHARIA = ['/risco-engenharia']

const RISCO_EQUIPAMENTO = ['/risco-equipamento']

const RESPONSABILIDADE_CIVIL = ['/responsabilidades']

const GARNTIA_JUDICIAL_DEPOSITO_RECURSAL = ['/judicial-deposito-recursal']

const OPORTUNIDADES = ['/oportunidades']

const Nav = ({
  logout,
  hasPin,
  mobileOpen,
  openAllItems,
  onDrawerToggle,
}) => {
  const classes = useStyles({ mobileOpen })
  const location = useLocation()
  const { screenLoaded } = useScreenLoader()
  const { setFilterPolicy } = useSecurityAction()
  const { getUser } = useSecurity()
  const user = getUser()

  const [open, setOpen] = useState({})
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => setOpen({
    product: openAllItems,
    quotation: openAllItems,
    policies: openAllItems,
  }), [openAllItems])

  const handleNavExpand = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleNavRetract = (event) => {
    if (!screenLoaded) {
      event.stopPropagation()
    }

    setOpen({})
    setAnchorEl(null)
  }

  const setOpenWhenExpanded = (menu) => {
    if (mobileOpen || (anchorEl && anchorEl.offsetWidth === 240)) {
      setOpen({ ...open, ...menu })
    }
  }

  const getPathName = (paths) => paths.find((item) => {
    const path = location.pathname.split('/').filter((p) => p)

    if (item === APOLICES[0] && path[0] === IMPORTACAO_DE_APOLICES[0]?.replace('/', '')) {
      return false
    }
    return path[0]?.includes(item.replace('/', ''))
  })

  const getComponentByExpand = () => (hasPin || mobileOpen || anchorEl ? Link : undefined)

  const checkIfAreAnPhysicalPerson = () => user?.isPhysicalPerson

  const items = (
    <List className={classes.listContainer}>
      <ListItem
        className={clsx(classes.listItem,
          getPathName(INICIO) && classes.active)}
        button
        title="Início"
        to="/inicio"
        component={getComponentByExpand()}
      >
        <ListItemIcon className={classes.listIcon}>
          <SVG src={icoChartPie} width="15" height="17.14" />
        </ListItemIcon>
        {(hasPin || mobileOpen || anchorEl)
          ? (
            <ListItemText
              className={clsx(classes.textItem,
                getPathName(INICIO) && classes.textActive)}
              primary="Início"
            />
          ) : null}
      </ListItem>

      <ListItem
        button
        title="Produtos"
        className={clsx(classes.listItem,
          'finlandia-products-tour',
          getPathName(TODOS_PRODUTOS) && classes.active)}
        onClick={() => setOpenWhenExpanded({ product: !open.product })}
      >
        <ListItemIcon className={classes.listIcon}>
          <SVG
            className={clsx(classes.iconInicio,
              getPathName(TODOS_PRODUTOS)
              && classes.iconActive)}
            src={icoUmbrellaFill}
          />
        </ListItemIcon>
        {(hasPin || mobileOpen || anchorEl)
          ? (
            <>
              <ListItemText
                className={clsx(classes.textItem,
                  getPathName(TODOS_PRODUTOS) && classes.textActive)}
                primary="Produtos"
              />

              {open.product && (
                <Icon className={classes.subMenuArrow}>expand_less</Icon>
              )}

              {!open.product && (
                <Icon className={classes.subMenuArrow}>expand_more</Icon>
              )}
            </>
          ) : null}
      </ListItem>

      <Collapse
        className={classes.subMenu}
        in={open.product}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <ListItem
            button
            component={getComponentByExpand()}
            to="/garantia"
            title="Seguro Garantia"
            className={clsx(classes.listItem, 'guarantee-tour')}
          >
            <ListItemText
              className={clsx(classes.textItemArrow,
                getPathName(GARANTIA) && classes.textActive)}
              primary="Seguro Garantia"
            />
          </ListItem>
          <ListItem
            button
            component={getComponentByExpand()}
            to="/risco-equipamento"
            title="Risco Diversos de Equipamento"
            className={clsx(classes.listItem, 'equipment-risk')}
          >
            <ListItemText
              className={clsx(classes.textItemArrow,
                getPathName(RISCO_EQUIPAMENTO) && classes.textActive)}
              primary="Risco Diversos de Equipamento"
            />
          </ListItem>
          <ListItem
            button
            component={getComponentByExpand()}
            to="/judicial-deposito-recursal"
            title="Garantia Judicial e Depósito Recursal"
            className={clsx(classes.listItem, 'judicial-guarantee-appeal')}
          >
            <ListItemText
              className={clsx(classes.textItemArrow,
                getPathName(GARNTIA_JUDICIAL_DEPOSITO_RECURSAL) && classes.textActive)}
              primary="Garantia Judicial e Depósito Recursal"
            />
          </ListItem>
          <ListItem
            button
            component={getComponentByExpand()}
            to="/fianca-locaticia"
            title="Fiança Locatícia"
            className={clsx(classes.listItem, 'lease-guarantee')}
          >
            <ListItemText
              className={clsx(classes.textItemArrow,
                getPathName(FIANCA_LOCATICIA) && classes.textActive)}
              primary="Fiança Locatícia"
            />
          </ListItem>
          <ListItem
            button
            component={getComponentByExpand()}
            to="/risco-engenharia"
            title="Risco de Engenharia"
            className={clsx(classes.listItem, 'engineering-risk')}
          >
            <ListItemText
              className={clsx(classes.textItemArrow,
                getPathName(RISCO_ENGENHARIA) && classes.textActive)}
              primary="Risco de Engenharia"
            />
          </ListItem>
          <ListItem
            button
            component={getComponentByExpand()}
            to="/beneficios"
            title="Benefícios"
            className={clsx(classes.listItem, 'benefits')}
          >
            <ListItemText
              className={clsx(classes.textItemArrow,
                getPathName(BENEFICIOS) && classes.textActive)}
              primary="Benefícios"
            />
          </ListItem>
          <ListItem
            button
            component={getComponentByExpand()}
            to="/responsabilidades"
            title="Seguro de Responsabilidades"
            className={clsx(classes.listItem, 'civil-responsability')}
          >
            <ListItemText
              className={clsx(classes.textItemArrow,
                getPathName(RESPONSABILIDADE_CIVIL) && classes.textActive)}
              primary="Seguro de Responsabilidades"
            />
          </ListItem>
          <ListItem
            button
            component={getComponentByExpand()}
            to="/patrimonial"
            title="Patrimonial"
            className={clsx(classes.listItem, 'equity')}
          >
            <ListItemText
              className={clsx(classes.textItemArrow,
                getPathName(PATRIMONIAL) && classes.textActive)}
              primary="Patrimonial"
            />
          </ListItem>
        </List>
      </Collapse>
      {!checkIfAreAnPhysicalPerson() && (
        <ListItem
          button
          title="Cotação"
          className={clsx(classes.listItem, 'quotation-tour',
            getPathName(FAZER_COTACAO) && classes.active)}
          onClick={() => setOpenWhenExpanded({ quotation: !open.quotation })}
        >
          <ListItemIcon className={classes.listIcon}>
            <SVG src={icoWallet} width="15" height="17.14" />
          </ListItemIcon>
          {(hasPin || mobileOpen || anchorEl)
            ? (
              <>
                <ListItemText
                  className={clsx(classes.textItem,
                    getPathName(FAZER_COTACAO) && classes.textActive)}
                  primary="Solicitação"
                />

                {open.quotation && (
                  <Icon className={classes.subMenuArrow}>expand_less</Icon>
                )}

                {!open.quotation && (
                  <Icon className={classes.subMenuArrow}>expand_more</Icon>
                )}
              </>
            ) : null}
        </ListItem>
      )}
      {!checkIfAreAnPhysicalPerson() && (
        <Collapse
          className={classes.subMenu}
          in={open.quotation}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItem
              button
              component={getComponentByExpand()}
              to="/cotacao"
              title="Solicitação de Apólice"
              className={clsx(classes.listItem, 'request-quote')}
            >
              <ListItemText
                className={clsx(classes.textItemArrow,
                  getPathName(FAZER_COTACAO) && classes.textActive)}
                primary="Solicitação de Apólice"
              />
            </ListItem>
            <ListItem
              button
              component={getComponentByExpand()}
              to="/endossos"
              title="Solicitação de Endosso"
              className={clsx(classes.listItem, 'request-quote')}
            >
              <ListItemText
                className={clsx(classes.textItemArrow,
                  getPathName(ENDOSSOS) && classes.textactive)}
                primary="Solicitação de Endosso"
              />
            </ListItem>
            <ListItem
              button
              component={getComponentByExpand()}
              to="/status-solicitacao"
              title="Status da Solicitação"
              className={clsx(classes.listItem)}
            >
              <ListItemText
                className={clsx(classes.textItemArrow,
                  getPathName(STATUS_SOLICITACAO) && classes.textActive)}
                primary="Status da Solicitação"
              />
            </ListItem>
          </List>
        </Collapse>
      )}
      <ListItem
        button
        title="Apólices"
        className={clsx(classes.listItem, 'policies-tour',
          getPathName(APOLICES) && classes.active)}
        onClick={() => setOpenWhenExpanded({ policies: !open.policies })}
      >
        <ListItemIcon className={classes.listIcon}>
          <SVG className={classes.iconInicio} src={icoPolicy} />
        </ListItemIcon>
        {(hasPin || mobileOpen || anchorEl)
          ? (
            <>
              <ListItemText
                className={classes.textItem}
                primary="Apólices"
              />

              {open.policies && (
                <Icon className={classes.subMenuArrow}>expand_less</Icon>
              )}

              {!open.policies && (
                <Icon className={classes.subMenuArrow}>expand_more</Icon>
              )}
            </>
          ) : null}
      </ListItem>

      <Collapse
        className={classes.subMenu}
        in={open.policies}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <ListItem
            button
            component={getComponentByExpand()}
            to="/apolices"
            title="Apólices"
            className={clsx(classes.listItem)}
            onClick={() => setFilterPolicy({})}
          >
            <ListItemText
              className={clsx(classes.textItemArrow,
                getPathName(APOLICES) && classes.textActive)}
              primary="Apólices"
            />
          </ListItem>
            <ListItem
              button
              component={getComponentByExpand()}
              to="/importacao-de-apolices"
              title="Importação de Apólices"
              className={clsx(classes.listItem)}
            >
              <ListItemText
                className={clsx(classes.textItemArrow,
                  getPathName(IMPORTACAO_DE_APOLICES) && classes.textActive)}
                primary="Importação de Apólices"
              />
            </ListItem>
        </List>
      </Collapse>

      <ListItem
        className={clsx(classes.listItem,
          getPathName(FINANCEIRO) && classes.active)}
        button
        title="Financeiro"
        to="/financeiro"
        component={getComponentByExpand()}
      >
        <ListItemIcon className={classes.listIcon}>
          <SVG
            className={clsx(
              classes.iconInicio,
              getPathName(FINANCEIRO) && classes.iconActive,
            )}
            src={icoFinance}
          />
        </ListItemIcon>
        {(hasPin || mobileOpen || anchorEl)
          ? (
            <ListItemText
              className={clsx(classes.textItem,
                getPathName(FINANCEIRO) && classes.textActive)}
              primary="Financeiro"
            />
          ) : null}
      </ListItem>

      <ListItem
        className={clsx(classes.listItem,
          getPathName(OPORTUNIDADES) && classes.active)}
        button
        title="Oportunidades"
        to="/oportunidades"
        component={getComponentByExpand()}
      >
        <ListItemIcon className={classes.listIcon}>
          <SVG
            className={clsx(
              classes.iconInicio,
              getPathName(OPORTUNIDADES) && classes.iconActive,
            )}
            src={icoOpportunities}
          />
        </ListItemIcon>
        {(hasPin || mobileOpen || anchorEl)
          ? (
            <ListItemText
              className={clsx(classes.textItem,
                getPathName(OPORTUNIDADES) && classes.textActive)}
              primary="Oportunidades"
            />
          ) : null}
      </ListItem>

      <ListItem
        className={clsx(classes.listItem,
          getPathName(AJUDA) && classes.active)}
        button
        title="Ajuda"
        to="/ajuda"
        component={getComponentByExpand()}
      >
        <ListItemIcon className={classes.listIcon}>
          <HelpIcon style={{ color: '#b1b3c9' }} fontSize="small" />
        </ListItemIcon>
        {(hasPin || mobileOpen || anchorEl)
          ? (
            <ListItemText
              className={clsx(classes.textItem,
                getPathName(AJUDA) && classes.textActive)}
              primary="Ajuda"
            />
          ) : null}
      </ListItem>

      <ListItem
        className={clsx(classes.listItem,
          getPathName(RECLAMACOES_SUGESTOES) && classes.active)}
        button
        title="Reclamações e Sugestões"
        to="/reclamacoes-sugestoes"
        component={getComponentByExpand()}
      >
        <ListItemIcon className={classes.listIcon}>
          <SVG
            className={clsx(
              classes.icon,
              getPathName(RECLAMACOES_SUGESTOES) && classes.iconActive,
            )}
            src={icoNote}
          />
        </ListItemIcon>
        {(hasPin || mobileOpen || anchorEl)
          ? (
            <ListItemText
              className={clsx(classes.textItem,
                getPathName(RECLAMACOES_SUGESTOES) && classes.textActive)}
              primary="Reclamações e Sugestões"
            />
          ) : null}
      </ListItem>

      <ListItem
        className={clsx(classes.listItem,
          getPathName(TERMS_OF_USE) && classes.active)}
        button
        title="Termos de Uso"
        to="/termos-de-uso"
        component={getComponentByExpand()}
      >
        <ListItemIcon className={classes.listIcon}>
          <SVG
            className={clsx(
              classes.icon,
              getPathName(TERMS_OF_USE) && classes.iconActive,
            )}
            src={icoTermsOfUse}
          />
        </ListItemIcon>
        {(hasPin || mobileOpen || anchorEl)
          ? (
            <ListItemText
              className={clsx(classes.textItem,
                getPathName(TERMS_OF_USE) && classes.textActive)}
              primary="Termos de Uso"
            />
          ) : null}
      </ListItem>
    </List>
  )

  return (
    <>
      <Hidden smDown>
        <nav
          className={clsx(classes.root, anchorEl && classes.expandNav)}
          onMouseEnter={handleNavExpand}
          onMouseLeave={handleNavRetract}
        >
          <Grid item sm={false}>
            <Box className={classes.title}>
              {(hasPin || mobileOpen || anchorEl)
                ? (
                  <Link to="/">
                    <Box mt={5} mb={4}>
                      <img src={logo} alt="Logo" />
                    </Box>
                  </Link>
                ) : (
                  <Box mt={5} mb={4}>
                    <img src={logoSmall} alt="Logo" />
                  </Box>
                )}
            </Box>
          </Grid>
          <Grid item sm>
            {items}
          </Grid>
        </nav>
      </Hidden>
      <Hidden mdUp implementation="css" className={classes.hiddenDiv}>
        <Grid container alignItems="center">
          <Grid
            md
            item
            xs="auto"
            sm="auto"
            className={clsx(classes.navHorizontal)}
          >
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
            <IconButton
              color="secondary"
              aria-label="open drawer"
              onClick={onDrawerToggle}
              className={classes.menuButton}
            >
              {
                mobileOpen
                  ? <Icon title="Fechar Menu">close</Icon>
                  : <Icon title="Abrir Menu">menu</Icon>
              }
            </IconButton>
          </Grid>
          <Box width="100%">
            <Drawer
              anchor="top"
              variant="persistent"
              open={mobileOpen}
              onClose={onDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <Box p={1} className={classes.userContainer}>
                <UserOptionMenu logout={logout} />
              </Box>
              {items}
            </Drawer>
          </Box>
        </Grid>
      </Hidden>
    </>
  )
}

Nav.propTypes = {
  openAllItems: PropTypes.bool,
  hasPin: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  mobileOpen: PropTypes.bool.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
}

Nav.defaultProps = {
  openAllItems: false,
}

export default Nav
