import React from 'react'
import { isEmpty } from 'lodash'
import { PropTypes } from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { Error } from '@material-ui/icons'
import useUtils from '../../../../shared/hooks/useUtils'
import {
  STATUS_PROPOSAL_DONE,
  STATUS_PROPOSAL_EXPIRED,
  STATUS_PROPOSAL_ACCEPTED,
  STATUS_PROPOSAL_REQUESTED,
  STATUS_QUOTE_DENIED,
  STATUS_QUOTE_EXPIRED,
  STATUS_QUOTE_APPROVED,
  COLOR_ERROR,
} from '../../../../constants'

import { ReactComponent as DownloadIcon } from '../../../../assets/img/icons/ico-download.svg'
import useQuotationClient from '../../../../clients/QuotationClient/useQuotationClient'
import useDownload from '../../../../shared/hooks/useDownload'
import { useAlert } from '../../../../shared/components'
import useStyles from './styles/containerFields'

const productLabel = 'Produto:'
const modalityLabel = 'Modalidade:'
const modalityPerformanceLabel = 'Modalidade Performance:'
const civilWorksLabel = 'Obra Civil:'
const contractPenaltyLabel = 'O contrato exige cobertura para multa'
const employmentClauseAgreementLabel = 'O contrato exige cobertura para cláusula trabalhista'

const policyHolderLabel = 'Tomador:'
const cpfcnpjInsuredLabel = 'CPF/CNPJ Segurado:'
const nameInsuredLabel = 'Nome do Segurado:'

const numberContractLabel = 'Número do Contrato:'
const numberAditiveLabel = 'Número do Aditivo:'
const numberEdict = 'Número do Edital:'
const numberProcessLabel = 'Número do Processo:'
const obrigationGuaranteeLabel = 'Obrigação Garantia (Objeto):'
const valueContractLabel = 'Valor Contrato:'
const percentageGuaranteeLabel = '%Garantia:'
const valueGuaranteeLabel = 'Valor Garantia:'
const startValidityLabel = 'Início de Vigência:'
const endValidityLabel = 'Fim de Vigência:'
const deadlineLabel = 'Prazo (Dias):'

const requesterLabel = 'Nome do Solicitante:'
const phoneRequesterLabel = 'Telefone do Solicitante:'
const emailRequesterLabel = 'E-mail do Solicitante:'
const idClientControlLabel = 'Identificador do Controle do Cliente:'
const observations = 'Observações:'

const LabelDataExpiracao = 'Data de Expiração'
const LabelDataLiberacao = 'Data de Liberação'
const LabelDataNegacao = 'Data Negação'

const formaDePagamento = 'Forma de Pagamento'
const premioLiquido = 'Prêmio Líquido'
const premioTotal = 'Prêmio Total'
const parcelas = 'Parcelas'
const pagamentoPrimeiraParcela = 'Pagamento 1ª Parcela'

const textQuoteDenied = 'Prezado cliente, após análise técnica e politicas de subscrição da seguradora, não será possível prosseguir com a emissão desta solicitação. Para mais detalhes, entre em contato com seu executivo comercial.'

const ContainerFields = (props) => {
  const classes = useStyles()
  const { quotation, quotationStatus, proposal } = props
  const { formatDate, formatCurrency, formatCPFOrCNPJ } = useUtils()
  const quotationClient = useQuotationClient()
  const { downloadPDF } = useDownload()
  const { addMsgDanger } = useAlert()

  const isProposal = () => !isEmpty(quotationStatus) && ([
    STATUS_PROPOSAL_REQUESTED,
    STATUS_PROPOSAL_DONE,
    STATUS_PROPOSAL_ACCEPTED,
    STATUS_PROPOSAL_EXPIRED,
    STATUS_QUOTE_EXPIRED,
  ].includes(quotationStatus))

  const downloadDocuments = (document) => {
    quotationClient().downloadDocumentsWings(document).then((response) => {
      downloadPDF(response?.data, document)
    }, () => {
      addMsgDanger('Não foi possível baixar o arquivo, tente mais tarde ou contate o Administrador.')
    })
  }

  const trueORFalse = (value) => {
    if (value === 'true') {
      return 'Sim'
    }
    if (value === 'false') {
      return 'Não'
    }
    return ''
  }

  return (
    <>
      {
        quotationStatus === STATUS_QUOTE_DENIED && (
          <Grid container className={classes.root}>
            <Grid item xs={12} className={classes.text}>
              <Box display="flex">
                <Box mr={1}>
                  <Error style={{ color: COLOR_ERROR }} />
                </Box>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {textQuoteDenied}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                className={classes.header}
                data-content="Cotação Negada"
              />
              <Grid container className={classes.confirmationBox} spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {LabelDataNegacao}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatDate(proposal?.quoteDenied?.DeniedAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
      {
        quotationStatus === STATUS_QUOTE_EXPIRED && (
          <Grid container className={classes.root}>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                className={classes.header}
                data-content="Cotação Expirada"
              />
              <Grid container className={classes.confirmationBox} spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {LabelDataExpiracao}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatDate(proposal?.quote.ExpiredAt)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {LabelDataLiberacao}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatDate(proposal?.quote.CreatedAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Detalhes do Produto"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {productLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Product?.Name ?? 'Seguro Garantia'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {modalityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                Performance
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {modalityPerformanceLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Rule.Modality}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {contractPenaltyLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {trueORFalse(quotation?.Rule.CoverageFine)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {employmentClauseAgreementLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {trueORFalse(quotation?.Rule.CoverageLabor)}
              </Typography>
            </Grid>
            {quotation?.Rule.CivilWork && (
              <Grid item xs={12} md={6}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {civilWorksLabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {trueORFalse(quotation?.Rule.CivilWork)}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Dados do Segurado"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {policyHolderLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Insured.PolicyHolder}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {cpfcnpjInsuredLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCPFOrCNPJ(quotation?.Insured.Document)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {nameInsuredLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Insured.Name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Objeto Segurado"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {numberContractLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.ContractNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {numberAditiveLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.AdditiveNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {numberEdict}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.NoticeNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {numberProcessLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.ProcessNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {obrigationGuaranteeLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.ObjectText}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {valueContractLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.ObjectProperty.ContractValue)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {percentageGuaranteeLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.PercentageGuarantee}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {valueGuaranteeLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.ObjectProperty.GuaranteeAmount)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {startValidityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatDate(quotation?.ObjectProperty.StartDateValidity)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {endValidityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatDate(quotation?.ObjectProperty.EndDateValidity)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {deadlineLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.Deadline}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Solicitante"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {requesterLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.Name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {phoneRequesterLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.Phone}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {emailRequesterLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.Email}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} hidden={!quotation?.Requester.CustomerControlIdentifier}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {idClientControlLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.CustomerControlIdentifier}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} hidden={!quotation?.Requester.Note}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {observations}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.Note}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.containerFileUpload}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Arquivos"
          />
          <Grid className={classes.fileUpload}>
            <Grid item xs={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                Nome do Arquivo:
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                Download:
              </Typography>
            </Grid>
          </Grid>
          {(
            quotation?.Attachments?.Documents
            ?? quotation?.Attachments?.documents
            ?? quotation?.Attachments
            ?? quotation?.FileUpload?.Documents
            ?? quotation?.FileUpload?.documents
            ?? quotation?.FileUpload
          )
            ?.map((item, index) => (
              <Grid key={index} item xs={12} className={classes.fileUpload}>
                <Grid item xs={4}>
                  <Typography className={classes.confirmationValue}>
                    {item?.DocumentName ?? item?.documentName}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Box>
                    <Button
                      variant="text"
                      onClick={() => {
                        downloadDocuments(item?.documentID ?? item?.DocumentID)
                      }}
                    >
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <Box mr={1}>
                          <DownloadIcon />
                        </Box>

                        <Box sx={{ textDecoration: 'underline', fontSize: 17 }}>
                          <b>Download</b>
                        </Box>
                      </Box>
                    </Button>
                  </Box>
                </Grid>
              </Grid>

            ))}
        </Grid>
        {
          isProposal() && (
            <Grid item xs={12}>
              <Typography
                gutterBottom
                className={classes.header}
                data-content="Forma de Pagamento"
              />
              <Grid container className={classes.confirmationBox} spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {formaDePagamento}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    Boleto
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {premioLiquido}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatCurrency(proposal?.quote?.CommercialPremium)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {premioTotal}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatCurrency(proposal?.quote?.GrossPremium)}
                  </Typography>
                </Grid>

                {
                  quotationStatus !== STATUS_QUOTE_APPROVED && (
                    <>
                      <Grid item xs={12} md={4}>
                        <Typography gutterBottom className={classes.confirmationLabel}>
                          {parcelas}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.confirmationValue}>
                          {proposal?.proposal?.Request?.Payment.Installments}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography gutterBottom className={classes.confirmationLabel}>
                          {pagamentoPrimeiraParcela}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.confirmationValue}>
                          {proposal?.proposal?.Request?.Payment.FirstInstallmentDueDateDelay}
                        </Typography>
                      </Grid>
                    </>
                  )
                }
              </Grid>
            </Grid>
          )
        }
      </Grid>
    </>
  )
}

ContainerFields.propTypes = {
  quotation: PropTypes.any.isRequired,
  proposal: PropTypes.any.isRequired,
  quotationStatus: PropTypes.any,
}
ContainerFields.defaultProps = {
  quotationStatus: undefined,
}

export default ContainerFields
