import React, { useState, useMemo } from 'react'
import { isEmpty } from 'lodash'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'

import useUtils from '../../hooks/useUtils'
import useSecurity from '../../../security/useSecurity'

import icoWhats from '../../../assets/img/icons/ico-whats.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    right: 35,
    bottom: 85,
    zIndex: 'auto',
    [theme.breakpoints.up('lg')]: {
      bottom: 90,
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  btn: {
    width: 48,
    height: 48,
    padding: 9,
    display: 'flex',
    textAlign: 'center',
    borderRadius: ' 50%',
    background: '#55CA64',
    justifyContent: 'center',
    '& img': {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: 40,
      height: 40,
    },
  },
}))

const WhatsBtn = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const { getOnlyNumber } = useUtils()
  const { getExecutive } = useSecurity()

  const executive = useMemo(() => getExecutive(), [getExecutive])

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const getNumberExecutive = () => `55${executive && !isEmpty(executive?.cellPhone) ? getOnlyNumber(executive?.cellPhone) : '31996716827'}`

  const open = Boolean(anchorEl)

  return (
    <div className={classes.container}>
      <a
        className={classes.btn}
        href={`https://api.whatsapp.com/send?phone=${getNumberExecutive()}&text=Olá!`}
        target="_blank"
        rel="noopener noreferrer"
        aria-owns={open ? 'atendimento-whats' : undefined}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <img src={icoWhats} alt="ico-whats" />
      </a>
      <Popover
        id="atendimento-whats"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock
      >
        <Typography>Fale por Whatsapp</Typography>
      </Popover>
    </div>
  )
}

export default WhatsBtn
