import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  root: {
    '& .MuiCollapse-root': {
      borderRadius: 14,
    },
  },
  header: {
    height: 120,
    padding: 40,
    display: 'flex',
    alignItems: 'center',
  },
  subheader: {
    border: 0,
    outline: 0,
    fontSize: 14,
    fontWeight: 'bold',
    position: 'relative',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    '&:before': {
      left: 0,
      top: '50%',
      height: 0.5,
      width: '100%',
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.text.light,
    },
    '&:after': {
      paddingRight: '1em',
      lineHeight: '1.5em',
      position: 'relative',
      display: 'inline-block',
      backgroundColor: '#FFFFFF',
      content: 'attr(data-content)',
      color: theme.palette.text.primary,
    },
  },
  content: {
    padding: theme.spacing(2, 5, 4),
  },
  labelInfo: {
    color: theme.palette.text.tertiary,
  },
  buttonsStep: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& .MuiBox-root': {
      minWidth: 190,
    },
    '@media (max-width: 699px)': {
      justifyContent: 'center',
    },
  },
  confirmationBox: {
    display: 'flex',
    borderLeft: '0px',
    borderRight: '0px',
    marginBlock: theme.spacing(1),
    '& .MuiGrid-item': {
      marginBottom: theme.spacing(1),
    },
  },
  confirmationLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.text.quaternary,
  },
  confirmationValue: {
    color: theme.palette.text.quaternary,
  },
  paymentsTypes: {
    marginBlock: theme.spacing(1),
    '& .MuiButton-root': {
      width: 125,
      height: 125,
      '& .MuiPaper-root': {
        padding: 5,
        height: 120,
        border: `1px solid ${theme.palette.text.secondary}`,
        '& path': {
          fill: theme.palette.text.secondary,
        },
      },
      '&.selected': {
        '& .MuiPaper-root': {
          border: `1px solid ${theme.palette.text.primary}`,
          '& path': {
            fill: theme.palette.text.primary,
          },
        },
      },
    },
  },
}))
