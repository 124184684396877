import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import useSecurity from '../useSecurity'

import firebase from '../firebase/FirebaseConfig'
import AuthContext from '../../shared/contexts/AuthContext'
import SlashScreen from '../../shared/components/SlashScreen/SlashScreen'

const AuthProvider = ({ children }) => {
  const { cleanAllData } = useSecurity()

  const [user, setUserAuth] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isLoginSocialNetwork, setIsSocialNetwork] = useState(false)
  const [maintenanceValue, setMaintenanceValue] = useState()
  const checkSocialNetwork = (response) => response.includes('google.com') || response.includes('microsoft.com')

  useEffect(() => {
    firebase.auth().onAuthStateChanged((userAuth) => {
      setUserAuth(null)
      if (userAuth) {
        firebase.auth().fetchSignInMethodsForEmail(userAuth?.email).then((response) => {
          firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          setIsSocialNetwork(checkSocialNetwork(response))
        })

        setUserAuth(userAuth)
      } else {
        cleanAllData()
      }

      setLoading(false)
    })
  }, [cleanAllData, setUserAuth, setIsSocialNetwork])

  if (loading) {
    return <SlashScreen />
  }

  return (
    <AuthContext.Provider value={{
      user,
      isLoginSocialNetwork,
      setMaintenanceValue,
      maintenanceValue,
    }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.element,
}

AuthProvider.defaultProps = {
  children: undefined,
}

export default AuthProvider
