import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import {
  Box,
  Card,
  makeStyles,
  CardHeader,
  CardContent,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 370,
    height: '100%',
    '& .MuiCardHeader-root': {
      padding: theme.spacing(4),
    },
    '& .MuiCardContent-root': {
      display: 'flex',
      flex: '1 0 auto',
      alignItems: 'center',
    },
  },
  contentBox: {
    width: '100%',
  },
}))

const CardPanel = (props) => {
  const {
    title,
    children,
    EndAdornment,
    className: classesParent,
  } = props

  const classes = useStyles()

  return (
    <Card className={clsx(classes.root, classesParent)}>

      {title !== '' && (
        <CardHeader
          title={title}
          action={EndAdornment && (
            <EndAdornment />
          )}
        />
      )}

      <CardContent>
        <Box className={classes.contentBox}>
          {children}
        </Box>
      </CardContent>
    </Card>
  )
}

CardPanel.propTypes = {
  EndAdornment: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
}

CardPanel.defaultProps = {
  className: '',
  EndAdornment: undefined,
}

export default CardPanel
