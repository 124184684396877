const ModalityData = ({
  Modality,
  CoverageFine,
  CoverageLabor,

}) => ({
  modality: Modality ?? '',
  coverageFine: CoverageFine ?? '',
  coverageLabor: CoverageLabor ?? '',
})

export default ModalityData
