import React, { forwardRef } from 'react'
import { PropTypes } from 'prop-types'
import DataConfirmation from '../DataConfirmation'
import ContainerFields from './ContainerFields'

const Confirmation = forwardRef((props, ref) => {
  const { quotation, proposal } = props
  return (
    <>
      <DataConfirmation
        ref={ref}
        {...props}
      >
        <ContainerFields
          quotation={quotation}
          proposal={proposal}
        />
      </DataConfirmation>
    </>
  )
})

Confirmation.propTypes = {
  quotation: PropTypes.any.isRequired,
  proposal: PropTypes.any,
  quotationStatus: PropTypes.any.isRequired,
}

Confirmation.defaultProps = {
  proposal: {},
}

export default Confirmation
