import axios from 'axios'
import { useCallback } from 'react'

const useTermsOfUseClient = () => {
  const sendAceptLastVersion = useCallback((ipAddress) => axios.post('/user/termsofservice/AceptLastVersion', { ipAddress }).then((response) => response.data), [])

  const getHasAceptedLastVersion = useCallback(() => axios.get('user/termsofservice/HasAceptedLastVersion').then((response) => response.data), [])

  const getTermsOfUse = useCallback(() => [
    {
      id: 1,
      title: '1. Objeto',
      subDescription: [
        {
          id: 1,
          textDescription: '1.1. Este documento, designado Termos de Uso, regula os termos e condições em que o Usuário poderá utilizar gratuitamente a Área do Cliente, disponibilizada em <https://areacliente.finlandiaseguros.com.br/login>, pela Finlândia Corretora de Seguros Ltda., com sede na Rua Senador Milton Campos, 35, 9º andar, CEP: 34.006-050, inscrita no CNPJ sob o nº 10.864.690/0001-80 (doravante designada por Finlândia).',
        },
        {
          id: 2,
          textDescription: 'A Área do Cliente é uma plataforma online disponibilizada pela Finlândia cujo principal objetivo é permitir que o Usuário tenha um espaço adequado para gerir suas apólices de seguro, controlando datas de vencimento dos prêmios, franquias e endossos, solicitar cotações e, eventualmente, efetivar a contratação de novos seguros intermediados pela Finlândia.',
        },
        {
          id: 3,
          textDescription: 'O Usuário deve ser um cliente da Finlândia, ou seja, alguém que já tenha contratado um seguro com o suporte desta e queira utilizar-se de uma ferramenta online para gerir suas apólices e conhecer melhor o mercado de seguros.',
        },
        {
          id: 4,
          textDescription: 'A utilização da Área do Cliente pode também ser regulada por outros termos e condições indicados durante o processo de registro na Área do Cliente.',
        },
        {
          id: 5,
          textDescription: '1.2. A utilização da Área do Cliente pelo Usuário implica a aceitação plena e sem reservas dos presentes Termos, que devem ser cuidadosamente lidos pelo Usuário.',
        },
        {
          id: 6,
          textDescription: '1.3. Os presentes Termos são aplicáveis à utilização da Área do Cliente e aos produtos e serviços fornecidos e ou prestados na Área do Cliente, e a sua versão atualizada pode ser sempre consultada em seguranca@finlandiaseguros.com.br.',
        },
        {
          id: 7,
          textDescription: '1.4. A Finlândia reserva-se o direito de modificar unilateralmente, e em qualquer momento, os presentes Termos de Uso. Caso tal ocorra, a Finlândia informará os Usuários da Área do Cliente. Após as alterações aos Termos de Uso, se continuar a utilizar a Área do Cliente, considera-se que o Usuário aceitou plenamente e sem reservas as alterações.',
        },
      ],
    },
    {
      id: 2,
      title: '2. Descrição do Serviço Área do Cliente',
      subDescription: [
        {
          id: 1,
          textDescription: '2.1. A plataforma Área do Cliente permite ao Usuário em seu nome ou em nome de pessoa jurídica com a qual possua vinculação (societária, contratual autônoma ou empregatícia):',
        },
        {
          id: 2,
          textDescription: '(i) Consultar e gerir as apólices de seguros, tenham sido intermediados pela Finlândia ou não, a fim de controlar datas de vencimento dos prêmios, coberturas, franquias e endossos;',
        },
        {
          id: 3,
          textDescription: '(ii) Submeter pedidos de cotação em relação a seguros;',
        },
        {
          id: 4,
          textDescription: '(iii) Contratar, por via eletrônica, seguros;',
        },
        {
          id: 5,
          textDescription: '(iv) Ter acesso a funcionalidades adicionais aos produtos e serviços disponibilizados na Área do Cliente, com caráter duradouro ou ocasional, em conformidade com os presentes Termos de Uso e nas condições contratuais específicas ou outros documentos que sejam aplicáveis.',
        },
        {
          id: 6,
          textDescription: '2.2. O Usuário é responsável pela utilização que fizer de todos os conteúdos, produtos e serviços disponíveis a partir da Área do Cliente, conforme previsto nos presentes Termos de Uso.',
        },
      ],
    },
    {
      id: 3,
      title: '3. Registro e Acesso à Área do Cliente',
      subDescription: [
        {
          id: 1,
          textDescription: '3.1. Para poder acessar e utilizar a Área do Cliente, o Usuário deverá possuir um e-mail e senha cadastrados (“Dados de Login”) e deverá seguir as indicações fornecidas durante o processo de registro.',
        },
        {
          id: 2,
          textDescription: '3.2. A Finlândia não será responsável por quaisquer perdas ou danos causados por utilizações abusivas da Área do Cliente com os Dados de Login do Usuário, exceto caso comprovado nexo de causalidade entre alguma conduta imputável à Finlândia a título de dolo ou culpa grave e as perdas e danos causados.',
        },
        {
          id: 3,
          textDescription: '3.3. A Finlândia poderá restringir ou limitar o acesso a toda ou a parte da Área do Cliente por motivos relacionados a situações técnicas, comerciais ou estratégicas.',
        },
        {
          id: 4,
          textDescription: '3.4. Ao registar-se na Área do Cliente, o Usuário deve ter presente e aceitar expressamente que a Rede IP (Internet Protocol) é uma rede pública de comunicações eletrônicas suscetível de utilização por vários utilizadores e, como tal, sujeita a sobrecargas informáticas, pelo que a Finlândia não garante o acesso à Área do Cliente sem interrupções, perda de informação ou atrasos.',
        },
        {
          id: 5,
          textDescription: '3.5. A Finlândia também não garante o acesso à Área do Cliente em situações de sobrecarga imprevisível dos sistemas em que o mesmo se suporta ou em qualquer outra situação de força maior, caso fortuito ou qualquer outra situação fora da esfera de ingerência da Finlândia e que por esta não possa ser controlada.',
        },
        {
          id: 6,
          textDescription: '3.6. Em caso de interrupção de acesso à Área do Cliente por razões de sobrecarga imprevisível dos sistemas em que o mesmo se suporta, a Finlândia compromete-se a regularizar o seu funcionamento com a maior brevidade possível, sem, contudo, estar vinculada a um prazo.',
        },
      ],
    },
    {
      id: 4,
      title: '4. Utilização da Área do Cliente',
      subDescription: [
        {
          id: 1,
          textDescription: '4.1. A Área do Cliente pode ser utilizada por quem tiver 18 (dezoito) ou mais anos de idade ou for menor emancipado e estiver devidamente legitimado para o efeito. Se for menor de idade não emancipado ou se tiver outro tipo de incapacidade, só deverá usar a Área do Cliente com autorização dos seus representantes legais e apenas para gerir produtos e serviços na medida em que tal não implique assumir perante a Finlândia obrigações contratuais.',
        },
        {
          id: 2,
          textDescription: '4.2. Sempre que usar a Área do Cliente em nome e/ou por conta de uma pessoa jurídica com a qual possua qualquer tipo de vínculo (societário, contratual autônomo ou empregatício), o Usuário deve garantir que tem autorização desta pessoa jurídica para o efeito e para vincular-se aos presentes Termos de Uso em nome da mesma, bem como a demais termos e condições que se fizerem necessários no âmbito da Área do Cliente.',
        },
        {
          id: 3,
          textDescription: '4.3. Ao dar o seu acordo aos Termos de Uso, o Usuário concorda também em fazer uma utilização responsável e diligente da Área do Cliente. As informações, instruções e ordens que forem transmitidas via Área do Cliente gozam de plenos efeitos jurídicos, pelo que, desde já, reconhece que as aquisições e demais operações eletrônicas solicitadas serão vinculativas perante a Finlândia, não podendo ser alegada a falta de assinatura (sua ou da pessoa jurídica em nome da qual atua) ou falta de poderes para vincular a pessoa jurídica em nome da qual atua para recusar o cumprimento das obrigações assumidas.',
        },
        {
          id: 4,
          textDescription: '4.4. Sempre que terceiros usarem a Área do Cliente com os Dados de Login do Usuário, com ou sem a sua autorização, considera-se, para todos os efeitos legais e contratuais, que a utilização foi efetuada pelo titular do Login registrado na Área do Cliente, incluindo solicitações/instruções que deem origem a algum serviço intermediado pela Finlândia e que será faturado contra o Usuário ou pessoa jurídica em nome da qual atua.',
        },
        {
          id: 5,
          textDescription: '4.5. A aceitação dos presentes Termos de Uso implica também garantir que o Usuário não irá utilizar a Área do Cliente de modo que provoque, ou seja suscetível de provocar, a interrupção da disponibilização da Área do Cliente, ou danos ao regular funcionamento da mesma. Adicionalmente, o Usuário compromete-se a cumprir todas as disposições legais aplicáveis à utilização da Área do Cliente, nomeadamente não praticar ou fomentar a prática de atos ilícitos ou ofensivos dos bons costumes, e a observar as regras de utilização específicas das operações disponibilizadas na Área do Cliente. Assim, a Área do Cliente não deverá ser usada para fins como (lista não exaustiva):',
        },
        {
          id: 6,
          textDescription: '(i) Disponibilização, envio ou transmissão de qualquer conteúdo ilícito, incluindo conteúdo difamatório, invasivo da privacidade de terceiros ou que prejudique menores ou incapazes de qualquer forma;',
        },
        {
          id: 7,
          textDescription: '(ii) Representação ou fazer-se passar falsamente por uma pessoa física ou jurídica;',
        },
        {
          id: 8,
          textDescription: '(iii) Disponibilização, transmissão ou envio (de forma dolosa ou culposa) de qualquer material que contenha vírus de software ou qualquer outro código informático, arquivos ou programas cujo objetivo seja interromper, destruir ou limitar a funcionalidade de qualquer computador ou sistema informático (hardware e software) ou equipamento de telecomunicações;',
        },
        {
          id: 9,
          textDescription: '(iv) Obtenção, armazenamento ou disponibilização, sob qualquer formato, de informações sobre outros Usuários;',
        },
        {
          id: 10,
          textDescription: '(v) Tentativa da prática de qualquer um dos atos identificados nas alíneas anteriores ou de outros não permitidos por Lei.',
        },
        {
          id: 11,
          textDescription: '4.6. O Usuário compromete-se, ainda, a não permitir a utilização da Área do Cliente em benefício (comercial ou outro) direto ou indireto de terceiros sem a autorização prévia e por escrito da Finlândia.',
        },
        {
          id: 12,
          textDescription: '4.7. Caso o Usuário disponibilize informação errada, desatualizada, incompleta ou falsa, a Finlândia tem o direito de:',
        },
        {
          id: 13,
          textDescription: '(i) Suspender ou cancelar o acesso à Área do Cliente;',
        },
        {
          id: 14,
          textDescription: '(ii) Cancelar o fornecimento de quaisquer outros produtos ou serviços que lhe sejam disponibilizados via Área do Cliente;',
        },
        {
          id: 15,
          textDescription: '(iii) Não permitir o seu acesso futuro a algum ou quaisquer produtos ou serviços disponibilizados na Área do Cliente ou pela Finlândia de modo geral.',
        },
        {
          id: 16,
          textDescription: '4.8. Por razões de segurança, as operações que efetuar através da Área do Cliente estão sujeitas à aceitação da Finlândia.',
        },
      ],
    },
    {
      id: 5,
      title: '5. Associação de Produtos e Serviços',
      subDescription: [
        {
          id: 1,
          textDescription: '5.1. Para associar apólices a sua Conta de Usuário, o Usuário deve indicar na Área do Cliente os dados que lhe forem solicitados pela Finlândia.',
        },
        {
          id: 2,
          textDescription: '5.2. O Usuário pode, a qualquer momento, adicionar e excluir apólices e associar outros serviços disponibilizados na Área do Cliente à sua Conta.',
        },
      ],
    },
    {
      id: 6,
      title: '6. Aquisição de Produtos e Serviços',
      subDescription: [
        {
          id: 1,
          textDescription: '6.1. Através da Área do Cliente, o Usuário poderá manifestar a sua intenção de contratar seguros intermediados pela Finlândia, solicitando cotações e propostas e, eventualmente, efetivar a contratação de apólices de seguros, para o que deverá pagar o preço associado determinado pela seguradora na proposta.',
        },
        {
          id: 2,
          textDescription: '6.2. Os pedidos de cotação de seguro efetuados através da Área do Cliente ficam sujeitos à aceitação pela Finlândia. Após a aceitação pela Finlândia, o Usuário receberá uma mensagem com os dados relativos à proposta solicitada.',
        },
        {
          id: 3,
          textDescription: '6.3. A Finlândia, na condição de corretora de seguros, é responsável apenas pela intermediação da contratação. As condições de cada seguro, nomeadamente, coberturas, prêmios, franquias, endossos e todos os demais elementos e circunstâncias do seguro são estabelecidos pela seguradora conforme a legislação aplicável, competindo ao Usuário a leitura completa dos termos e condições do seguro cotado a fim de decidir pela sua efetiva contratação.',
        },
        {
          id: 4,
          textDescription: '6.4. Caso seja possível a realização da contratação do seguro pela Área do Cliente, o Usuário deverá formalizar o seu acordo (ou da pessoa jurídica em nome e/ou por conta da qual solicitou o seguro) à proposta de seguro, e esta concordância significa o compromisso de proceder ao pagamento do seguro conforme submetido através da Área do Cliente.',
        },
        {
          id: 5,
          textDescription: '6.5. Sempre que solicitar a contratação de um seguro ou demais produtos e/ou serviços através da Área do Cliente, o Usuário assume para si (ou para a pessoa jurídica em nome e/ou por conta da qual a solicitação é efetuada) a obrigação de pagar os preços aplicáveis, sendo os produtos e/ou serviços faturados conforme procedimento indicado pela Finlândia. Sem o pagamento conforme orientações recebidas, o produto e/ou serviço será considerado contratado.',
        },
        {
          id: 6,
          textDescription: '6.5. Todos os preços apresentados, bem como todos os produtos e serviços que poderá contratar através da Área do Cliente estão sujeitos a alteração, sem a necessidade de qualquer aviso prévio. Antes de aderir a produtos e/ou serviços disponíveis na Área do Cliente, o Usuário deve confirmar o preço e as condições comerciais aplicáveis aos produtos e serviços em causa.',
        },
      ],
    },
    {
      id: 7,
      title: '7. Inserção de Apólices',
      subDescription: [
        {
          id: 1,
          textDescription: '7.1. O Usuário poderá consultar e/ou gerir as apólices de seguro de que seja titular (ou da pessoa jurídica em nome da qual atua) e demais produtos e serviços contratados por meio da Finlândia ou por terceiros, comprometendo-se a inserir na Área do Cliente somente informações passíveis de serem compartilhadas, observados seus deveres de confidencialidade e responsabilizando-se exclusiva e integralmente por eventuais pedidos de indenização decorrentes do compartilhamento de informações e documentos inseridos em sua Área do Cliente.',
        },
        {
          id: 2,
          textDescription: '7.2. A Finlância poderá compartilhar os dados pessoais do Usuário para as finalidades abaixo indicadas, respeitando sempre a minimização de dados, com o compartilhamento apenas dos dados estritamente necessários para a consecução da:',
        },
        {
          id: 3,
          textDescription: '• Cotação de seguro: a Finlândia poderá compartilhar dados pessoais do Usuário com terceiros para realizar cotações de seguros em diferentes seguradoras, procurando sempre a melhor taxa e atendimento para o Usuário.',
        },
        {
          id: 4,
          textDescription: '• Contratação de prestadores de serviços: em algumas situações, a Finlândia poderá contratar outras empresas para auxiliar na prestação de serviços, o que acontece, por exemplo, na contratação de softwares internos para gestão de e-mails.',
        },
        {
          id: 5,
          textDescription: '• Cumprimento de obrigação legal ou regulatória: em algumas situações, quando a legislação impuser, ou por determinação judicial, a Finlândia poderá ser obrigada a compartilhar os dados do Usuário.',
        },
        {
          id: 6,
          textDescription: '7.3. A informação referente a apólices vencidas fica disponível pelo prazo máximo de 6 meses posteriores ao vencimento.',
        },
      ],
    },
    {
      id: 8,
      title: '8. Propriedade Intelectual',
      subDescription: [
        {
          id: 1,
          textDescription: '8.1. A Área do Cliente é um site de propriedade da Finlândia.',
        },
        {
          id: 2,
          textDescription: '8.2. O Usuário reconhece que a Área do Cliente pode conter informação confidencial e que a plataforma está protegida pelos direitos autorais e direitos conexos, obrigando-se a respeitar tais direitos, sendo vedado seu uso por terceiros não autorizados.',
        },
        {
          id: 3,
          textDescription: '8.3. O Usuário reconhece, ainda, que qualquer conteúdo que conste na Área do Cliente que diga respeito à Finlândia, aos seus parceiros e ao funcionamento da plataforma está protegido pelas leis relativas a direitos autorais e direitos conexos, pelas leis relativas a propriedade industrial e outras leis de proteção de propriedade, pelo que a utilização desses conteúdos apenas poderá ocorrer ao abrigo de autorização expressa dos respetivos titulares.',
        },
        {
          id: 4,
          textDescription: '8.4. Os direitos a que se referem os itens anteriores serão respeitados na íntegra, pelo que o Usuário se compromete a se abster de praticar quaisquer atos que possam violar a lei ou os referidos direitos, tais como, sem limitação, a modificação não autorizada, a reprodução, a comercialização, a transmissão, a distribuição ou a colocação à disposição ao público desses conteúdos.',
        },
        {
          id: 5,
          textDescription: '8.5. Todas as marcas e logotipos apresentados na Área do Cliente, em especial as marcas, os símbolos e logotipos da Finlândia e parceiros estão protegidos por direitos de propriedade intelectual.',
        },
        {
          id: 6,
          textDescription: '8.6. É expressamente proibida ao Usuário a extração e/ou utilização sistemática de parte ou de todo o conteúdo da Área do Cliente sem a autorização prévia e expressa da Finlândia. Em particular, é proibida qualquer forma de data mining ou a utilização de outras ferramentas e/ou instrumentos de recolhimento e extração de dados para reutilização de partes da Área do Cliente sem a autorização prévia e expressa da Finlândia. Não é igualmente permitida a criação e/ou publicação de bases de dados que contenham informação da Área do Cliente (e.g., preços e listagens de produtos e/ou serviços) sem a prévia e expressa autorização da Finlândia.',
        },
        {
          id: 7,
          textDescription: '8.7. A Finlândia tem o direito de, a qualquer momento, e sem aviso prévio, alterar o conteúdo e a configuração da Área do Cliente.',
        },
      ],
    },
    {
      id: 9,
      title: '9. Suspensão de acesso à Área do Cliente',
      subDescription: [
        {
          id: 1,
          textDescription: '9.1. Caso os presentes Termos de Uso não sejam respeitados, a Finlândia pode, em qualquer momento, suspender ou fazer cessar imediatamente o acesso do Usuário à Área do Cliente (e, eventualmente, a outras plataformas associadas e/ou subjacentes à utilização da Área do Cliente), caso em que lhe será dado conhecimento da suspensão (antes ou depois de a suspensão ser implementada, consoante a gravidade dos fatos que determinem a suspensão ou a cessação do acesso). A Finlândia poderá, ainda, solicitar a confirmação de qualquer informação que tenha sido fornecida pelo Usuário.',
        },
        {
          id: 2,
          textDescription: '9.2. Não será devido o pagamento de qualquer compensação (ao Usuário ou à pessoa jurídica em nome da qual atua) pela Finlândia em caso de suspensão ou cessação de acesso à Área do Cliente determinada pela Finlândia ao abrigo do item anterior e da cláusula 13, não podendo a Finlândia ser responsabilizada ou, de alguma forma, onerada por qualquer consequência resultante da suspensão ou cancelamento do acesso do Usuário à Área do Cliente.',
        },
        {
          id: 3,
          textDescription: '9.3. A Finlândia pode, ainda, ocasionalmente, restringir ou suspender temporariamente o acesso do Usuário a toda ou a apenas partes da Área do Cliente (e, eventualmente, a outras plataformas associadas e/ou subjacentes à utilização da Área do Cliente), para efeitos de ações de manutenção, reparação ou introdução de funcionalidades, produtos e serviços, caso em que a restrição ou suspensão temporária será limitada ao período estritamente necessário para a realização das referidas ações.',
        },
      ],
    },
    {
      id: 10,
      title: '10. Responsabilidade',
      subDescription: [
        {
          id: 1,
          textDescription: '10.1. A Área do Cliente da Finlândia possui os adequados níveis de segurança, todavia, a Finlândia não é responsável pelos prejuízos ou danos decorrentes do descumprimento ou cumprimento defeituoso dos presentes Termos de Uso, ou por quaisquer prejuízos sofridos pelo Usuário ou por terceiros, quando tal não lhe seja diretamente imputável a título de dolo ou culpa grave, não se responsabilizando especialmente por:',
        },
        {
          id: 2,
          textDescription: '(i) Atrasos, interrupções, erros e suspensões de comunicações que tenham origem em fatores fora do seu controle, nomeadamente, quaisquer deficiências ou falhas provocadas pela rede de comunicações ou serviços de comunicações prestados por terceiros, pelo sistema informático, pelos modems, pelo software de acesso ou eventuais vírus informáticos e outros malwares;',
        },
        {
          id: 3,
          textDescription: '(ii) Erros, omissões ou outras imprecisões relativas às informações disponibilizadas através da Área do Cliente pelo Usuário;',
        },
        {
          id: 4,
          textDescription: '(iii) Danos causados por culpa do Usuário ou de terceiros, incluindo as violações da propriedade intelectual;',
        },
        {
          id: 5,
          textDescription: '(iv) Incumprimento ou cumprimento defeituoso de decisões judiciais ou de autoridades administrativas;',
        },
        {
          id: 6,
          textDescription: '(v) Incumprimento ou cumprimento defeituoso quando decorrentes de situações de força maior, ou seja, situações de natureza extraordinária fora do controle da Finlândia e que pela mesma não possam ser controladas, tais como incêndios, cortes de energia, explosões, guerras, tumultos, insurreições civis, decisões governamentais, greves, terremotos, inundações, outros cataclismos naturais ou outras situações não controláveis pela Finlândia que impeçam ou prejudiquem o cumprimento das obrigações assumidas.',
        },
        {
          id: 7,
          textDescription: '10.2. A Finlândia adotará as medidas necessárias para assegurar que a Área do Cliente não contenha qualquer tipo de vírus ou de outros elementos do gênero igualmente perigosos para os equipamentos terminais utilizados para acesso à Área do Cliente. No entanto, uma vez que a Finlândia não consegue controlar integralmente a circulação de informação através da Internet, a Finlândia não poderá garantir que os mesmos não contêm qualquer tipo de vírus ou outros elementos que possam danificar o equipamento terminal do Usuário.',
        },
      ],
    },
    {
      id: 11,
      title: '11. Esclarecimentos e Reclamações',
      subDescription: [
        {
          id: 1,
          textDescription: 'A Área do Cliente disponibiliza uma página, encontrada no menu denominada “Reclamações e Sugestões” com um formulário para contato, esclarecimento ou reclamação.',
        },
      ],
    },
    {
      id: 12,
      title: '12. Comunicações',
      subDescription: [
        {
          id: 1,
          textDescription: '12.1. Para efeitos de citação e/ou notificações judiciais, as partes convencionam o endereço do Usuário ou da pessoa jurídica à qual é vinculado existente nas bases de dados da Finlândia.',
        },
        {
          id: 2,
          textDescription: '12.2. O Usuário deve comunicar à Finlândia por escrito qualquer alteração de endereço, no prazo máximo de 30 (trinta) dias a contar da data da alteração, sob pena de, em caso de ação judicial, se considerar que a sua citação ou notificação ocorreu no endereço que tiver inicialmente indicado à Finlândia.',
        },
      ],
    },
    {
      id: 13,
      title: '13. Cessação',
      subDescription: [
        {
          id: 1,
          textDescription: '13.1. O Usuário pode deixar de usar a Área do Cliente em qualquer momento e sem necessidade de justificação.',
        },
        {
          id: 2,
          textDescription: '13.2. A Área do Cliente é disponibilizada por um prazo indeterminado, podendo a sua disponibilização, total ou parcial, pela Finlândia cessar, definitivamente ou temporariamente, a qualquer momento, de acordo com a sua vontade.',
        },
        {
          id: 3,
          textDescription: '13.3. Se o Usuário deixar de usar a Área do Cliente ou se a Finlândia deixar de a disponibilizar, tal não irá afetará a prestação dos produtos e ou serviços a que tiver aderido através da Área do Cliente, exceto se os mesmos apenas estiverem disponíveis através da Área do Cliente.',
        },
        {
          id: 4,
          textDescription: '13.4. Contas de usuário sem acesso por mais de 6 (seis) meses poderão ser canceladas pela Finlândia, sem necessidade de aviso prévio ao Usuário.',
        },
      ],
    },
    {
      id: 14,
      title: '14. Privacidade e Dados Pessoais',
      subDescription: [
        {
          id: 1,
          textDescription: '14.1. Os dados pessoais cadastrados na Área do Cliente são tratados de forma segura e a Finlândia limita o tratamento dos dados pessoais para as suas devidas finalidades e em conformidade com a lei. Contudo, a Finlândia declara que não assume qualquer obrigação ou responsabilidade por: a) Precisão ou integralidade do conteúdo disponibilizado na Área do Cliente; b) Danos e prejuízos causados pelo acesso, interceptação, eliminação, alteração, modificação ou manipulação, por terceiros não autorizados, dos arquivos e comunicações transmitidos através da Área do Cliente; c) Danos pessoais, de qualquer natureza, resultantes do acesso e do uso da Área do Cliente; d) Perda de lucros, perda de receita, perda de dados, perdas financeiras ou danos indiretos, especiais, consequenciais, exemplares ou punitivos, quando permitido por lei; e) Problemas na disponibilidade, infalibilidade e continuidade do funcionamento dos serviços disponibilizados na Área do Cliente, bem como eventuais danos e prejuízos diretos ou indiretos que podem ser decorrentes da indisponibilidade, falha e alteração do funcionamento da Área do Cliente.',
        },
        {
          id: 2,
          textDescription: '14.2. A Finlândia não é responsável por quaisquer perdas ou danos, diretos ou indiretos, sofridos pelo Usuário, relativamente à informação contida na Área do Cliente ou resultantes da utilização que fizer dos serviços disponíveis na Área do Cliente, nomeadamente por perdas ou modificações das suas informações, nem garante a privacidade de qualquer informação que envie sempre que a mesma seja transmitida pela Internet sem condições adicionais de segurança.',
        },
        {
          id: 3,
          textDescription: '14.3. Os dados pessoais disponibilizados pelo Usuário à Finlândia serão processados e armazenados informaticamente e destinam-se a ser utilizados pela Finlândia no âmbito da relação contratual e/ou comercial estabelecida com o Usuário, para a intermediação de seguros ou outros produtos e serviços da Finlândia ou de qualquer outra empresa do Grupo Finlândia e para fins de marketing.',
        },
        {
          id: 4,
          textDescription: '14.3.1. Os dados pessoais tratados na Área do Cliente são abaixo descritos:',
        },
        {
          id: 5,
          textDescription: '• Para fins de Dados de Login: nome completo, telefone de contato, e-mail e endereço;',
        },
        {
          id: 6,
          textDescription: '• Para fins de cotação de seguro para pessoa física, os dados tratados variam de acordo com o produto a ser cotado e podem incluir: nome, CPF, data de nascimento, sexo, cargo, município que reside, data de nascimento, assinatura;',
        },
        {
          id: 7,
          textDescription: '• Para fins de análise de risco de seguros para pessoa jurídica: imposto de renda do sócio, recibo de envio do imposto de renda, relatórios médicos;',
        },
        {
          id: 8,
          textDescription: '• Para fins de realização de endosso: nome, e-mail e telefone;',
        },
        {
          id: 9,
          textDescription: '• Para fins de contato: nome e telefone.',
        },
        {
          id: 10,
          textDescription: '14.4. No âmbito da utilização da Área do Cliente, o tratamento dos dados pessoais do Usuário se dá, precipuamente, com amparo na base legal referente à execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o Usuário (art. 7º, V, Lei nº 13.709/18), podendo a Finlândia recolher e utilizar, ainda, dados técnicos e informação relacionada, incluindo, mas não limitado, a informação técnica sobre o seu dispositivo, sistema e software de aplicações e periféricos, os quais são recolhidos de forma periódica de forma a facilitar a prestação de atualizações de (“updates”) de software, apoio ao produto e outros serviços ao Usuário (se existentes) relacionados com o produto e/ou serviço contratados.',
        },
        {
          id: 11,
          textDescription: '14.5. Nos casos de omissão da presente Cláusula 14, é aplicável a Política de Privacidade do Grupo Finlândia.',
        },
      ],
    },
    {
      id: 15,
      title: '15. Lei Aplicável',
      subDescription: [
        {
          id: 1,
          textDescription: 'Nos casos de omissão dos presentes Termos de Uso, será aplicável a lei brasileira.',
        },
      ],
    },
    {
      id: 16,
      title: '16. Independência das Cláusulas',
      subDescription: [
        {
          id: 1,
          textDescription: 'Se qualquer parte deste Termo for considerada inexequível, inválida ou ilegal, a parte restante permanecerá em total vigência e efeito.',
        },
      ],
    },
  ], [])

  return useCallback(() => ({
    getTermsOfUse,
    sendAceptLastVersion,
    getHasAceptedLastVersion,
  }), [
    getTermsOfUse,
    sendAceptLastVersion,
    getHasAceptedLastVersion,
  ])
}

export default useTermsOfUseClient
