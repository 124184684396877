import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import {
  isNull,
  isEmpty,
} from 'lodash'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useBreadcrumb } from '../../../shared/contexts/BreadcrumbContext'

import {
  useAlert, useLoader,
} from '../../../shared/components'
import useUtils from '../../../shared/hooks/useUtils'
import { useQuotationClient, useQuotationTypesClient } from '../../../clients/QuotationClient'
import useUserClient from '../../../clients/UserClient/useUserClient'
import {
  QUOTE_BID,
  QUOTE_LEASE_BAIL,
  QUOTE_PERFORMANCE,
  STATUS_QUOTE_DENIED,
  STATUS_QUOTE_EXPIRED,
  STATUS_QUOTE_SALES_FORCE,
  QUOTE_JUDICIAL_CIVEL,
  QUOTE_JUDICIAL_LABOR,
  QUOTE_JUDICIAL_APPEAL,
  STATUS_QUOTE_APPROVED,
  QUOTE_GUARANTEE_OTHER,
  STATUS_QUOTE_ANALIZING,
  STATUS_PROPOSAL_REQUESTED,
  STATUS_PROPOSAL_DONE,
  STATUS_PROPOSAL_ACCEPTED,
  STATUS_PROPOSAL_EXPIRED,
  STATUS_POLICY_ISSUED,
  QUOTE_ENGINEERING_RISK,
  STATUS_QUOTE_NOT_SENDED,
  STATUS_QUOTE_NOT_SENDED_ZERO,
  QUOTE_URL_EXECUTIVE_REQUEST_CONFIRMATION,
  QUOTE_URL_EXECUTIVE_REQUEST,
} from '../../../constants'

import PriceContext from '../../../shared/contexts/PriceContext'

import InfoPayment from './payment/InfoPayment'
import ConfirmationBID from './guarantee-bid/Confirmation'
import ConfirmationLeaseBail from './lease-bail/Confirmation'
import ConfirmationGuaranteeOther from './guarantee-other/Confirmation'
import ConfirmationEngineeringRisk from './engineering-risk/Confirmation'
import ConfirmationPerformance from './guarantee-performance/Confirmation'
import ProposalConfirmationBid from '../proposal/guarantee-bid/Confirmation'
import ProposalConfirmationLeaseBail from '../proposal/lease-bail/Confirmation'
import ConfirmationJudicialAppeal from './judicial-guarantee-appeal/Confirmation'
import ConfirmationJudicialCivel from './judicial-guarantee-civel/Confirmation'
import ConfirmationJudicialLabor from './judicial-guarantee-labor/Confirmation'
import ProposalConfirmationPerformance from '../proposal/guarantee-performance/Confirmation'
import ProposalConfirmationJudicialAppeal from '../proposal/judicial-guarantee-appeal/Confirmation'
import ProposalConfirmationJudicialCivel from '../proposal/judicial-guarantee-civel/Confirmation'
import ProposalConfirmationJudicialLabor from '../proposal/judicial-guarantee-labor/Confirmation'
import EnginneringRiskQuestion from './guarantee-performance/fragments/EnginneringRiskQuestion/EnginneringRiskQuestion'

import ExecutiveRedirectModal from '../../executive-portal-request/ExecutiveRedirectModal'

const SECONDS_WAITING = 6000
const MAX_SECONDS_WAITING = 90000

const StepType = {
  QUOTE_CONFIRMATION: 0,
  QUOTE_PAYMENT: 1,
}

const QuotationDetails = () => {
  const { Provider } = PriceContext

  const history = useHistory()
  const location = useLocation()
  const quotationClient = useQuotationClient()
  const quotationTypesClient = useQuotationTypesClient()
  const userClient = useUserClient()

  const { state } = useLocation()
  const { setTitlePage } = useBreadcrumb()
  const { quotationIdentifier } = useParams()
  const { showLoader, hideLoader } = useLoader()
  const { makeQueryPromise } = useUtils()
  const { cleanMsg, addMsgDanger } = useAlert('quotation')
  const { addMsgDanger: addMsgError, addMsgSuccess } = useAlert()

  const [steps] = useState([
    StepType.QUOTE_CONFIRMATION,
    StepType.QUOTE_PAYMENT,
  ])
  const [proposal, setProposal] = useState()
  const [stepsRef, setStepsRef] = useState({})
  const [quoteType, setQuoteType] = useState()
  const [quoteStatus, setQuoteStatus] = useState()
  const [actionNext, setActionNext] = useState(false)
  const [confirmation, setConfirmation] = useState({})
  const [closeQuestion, setCloseQuestion] = useState(false)
  const [questionExecuted, setQuestionExecuted] = useState(false)
  const [isShowQuestion, setIsShowQuestion] = useState(false)
  const [quotationFull, setQuotationFull] = useState()
  const [quotationDetails, setQuotationDetails] = useState()
  const [actionPrevious, setActionPrevious] = useState(false)
  const [quoteRegisterDataPerformance, setQuoteRegisterDataPerformance] = useState({})
  const [activeStep, setActiveStep] = useState(StepType.QUOTE_CONFIRMATION)
  const [mainActivityIsConstruction, setMainActivityIsConstruction] = useState(false)
  const [mainActivityIsAdministrative, setMainActivityIsAdministrative] = useState(false)
  const [identifier, setIdentifier] = useState('')
  const [openExecutiveRedirectModal, setExecutiveRedirectModal] = useState(false)

  const QuotePaymentRef = useRef()
  const QuoteConfirmationRef = useRef()

  const getTokenUrl = location?.pathname?.split(':')
  const routeSolicitation = getTokenUrl[0]
  const tokenParams = getTokenUrl[1]

  useEffect(() => {
    const stepsControl = {}
    stepsControl[StepType.QUOTE_CONFIRMATION] = QuoteConfirmationRef
    stepsControl[StepType.QUOTE_PAYMENT] = QuotePaymentRef

    setStepsRef(stepsControl)
  }, [
    setStepsRef,
    QuotePaymentRef,
    QuoteConfirmationRef,
  ])

  useEffect(() => {
    if (state?.showPayment
      && quotationFull?.Status === STATUS_QUOTE_APPROVED) {
      setActiveStep(StepType.QUOTE_PAYMENT)
    }
  }, [
    state?.showPayment,
    quotationFull,
  ])

  useEffect(() => {
    if (quoteType === QUOTE_PERFORMANCE && state && state.quotation && state.quotation.data) {
      userClient().mainActivityIsConstruction(state.quotation.data.insured.documentNumber)
        .then((response) => {
          setMainActivityIsConstruction(response.data.mainActivityIsConstruction)
          setMainActivityIsAdministrative(response.data.mainActivityIsAdministrative)
        })
    }
  }, [quoteType, userClient, state])

  const isActiveStep = (step) => activeStep === step

  const getPrefixParam = useCallback((idQuote) => {
    const prefixMap = {
      BID: QUOTE_BID,
      FL: QUOTE_LEASE_BAIL,
      PER: QUOTE_PERFORMANCE,
      JC: QUOTE_JUDICIAL_CIVEL,
      JT: QUOTE_JUDICIAL_LABOR,
      RE: QUOTE_ENGINEERING_RISK,
      JDR: QUOTE_JUDICIAL_APPEAL,
    }

    const key = Object.keys(prefixMap).find((k) => idQuote?.startsWith(k))
    return key ? prefixMap[key] : QUOTE_GUARANTEE_OTHER
  }, [])

  const setDataQuotationDetails = useCallback((quotation) => {
    setQuotationFull(quotation)
    setQuoteType(quotation?.QuoteType)
    setQuoteStatus(quotation?.Status)
    setQuotationDetails(quotation[quotation?.attr])
  }, [
    setQuotationDetails,
  ])

  const filterQuotationDetails = useCallback((quotation) => {
    const type = getPrefixParam(quotation?.Identifier)

    if (!isEmpty(quotation?.Identifier)) {
      setProposal({
        quote: quotation.Quote,
        proposal: quotation.Proposal,
        quoteDenied: quotation.QuoteDenied,
      })
      setConfirmation({ quote: { ...quotation?.Quote } })
    }

    const attrQuotation = quotationTypesClient()
      .getAttrQuoteByType(type)

    setDataQuotationDetails({
      ...quotation,
      QuoteType: type,
      attr: attrQuotation,
    })
  }, [
    getPrefixParam,
    setConfirmation,
    quotationTypesClient,
    setDataQuotationDetails,
  ])

  const checkStatusQuoteAnalizingDeniedExpired = () => !([
    STATUS_QUOTE_DENIED,
    STATUS_QUOTE_EXPIRED,
    STATUS_QUOTE_ANALIZING,
    STATUS_POLICY_ISSUED,
    STATUS_QUOTE_NOT_SENDED_ZERO,
    STATUS_QUOTE_SALES_FORCE,
  ].includes(quoteStatus ?? -1))

  const isProposal = () => !isNull(quoteStatus) && ([
    STATUS_PROPOSAL_REQUESTED,
    STATUS_PROPOSAL_DONE,
    STATUS_PROPOSAL_ACCEPTED,
    STATUS_PROPOSAL_EXPIRED,
    STATUS_POLICY_ISSUED,
  ].includes(quoteStatus))

  const listQuotations = () => {
    history.push('/status-solicitacao')
  }

  const handleNext = () => {
    if (quoteStatus === 0 || !checkStatusQuoteAnalizingDeniedExpired()) {
      listQuotations()
      return
    }

    const stepRef = stepsRef[activeStep]
    stepRef.current.onSubmit().then((data) => {
      if (data) {
        addMsgDanger(data)
      } else {
        setActionNext(true)
        setActionPrevious(false)
      }
    })
  }

  const onNext = () => {
    cleanMsg()
    if (steps.includes(activeStep + 1)) {
      setActiveStep(activeStep + 1)
    }
  }

  const onPrevious = () => {
    cleanMsg()
    setActiveStep(activeStep - 1)
  }

  const handleExecutiveRedirectModal = (executiveSolicitationIdentifier) => {
    setIdentifier(executiveSolicitationIdentifier)
    setExecutiveRedirectModal(true)
  }

  const onRegister = (data) => {
    const dataSuccess = {
      title: data?.Title,
      message: data?.Message,
      labelButton: 'Acompanhe sua solicitação',
      action: () => history.replace('/status-solicitacao'),
    }

    if (QUOTE_URL_EXECUTIVE_REQUEST_CONFIRMATION === routeSolicitation) {
      handleExecutiveRedirectModal(data?.Identifier)
    } else {
      addMsgSuccess(dataSuccess)
    }
  }

  const onError = (error) => {
    const { Title: title } = error?.data

    if (title && title !== '') {
      addMsgError(title)
    }

    const dataError = {
      title: 'Algo deu errado ao solicitar apólice.',
      message: '',
      labelButton: 'Voltar',
      action: () => {
        if (QUOTE_URL_EXECUTIVE_REQUEST_CONFIRMATION === routeSolicitation) {
          history.replace(`${QUOTE_URL_EXECUTIVE_REQUEST}:${tokenParams}`)
        } else {
          history.replace('/cotacao')
        }
      },
    }

    addMsgError(dataError)
  }

  const handleEdit = (path) => {
    const quote = quotationDetails
      ? {
        quote: {
          ...quotationDetails,
          quotationIdentifier: quotationIdentifier ?? quotationFull?.Identifier,
          status: quotationFull?.Status ?? STATUS_QUOTE_NOT_SENDED,
        },
      } : null
    if (quote) {
      if (QUOTE_URL_EXECUTIVE_REQUEST_CONFIRMATION === routeSolicitation) {
        history.replace(`${QUOTE_URL_EXECUTIVE_REQUEST}${path}/:${tokenParams}`, quote)
      } else {
        history.replace(`/cotacao/${path}`, quote)
      }
    }
  }

  const getQuotationByIdentifier = useCallback((quoteIdentifier) => new Promise(
    (resolve, reject) => {
      quotationClient().getQuotationDetails(quoteIdentifier)
        .then((response) => {
          resolve(response?.data)
        }, (error) => {
          reject(error)
        })
    },
  ), [quotationClient])

  const clickCloseQuestion = () => {
    setCloseQuestion(true)
  }

  const IsShowPerformanceQuestion = useCallback(() => {
    if (closeQuestion) {
      setIsShowQuestion(false)
    } else {
      setIsShowQuestion(isEmpty(state?.quotation?.QuotationIdentifier)
      || !state?.quotation?.isEdit)
    }
  }, [closeQuestion, state])

  const handleQuotationApproved = useCallback((quoteRegisterData) => {
    let promiseRequest = null
    let countSecondsWaiting = 1

    showLoader({
      title: 'Aguarde,',
      subtitle: 'estamos processando sua solicitação...',
    })

    setTitlePage(`Cotação ${quoteRegisterData?.Identifier}`)

    const ClientIsNominated = state?.quotation?.data?.ClientIsNominated

    const intervalApprovation = setInterval(async () => {
      if ((countSecondsWaiting * SECONDS_WAITING >= MAX_SECONDS_WAITING) || !ClientIsNominated) {
        hideLoader()
        onRegister(quoteRegisterData)
        clearInterval(intervalApprovation)
      } else {
        if (!promiseRequest) {
          let quoteResponse = null
          promiseRequest = makeQueryPromise(getQuotationByIdentifier(
            quoteRegisterData?.Identifier,
          ))

          await promiseRequest.then((response) => {
            if (promiseRequest.isFulfilled()) {
              quoteResponse = response
            }
          }).catch(() => {
            promiseRequest = null
          })

          if (!isEmpty(quoteResponse)
            && quoteResponse?.Status === STATUS_QUOTE_APPROVED
            && QUOTE_URL_EXECUTIVE_REQUEST_CONFIRMATION !== routeSolicitation) {
            history.replace(`/cotacao/${quoteResponse?.Identifier}/status`, {
              showPayment: true,
            })
            hideLoader()
            clearInterval(intervalApprovation)
          }
          if (!isEmpty(quoteResponse) && quoteResponse?.QuoteDenied) {
            hideLoader()
            onRegister(quoteRegisterData)
            clearInterval(intervalApprovation)
          } else {
            promiseRequest = null
          }
        }
        countSecondsWaiting++
      }
    }, SECONDS_WAITING)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleQuestionsREPerformance = (quoteRegisterData) => {
    if (QUOTE_URL_EXECUTIVE_REQUEST_CONFIRMATION === routeSolicitation) {
      onRegister(quoteRegisterData)
    } else if (!mainActivityIsConstruction && !mainActivityIsAdministrative) {
      handleQuotationApproved(quoteRegisterData)
    } else {
      IsShowPerformanceQuestion()
      setQuoteRegisterDataPerformance(quoteRegisterData)
    }
  }

  useEffect(() => {
    const checkCloseQuestion = setInterval(() => {
      if (closeQuestion && !questionExecuted) {
        setIsShowQuestion(false)
        setQuestionExecuted(true)
        handleQuotationApproved(quoteRegisterDataPerformance)
      }
    }, 5000)
    return () => {
      clearInterval(checkCloseQuestion)
    }
  }, [
    closeQuestion,
    questionExecuted,
    handleQuotationApproved,
    quoteRegisterDataPerformance,
  ])

  const renderQuotePerformance = () => {
    if (isProposal()) {
      return (
        <ProposalConfirmationPerformance
          quotation={quotationDetails}
          quotationStatus={quoteStatus}
          proposal={proposal}
        />
      )
    }
    return (
      <>
        {isShowQuestion ? (
          <EnginneringRiskQuestion
            quoteDetails={quotationDetails}
            identifier={quoteRegisterDataPerformance?.Identifier}
            isConstruction={mainActivityIsConstruction}
            onClickSubmit={clickCloseQuestion}
          />
        ) : (
          <ConfirmationPerformance
            quotation={quotationDetails}
            quotationAnalizingDeniedExpired={!checkStatusQuoteAnalizingDeniedExpired()}
            quotationStatus={quoteStatus}
            proposal={proposal}
            ref={stepsRef[StepType.QUOTE_CONFIRMATION]}
            handleNext={() => {
              const quoteState = state?.quotation
              if (!isEmpty(quoteState?.QuotationIdentifier) && quoteState?.isEdit) {
                quotationClient().edit({
                  identifier: quoteState?.QuotationIdentifier,
                  performance: { ...quoteState?.data },
                }).then((response) => {
                  handleQuotationApproved(response?.data)
                }, onError)
                return
              }

              if (quoteState?.Status === STATUS_QUOTE_NOT_SENDED) {
                quotationClient()
                  .registerGuaranteePerformance({
                    performance: { ...quoteState?.data },
                  }).then((response) => {
                    handleQuestionsREPerformance(response?.data)
                  }, onError)
                return
              }

              handleNext()
            }}
            handlePrevious={() => handleEdit('garantia/performance')}
          />
        )}
      </>
    )
  }

  const renderQuoteBid = () => {
    if (isProposal()) {
      return (
        <ProposalConfirmationBid
          quotation={quotationDetails}
          quotationStatus={quoteStatus}
          proposal={proposal}
        />
      )
    }
    return (
      <ConfirmationBID
        quotation={quotationDetails}
        proposal={proposal}
        quotationStatus={quoteStatus}
        quotationAnalizingDeniedExpired={!checkStatusQuoteAnalizingDeniedExpired()}
        ref={stepsRef[StepType.QUOTE_CONFIRMATION]}
        handleNext={() => {
          const quoteState = state?.quotation
          if (!isEmpty(quoteState?.QuotationIdentifier) && quoteState?.isEdit) {
            quotationClient().edit({
              identifier: quoteState?.QuotationIdentifier,
              bid: { ...quoteState?.data },
            }).then((response) => {
              handleQuotationApproved(response?.data)
            }, onError)
            return
          }

          if (quoteState?.Status === STATUS_QUOTE_NOT_SENDED) {
            quotationClient()
              .registerGuaranteeBID({
                bid: { ...quoteState?.data },
              }).then((response) => {
                handleQuotationApproved(response?.data)
              }, onError)
            return
          }

          handleNext()
        }}
        handlePrevious={() => handleEdit('garantia/licitante')}
      />
    )
  }

  const renderQuoteLeaseBail = () => {
    if (isProposal()) {
      return (
        <ProposalConfirmationLeaseBail
          quotation={quotationDetails}
          quotationStatus={quoteStatus}
          proposal={proposal}
        />
      )
    }
    return (
      <ConfirmationLeaseBail
        quotation={quotationDetails}
        proposal={proposal}
        quotationStatus={quoteStatus}
        quotationAnalizingDeniedExpired={!checkStatusQuoteAnalizingDeniedExpired()}
        ref={stepsRef[StepType.QUOTE_CONFIRMATION]}
        handleNext={handleNext}
        handlePrevious={() => handleEdit('fianca-locaticia')}
      />
    )
  }

  const renderQuoteJudicialAppeal = () => {
    if (isProposal()) {
      return (
        <ProposalConfirmationJudicialAppeal
          quotation={quotationDetails}
          quotationStatus={quoteStatus}
          proposal={proposal}
        />
      )
    }

    return (
      <ConfirmationJudicialAppeal
        proposal={proposal}
        quotation={quotationDetails}
        quotationStatus={quoteStatus}
        quotationAnalizingDeniedExpired={!checkStatusQuoteAnalizingDeniedExpired()}
        ref={stepsRef[StepType.QUOTE_CONFIRMATION]}
        handleNext={() => {
          const quoteState = state?.quotation
          if (!isEmpty(quoteState?.QuotationIdentifier) && quoteState?.isEdit) {
            quotationClient().edit({
              identifier: quoteState?.QuotationIdentifier,
              appealDeposit: { ...quoteState?.data },
            }).then((response) => {
              handleQuotationApproved(response?.data)
            }, onError)
            return
          }

          if (quoteState?.Status === STATUS_QUOTE_NOT_SENDED) {
            quotationClient()
              .registerJudicialGuaranteeAppeal({
                appealDeposit: { ...quoteState?.data },
              }).then((response) => {
                handleQuotationApproved(response?.data)
              }, onError)
            return
          }

          handleNext()
        }}
        handlePrevious={() => handleEdit('judicial-deposito-recursal')}
      />
    )
  }

  const renderQuoteJudicialLabor = () => {
    if (isProposal()) {
      return (
        <ProposalConfirmationJudicialLabor
          quotation={quotationDetails}
          quotationStatus={quoteStatus}
          proposal={proposal}
        />
      )
    }

    return (
      <ConfirmationJudicialLabor
        proposal={proposal}
        quotation={quotationDetails}
        quotationStatus={quoteStatus}
        quotationAnalizingDeniedExpired={!checkStatusQuoteAnalizingDeniedExpired()}
        ref={stepsRef[StepType.QUOTE_CONFIRMATION]}
        handleNext={() => {
          const quoteState = state?.quotation
          if (!isEmpty(quoteState?.QuotationIdentifier) && quoteState?.isEdit) {
            quotationClient().edit({
              identifier: quoteState?.QuotationIdentifier,
              judicialLabor: { ...quoteState?.data },
            }).then((response) => {
              handleQuotationApproved(response?.data)
            }, onError)
            return
          }

          if (quoteState?.Status === STATUS_QUOTE_NOT_SENDED) {
            quotationClient()
              .registerJudicialGuaranteeLabor({
                judicialLabor: { ...quoteState?.data },
              }).then((response) => {
                handleQuotationApproved(response?.data)
              }, onError)
            return
          }

          handleNext()
        }}
        handlePrevious={() => handleEdit('judicial-trabalhista')}
      />
    )
  }

  const renderQuoteJudicialCivel = () => {
    if (isProposal()) {
      return (
        <ProposalConfirmationJudicialCivel
          quotation={quotationDetails}
          quotationStatus={quoteStatus}
          proposal={proposal}
        />
      )
    }

    return (
      <ConfirmationJudicialCivel
        proposal={proposal}
        quotation={quotationDetails}
        quotationStatus={quoteStatus}
        quotationAnalizingDeniedExpired={!checkStatusQuoteAnalizingDeniedExpired()}
        ref={stepsRef[StepType.QUOTE_CONFIRMATION]}
        handleNext={() => {
          const quoteState = state?.quotation
          if (!isEmpty(quoteState?.QuotationIdentifier) && quoteState?.isEdit) {
            quotationClient().edit({
              identifier: quoteState?.QuotationIdentifier,
              judicialCivel: { ...quoteState?.data },
            }).then((response) => {
              handleQuotationApproved(response?.data)
            }, onError)
            return
          }

          if (quoteState?.Status === STATUS_QUOTE_NOT_SENDED) {
            quotationClient()
              .registerJudicialGuaranteeCivel({
                judicialCivel: { ...quoteState?.data },
              }).then((response) => {
                handleQuotationApproved(response?.data)
              }, onError)
            return
          }

          handleNext()
        }}
        handlePrevious={() => handleEdit('judicial-civel')}
      />
    )
  }

  const renderQuoteEngineeringRisk = () => (
    <ConfirmationEngineeringRisk
      quotation={quotationDetails}
      quotationStatus={quoteStatus}
      quotationAnalizingDeniedExpired={!checkStatusQuoteAnalizingDeniedExpired()}
      ref={stepsRef[StepType.QUOTE_CONFIRMATION]}
      handleNext={handleNext}
      handlePrevious={() => handleEdit('risco-engenharia')}
    />
  )

  const renderQuoteGuaranteeOther = () => (
    <ConfirmationGuaranteeOther
      quotation={quotationDetails}
      quotationStatus={quoteStatus}
      quotationAnalizingDeniedExpired={!checkStatusQuoteAnalizingDeniedExpired()}
      ref={stepsRef[StepType.QUOTE_CONFIRMATION]}
      handleNext={handleNext}
      handlePrevious={() => handleEdit('garantia')}
    />
  )

  const setQuotationDetailsByIdentifier = useCallback(() => {
    setTitlePage(`Cotação ${quotationIdentifier}`)

    getQuotationByIdentifier(quotationIdentifier)
      .then((response) => {
        if (response) {
          filterQuotationDetails(response)
        }
      }, (error) => {
        history.replace(`/error/${error?.status}`)
      })
  }, [
    history,
    setTitlePage,
    quotationIdentifier,
    filterQuotationDetails,
    getQuotationByIdentifier,
  ])

  useEffect(() => {
    if (state?.quotation || location?.pathname === '/cotacao/confirmacao') {
      setTitlePage('Confirmação')

      const quotationForWings = quotationTypesClient()
        .getQuoteByTypeForWings(state?.quotation)

      setDataQuotationDetails(quotationForWings)
    } else {
      setQuotationDetailsByIdentifier()
    }
  }, [
    location,
    setTitlePage,
    state?.quotation,
    quotationTypesClient,
    setDataQuotationDetails,
    setQuotationDetailsByIdentifier,
  ])

  return (
    <Provider value={{
      confirmation,
      setConfirmation,
      actionNext,
      actionPrevious,
      onNext,
      onRegister,
      addMsgError,
    }}
    >
      <>
        {quotationDetails && (
          (
            isActiveStep(StepType.QUOTE_CONFIRMATION) && (
              (quoteType === QUOTE_JUDICIAL_APPEAL
                && (
                  renderQuoteJudicialAppeal()
                )
              ) || (
                quoteType === QUOTE_JUDICIAL_LABOR
                && (
                  renderQuoteJudicialLabor()
                )
              ) || (
                quoteType === QUOTE_JUDICIAL_CIVEL
                && (
                  renderQuoteJudicialCivel()
                )
              ) || (
                quoteType === QUOTE_LEASE_BAIL && (
                  renderQuoteLeaseBail()
                )
              ) || (
                quoteType === QUOTE_BID && (
                  renderQuoteBid()
                )
              ) || (
                quoteType === QUOTE_PERFORMANCE && (
                  renderQuotePerformance()
                )
              ) || (
                quoteType === QUOTE_ENGINEERING_RISK && (
                  renderQuoteEngineeringRisk()
                )
              ) || (
                quoteType === QUOTE_GUARANTEE_OTHER && (
                  renderQuoteGuaranteeOther()
                )
              )
            )
          ) || (
            isActiveStep(StepType.QUOTE_PAYMENT)
            && checkStatusQuoteAnalizingDeniedExpired() && (
              <InfoPayment
                quotation={quotationDetails}
                quotationFull={quotationFull}
                ref={stepsRef[StepType.QUOTE_PAYMENT]}
                handleNext={handleNext}
                handlePrevious={onPrevious}
              />
            )
          )
        )}
      </>
      {openExecutiveRedirectModal && (
        <ExecutiveRedirectModal
          identifier={identifier}
          tokenParams={tokenParams}
        />
      )}
    </Provider>
  )
}

export default QuotationDetails
