import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme) => ({
  header: {
    border: 0,
    outline: 0,
    fontSize: 14,
    fontWeight: 'bold',
    position: 'relative',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    '&:before': {
      left: 0,
      top: '50%',
      height: 0.5,
      width: '100%',
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.text.light,
    },
    '&:after': {
      paddingRight: '1em',
      lineHeight: '1.5em',
      position: 'relative',
      display: 'inline-block',
      backgroundColor: '#FFFFFF',
      content: 'attr(data-content)',
      color: theme.palette.text.primary,
    },
  },
  confirmationBox: {
    display: 'flex',
    borderLeft: '0px',
    borderRight: '0px',
    marginBlock: theme.spacing(1),
    '& .MuiGrid-item': {
      marginBottom: theme.spacing(1),
    },
  },
  confirmationLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.text.quaternary,
  },
  confirmationValue: {
    color: theme.palette.text.quaternary,
  },
  text: {
    marginTop: -15,
    marginBottom: 15,
  },
  containerRentContract: {
    marginBottom: '20px',
  },
  rentContract: {
    display: 'flex',
    marginTop: '20px',
  },
  containerFileUpload: {
    marginBottom: '20px',
  },
  fileUpload: {
    display: 'flex',
    marginTop: '20px',
  },
}))
