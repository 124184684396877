import iconFinladia from '../assets/img/logo-small.svg'
import iconAon from '../assets/img/icons/ico-Aon.svg'
import iconMarsh from '../assets/img/icons/icon-marsh.svg'
import iconWiz from '../assets/img/icons/icon-wiz.png'

import icoBmg from '../assets/img/icons/ico-bmg.png'
import icoJns from '../assets/img/icons/icon-jns.png'
import icoAvla from '../assets/img/icons/icon_avla.svg'
import icoEzze from '../assets/img/icons/icon_ezze.png'
import icoNewe from '../assets/img/icons/icon_newe.png'
import icoTokio from '../assets/img/icons/ico-tokio.png'
import icoEssor from '../assets/img/icons/ico_essor.png'
import icoSombrero from '../assets/img/icons/ico_sombrero.png'
import icoExcelsior from '../assets/img/icons/icon_excelsior.png'
import icoPottencial from '../assets/img/icons/ico-pottencial.svg'
import icoPortoSeguro from '../assets/img/icons/icon-porto-seguro.png'

/* eslint-disable no-unused-vars */
export const DEFAULT_THEME = {
  id: 'default',
  primary: '#0E347D',
}

export const COLOR_ERROR = '#ff3c3c'
export const COLOR_SUCCESS = '#34c576'
export const COLOR_WARNING = '#ffc654'

export const UNAUTHORIZED_REASON_ENUM = {
  INVALID_TOKEN: 0,
  BLOCKED_USER: 1,
  NO_CLIENTS: 2,
  INVALID_ACESSKEY: 3,
}

export const PRODUCT_JUDICIAL_GUARANTEE_APPEAL = 2
export const PRODUCT_GUARANTEE_MODALITY = 3
export const PRODUCT_ENGINEERING_RISK = 4

export const DEPOSIT_VALUE_APPEAL_INTERLOCUTORY = 13133.46
export const DEPOSIT_VALUE_APPEAL_EMBARGOES = 26266.92
export const JUDICIAL_APPEAL_INTERLOCUTORY = 1
export const JUDICIAL_APPEAL_EMBARGOES = 2
export const JUDICIAL_APPEAL_MAGAZINE_RESOURCE = 3
export const JUDICIAL_APPEAL_TERMINATION_ACTION = 4
export const JUDICIAL_APPEAL_EXTRAORDINARY_RESOURCE = 5
export const JUDICIAL_APPEAL_ORDINARY_RESOURCE = 6

export const GUARANTEE_PERFORMANCE_PRIVATE = 1
export const GUARANTEE_PERFORMANCE_PUBLIC = 2

export const QUOTE_TYPE_AUTOMATIC = 1
export const QUOTE_TYPE_MANUAL = 2

export const QUOTE_BID = 1
export const QUOTE_LEASE_BAIL = 2
export const QUOTE_PERFORMANCE = 3
export const QUOTE_JUDICIAL_APPEAL = 4
export const QUOTE_ENGINEERING_RISK = 5
export const QUOTE_GUARANTEE_OTHER = 6
export const QUOTE_JUDICIAL_LABOR = 7
export const QUOTE_JUDICIAL_CIVEL = 8

export const PRODUCT_KEY_BID = 'garantia-bid'
export const PRODUCT_KEY_PERFORMANCE = 'garantia-performance'
export const PRODUCT_KEY_JUDICIAL_CIVEL = 'Judicial-civel'
export const PRODUCT_KEY_JUDICIAL_CIVEL_MIN = 'judicial-civel'
export const PRODUCT_KEY_JUDICIAL_LABOR = 'judicial-trabalhista'
export const PRODUCT_KEY_JUDICIAL_APPEAL = 'judicial-deposito-recursal'

export const STATUS_QUOTE_NOT_SENDED_ZERO = 0
export const STATUS_QUOTE_NOT_SENDED = -1
export const STATUS_QUOTE_ANALIZING = 1
export const STATUS_QUOTE_APPROVED = 2
export const STATUS_QUOTE_DENIED = 3
export const STATUS_QUOTE_EXPIRED = 4
export const STATUS_QUOTE_SALES_FORCE = 17

export const STATUS_PROPOSAL_REQUESTED = 5
export const STATUS_PROPOSAL_DONE = 6
export const STATUS_PROPOSAL_ACCEPTED = 7
export const STATUS_PROPOSAL_EXPIRED = 8

export const STATUS_POLICY_ISSUED = 9

export const STATUS_ENDORSEMENT_ANALIZING = 10
export const STATUS_ENDORSEMENT_DENIED = 11
export const STATUS_ENDORSEMENT_ISSUED = 12

export const ERROR_FIREBASE_AUTH_POPUP_CLOSED = 'auth/popup-closed-by-user'

export const MAX_LIMIT_CHARACTER_OBJECT_LABEL = 1000
export const CONTEXT_EMPTY = '00000000-0000-0000-0000-000000000000'

const GARANT_CODE = '04e701fc-0098-4f05-969e-e0de9dc08592'

export const PRODUCT_JUDICIAL_DEPOSITO_RECURSAL = '85623a9e-86c3-4f70-889a-d449276cb036'
export const PRODUCT_JUDICIAL_DEPOSITO_RECURSAL_SUBSTITUICAO = '480d6b3d-eee6-48ab-9469-3442c4dec9c2'
export const PRODUCT_JUDICIAL_GUARANTEE_LABOR = '2e38f30a-75db-466e-a85e-4823c6df2f34'
export const PRODUCT_JUDICIAL_GUARANTEE_CIVEL = '23395da3-19b5-4988-afe5-4beb9f6878d3'
export const PRODUCT_FIANCA_LOCATICIA = '1f929652-c8eb-4b04-af54-eed15d975b29'
export const PRODUCT_BID = 'ee819339-76c3-452e-b739-feb483da47cb'
export const PRODUCT_PERFORMANCE = 'a83ec9ac-12a4-454a-a9f6-b2c65151f61f'
export const PRODUCT_OTHER = GARANT_CODE
export const PRODUCT_ENG_RISK = '0cf8ccb1-e03c-49e4-9ab2-b77093b8fdd7'
export const PRODUCT_SEVERAL_RISK = '5c8728bd-e0d9-492f-9720-e5d98670800a'
export const PRODUCT_BENEFITS = 'e39b83eb-85b7-49f8-9075-558aa28c5a07'
export const PRODUCT_RESPONSABILITY_CIVIL = 'bcae14f8-7b11-4b82-b699-cec9b03d4141'
export const PRODUCT_PATRIMONIAL = 'f8f16a76-99ed-49f1-8ddd-1874f0db23ac'

export const PRODUCTS_LICIT = [
  GARANT_CODE,
  '8d0cc731-5fa6-438d-bae7-35a51472c9fc',
]

export const PRODUCT_PERFORMANCE_PRIVATE = '7f6f07f2-ffc6-4591-bfa2-09ec49168aa2'
export const PRODUCT_PERFORMANCE_PUBLIC = '0040b17d-90cf-405a-8962-07cc3f19d9cb'

export const EMISSION_PERFORMANCE_AUTO = '_18c790c2-7f61-4c9b-a87d-101737c47a40'
export const EMISSION_PERFORMANCE_MANUAL = '_2d19feec-c07c-4dcc-a2c2-863ccdf03e60'

export const PRODUCTS_CONSTRUCTION = [
  PRODUCT_PERFORMANCE_PRIVATE,
  PRODUCT_PERFORMANCE_PUBLIC,
]

export const TEXT_STATUS_QUOTE_APPROVED = 'Emitir Apólice'
export const TEXT_STATUS_QUOTE_ANALIZING = 'Cotação em Análise'
export const TEXT_STATUS_ENDORSEMENT_ANALIZING = 'Endosso em Análise'

export const TEXT_STATUS_PROPOSAL_DONE = 'Proposta Pronta'
export const TEXT_STATUS_PROPOSAL_REQUESTED = 'Proposta Solicitada'

export const ERROR_FIREBASE_PASSWORD_ENGLISH = 'The password is invalid or the user does not have a password.'
export const ERROR_FIREBASE_DISABLED_BY_AN_ADMINISTRATOR = 'The user account has been disabled by an administrator.'
export const ERROR_FIREBASE_NO_USER_RECORD_ENGLISH = 'There is no user record corresponding to this identifier. The user may have been deleted.'

export const ERROR_MISSED_APPOINTMENT = 'Usuário sem cliente(s) nomeado(s). Por favor contate o Executivo.'

const TYPE_PRODUCT_GUARANTEE = { key: 'type_guarantee', text: 'Garantia', order: 1 }
const TYPE_PRODUCT_JUDICIAL = { key: 'type_judicial', text: 'Garantia Judicial', order: 2 }
const TYPE_PRODUCT_OTHERS = { key: 'type_others', text: 'Outros', order: 3 }

export const PREFIX_RETURN_PRODUCT = {
  JC: 'Judicial',
  JT: 'Judicial',
  JE: 'Judicial',
  JDR: 'Judicial',
  BID: 'Licitante',
  PER: 'Performance',
  RE: 'Risco Engenharia',
  FL: 'Fiança Locatícia',
}

export const PREFIX_RETURN_MODALITY = {
  BID: 'Licitante',
  PER: 'Performance',
  JC: 'Judicial Cível',
  RE: 'Risco Engenharia',
  FL: 'Fiança Locatícia',
  JT: 'Judicial Trabalhista',
  JE: 'Judicial Execução Fiscal',
  JDR: 'Judicial Depósito Recursal',
}

export const MODALITY_RETURN_PREFIX = {
  licitante: 'BID',
  performance: 'PER',
  'judicial-civel': 'JC',
  'risco-engenharia': 'RE',
  'fianca-locaticia': 'FL',
  'judicial-trabalhista': 'JT',
  'judicial-deposito-recursal': 'JDR',
}

export const PREFIX_RETURN_QUOTE = {
  BID: QUOTE_BID,
  FL: QUOTE_LEASE_BAIL,
  PER: QUOTE_PERFORMANCE,
  RE: QUOTE_ENGINEERING_RISK,
  JDR: QUOTE_JUDICIAL_APPEAL,
  licitante: QUOTE_BID,
  performance: QUOTE_PERFORMANCE,
  'fianca-locaticia': QUOTE_LEASE_BAIL,
  'risco-engenharia': QUOTE_ENGINEERING_RISK,
  'judicial-deposito-recursal': QUOTE_JUDICIAL_APPEAL,
}

export const MODALITY_AND_PRODUCT_RETURN_PRODUCT_CODE = {
  licitante: PRODUCT_BID,
  performance: PRODUCT_PERFORMANCE,
  'risco-engenharia': PRODUCT_ENG_RISK,
  'fianca-locaticia': PRODUCT_FIANCA_LOCATICIA,
  'judicial-civel': PRODUCT_JUDICIAL_GUARANTEE_CIVEL,
  'judicial-trabalhista': PRODUCT_JUDICIAL_GUARANTEE_LABOR,
  'judicial-deposito-recursal': PRODUCT_JUDICIAL_DEPOSITO_RECURSAL,
}

export const CUSTOM_NAMES_PRODUCT_FILTER = [
  {
    name: 'Licitante (BID)',
    id: PRODUCT_BID,
  },
  {
    name: 'Performance',
    id: PRODUCT_PERFORMANCE,
  },
  {
    name: 'Judicial Depósito Recursal',
    id: PRODUCT_JUDICIAL_DEPOSITO_RECURSAL,
  },
  {
    name: 'Judicial Depósito Recursal Substituição',
    id: PRODUCT_JUDICIAL_DEPOSITO_RECURSAL_SUBSTITUICAO,
  },
]

export const GROUPS_TYPE_PRODUCT = [
  {
    type: TYPE_PRODUCT_GUARANTEE,
    id: PRODUCT_PERFORMANCE,
  },
  {
    type: TYPE_PRODUCT_GUARANTEE,
    id: PRODUCT_BID,
  },
  {
    type: TYPE_PRODUCT_OTHERS,
    id: PRODUCT_FIANCA_LOCATICIA,
  },
  {
    type: TYPE_PRODUCT_JUDICIAL,
    id: PRODUCT_JUDICIAL_DEPOSITO_RECURSAL,
  },
  {
    type: TYPE_PRODUCT_OTHERS,
    id: PRODUCT_SEVERAL_RISK,
  },
  {
    type: TYPE_PRODUCT_OTHERS,
    id: PRODUCT_BENEFITS,
  },
  {
    type: TYPE_PRODUCT_OTHERS,
    id: PRODUCT_RESPONSABILITY_CIVIL,
  },
  {
    type: TYPE_PRODUCT_OTHERS,
    id: PRODUCT_PATRIMONIAL,
  },
  {
    type: TYPE_PRODUCT_OTHERS,
    id: PRODUCT_ENG_RISK,
  },
  {
    type: TYPE_PRODUCT_JUDICIAL,
    id: PRODUCT_JUDICIAL_DEPOSITO_RECURSAL_SUBSTITUICAO,
  },
  {
    type: TYPE_PRODUCT_JUDICIAL,
    id: PRODUCT_JUDICIAL_GUARANTEE_CIVEL,
  },
  {
    type: TYPE_PRODUCT_JUDICIAL,
    id: PRODUCT_JUDICIAL_GUARANTEE_LABOR,
  },
  {
    type: TYPE_PRODUCT_OTHERS,
    id: GARANT_CODE,
  },
]

export const STATUS_QUOTATION = [
  {
    key: STATUS_PROPOSAL_ACCEPTED,
    text: 'Aguardando Emissão',
  },
  {
    key: STATUS_QUOTE_ANALIZING,
    text: 'Cotação em Análise',
  },
  {
    key: STATUS_QUOTE_EXPIRED,
    text: 'Cotação Expirada',
  },
  {
    key: STATUS_QUOTE_DENIED,
    text: 'Cotação Negada',
  },
  {
    key: STATUS_QUOTE_APPROVED,
    text: 'Emitir Apólice',
  },
  {
    key: STATUS_POLICY_ISSUED,
    text: 'Emissão Realizada',
  },
  {
    key: STATUS_PROPOSAL_EXPIRED,
    text: 'Proposta Expirada',
  },
  {
    key: STATUS_PROPOSAL_DONE,
    text: 'Proposta Pronta',
  },
  {
    key: STATUS_PROPOSAL_REQUESTED,
    text: 'Proposta Solicitada',
  },
]

export const DATE_POLICY_60 = 60
export const DATE_POLICY_30 = 30
export const DATE_POLICY_5 = 5

export const STATUS_POLICY_DONE = 'Emitida'
export const STATUS_POLICY_VIGENCY = 'Vigente'
export const STATUS_POLICY_DUE = 'Vencida'
export const STATUS_POLICY_CANCELED = 'Cancelado'

const TOOLTIP_STATUS_POLICY_DONE = 'São todas as apólices emitidas que período de vigência ainda não iniciou'
const TOOLTIP_STATUS_POLICY_VIGENCY = 'São todas as apólices dentro do período de vigência'
const TOOLTIP_STATUS_POLICY_DUE = 'São todas as apólices vencidas e que não foram prorrogadas'
const TOOLTIP_STATUS_POLICY_CANCELED = 'São documentos que foram cancelados através de um endosso'

export const STATUS_POLICY = [
  {
    key: STATUS_POLICY_CANCELED,
    text: 'Cancelado',
    tooltip: TOOLTIP_STATUS_POLICY_CANCELED,
  },
  {
    key: STATUS_POLICY_DONE,
    text: 'Emitida',
    tooltip: TOOLTIP_STATUS_POLICY_DONE,
  },
  {
    key: STATUS_POLICY_DUE,
    text: 'Vencida',
    tooltip: TOOLTIP_STATUS_POLICY_DUE,
  },
  {
    key: STATUS_POLICY_VIGENCY,
    text: 'Vigente',
    tooltip: TOOLTIP_STATUS_POLICY_VIGENCY,
  },
]

export const STATUS_ENDORSEMENT = [
  {
    key: STATUS_ENDORSEMENT_ISSUED,
    text: 'Endosso Emitido',
  },
  {
    key: STATUS_ENDORSEMENT_ANALIZING,
    text: 'Endosso em Análise',
  },
  {
    key: STATUS_ENDORSEMENT_DENIED,
    text: 'Endosso Negado',
  },
]

export const STATUS_QUOTATION_FILTER = [
  {
    key: STATUS_PROPOSAL_ACCEPTED,
    text: 'Aguardando Emissão',
  },
  {
    key: STATUS_QUOTE_ANALIZING,
    text: TEXT_STATUS_QUOTE_ANALIZING,
  },
  {
    key: STATUS_QUOTE_EXPIRED,
    text: 'Cotação Expirada',
  },
  {
    key: STATUS_QUOTE_DENIED,
    text: 'Cotação Negada',
  },
  {
    key: STATUS_QUOTE_APPROVED,
    text: TEXT_STATUS_QUOTE_APPROVED,
  },
  {
    key: STATUS_POLICY_ISSUED,
    text: 'Emissão Realizada',
  },
  ...STATUS_ENDORSEMENT,
  {
    key: STATUS_PROPOSAL_EXPIRED,
    text: 'Proposta Expirada',
  },
  {
    key: STATUS_PROPOSAL_DONE,
    text: TEXT_STATUS_PROPOSAL_DONE,
  },
  {
    key: STATUS_PROPOSAL_REQUESTED,
    text: TEXT_STATUS_PROPOSAL_REQUESTED,
  },
]

export const STATUS_FINANCIAL_PAID = 'Paid'
export const STATUS_FINANCIAL_PENDING = 'Pending'
export const STATUS_FINANCIAL_DUE = 'Expired'

export const STATUS_FINANCIAL = [
  {
    key: STATUS_FINANCIAL_PENDING,
    text: 'Em Aberto',
  },
  {
    key: STATUS_FINANCIAL_PAID,
    text: 'Pago',
  },
  {
    key: STATUS_FINANCIAL_DUE,
    text: 'Vencido',
  },
]

export const VALUES_ORDER = [
  {
    value: 1,
    text: 'Mais Novo - Data de Vencimento',
  },
  {
    value: 2,
    text: 'Mais Antigo - Data de Vencimento',
  },
  {
    value: 3,
    text: 'Maior Valor',
  },
  {
    value: 4,
    text: 'Menor Valor',
  },
  {
    value: 5,
    text: 'Mais Novo - Data de Emissão',
  },
  {
    value: 6,
    text: 'Mais Antigo - Data de Emissão',
  },
]

export const ROTAS_DE_FORMULARIOS = [
  '/cotacao/garantia',
  '/cotacao/garantia/performance',
  '/cotacao/garantia/licitante',
  '/cotacao/judicial-civel',
  '/cotacao/judicial-trabalhista',
  '/cotacao/judicial-deposito-recursal',
  '/cotacao/fianca-locaticia',
  '/cotacao/risco-engenharia',
  '/cotacao/risco-equipamento',
  '/endossos',
  '/importacao-de-apolices',
  '/cadastro-novos-usuarios',
  '/atualizacao-cadastral',
]

export const INSURANCE_COMPANY = [
  {
    id: 'b5dbed67-657c-43b4-bcce-d17483d81fdb',
    name: 'Tokio',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoTokio,
      styles: {
        width: 87,
        height: 60,
        marginTop: -5,
        marginLeft: 23,
      },
    },
  },
  {
    id: '03067d40-a492-4271-bb0d-a3c56f5a56a6',
    name: 'Porto Seguro',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoPortoSeguro,
      styles: {
        height: 30,
        width: 35,
      },
    },
  },
  {
    id: '2003f3a1-7b01-440e-9c0e-f64ee4d994d2',
    name: 'Pottencial',
    icon: {
      svg: icoPottencial,
      style: {
        height: 22,
        width: 22,
      },
    },
  },
  {
    id: '73befb3b-8d1e-48e9-97e7-bb745f7f3af9',
    name: 'Essor',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoEssor,
      styles: {
        width: 16,
        height: 26,
        marginRight: 10,
      },
    },
  },
  {
    id: '72c04995-3976-433f-b7dc-d83566b04653',
    name: 'Excelsior',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoExcelsior,
      styles: {
        width: 26,
        height: 21,
        marginRight: 4,
      },
    },
  },
  {
    id: 'd59c0e97-ff34-43de-89ac-f20e48d2dd61',
    name: 'BMG',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoBmg,
      styles: {
        width: 23,
        height: 27,
        marginRight: 4,
      },
    },
  },
  {
    id: '927ae810-4cdb-470a-8b93-03bdf2d2899a',
    name: 'JNS',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoJns,
      styles: {
        width: 35,
        height: 18,
        marginRight: 4,
      },
    },
  },
  {
    id: 'e99f5628-89af-4ca1-b1d5-9cf4fb595120',
    name: 'EZZE',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoEzze,
      styles: {
        width: 40,
        height: 16,
        marginRight: 4,
      },
    },
  },
  {
    id: '9f1995ee-5034-4a47-978e-2ac0586f228b',
    name: 'Newe',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoNewe,
      styles: {
        width: 50,
        height: 19,
        marginRight: 4,
      },
    },
  },
  {
    id: 'b384b1d1-7776-4a08-bb6c-317920d00992',
    name: 'Sombrero',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoSombrero,
      styles: {
        width: 25,
        height: 20,
        marginRight: 4,
      },
    },
  },
  {
    id: 'd300b5e5-48ef-4681-8e38-7bbe3f76f245',
    name: 'Avla',
    icon: {
      svg: icoAvla,
      style: {
        height: 22,
        width: 22,
      },
    },
  },
]

export const BROKERS = [
  {
    cnpj: '10864690000180',
    name: 'Finlândia',
    fullName: 'Finlândia Corretora de Seguros Ltda',
    icon: {
      svg: iconFinladia,
      style: {
        height: 24,
        width: 24,
      },
    },
  },
  {
    cnpj: '61038592000125',
    name: 'Marsh',
    fullName: 'Marsh Corretora de Seguros Ltda',
    icon: {
      svg: iconMarsh,
      style: {
        height: 26,
        width: 26,
      },
    },
  },
  {
    cnpj: '48102552000137',
    name: 'Aon',
    fullName: 'Aon Holdings Corretora de Seguros Ltda',
    icon: {
      svg: iconAon,
      style: {
        height: 60,
        width: 90,
        marginLeft: 25,
      },
    },
  },
  {
    cnpj: '42278473000103',
    name: 'Wiz',
    fullName: 'Wiz Co Participações e Corretagem de Seguros S.A',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: iconWiz,
      styles: {
        width: 42.6,
        height: 17.86,
        marginRight: 3,
      },
    },
  },
]

export const ENDORSEMENT_URL_EXECUTIVE_REQUEST = '/solicitacao-executivo/endossos/'
export const QUOTE_URL_EXECUTIVE_REQUEST = '/solicitacao-executivo/cotacao/'
export const QUOTE_URL_EXECUTIVE_REQUEST_CONFIRMATION = '/solicitacao-executivo/cotacao/confirmacao/'
export const QUOTE_URL_EXECUTIVE_REQUEST_PER = '/solicitacao-executivo/cotacao/garantia/performance/'
export const QUOTE_URL_EXECUTIVE_REQUEST_BID = '/solicitacao-executivo/cotacao/garantia/licitante/'
export const QUOTE_URL_EXECUTIVE_REQUEST_JC = '/solicitacao-executivo/cotacao/judicial-civel/'
export const QUOTE_URL_EXECUTIVE_REQUEST_JDR = '/solicitacao-executivo/cotacao/judicial-deposito-recursal/'
export const QUOTE_URL_EXECUTIVE_REQUEST_JT = '/solicitacao-executivo/cotacao/judicial-trabalhista/'

export const ROUTES_EXECUTIVE_REQUEST = [
  ENDORSEMENT_URL_EXECUTIVE_REQUEST,
  QUOTE_URL_EXECUTIVE_REQUEST,
  QUOTE_URL_EXECUTIVE_REQUEST_PER,
  QUOTE_URL_EXECUTIVE_REQUEST_BID,
  QUOTE_URL_EXECUTIVE_REQUEST_JC,
  QUOTE_URL_EXECUTIVE_REQUEST_JDR,
  QUOTE_URL_EXECUTIVE_REQUEST_JT,
  QUOTE_URL_EXECUTIVE_REQUEST_CONFIRMATION,
]

const constants = {
  CONTEXT_EMPTY,
  DEFAULT_THEME,
  STATUS_QUOTATION,
  JUDICIAL_APPEAL_EMBARGOES,
  PRODUCT_GUARANTEE_MODALITY,
  GUARANTEE_PERFORMANCE_PUBLIC,
  GUARANTEE_PERFORMANCE_PRIVATE,
  JUDICIAL_APPEAL_INTERLOCUTORY,
  DEPOSIT_VALUE_APPEAL_EMBARGOES,
  ERROR_FIREBASE_AUTH_POPUP_CLOSED,
  PRODUCT_JUDICIAL_GUARANTEE_APPEAL,
  JUDICIAL_APPEAL_MAGAZINE_RESOURCE,
  JUDICIAL_APPEAL_ORDINARY_RESOURCE,
  DEPOSIT_VALUE_APPEAL_INTERLOCUTORY,
  JUDICIAL_APPEAL_TERMINATION_ACTION,
  JUDICIAL_APPEAL_EXTRAORDINARY_RESOURCE,
  QUOTE_TYPE_AUTOMATIC,
  QUOTE_TYPE_MANUAL,
  QUOTE_BID,
  QUOTE_LEASE_BAIL,
  QUOTE_PERFORMANCE,
  QUOTE_JUDICIAL_LABOR,
  QUOTE_JUDICIAL_CIVEL,
  QUOTE_JUDICIAL_APPEAL,
  STATUS_QUOTE_ANALIZING,
  STATUS_QUOTE_DENIED,
  STATUS_QUOTE_APPROVED,
  STATUS_QUOTE_EXPIRED,
  STATUS_PROPOSAL_REQUESTED,
  STATUS_PROPOSAL_DONE,
  STATUS_PROPOSAL_ACCEPTED,
  STATUS_PROPOSAL_EXPIRED,
  STATUS_POLICY_ISSUED,
  STATUS_POLICY,
  STATUS_ENDORSEMENT,
  STATUS_ENDORSEMENT_ANALIZING,
  STATUS_ENDORSEMENT_DENIED,
  STATUS_ENDORSEMENT_ISSUED,
  PRODUCT_ENGINEERING_RISK,
  ERROR_FIREBASE_PASSWORD_ENGLISH,
  MAX_LIMIT_CHARACTER_OBJECT_LABEL,
  ERROR_FIREBASE_NO_USER_RECORD_ENGLISH,
  ROUTES_EXECUTIVE_REQUEST,
}

export default constants
