import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'
import ReactTooltip from 'react-tooltip'

import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { isEqual, isEmpty } from 'lodash'
import { useFormik } from 'formik'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

import makeStyles from '@material-ui/core/styles/makeStyles'

import { usePrice } from '../../../../../shared/contexts/PriceContext'
import useUtils from '../../../../../shared/hooks/useUtils'

import {
  useAlert,
  DateInput,
  ButtonLight,
  ButtonGradient,
} from '../../../../../shared/components'
import CollapsePrice from '../../../../../shared/components/CollapsePrice/CollapsePrice'
import { GuaranteedMessages, GlobalMessages } from '../../../../../shared/messages'
import CurrencyInput from '../../../../../shared/components/CurrencyInput/CurrencyInput'

import ObjectPropertyData from '../../../../../models/BidModels/ObjectPropertyData'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  labelObject: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
    marginBottom: '10px',
  },
}))

const ObjectProperty = forwardRef((props, ref) => {
  const classes = useStyles()
  const {
    date,
    isBlank,
    formatDate,
    formatDateApi,
    formatCurrencyBRLToDecimal,
  } = useUtils()

  const { addMsgWarning } = useAlert()

  const {
    quote,
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props

  const {
    actionNext,
    setFormikChanged,
    guaranteeBID,
    setGuaranteeBID,
    onNext,
  } = usePrice()

  const calculateDeadline = (dateInitial, dateFinal) => {
    const initial = new Date(dateInitial)
    const final = new Date(dateFinal)
    const differenceInMilliseconds = final - initial
    // eslint-disable-next-line sonarjs/prefer-immediate-return
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24)
    return differenceInDays
  }

  const initialValuesForm = useMemo(() => (Object.assign(
    ObjectPropertyData(quote?.ObjectProperty ?? {}),
  )), [quote?.ObjectProperty])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesForm,
    validationSchema: Yup.object({
      objectText: Yup.string().required(),
      complementObjectText: Yup.string().max(1500, GlobalMessages.LIMIT_MAX_CHARACTER(1500))
        .required(),
      startDateValidity: Yup.date().min(date().subtract(1, 'days'), `A data mínima permitida para o início de vigência é ${formatDate(date())}`).required(),
      endDateValidity: Yup.date().when('startDateValidity', (startDateValidity) => startDateValidity
        && Yup.date().min(startDateValidity, GlobalMessages.DATA_FINAL_MAIOR_INICIAL).required()),
      deadline: Yup.string().required(),
      noticeNumber: Yup.string().max(50, GlobalMessages.LIMIT_MAX_CHARACTER(50)).required(),
      contractValue: Yup.number()
        .test('contractValue', GlobalMessages.CAMPO_MAIOR_ZERO('Valor Contrato'), (value) => (value && Number(value) !== 0))
        .required(),
      guaranteeValue: Yup.number()
        .test('guaranteeValue', GlobalMessages.CAMPO_MAIOR_ZERO('Valor Garantia'), (value) => (value && Number(value) !== 0))
        .required(),
    }),
    onSubmit: (data) => {
      const values = {
        ...guaranteeBID,
        ...{ objectProperty: { ...data } },
      }
      const dateFinal = values?.objectProperty?.endDateValidity
      const dateInitial = values?.objectProperty?.startDateValidity
      const deadlineInDays = values?.objectProperty?.deadline

      if (calculateDeadline(dateInitial, dateFinal) === Number(deadlineInDays)) {
        setGuaranteeBID(values)

        if (actionNext) {
          setFormikChanged(!isEqual(initialValuesForm, data))
          onNext(values)
        }
      } else {
        addMsgWarning({
          message: 'O prazo esta incorreto, para prosseguir informe o prazo novamente.',
          action: () => formik.setFieldValue('deadline', ''),
        })
      }
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  const [endDate, setEndDate] = useState('')
  const [deadline, setDeadline] = useState('')
  const [edictNumber, setEdictNumber] = useState('')
  const [contractValue, setContractValue] = useState(0)
  const [guaranteeValue, setGuaranteeValue] = useState(0)

  useEffect(() => {
    const propertyNoticeNumber = formik.getFieldProps('noticeNumber')

    if (propertyNoticeNumber?.value) {
      setEdictNumber(propertyNoticeNumber.value)
    } else {
      setEdictNumber('')
    }
  }, [formik])

  useEffect(() => {
    const endDateValidity = formik.getFieldProps('endDateValidity')
    const startDateValidity = formik.getFieldProps('startDateValidity')

    setDeadline('')
    if (startDateValidity?.value && endDateValidity?.value) {
      const deadlineCount = date(endDateValidity?.value)
        .diff(date(startDateValidity?.value), 'days')
      setDeadline(deadlineCount)
    }
  }, [formik, date, setDeadline])

  const handleChangeDeadline = () => {
    const objectDeadline = formik.getFieldProps('deadline')
    const startDateValidity = formik.getFieldProps('startDateValidity')

    setEndDate('')
    if (!isEmpty(objectDeadline?.value) && !isEmpty(startDateValidity?.value)) {
      const endDateUpdated = date(startDateValidity?.value)
        .add(objectDeadline?.value, 'days')

      setEndDate(formatDateApi(endDateUpdated))
    }
  }
  const concatTextDefault = (edict) => GuaranteedMessages
    .SEGURO_GARANTE_INDENIZACAO_NUMERO_EDITAL(edict)

  const handleGuaranteeValue = useCallback((event) => {
    const { value, valueConst } = event.target
    const valueDecimal = valueConst ?? formatCurrencyBRLToDecimal(value)
    setGuaranteeValue(valueDecimal)
  }, [
    formatCurrencyBRLToDecimal,
  ])

  const handleContractValue = useCallback((event) => {
    const { value, valueConst } = event.target
    const valueDecimal = valueConst ?? formatCurrencyBRLToDecimal(value)
    setContractValue(valueDecimal)
  }, [
    formatCurrencyBRLToDecimal,
  ])

  useEffect(() => {
    if (quote?.ObjectProperty) {
      const fragmentData = quote?.ObjectProperty

      const valueDecimal = fragmentData?.GuaranteeValue
      setGuaranteeValue(valueDecimal)

      const valueContractDecimal = fragmentData?.ContractValue
      setContractValue(valueContractDecimal)
    }
  }, [quote, setGuaranteeValue, formatCurrencyBRLToDecimal])

  const numberLabel = 'Número do Edital'
  const complementObjectLabel = 'Complemento Obrigação Garantia (Objeto)'
  const objectLabel = 'Obrigação Garantia (Objeto)'
  const contractValueLabel = 'Valor Contrato'
  const guaranteeValueLabel = 'Valor Garantia'
  const startDateValidityLabel = 'Início de Vigência'
  const endDateValidityLabel = 'Fim de Vigência'
  const deadlineLabel = 'Prazo (Dias)'

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={isEmpty(quote?.ObjectProperty) && !formik.isSubmitting}
        title="Objeto Segurado"
        subtitle="Informe abaixo os dados do objeto segurado"
      >
        <Grid container spacing={5}>
          <Grid item sm={3} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="numberLabel" className={classes.label}>{numberLabel}</label>
              <Input
                fullWidth
                id="noticeNumber"
                color="secondary"
                placeholder="000000/0000"
                {...formik.getFieldProps('noticeNumber')}
                error={formik.touched.noticeNumber && !!formik.errors.noticeNumber}
                onChange={(event) => {
                  const { value } = event.target
                  if (isBlank(value)) {
                    formik.setFieldValue('noticeNumber', '')
                    formik.setFieldValue('objectText', '')
                  } else {
                    formik.setFieldValue('objectText', concatTextDefault(value))
                  }
                  formik.setFieldValue('noticeNumber', value)
                }}
                onBlur={(event) => {
                  const { value } = event.target
                  if (!isBlank(value)) {
                    formik.setFieldValue('objectText', concatTextDefault(value))
                  }
                }}
              />
              <FormHelperText
                hidden={!formik.touched.noticeNumber || !formik.errors.noticeNumber}
                error={formik.touched.noticeNumber && !!formik.errors.noticeNumber}
              >
                {formik.errors.noticeNumber}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="objectLabel" className={classes.labelObject}>{objectLabel}</label>
              <TextField
                id="objectText"
                multiline
                variant="outlined"
                fullWidth
                minRows={3}
                disabled
                {...formik.getFieldProps('objectText')}
                error={formik.touched.objectText && !!formik.errors.objectText}
                onChange={(e) => {
                  const { value } = e.target
                  formik.setFieldValue('objectText', value?.replace('', '')?.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, ''))
                }}
              />
              <FormHelperText
                hidden={!formik.touched.objectText || !formik.errors.objectText}
                error={formik.touched.objectText && !!formik.errors.objectText}
              >
                {formik.errors.objectText}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="complementObjectLabel" className={classes.labelObject}>{complementObjectLabel}</label>
              <TextField
                id="complementObjectText"
                multiline
                variant="outlined"
                fullWidth
                minRows={2}
                disabled={!formik.values.noticeNumber}
                {...formik.getFieldProps('complementObjectText')}
                onKeyUp={(event) => {
                  const { value } = event.target
                  formik.setFieldValue('objectText', concatTextDefault(`${edictNumber}.\n ${value}`))
                }}
                onBlur={(event) => {
                  const { value } = event.target
                  formik.values.objectText = concatTextDefault(`${edictNumber}.\n ${value}`)
                }}
                onChange={(e) => {
                  const { value } = e.target
                  formik.setFieldValue('complementObjectText', value?.replace('', '')?.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, ''))
                }}
              />
              <FormHelperText
                hidden={!formik.touched.complementObjectText || !formik.errors.complementObjectText}
                error={formik.touched.complementObjectText && !!formik.errors.complementObjectText}
              >
                {formik.errors.complementObjectText}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="contractValueLabel" className={classes.label}>{contractValueLabel}</label>
              <CurrencyInput
                fullWidth
                value={contractValue}
                id="contractValue"
                color="secondary"
                {...formik.getFieldProps('contractValue')}
                error={formik.touched.contractValue && !!formik.errors.contractValue}
                onKeyPress={handleContractValue}
                onBlur={handleContractValue}
              />
              <FormHelperText
                hidden={!formik.touched.contractValue || !formik.errors.contractValue}
                error={formik.touched.contractValue && !!formik.errors.contractValue}
              >
                {formik.errors.contractValue}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="guaranteeValueLabel" className={classes.label}>{guaranteeValueLabel}</label>
              <CurrencyInput
                fullWidth
                value={guaranteeValue}
                id="guaranteeValue"
                color="secondary"
                {...formik.getFieldProps('guaranteeValue')}
                error={
                  formik.touched.guaranteeValue && !!formik.errors.guaranteeValue
                }
                onKeyPress={handleGuaranteeValue}
                onBlur={handleGuaranteeValue}
              />
              <FormHelperText
                hidden={
                  !formik.touched.guaranteeValue || !formik.errors.guaranteeValue
                }
                error={
                  formik.touched.guaranteeValue && !!formik.errors.guaranteeValue
                }
              >
                {formik.errors.guaranteeValue}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item sm={4} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="startDateValidityLabel" className={classes.label}>
                    {startDateValidityLabel}
                  </label>
                  <DateInput
                    id="startDateValidity"
                    title={startDateValidityLabel}
                    {...formik.getFieldProps('startDateValidity')}
                    error={formik.touched.startDateValidity
                      && !!formik.errors.startDateValidity}
                    helperText={formik.touched.startDateValidity
                      && formik.errors.startDateValidity}
                    onChange={(event) => {
                      const { value } = event.target
                      if (isBlank(value)) {
                        formik.setFieldValue('startDateValidity', '')
                      }
                      formik.setFieldValue('startDateValidity', formatDateApi(value))

                      formik.setFieldValue('deadline', deadline)
                    }}
                    onBlur={() => formik.setFieldValue('deadline', deadline)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="endDateValidityLabel" className={classes.label}>
                    {endDateValidityLabel}
                  </label>
                  <DateInput
                    minDate={formatDateApi()}
                    id="endDateValidity"
                    title={endDateValidityLabel}
                    disabled={!formik.values.startDateValidity}
                    {...formik.getFieldProps('endDateValidity')}
                    error={formik.touched.endDateValidity
                      && !!formik.errors.endDateValidity}
                    helperText={formik.touched.endDateValidity
                      && formik.errors.endDateValidity}
                    onChange={(event) => {
                      const { value } = event.target
                      if (isBlank(value)) {
                        formik.setFieldValue('endDateValidity', '')
                      }
                      formik.setFieldValue('endDateValidity', formatDateApi(value))

                      formik.setFieldValue('deadline', deadline)

                      setEndDate(formatDateApi(value))
                    }}
                    onBlur={() => formik.setFieldValue('deadline', deadline)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="deadlineLabel" className={classes.label}>{deadlineLabel}</label>
                  <Input
                    fullWidth
                    id="deadline"
                    color="secondary"
                    disabled={!formik.values.startDateValidity}
                    {...formik.getFieldProps('deadline')}
                    error={formik.touched.deadline && !!formik.errors.deadline}
                    helperText={formik.touched.deadline
                      && formik.errors.deadline}
                    onKeyUp={(event) => {
                      const { value } = event.target
                      if (isBlank(value)) {
                        formik.setFieldValue('deadline', '')
                      }
                      formik.setFieldValue('deadline', value)
                      handleChangeDeadline()
                    }}
                    onBlur={(event) => {
                      const { value } = event.target
                      if (!isBlank(value)) {
                        formik.setFieldValue('endDateValidity', endDate)
                      }
                    }}
                  />
                  <FormHelperText
                    hidden={
                      !formik.touched.deadline || !formik.errors.deadline
                    }
                    error={
                      formik.touched.deadline && !!formik.errors.deadline
                    }
                  >
                    {formik.errors.deadline}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.buttonsStep}>
              <Box>
                <ButtonLight
                  onClick={handlePrevious}
                  title="Voltar"
                >
                  Voltar
                </ButtonLight>
              </Box>
              <Box ml={4}>
                <ButtonGradient
                  onClick={handleNext}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
            <ReactTooltip place="top" type="dark" />
          </Grid>
        </Grid>
      </CollapsePrice>
    </>
  )
})

ObjectProperty.propTypes = {
  quote: PropTypes.any,
  checked: PropTypes.bool,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

ObjectProperty.defaultProps = {
  checked: false,
  quote: undefined,
}

export default ObjectProperty
