import React, {
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import makeStyles from '@material-ui/core/styles/makeStyles'

import {
  STATUS_QUOTE_APPROVED,
  STATUS_QUOTE_ANALIZING,
  STATUS_QUOTE_DENIED,
  STATUS_QUOTE_EXPIRED,
  STATUS_QUOTE_NOT_SENDED_ZERO,
} from '../../../constants'

import {
  ButtonLight,
  ButtonGradient,
  useLoader,
} from '../../../shared/components'
import { GlobalMessages } from '../../../shared/messages'
import { usePrice } from '../../../shared/contexts/PriceContext'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiCollapse-root': {
      borderRadius: 14,
    },
  },
  header: {
    height: 120,
    padding: 40,
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: theme.spacing(2, 5, 4),
  },
  labelInfo: {
    color: theme.palette.text.tertiary,
  },
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
}))

const DEFAULT_CONFIRMATION = {
  responsibilityConfirmation: false,
}

const DataConfirmation = forwardRef((props, ref) => {
  const classes = useStyles()
  const {
    children,
    handleNext,
    handlePrevious,
    quotationStatus,
    quotationAnalizingDeniedExpired,
  } = props
  const { quotationIdentifier } = useParams()
  const { showLoader, hideLoader } = useLoader()
  const {
    onNext,
    actionNext,
  } = usePrice()

  const [buttonDisabled, setButtonDisabled] = useState(false)

  const isRenderBackButton = useMemo(() => [
    STATUS_QUOTE_ANALIZING,
    STATUS_QUOTE_NOT_SENDED_ZERO,
    STATUS_QUOTE_DENIED,
    STATUS_QUOTE_EXPIRED,
  ], [])

  const labelButtonByStatus = useMemo(() => {
    const labelStatus = (quotationStatus === STATUS_QUOTE_APPROVED) ? 'Seguir com Emissão' : 'Continuar'
    const label = isRenderBackButton.includes(quotationStatus) ? 'Voltar' : labelStatus

    return quotationIdentifier ? label : 'Continuar'
  }, [quotationIdentifier, quotationStatus, isRenderBackButton])

  const formik = useFormik({
    initialValues: { ...DEFAULT_CONFIRMATION },

    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(false)

      if (actionNext) {
        showLoader()

        setTimeout(() => {
          hideLoader()
        }, 800)

        onNext({})
      }
    },
  })

  const handleContinue = () => {
    setButtonDisabled(true)
    handleNext()
  }

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  return (
    <>
      <Box className={classes.root}>
        <Paper elevation={1}>
          <Grid container>
            <Grid
              item
              xs={12}
              className={classes.header}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" color="initial">
                    Confirme os dados da sua cotação
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.labelInfo}>
                    Confira abaixo as informações da sua cotação
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.content}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  {children}
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.buttonsStep}>
                    {
                      !quotationAnalizingDeniedExpired && (
                        <Box>
                          <ButtonLight
                            onClick={handlePrevious}
                            title="Editar"
                          >
                            Editar
                          </ButtonLight>
                        </Box>
                      )
                    }
                    <Box display="flex" gridGap="10px" alignItems="center">
                      <ButtonGradient
                        disabled={buttonDisabled}
                        onClick={handleContinue}
                        title={labelButtonByStatus}
                      >
                        {labelButtonByStatus}
                      </ButtonGradient>
                    </Box>

                    {buttonDisabled && (
                      <Box>
                        <CircularProgress style={{ width: 30, height: 30 }} />
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  )
})

DataConfirmation.propTypes = {
  children: PropTypes.any,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  quotationStatus: PropTypes.any.isRequired,
  quotationAnalizingDeniedExpired: PropTypes.bool,
}

DataConfirmation.defaultProps = {
  children: '',
  quotationAnalizingDeniedExpired: false,
}

export default DataConfirmation
