const ObjectPropertyData = ({
  Deadline,
  ObjectText,
  CourtSection,
  ProcessNumber,
  ContractValue,
  PercentageHarm,
  ProposedLegalAction,
  EndDateValidity,
  StartDateValidity,
  InsuredImportance,
  CourtRegionalLabor,
}) => ({
  deadline: Deadline ?? '',
  objectText: ObjectText ?? '',
  courtSection: CourtSection ?? '',
  processNumber: ProcessNumber ?? '',
  contractValue: ContractValue ?? 0,
  percentageHarm: PercentageHarm ?? 30,
  proposedLegalAction: ProposedLegalAction ?? '',
  endDateValidity: EndDateValidity ?? '',
  startDateValidity: StartDateValidity ?? '',
  insuredImportance: InsuredImportance ?? 0,
  courtRegionalLabor: CourtRegionalLabor ?? '',
})

export default ObjectPropertyData
