import React, { useState } from 'react'
import SVG from 'react-inlinesvg'

import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  Box,
  Card,
  Grid,
  Icon,
  Avatar,
  Button,
  Popover,
  CardMedia,
  Typography,
  CardContent,
} from '@material-ui/core'
import {
  CallOutlined as CallOutlinedIcon,
} from '@material-ui/icons'

import icoWhats from '../../../assets/img/icons/ico-whats.svg'
import imgCallCenter from '../../../assets/img/float-buttons/img-callcenter.svg'
import bgModalExecutive from '../../../assets/img/backgrounds/bg-modal-executive.svg'
import useUtils from '../../hooks/useUtils'

const useStyles = makeStyles((theme) => ({
  container: {
    right: 35,
    bottom: 160,
    zIndex: 'auto',
    position: 'fixed',
    [theme.breakpoints.up('lg')]: {
      bottom: 165,
    },
  },
  paper: {
    width: 400,
    maxHeight: 461,
  },
  btn: {
    width: 48,
    height: 48,
    display: 'flex',
    textAlign: 'center',
    borderRadius: ' 50%',
    justifyContent: 'center',
    backgroundImage: `linear-gradient(90deg, #19B8DA, ${theme.palette.primary.main} 80%)`,
    '& .MuiAvatar-root': {
      width: 36,
      height: 36,
      backgroundColor: '#FFFFFF',
    },
    [theme.breakpoints.down('md')]: {
      width: 40,
      height: 40,
    },
  },
  root: {
    color: theme.palette.text.quaternary,
    padding: theme.spacing(4),
    '& .MuiTypography-h5': {
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #F6F6F6',
    },
  },
  media: {
    height: 90,
    animation: 'fadein 0.5s',
    backgroundImage: `url(${bgModalExecutive})`,
  },
  close: {
    cursor: 'pointer',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  avatarModal: {
    width: 80,
    height: 80,
    top: theme.spacing(3),
    left: theme.spacing(4),
    backgroundColor: '#FFFFFF',
  },
  buttonContact: {
    padding: 0,
    width: '100%',
    color: 'inherit',
    '& .MuiButton-label': {
      justifyContent: 'left',
    },
    '& path': {
      fill: theme.palette.info.dark,
    },
  },
  iconWhatsApp: {
    width: '24',
    height: '24',
    color: '#55CA64',
    marginRight: 8,
    // '& path': {
    //   fill: '#55CA64',
    // },
  },
}))

const WHATSAPP_CENTRAL_ATENDIMENTO = '31996716827'

const CallCenterBtn = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { formatPhoneOrCellphone } = useUtils()

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const classes = useStyles()
  return (
    <div className={classes.container} {...props}>
      <Button
        className={classes.btn}
        aria-owns={open ? 'info-call-center' : undefined}
        onClick={handlePopoverOpen}
      >
        <Avatar alt="avatar-call-center" src={imgCallCenter} />
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        disableRestoreFocus
        disableScrollLock
      >
        <Card>
          <CardMedia
            className={classes.media}
            alt="Fundo degradê com tons de azul"
          >
            <Avatar
              src={imgCallCenter}
              alt="avatar-callcenter"
              className={classes.avatarModal}
            />
            <Icon
              title="Fechar"
              className={classes.close}
              onClick={handlePopoverClose}
            >
              close
            </Icon>
          </CardMedia>
          <CardContent className={classes.root}>
            <Typography gutterBottom variant="h5" component="h2">
              Central de Atendimento
            </Typography>
            <Grid item container direction="column" spacing={2}>
              <Grid item>
                <Button
                  className={classes.buttonContact}
                  href={`https://api.whatsapp.com/send?phone=55${WHATSAPP_CENTRAL_ATENDIMENTO}&text=Olá!`}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="WhatsApp"
                >
                  <Box mr={1}>
                    <SVG src={icoWhats} className={classes.iconWhatsApp} />
                  </Box>
                  <Typography variant="body2">
                    {formatPhoneOrCellphone(WHATSAPP_CENTRAL_ATENDIMENTO)}
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.buttonContact}
                  title="Telefone"
                >
                  <Box mr={1}>
                    <CallOutlinedIcon />
                  </Box>
                  <Typography variant="body2" style={{ marginLeft: 9 }}>
                    0800 602 7688
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Popover>
    </div>
  )
}

export default CallCenterBtn
