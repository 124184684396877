import React from 'react'
import { isEmpty } from 'lodash'
import { PropTypes } from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import Error from '@material-ui/icons/Error'

import useQuotationClient from '../../../../clients/QuotationClient/useQuotationClient'
import { ReactComponent as DownloadIcon } from '../../../../assets/img/icons/ico-download.svg'

import useDownload from '../../../../shared/hooks/useDownload'
import { useAlert } from '../../../../shared/components'
import useUtils from '../../../../shared/hooks/useUtils'
import {
  STATUS_PROPOSAL_DONE,
  STATUS_PROPOSAL_EXPIRED,
  STATUS_PROPOSAL_ACCEPTED,
  STATUS_PROPOSAL_REQUESTED,
  STATUS_QUOTE_DENIED,
  STATUS_QUOTE_EXPIRED,
  STATUS_QUOTE_APPROVED,
  COLOR_ERROR,
} from '../../../../constants'
import useStyles from './styles/containerFields'

const modalidadeLabel = 'Modalidade:'
const productLabel = 'Produto:'

const policyHolderLabel = 'Tomador:'
const cpfCnpjLabel = 'CPF/CNPJ do Segurado:'
const insuredLabel = 'Segurado:'
const typeLabel = 'Tipo:'

const noticeNumberLabel = 'Número do Edital:'
const objectTextLabel = 'Obrigação Garantia:'
const garanteeValue = 'Valor Garantia:'
const contractValue = 'Valor Contrato:'
const startDateValidityLabel = 'Início de Vigência:'
const endDateValidityLabel = 'Fim de Vigência:'
const deadlineLabel = 'Prazo (dias):'

const requesterName = 'Nome do Solicitante:'
const requesterPhone = 'Telefone do Solicitante:'
const requesterEmail = 'Email do Solicitante:'
const idClientControlLabel = 'Identificador do Controle do Cliente:'
const requesterNote = 'Observações:'

const LabelDataExpiracao = 'Data de Expiração'
const LabelDataLiberacao = 'Data de Liberação'
const LabelDataNegacao = 'Data Negação'

const formaDePagamento = 'Forma de Pagamento'
const premioLiquido = 'Prêmio Líquido'
const premioTotal = 'Prêmio Total'
const parcelas = 'Parcelas'
const pagamentoPrimeiraParcela = 'Pagamento 1ª Parcela'
const insuranceCompanyName = 'Nome da Seguradora:'

const textQuoteDenied = 'Prezado cliente, após análise técnica e politicas de subscrição da seguradora, não será possível prosseguir com a emissão desta solicitação. Para mais detalhes, entre em contato com seu executivo comercial.'

const ContainerFields = ((props) => {
  const { quotation, quotationStatus, proposal } = props
  const classes = useStyles()
  const {
    formatDate,
    formatCurrency,
    formatCPFOrCNPJ,
    formatPhoneOrCellphone,
  } = useUtils()
  const { downloadPDF } = useDownload()
  const { addMsgDanger } = useAlert()
  const quotationClient = useQuotationClient()

  const isProposal = () => !isEmpty(quotationStatus) && ([
    STATUS_PROPOSAL_REQUESTED,
    STATUS_PROPOSAL_DONE,
    STATUS_PROPOSAL_ACCEPTED,
    STATUS_PROPOSAL_EXPIRED,
  ].includes(quotationStatus))

  const downloadDocuments = (document) => {
    quotationClient().downloadDocumentsWings(document).then((response) => {
      downloadPDF(response?.data, document)
    }, () => {
      addMsgDanger('Não foi possível baixar o arquivo, tente mais tarde ou contate o Administrador.')
    })
  }

  return (
    <>
      {
        quotationStatus === STATUS_QUOTE_DENIED && (
          <Grid container className={classes.root}>
            <Grid item xs={12} className={classes.text}>
              <Box display="flex">
                <Box mr={1}>
                  <Error style={{ color: COLOR_ERROR }} />
                </Box>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {textQuoteDenied}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                className={classes.header}
                data-content="Cotação Negada"
              />
              <Grid container className={classes.confirmationBox} spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {LabelDataNegacao}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatDate(proposal?.quoteDenied?.DeniedAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
      {
        quotationStatus === STATUS_QUOTE_EXPIRED && (
          <Grid container className={classes.root}>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                className={classes.header}
                data-content="Cotação Expirada"
              />
              <Grid container className={classes.confirmationBox} spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {LabelDataExpiracao}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatDate(proposal?.quote.ExpiredAt)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {LabelDataLiberacao}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatDate(proposal?.quote.CreatedAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Detalhes do Produto"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {modalidadeLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                Licitante
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {productLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Product?.Name ?? 'Seguro Garantia'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Dados do Segurado"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {policyHolderLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Insured.PolicyHolder}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {cpfCnpjLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCPFOrCNPJ(quotation?.Insured.Document)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {insuredLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Insured.Name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {typeLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Insured.BIDType}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Segurado"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {noticeNumberLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.NoticeNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {garanteeValue}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.ObjectProperty.GuaranteeValue)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {contractValue}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.ObjectProperty.ContractValue)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {startDateValidityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatDate(quotation?.ObjectProperty.StartDateValidity)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {endDateValidityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatDate(quotation?.ObjectProperty.EndDateValidity)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {objectTextLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.ObjectText}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {deadlineLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.Deadline}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Solicitante"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {requesterName}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.Name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {requesterPhone}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatPhoneOrCellphone(quotation?.Requester.Phone)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {requesterEmail}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.Email}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} hidden={!quotation?.Requester.CustomerControlIdentifier}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {idClientControlLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.CustomerControlIdentifier}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} hidden={!quotation?.Requester.Note}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {requesterNote}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Requester.Note}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.containerFileUpload}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Arquivos"
          />
          <Grid className={classes.fileUpload}>
            <Grid item xs={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                Nome do Arquivo:
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                Download:
              </Typography>
            </Grid>
          </Grid>
          {(quotation?.FileUpload?.Documents ?? quotation?.FileUpload)
            ?.map((item, index) => (
              <Grid key={index} item xs={12} className={classes.fileUpload}>
                <Grid item xs={4}>
                  <Typography className={classes.confirmationValue}>
                    {item?.DocumentName}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Box>
                    <Button
                      variant="text"
                      onClick={() => downloadDocuments(item?.DocumentID)}
                    >
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <Box mr={1}>
                          <DownloadIcon />
                        </Box>

                        <Box sx={{ textDecoration: 'underline', fontSize: 17 }}>
                          <b>Download</b>
                        </Box>
                      </Box>
                    </Button>
                  </Box>
                </Grid>
              </Grid>

            ))}
        </Grid>
        {
          isProposal() && (
            <>
              {(proposal?.quote?.InsurerName !== null
                || !isEmpty(proposal?.quote?.InsurerName)) && (
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      className={classes.header}
                      data-content="Seguradora"
                    />
                    <Grid container className={classes.confirmationBox} spacing={2}>
                      <Grid item xs={12} md={4}>
                        <Typography gutterBottom className={classes.confirmationLabel}>
                          {insuranceCompanyName}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.confirmationValue}>
                          {proposal?.quote?.InsurerName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  // eslint-disable-next-line indent
                )}
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  className={classes.header}
                  data-content="Forma de Pagamento"
                />
                <Grid container className={classes.confirmationBox} spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Typography gutterBottom className={classes.confirmationLabel}>
                      {formaDePagamento}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.confirmationValue}>
                      Boleto
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography gutterBottom className={classes.confirmationLabel}>
                      {premioLiquido}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.confirmationValue}>
                      {formatCurrency(proposal?.quote?.CommercialPremium)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography gutterBottom className={classes.confirmationLabel}>
                      {premioTotal}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.confirmationValue}>
                      {formatCurrency(proposal?.quote?.GrossPremium)}
                    </Typography>
                  </Grid>
                  {
                    quotationStatus !== STATUS_QUOTE_APPROVED && (
                      <>
                        <Grid item xs={12} md={4}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            {parcelas}
                          </Typography>
                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {proposal?.proposal?.Request?.Payment.Installments}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            {pagamentoPrimeiraParcela}
                          </Typography>
                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {proposal?.proposal?.Request?.Payment.FirstInstallmentDueDateDelay}
                          </Typography>
                        </Grid>
                      </>
                    )
                  }
                </Grid>
              </Grid>
            </>
          )
        }
      </Grid>
    </>
  )
})

ContainerFields.propTypes = {
  quotation: PropTypes.any.isRequired,
  proposal: PropTypes.any.isRequired,
  quotationStatus: PropTypes.any,
}
ContainerFields.defaultProps = {
  quotationStatus: undefined,
}

export default ContainerFields
