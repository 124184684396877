import React from 'react'

import BackgroundApolices from '../../assets/img/backgrounds/bg-apolices.webp'

const EndorsementDetails = React.lazy(() => import('./Endorsement'))
const EndorsementDetailsStatus = React.lazy(() => import('./EndorsementDetails'))
const ContinuePerformanceDetails = React.lazy(() => import('./fragments/ContinuePerformanceDetails'))

const EndorsementRoutes = [
  {
    path: '/:policyIdentifier',
    Component: EndorsementDetails,
    name: 'Endosso',
    bgImage: BackgroundApolices,
  },
  {
    path: '/:identifier/status',
    Component: EndorsementDetailsStatus,
    name: 'Endosso',
    bgImage: BackgroundApolices,
  },
  {
    path: '/:identifier/detalhes/performance',
    Component: ContinuePerformanceDetails,
    name: 'Endosso',
    bgImage: BackgroundApolices,
  },
]

export default EndorsementRoutes
