import React from 'react'

import BackgroundApolices from '../../assets/img/backgrounds/bg-apolices.webp'

const NewDetails = React.lazy(() => import('./policy-details/NewDetails'))

const PolicyRoutes = [
  {
    path: '/:policyIdentifier',
    Component: NewDetails,
    name: 'Detalhes da Apólice',
    bgImage: BackgroundApolices,
  },
]

export default PolicyRoutes
