import React, {
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react'
import ReactTooltip from 'react-tooltip'

import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import makeStyles from '@material-ui/core/styles/makeStyles'

import { GlobalMessages } from '../../../shared/messages'
import { useEndorsement } from '../../../shared/contexts/EndorsementContext'

import {
  ButtonLight,
  ButtonGradient,
} from '../../../shared/components'
import CollapsePrice from '../../../shared/components/CollapsePrice/CollapsePrice'

import EndorsementCurrentStateDocument from '../../../models/PolicyModels/EndorsementCurrentStateDocument'

import ListDocuments from './ListDocuments'
import {
  PRODUCT_KEY_JUDICIAL_APPEAL,
  PRODUCT_KEY_JUDICIAL_LABOR,
  PRODUCT_KEY_JUDICIAL_CIVEL,
  PRODUCT_KEY_JUDICIAL_CIVEL_MIN,
} from '../../../constants'
import useUtils from '../../../shared/hooks/useUtils'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  labelObject: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  confirmationLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.text.quaternary,
  },
  confirmationValue: {
    color: theme.palette.text.quaternary,
  },
}))

const CurrentStateDocument = forwardRef((props, ref) => {
  const classes = useStyles()

  const {
    ternaryCheck,
    formatCPFOrCNPJ,
    formatDate,
    formatCurrency,
  } = useUtils()

  const {
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props

  const {
    onNext,
    actionNext,
    endorsement,
    setEndorsement,
    endorsementData,
    policyCurrent,
  } = useEndorsement()

  const initialValuesForm = useMemo(() => (Object.assign(
    EndorsementCurrentStateDocument({
      name: endorsement?.currentStateDocument?.name
        ? endorsement?.currentStateDocument?.name
        : policyCurrent?.segurado?.nomeBeneficiario,
      document: endorsement?.currentStateDocument?.document
        ? endorsement?.currentStateDocument?.document
        : policyCurrent?.segurado?.cnpjBeneficiario,
      deadline: endorsement?.currentStateDocument?.deadline ?? undefined,
      startDateValidity: endorsement?.currentStateDocument?.startDateValidity ?? undefined,
      endDateValidity: endorsement?.currentStateDocument?.endDateValidity ?? undefined,
      contractValue: endorsement?.currentStateDocument?.contractValue ?? 0,
      guaranteeValue: endorsement?.currentStateDocument?.guaranteeValue
        ? endorsement?.currentStateDocument?.guaranteeValue
        : policyCurrent?.situacao?.importanciaSegurada,
      objectText: endorsement?.currentStateDocument?.objectText?.replace('', '')?.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '') ?? undefined,
      historyDocuments: endorsement?.currentStateDocument?.historyDocuments ?? undefined,
    }),
  )), [endorsement, policyCurrent])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesForm,
    validationSchema: Yup.object({
      name: Yup.string(),
      document: Yup.string(),
      deadline: Yup.string(),
      startDateValidity: Yup.date(),
      endDateValidity: Yup.date(),
      contractValue: Yup.number(),
      guaranteeValue: Yup.number(),
      objectText: Yup.string(),
    }),
    onSubmit: (data) => {
      const values = {
        ...endorsement,
        ...{ currentStateDocument: { ...data } },
      }
      setEndorsement(values)

      if (actionNext) {
        onNext(values)
      }
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  const nameLabel = 'Beneficiário:'
  const cpfCnpjLabel = 'CPF/CNPJ do Beneficiário:'
  const startDateValidityLabel = 'Início de Vigência:'
  const endDateValidityLabel = 'Fim de Vigência:'
  const contractValueLabel = ternaryCheck((
    endorsementData?.productKey === PRODUCT_KEY_JUDICIAL_CIVEL
    || endorsementData?.productKey === PRODUCT_KEY_JUDICIAL_CIVEL_MIN), 'Valor da Garantia:', 'Valor Contrato:')
  const guaranteeValueLabel = 'Importância Segurada:'
  const objectLabel = 'Obrigação Garantia (Objeto):'
  const deadlineLabel = ternaryCheck((
    endorsementData?.productKey === PRODUCT_KEY_JUDICIAL_APPEAL
    || endorsementData?.productKey === PRODUCT_KEY_JUDICIAL_LABOR
    || endorsementData?.productKey === PRODUCT_KEY_JUDICIAL_CIVEL
    || endorsementData?.productKey === PRODUCT_KEY_JUDICIAL_CIVEL_MIN), 'Prazo (Anos):', 'Prazo (Dias):')

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={!formik.isSubmitting}
        title="Situação Atual do Documento"
        subtitle="Visualize abaixo a situação atual do documento de endosso"
      >
        <Grid container spacing={5}>
          <ListDocuments
            document={endorsement?.currentStateDocument?.historyDocuments ?? []}
          />
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item sm={4} xs={12}>
                <Typography className={classes.confirmationLabel}>
                  <label htmlFor="cpfCnpjLabel">{cpfCnpjLabel}</label>
                </Typography>
                <Typography className={classes.confirmationValue}>
                  {formatCPFOrCNPJ(formik?.values?.document)}
                </Typography>
              </Grid>
              <Grid item sm={8} xs={12}>
                <Typography className={classes.confirmationLabel}>
                  <label htmlFor="nameLabel">{nameLabel}</label>
                </Typography>
                <Typography className={classes.confirmationValue}>
                  {formik?.values?.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item sm={4} xs={12}>
                <Typography className={classes.confirmationLabel}>
                  <label htmlFor="startDateValidityLabel">
                    {startDateValidityLabel}
                  </label>
                </Typography>
                <Typography className={classes.confirmationValue}>
                  {formatDate(formik.values.startDateValidity)}
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography className={classes.confirmationLabel}>
                  <label htmlFor="endDateValidityLabel">
                    {endDateValidityLabel}
                  </label>
                </Typography>
                <Typography className={classes.confirmationValue}>
                  {formatDate(formik?.values?.endDateValidity)}
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography className={classes.confirmationLabel}>
                  <label htmlFor="deadlineLabel">{deadlineLabel}</label>
                </Typography>
                <Typography className={classes.confirmationValue}>
                  {formik?.values?.deadline}
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography className={classes.confirmationLabel}>
                  <label htmlFor="contractValueLabel">{contractValueLabel}</label>
                </Typography>
                <Typography className={classes.confirmationValue}>
                  {formatCurrency(formik?.values?.contractValue)}
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography className={classes.confirmationLabel}>
                  <label htmlFor="guaranteeValueLabel">{guaranteeValueLabel}</label>
                </Typography>
                <Typography className={classes.confirmationValue}>
                  {formatCurrency(formik?.values?.guaranteeValue)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Typography className={classes.confirmationLabel}>
              <label htmlFor="objectLabel">{objectLabel}</label>
            </Typography>
            <Typography className={classes.confirmationValue}>
              {formik?.values?.objectText}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.buttonsStep}>
              <Box>
                <ButtonLight
                  onClick={handlePrevious}
                  title="Voltar"
                >
                  Voltar
                </ButtonLight>
              </Box>
              <Box ml={4}>
                <ButtonGradient
                  onClick={handleNext}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
            <ReactTooltip place="top" type="dark" />
          </Grid>
        </Grid>
      </CollapsePrice>
    </>
  )
})

CurrentStateDocument.propTypes = {
  checked: PropTypes.bool,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

CurrentStateDocument.defaultProps = {
  checked: false,
}

export default CurrentStateDocument
