import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
  containerDownload: {
    marginTop: 2,
    display: 'flex',
    cursor: 'pointer',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  labelDownload: {
    fontWeight: 500,
    textDecoration: 'underline',
  },
  IconDownload: {
    marginRight: 5,
  },
}))

export default useStyles
