import React from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import Arrow from '@material-ui/icons/KeyboardArrowRight'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  containerDownload: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  colorMore: {
    color: theme.palette.secondary.button,
  },
  titleHelpEndorsement: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 15,
    },
  },
  titleHelpPolicy: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 20,
    },
  },
  moreLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 5,
      marginBottom: 5,
    },
  },
}))

const POLICY_TEXT = 'Como realizar nova solicitação de Apólice'

const HelpLink = ({ text, link, minWidth }) => {
  const classes = useStyles()

  return (
    <Box
      className={classes.containerDownload}
      style={{
        minWidth,
      }}
    >
      <Typography color="primary" className={text === POLICY_TEXT ? classes.titleHelpPolicy : classes.titleHelpEndorsement}>
        {text}
        {' '}
        <Hidden xsDown>
          -
        </Hidden>

      </Typography>

      <Box mx={0.3} />

      <Link href={link} target="_blank">
        <Box className={classes.moreLink}>
          <Typography className={classes.colorMore}>
            <b>
              Saiba mais
            </b>
          </Typography>

          <Arrow fontSize="medium" className={classes.colorMore} />
        </Box>
      </Link>
    </Box>
  )
}

HelpLink.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  minWidth: PropTypes.number.isRequired,
}

export default HelpLink
