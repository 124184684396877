import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { Checkbox } from '@material-ui/core'

const GreenCheckbox = withStyles((theme) => ({
  root: {
    '&$checked': {
      color: theme.palette.success.main,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />)

export default GreenCheckbox
