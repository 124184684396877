import { useCallback } from 'react'
import axios from 'axios'
import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

import { useTheme } from '@material-ui/core/styles'

import { TOPIC_NEW_INSURANCE_POLICY, TOPIC_READY_PROPOSAL } from '../../constants/FirebaseTopicConstants'

const useNotificationClient = () => {
  const theme = useTheme()

  const connectWebSocket = useCallback(() => (
    new HubConnectionBuilder()
      .withUrl([process.env.REACT_APP_BFF_URL, 'notification/hub'].join('/'), {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect()
      .build()
  ), [])

  const getTopics = useCallback((notification) => [
    {
      id: notification?.id,
      url: `/apolices/${notification?.data}`,
      icon: 'InsertDriveFileRounded',
      title: 'Nova Apólice',
      topic: TOPIC_NEW_INSURANCE_POLICY,
      documentNumber: notification?.data,
      color: theme.palette.info.dark ?? theme.palette.primary,
    },
    {
      id: notification?.id,
      url: `/cotacao/${notification?.data}/status`,
      icon: 'CheckCircle',
      title: 'Proposta Pronta',
      topic: TOPIC_READY_PROPOSAL,
      color: theme.palette.success.dark ?? theme.palette.primary,
    },
  ], [theme])

  const getByTopic = useCallback((dataDetails) => (
    getTopics(dataDetails).find((topicNotification) => (
      topicNotification?.topic === dataDetails?.notificationType
    ))
  ), [getTopics])

  const readAll = useCallback(() => axios.post('/notification/markasread/all', undefined, {
    noLoading: true,
  }).then((response) => (response?.data ? response : { data: { ...response } })), [])

  return useCallback(() => ({
    readAll,
    getByTopic,
    connectWebSocket,
  }), [
    readAll,
    getByTopic,
    connectWebSocket,
  ])
}

export default useNotificationClient
