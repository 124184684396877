import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'

import useStyles from './useStyles'

const linear = 'all 110ms linear'

const useCustomStyle = makeStyles((theme) => ({
  btn: {
    color: theme.palette.secondary.button,
    border: `2px solid ${theme.palette.secondary.button}`,
    transition: linear,
    paddingLeft: 15,
    paddingRight: 15,

    '& svg': {
      '& path': {
        fill: theme.palette.secondary.button,
      },
    },

    '&:hover': {
      transition: linear,
      color: '#fff',
      backgroundColor: theme.palette.secondary.button,

      '& svg': {
        '& path': {
          fill: '#fff',
        },
      },
    },
  },
  btnContained: {
    transition: linear,
    color: '#fff',
    backgroundColor: theme.palette.secondary.button,

    '& svg': {
      '& path': {
        fill: '#fff',
      },
    },
  },
}))

const ButtonLight = (props) => {
  const { children, contained } = props
  const classes = useStyles()
  const classesCustom = useCustomStyle()

  const buttonProps = { ...props }
  delete buttonProps.contained

  return (
    <>
      <Button
        className={`${classes.btn} ${classesCustom.btn} ${contained ? classesCustom.btnContained : ''}`}
        {...buttonProps}
      >
        {children}
      </Button>
    </>
  )
}

ButtonLight.propTypes = {
  children: PropTypes.any,
  contained: PropTypes.bool,
}

ButtonLight.defaultProps = {
  children: undefined,
  contained: false,
}

export default ButtonLight
