import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'

import makeStyles from '@material-ui/core/styles/makeStyles'

import Autocomplete from '@material-ui/lab/Autocomplete'
import SearchIcon from '@material-ui/icons/Search'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isEqual, isEmpty } from 'lodash'
import ReactTooltip from 'react-tooltip'
import { isCnpj } from 'validator-brazil'

import { usePrice } from '../../../../../shared/contexts/PriceContext'
import useYup from '../../../../../shared/hooks/useYup'

import useBrokerClient from '../../../../../clients/BrokerClient/useBrokerClient'
import useViaCepClient from '../../../../../clients/ViaCepClient/useViaCepClient'
import useReceitaWSClient from '../../../../../clients/ReceitaWSClient/useReceitaWSClient'
import useCourtRegionalLaborClient from '../../../../../clients/CourtRegionalLaborClient/useCourtRegionalLaborClient'
import useStickClient from '../../../../../clients/StickClient/useStickClient'
import useUserClient from '../../../../../clients/UserClient/useUserClient'

import {
  useAlert,
  CEPInput,
  useLoader,
  ButtonLight,
  ButtonGradient,
  CPFOrCNPJInput,
  ProcessNumberInput,
} from '../../../../../shared/components'
import { GlobalMessages, JudicialGuaranteeeAppealMessages } from '../../../../../shared/messages'
import CollapsePrice from '../../../../../shared/components/CollapsePrice/CollapsePrice'
import DropdownSelectOne from '../../../../../shared/components/DropdownSelectOne/DropdownSelectOne'
import useUtils from '../../../../../shared/hooks/useUtils'

import InsuredObjectPropertyData from '../../../../../models/JudicialGuaranteeAppealModels/InsuredObjectPropertyData'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  noOptionsLabel: {
    fontSize: 14,
    color: '#c93952',
    fontWeight: 'bold',
  },
}))

const InsuredObjectProperty = forwardRef((props, ref) => {
  const classes = useStyles()
  const {
    quote,
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props
  const { cep, cpfOrCnpj } = useYup()
  const { showLoader, hideLoader } = useLoader()
  const { formatCPFOrCNPJ } = useUtils()

  const brokerClient = useBrokerClient()
  const viaCepClient = useViaCepClient()
  const receitaWSClient = useReceitaWSClient()
  const courtRegionalLaborClient = useCourtRegionalLaborClient()
  const stickClient = useStickClient()
  const userClient = useUserClient()

  const {
    onNext,
    actionNext,
    appealSelected,
    setFormikChanged,
    judicialGuaranteeAppeal,
    setJudicialGuaranteeAppeal,
  } = usePrice()

  const [city, setCity] = useState([''])
  const [state, setState] = useState([''])
  const [sticks, setSticks] = useState([{}])
  const [stickOptions, setStickOptions] = useState([''])
  const [selectedState, setSelectedState] = useState('')
  const [policyHolderSelected, setPolicyHolderSelected] = useState('')
  const [courtRegionalLabors, setCourtRegionalLabors] = useState([''])
  const [objectMessage, setObjectMessage] = useState(
    JudicialGuaranteeeAppealMessages.FORMA_ARTIGO_PRESENTE_GARANTIA_AGRAVO(),
  )
  const [courtSection, setCourtSection] = useState({})
  const [courtRegional, setCourtRegional] = useState({})
  const [stickDisabled, setStickDisabled] = useState(true)
  const [claimantDisabled, setClaimantDisabled] = useState(true)
  const [courtRegionalLaborDisabled, setCourtRegionalLaborDisabled] = useState(false)
  const { addMsgDanger } = useAlert()

  const mountDataCourt = (dataForm) => ({
    ...dataForm,
    courtSection: { ...courtSection },
    courtRegionalLabor: { ...courtRegional },
  })

  const initialValuesForm = useMemo(() => (Object.assign(
    InsuredObjectPropertyData({
      ...quote?.InsuredObjectProperty,
      State: (!isEmpty(quote?.InsuredObjectProperty?.State)
        && state.includes(quote?.InsuredObjectProperty?.State))
        ? quote?.InsuredObjectProperty?.State
        : undefined,
      CourtSection: quote?.InsuredObjectProperty?.CourtSection?.descricao
        ?? undefined,
      CourtRegionalLabor: quote?.InsuredObjectProperty?.CourtRegionalLabor?.descricao
        ?? undefined,
    } ?? {}),
  )), [state, quote?.InsuredObjectProperty])

  const policyHolderCnpj = useMemo(() => (
    Number(policyHolderSelected?.replace(/[^0-9]/g, ''))
  ), [policyHolderSelected])

  const fieldValueIquals = () => (cpfOrCnpj
    .test('document', GlobalMessages.EQUAL_TO_POLICY_HOLDER,
      (value) => (
        value && (value && Number(value) !== policyHolderCnpj)
      ))
    .required())

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesForm,
    validationSchema: Yup.object({
      document: fieldValueIquals(),
      claimant: !claimantDisabled ? Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Apenas letras são permitidas').required('Campo obrigatório') : Yup.string().required(),
      cep: cep.required(),
      place: Yup.string().required(),
      number: Yup.string().required(),
      complement: Yup.string(),
      district: Yup.string().required(),
      state: Yup.string().required(),
      city: Yup.string().required(),
      processNumber: Yup.string().max(50, GlobalMessages.LIMIT_MAX_CHARACTER(50)).required(),
      courtRegionalLabor: Yup.string().required(),
      courtSection: Yup.string().required(),
      objectText: Yup.string(),
    }),
    onSubmit: (data) => {
      const values = {
        ...judicialGuaranteeAppeal,
        ...{
          insuredObjectProperty: {
            ...mountDataCourt(data),
            objectText: objectMessage,
          },
        },
      }
      setJudicialGuaranteeAppeal(values)

      const {
        document,
        claimant,
        cep: cepValue,
        place,
        number,
        complement,
        district,
        state: stateValue,
        city: cityValue,
      } = values.insuredObjectProperty

      const valueInsuredObject = {
        cep: cepValue,
        city: cityValue,
        name: claimant,
        complement,
        district,
        document,
        number,
        place,
        state: stateValue,
      }

      if (actionNext) {
        setFormikChanged(!isEqual(initialValuesForm, data))
        onNext(values)

        if (!isCnpj(valueInsuredObject.document)) {
          userClient().createUpdateInsuredObjectProperty(valueInsuredObject)
            .then((response) => {
              addMsgDanger(response.data.error)
            })
        }
      }
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  useEffect(() => {
    brokerClient().getCitiesIbge(selectedState).then((response) => {
      const citiesNames = response.map((ibgeList) => ibgeList.nome)
      setCity(citiesNames.sort())
    })
  }, [selectedState, brokerClient])

  useEffect(() => {
    brokerClient().getStatesIbge().then((response) => {
      const statesNames = response.map((ibgeList) => ibgeList.sigla)
      setState(statesNames.sort())
    })
  }, [brokerClient])

  useEffect(() => {
    const insuredStick = formik.getFieldProps('courtSection')
    const insuredClaimant = formik.getFieldProps('claimant')
    const insuredDocument = formik.getFieldProps('document')
    const insuredProcessNumber = formik.getFieldProps('processNumber')
    const insuredCourtRegionalLabor = formik.getFieldProps('courtRegionalLabor')

    const cpfCnpj = insuredDocument?.value

    if (cpfCnpj
      || insuredStick?.value
      || insuredClaimant?.value
      || insuredProcessNumber?.value
      || insuredCourtRegionalLabor?.value
    ) {
      setObjectMessage(JudicialGuaranteeeAppealMessages.FORMA_ARTIGO_PRESENTE_GARANTIA_AGRAVO(
        insuredProcessNumber?.value,
        insuredClaimant?.value,
        cpfCnpj ? formatCPFOrCNPJ(cpfCnpj) : undefined,
        insuredCourtRegionalLabor?.value,
        insuredStick?.value,
        appealSelected,

      ))
    } else {
      setObjectMessage(JudicialGuaranteeeAppealMessages.FORMA_ARTIGO_PRESENTE_GARANTIA_AGRAVO())
    }
  }, [
    formik,
    setObjectMessage,
    formatCPFOrCNPJ,
    appealSelected,
  ])

  const formatCourtRegionalLabors = useCallback(
    (courtRegionalLaborsResponse) => courtRegionalLaborsResponse.map(
      ({ descricao, id }) => ({ id, text: descricao }),
    ), [],
  )

  useEffect(() => {
    courtRegionalLaborClient().getAll().then((response) => {
      setCourtRegionalLabors(formatCourtRegionalLabors(response)
        .sort((a, b) => (a.id - b.id)))
    })
  }, [
    formatCourtRegionalLabors,
    courtRegionalLaborClient,
  ])

  useEffect(() => {
    if (!isEmpty(courtRegional) && courtRegional.id) {
      stickClient().getById(courtRegional.id).then((res) => {
        const sticksResponse = res.data.sort((a, b) => a.descricao.localeCompare(b.descricao))

        setSticks(sticksResponse)
        setStickOptions(sticksResponse.map(({ descricao }) => descricao))
      })
    }
  }, [stickClient, courtRegional])

  const handleCourtRegionalLabor = (e) => {
    const { value } = e.target

    if (value) {
      const courtRegLaborFound = courtRegionalLabors
        .find((regCourtItem) => regCourtItem.text === value)

      setCourtRegional({
        descricao: courtRegLaborFound.text,
        id: courtRegLaborFound.id,
      })

      formik.values.courtRegionalLabor = courtRegLaborFound?.text
    }
  }

  const handleCourtSection = useCallback((value) => {
    const courtSectionFound = sticks.find((stickItem) => stickItem.descricao === value)

    if (!isEmpty(courtSectionFound)) {
      setCourtSection({
        id: courtSectionFound.id,
        descricao: courtSectionFound.descricao,
      })
    }
  }, [sticks, setCourtSection])

  const handleInfoCnpj = (ev) => {
    const { value } = ev.target

    const cnpj = value?.replace(/[^0-9]/g, '')

    if (isCnpj(cnpj)) {
      formik.setFieldValue('claimant', '')
      formik.setFieldValue('cep', '')
      formik.setFieldValue('place', '')
      formik.setFieldValue('number', '')
      formik.setFieldValue('complement', '')
      formik.setFieldValue('district', '')
      formik.setFieldValue('state', '')
      formik.setFieldValue('city', '')
      showLoader()
      setSelectedState('')
      receitaWSClient().getInfoByCnpj(cnpj).then(async (response) => {
        if (response) {
          formik.setFieldValue('claimant', response.nome)
          hideLoader()
          setClaimantDisabled(true)
        }
      }).catch(() => {
        setClaimantDisabled(false)
        hideLoader()
      })
    }
    if (cnpj && !isCnpj(cnpj)) {
      city.length = 1
      showLoader()
      setClaimantDisabled(false)
      userClient().getClientInsuredObjectProperty(cnpj).then(async (response) => {
        if (response) {
          formik.setFieldValue('claimant', response?.data?.nome ?? '')
          formik.setFieldValue('cep', response?.data?.cep ?? '')
          formik.setFieldValue('place', response?.data?.logradouro ?? '')
          formik.setFieldValue('number', response?.data?.numero ?? '')
          formik.setFieldValue('complement', response?.data?.complemento ?? '')
          formik.setFieldValue('district', response?.data?.bairro ?? '')
          formik.setFieldValue('state', response?.data?.uf ?? '')
          formik.setFieldValue('city', response?.data?.cidade ?? '')
          hideLoader()
        }
      }).catch(() => {
        hideLoader()
      })
    }
    setPolicyHolderSelected(judicialGuaranteeAppeal?.policyHolderClaimant?.document)
  }

  const handleInfoProcessNumber = (ev) => {
    const { value } = ev.target
    courtRegionalLabors.map((item) => item.id)

    setStickDisabled(true)
    setCourtRegionalLaborDisabled(true)

    if (value === '') {
      setCourtSection({})
      setCourtRegional({})
      formik.setFieldValue('courtSection', '')
      formik.setFieldValue('courtRegionalLabor', '')
    }

    const processNumberValue = value?.split('.', 4)

    if (processNumberValue.length > 3
      && processNumberValue[3] <= 24
      && courtRegionalLabors.indexOf(Number(processNumberValue[3]) - 1)
    ) {
      handleCourtRegionalLabor({
        target: {
          value: courtRegionalLabors[Number(processNumberValue[3]) - 1]?.text,
        },
      })
      setCourtRegionalLaborDisabled(false)
      setStickDisabled(false)
    }

    if (processNumberValue[3] > 24) {
      handleCourtRegionalLabor({
        target: { value: '' },
      })
    }
  }

  const handleInfoCep = (ev) => {
    const { value } = ev.target

    const cepValue = value?.replace(/[^0-9]/g, '')

    viaCepClient().getInfoByCep(cepValue).then((response) => {
      formik.setFieldValue('place', response.logradouro ?? '')
      formik.setFieldValue('district', response.bairro ?? '')

      setSelectedState(response.uf ?? '')
      formik.setFieldValue('state', response.uf ?? '')
      formik.setFieldValue('city', response.localidade ?? '')
    })
  }

  useEffect(() => {
    if (quote?.InsuredObjectProperty) {
      const fragmentData = quote?.InsuredObjectProperty

      setCourtSection(fragmentData?.CourtSection)
      setCourtRegional(fragmentData?.CourtRegionalLabor)
    }
    if (formik.values.processNumber) {
      setStickDisabled(false)
    }
  }, [quote?.InsuredObjectProperty, formik.values.processNumber])

  const placeholderCEP = '000000-000'
  const placeholderCPFCNPJ = '000.000.000-00 / 00.000.000/0000-00'
  const placeholderProcessNumber = '0000000-00.0000.0.00.0000'

  const claimantLabel = 'Reclamante'
  const cpfCnpjLabel = 'CPF/CNPJ do Segurado'
  const cepLabel = 'CEP'
  const numberLabel = 'Número'
  const placeLabel = 'Logradouro'
  const complementLabel = 'Complemento'
  const districtLabel = 'Bairro'
  const cityLabel = 'Cidade'
  const stateLabel = 'Estado'
  const processNumberLabel = 'Número do Processo'
  const courtRegionalLaborLabel = 'Tribunal e região da Justiça do Trabalho'
  const stickLabel = 'Vara'
  const objectLabel = 'Objeto'

  const noOptionsMessage = (
    <Typography className={classes.noOptionsLabel}>
      {GlobalMessages.NO_OPTIONS_MESSAGE}
    </Typography>
  )

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={isEmpty(quote?.InsuredObjectProperty) && !formik.isSubmitting}
        title="Segurado e Objeto do Seguro"
        subtitle="Informe abaixo os dados do segurado e objeto do seguro"
      >
        <Grid container spacing={5}>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="cpfCnpjLabel" className={classes.label}>{cpfCnpjLabel}</label>
              <CPFOrCNPJInput
                fullWidth
                id="document"
                color="secondary"
                placeholder={placeholderCPFCNPJ}
                {...formik.getFieldProps('document')}
                error={formik.touched.document && !!formik.errors.document}
                onBlur={handleInfoCnpj}
              />
              <FormHelperText
                hidden={!formik.touched.document || !formik.errors.document}
                error={formik.touched.document && !!formik.errors.document}
              >
                {formik.errors.document}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="claimantLabel" className={classes.label}>{claimantLabel}</label>
              <Input
                fullWidth
                id="claimant"
                color="secondary"
                disabled={claimantDisabled}
                placeholder="Nome do Reclamante"
                {...formik.getFieldProps('claimant')}
                error={formik.touched.claimant && !!formik.errors.claimant}
              />
              <FormHelperText
                hidden={!formik.touched.claimant || !formik.errors.claimant}
                error={formik.touched.claimant && !!formik.errors.claimant}
              >
                {formik.errors.claimant}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={2} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="cepLabel" className={classes.label}>{cepLabel}</label>
              <CEPInput
                fullWidth
                id="cep"
                color="secondary"
                placeholder={placeholderCEP}
                {...formik.getFieldProps('cep')}
                error={formik.touched.cep && !!formik.errors.cep}
                onBlur={handleInfoCep}
                onFocus={(event) => event.target.setAttribute('autocomplete', 'none')}
              />
              <FormHelperText
                hidden={!formik.touched.cep || !formik.errors.cep}
                error={formik.touched.cep && !!formik.errors.cep}
              >
                {formik.errors.cep}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={5} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="placeLabel" className={classes.label}>{placeLabel}</label>
              <Input
                fullWidth
                id="place"
                color="secondary"
                placeholder="Rua, Avenida"
                {...formik.getFieldProps('place')}
                error={formik.touched.place && !!formik.errors.place}
              />
              <FormHelperText
                hidden={!formik.touched.place || !formik.errors.place}
                error={formik.touched.place && !!formik.errors.place}
              >
                {formik.errors.place}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={1} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="numberLabel" className={classes.label}>{numberLabel}</label>
              <Input
                fullWidth
                id="number"
                color="secondary"
                placeholder="000"
                {...formik.getFieldProps('number')}
                error={formik.touched.number && !!formik.errors.number}
              />
              <FormHelperText
                hidden={!formik.touched.number || !formik.errors.number}
                error={formik.touched.number && !!formik.errors.number}
              >
                {formik.errors.number}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="complementLabel" className={classes.label}>{complementLabel}</label>
              <Input
                fullWidth
                id="complement"
                color="secondary"
                placeholder="Apto, Quadra, Lote"
                {...formik.getFieldProps('complement')}
                error={formik.touched.complement && !!formik.errors.complement}
              />
              <FormHelperText
                hidden={!formik.touched.complement || !formik.errors.complement}
                error={formik.touched.complement && !!formik.errors.complement}
              >
                {formik.errors.complement}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="districtLabel" className={classes.label}>{districtLabel}</label>
              <Input
                fullWidth
                id="district"
                color="secondary"
                placeholder="Vila, Setor"
                {...formik.getFieldProps('district')}
                error={formik.touched.district && !!formik.errors.district}
              />
              <FormHelperText
                hidden={!formik.touched.district || !formik.errors.district}
                error={formik.touched.district && !!formik.errors.district}
              >
                {formik.errors.district}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="stateLabel" className={classes.label}>{stateLabel}</label>
              <DropdownSelectOne
                id="state"
                options={state}
                value={selectedState}
                {...formik.getFieldProps('state')}
                error={formik.touched.state && !!formik.errors.state}
                onChange={(e) => {
                  const { value } = e.target
                  setSelectedState(value)

                  formik.setFieldValue('state', value)
                }}
              />
              <FormHelperText
                hidden={!formik.touched.state || !formik.errors.state}
                error={formik.touched.state && !!formik.errors.state}
              >
                {formik.errors.state}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="cityLabel" className={classes.label}>{cityLabel}</label>
              <Autocomplete
                className={classes.textField}
                id="city"
                options={city}
                noOptionsText={noOptionsMessage}
                getOptionLabel={(option) => option}
                onInputChange={(e, value) => {
                  formik.values.city = value
                }}
                getOptionSelected={(option, value) => option?.iso === value?.iso}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onFocus={(event) => event.target.setAttribute('autocomplete', 'none')}
                    placeholder="Procurar"
                    margin="normal"
                    error={formik.touched.city && !!formik.errors.city}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {...formik.getFieldProps('city')}
              />
              <FormHelperText
                hidden={!formik.touched.city || !formik.errors.city}
                error={formik.touched.city && !!formik.errors.city}
              >
                {formik.errors.city}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="processNumberLabel" className={classes.label}>{processNumberLabel}</label>
              <ProcessNumberInput
                fullWidth
                id="processNumber"
                color="secondary"
                placeholder={placeholderProcessNumber}
                {...formik.getFieldProps('processNumber')}
                error={formik.touched.processNumber && !!formik.errors.processNumber}
                onKeyUp={handleInfoProcessNumber}
              />
              <FormHelperText
                hidden={!formik.touched.processNumber || !formik.errors.processNumber}
                error={formik.touched.processNumber && !!formik.errors.processNumber}
              >
                {formik.errors.processNumber}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="courtRegionalLaborLabel" className={classes.label}>{courtRegionalLaborLabel}</label>
              <DropdownSelectOne
                id="courtRegionalLabor"
                options={courtRegionalLabors}
                disabled={courtRegionalLaborDisabled}
                placeholder={courtRegionalLaborLabel}
                {...formik.getFieldProps('courtRegionalLabor')}
                error={formik.touched.courtRegionalLabor && !!formik.errors.courtRegionalLabor}
                onChange={(e) => {
                  formik.values.courtSection = ''
                  handleCourtRegionalLabor(e)
                }}
              />
              <FormHelperText
                hidden={!formik.touched.courtRegionalLabor || !formik.errors.courtRegionalLabor}
                error={formik.touched.courtRegionalLabor && !!formik.errors.courtRegionalLabor}
              >
                {formik.errors.courtRegionalLabor}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="stickLabel" className={classes.label}>{stickLabel}</label>
              <Autocomplete
                className={classes.textField}
                id="courtSection"
                options={stickOptions}
                disabled={stickDisabled}
                noOptionsText={noOptionsMessage}
                getOptionLabel={(option) => option ?? ''}
                onInputChange={(e, value) => {
                  formik.values.courtSection = value ?? ''
                  handleCourtSection(value)
                }}
                getOptionSelected={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onFocus={(event) => event.target.setAttribute('autocomplete', 'none')}
                    placeholder="Procurar"
                    margin="normal"
                    error={formik.touched.courtSection && !!formik.errors.courtSection}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {...formik.getFieldProps('courtSection')}
              />

              <FormHelperText
                hidden={!formik.touched.courtSection || !formik.errors.courtSection}
                error={formik.touched.courtSection && !!formik.errors.courtSection}
              >
                {formik.errors.courtSection}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="objectLabel" className={classes.label}>{objectLabel}</label>
              <Box mt={3} textAlign="justify">
                <Typography>{objectMessage}</Typography>
              </Box>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.buttonsStep}>
              <Box>
                <ButtonLight
                  onClick={handlePrevious}
                  title="Voltar"
                >
                  Voltar
                </ButtonLight>
              </Box>
              <Box ml={4}>
                <ButtonGradient
                  onClick={handleNext}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
            <ReactTooltip place="top" type="dark" />
          </Grid>
        </Grid>
      </CollapsePrice>
    </>
  )
})

InsuredObjectProperty.propTypes = {
  quote: PropTypes.any,
  checked: PropTypes.bool,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

InsuredObjectProperty.defaultProps = {
  checked: false,
  quote: undefined,
}

export default InsuredObjectProperty
