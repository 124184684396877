import React from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import makeStyles from '@material-ui/core/styles/makeStyles'

import { ButtonGradient, ButtonLight, ModalInfo } from '../../../../../shared/components'

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  buttonsStep: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& .MuiBox-root': {
      minWidth: 190,
    },
    '@media (max-width: 699px)': {
      justifyContent: 'center',
    },
  },
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 600,
    },
  },
}))

const ModalPolicyEmission = (props) => {
  const classes = useStyles()
  const {
    openModal, closeModal, handleConfirmation, isEndorsement,
  } = props

  return (
    <>
      {openModal && (
        <ModalInfo
          close={false}
          open={openModal}
          className={classes.modal}
        >
          <Box p={2} alignItems="center" justifyContent="center">
            <Box>
              <Box mt={2} mb={5}>
                <Typography align="center" variant="h4">
                  Deseja emitir
                  {' '}
                  {isEndorsement ? ' o endosso ' : ' a apólice '}
                  ?
                </Typography>
              </Box>

              <Box mb={5}>
                <Typography align="center">
                  Ao confirmar a emissão, você está de acordo com os dados inseridos
                  e apresentados na minuta e seu devido custo.
                </Typography>
              </Box>

              <Grid item xs={12}>
                <Box justifyContent="center" className={classes.buttonsStep}>
                  <Box>
                    <ButtonGradient
                      onClick={handleConfirmation}
                    >
                      Sim
                    </ButtonGradient>
                  </Box>
                  <Box>
                    <ButtonLight
                      onClick={closeModal}
                    >
                      Não
                    </ButtonLight>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Box>
        </ModalInfo>
      )}
    </>
  )
}

ModalPolicyEmission.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
  isEndorsement: PropTypes.bool,
}

ModalPolicyEmission.defaultProps = {
  isEndorsement: false,
}

export default ModalPolicyEmission
