import React, { Suspense, useContext } from 'react'
import {
  Route,
  Switch,
  Redirect,
  useHistory,
} from 'react-router-dom'

import MasterRoutes from './MasterRoutes'
import ExternalRoutes from './ExternalRoutes'
import SuspenseRoutes from './SuspenseRoutes'

import CheckLogin from '../pages/check-login/CheckLogin'
import Login from '../shared/layouts/Login'
import Master from '../shared/layouts/Master'
import PrivateRoute from '../security/PrivateRoute'

import { AuthContext } from '../security/Auth'

const combinePaths = (parent, child) => `${parent.replace(/\/$/, '')}/${child.replace(/^\//, '')}`

const buildPaths = (navigation, parentPath = '') => navigation.map((route) => {
  const path = combinePaths(parentPath, route.path)

  return {
    ...route,
    path,
    ...(route.routes && { routes: buildPaths(route.routes, path) }),
  }
})

const loadMasterRoutes = (routes) => routes
  .map((route) => [route.routes ? loadMasterRoutes(route.routes) : [], route])
  .flat(Infinity)

const Routes = () => {
  const history = useHistory()

  const {
    user,
    maintenanceValue,
  } = useContext(AuthContext)

  if (maintenanceValue?.Avaliable === false || maintenanceValue === 'maintenance') {
    history.replace('/error/503')
  }

  return (
    <Switch>
      <Route path="/login">
        <Login>
          <CheckLogin />
        </Login>
      </Route>
      {
        ExternalRoutes.map(({ path, Component }, key) => (
          <Route path={path} key={key} component={Component} />
        ))
      }
      {
        SuspenseRoutes.map(({ path, Component }, key) => (
          <Route path={path} key={key}>
            <Login>
              <Suspense fallback={<></>}>
                <Component />
              </Suspense>
            </Login>
          </Route>
        ))
      }

      {!user && (<Redirect to="/login" />)}

      {
        loadMasterRoutes(buildPaths(MasterRoutes)).map(({ path, bgImage, Component }, key) => (
          <PrivateRoute
            exact
            key={key}
            path={path}
            layout={Master}
            bgImage={bgImage}
            component={Component}
          />
        ))
      }

      <Redirect to="/error/404" />
    </Switch>
  )
}

export default Routes
