import { useCallback } from 'react'
import fetchJsonp from 'fetch-jsonp'

const useReceitaWSClient = () => {
  const getInfoByCnpj = useCallback(async (cnpj) => fetchJsonp([process.env.REACT_APP_URL_API_RECEITA_WS, `cnpj/${cnpj}`].join('/'), {
    timeout: 60000,
  }).then(
    (result) => result.json(),
  ), [])

  return useCallback(() => ({
    getInfoByCnpj,
  }), [
    getInfoByCnpj,
  ])
}

export default useReceitaWSClient
