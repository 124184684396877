import { useCallback } from 'react'
import produce from 'immer'
import { useDispatch } from 'react-redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { DEFAULT_THEME } from '../../constants'

const INITIAL_STATE = {
  user: {
    color: DEFAULT_THEME,
  },
  executive: {},
}

export const Types = {
  USER: 'security/USER',
  EXECUTIVE: 'security/EXECUTIVE',
  ASSISTANTS: 'security/ASSISTANTS',
  SUPERINTENDENT: 'security/SUPERINTENDENT',
  POLICY: 'security/POLICY',
  TRACKSALES: 'security/TRACKSALES',
  DATA_IS_OUTDATED: 'security/DATA_IS_OUTDATED',
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.USER: {
      const { user } = action.payload

      return produce(state, (draft) => {
        draft.user = user
      })
    }

    case Types.EXECUTIVE: {
      const { executive } = action.payload

      return produce(state, (draft) => {
        draft.executive = executive
      })
    }

    case Types.SUPERINTENDENT: {
      const { superIntendent } = action.payload

      return produce(state, (draft) => {
        draft.superIntendent = superIntendent
      })
    }

    case Types.ASSISTANTS: {
      const { assistants } = action.payload

      return produce(state, (draft) => {
        draft.assistants = assistants
      })
    }

    case Types.POLICY: {
      const { policy } = action.payload

      return produce(state, (draft) => {
        draft.policy = policy
      })
    }

    case Types.TrackSaleSchedule: {
      const { trackSaleSchedule } = action.payload

      return produce(state, (draft) => {
        draft.trackSaleSchedule = trackSaleSchedule
      })
    }

    case Types.DATA_IS_OUTDATED: {
      const { dataIsOutdated } = action.payload

      return produce(state, (draft) => {
        draft.dataIsOutdated = dataIsOutdated
      })
    }

    default: {
      return state
    }
  }
}

export const setUserStore = (user) => ({
  type: Types.USER,
  payload: { user },
})

export const useSecurityAction = () => {
  const dispatch = useDispatch()
  const setUser = useCallback(
    (user) => dispatch({
      type: Types.USER,
      payload: { user },
    }),
    [dispatch],
  )

  const setExecutive = useCallback(
    (executive) => dispatch({
      type: Types.EXECUTIVE,
      payload: { executive },
    }),
    [dispatch],
  )

  const setAssistants = useCallback(
    (assistants) => dispatch({
      type: Types.ASSISTANTS,
      payload: { assistants },
    }),
    [dispatch],
  )

  const setSuperIntendent = useCallback(
    (superIntendent) => dispatch({
      type: Types.SUPERINTENDENT,
      payload: { superIntendent },
    }),
    [dispatch],
  )

  const setDemand = useCallback(
    (demand) => dispatch({
      type: Types.DEMAND,
      payload: { demand },
    }),
    [dispatch],
  )

  const setFilterPolicy = useCallback(
    (policy) => dispatch({
      type: Types.POLICY,
      payload: { policy },
    }),
    [dispatch],
  )

  const setTrackSaleSchedule = useCallback(
    (trackSaleSchedule) => dispatch({
      type: Types.TRACKSALES,
      payload: { trackSaleSchedule },
    }),
    [dispatch],
  )

  const setDataIsOutdated = useCallback(
    (dataIsOutdated) => dispatch({
      type: Types.DATA_IS_OUTDATED,
      payload: { dataIsOutdated },
    }),
    [dispatch],
  )

  const cleanUser = useCallback(
    () => dispatch({
      type: Types.USER,
      payload: { user: {} },
    }),
    [dispatch],
  )

  const cleanDemand = useCallback(
    () => dispatch({
      type: Types.DEMAND,
      payload: { demand: {} },
    }),
    [dispatch],
  )

  const cleanExecutive = useCallback(
    () => dispatch({
      type: Types.EXECUTIVE,
      payload: { executive: {} },
    }),
    [dispatch],
  )

  const cleanAssistants = useCallback(
    () => dispatch({
      type: Types.ASSISTANTS,
      payload: { assistants: [] },
    }),
    [dispatch],
  )

  const cleanSuperIntendent = useCallback(
    () => dispatch({
      type: Types.SUPERINTENDENT,
      payload: { superIntendent: {} },
    }),
    [dispatch],
  )

  const cleanFilterPolici = useCallback(
    () => dispatch({
      type: Types.POLICY,
      payload: { policy: {} },
    }),
    [dispatch],
  )

  const cleanTrackSaleSchedule = useCallback(
    () => dispatch({
      type: Types.TRACKSALES,
      payload: { trackSaleSchedule: {} },
    }),
    [dispatch],
  )

  const cleanDataIsOutdated = useCallback(
    () => dispatch({
      type: Types.DATA_IS_OUTDATED,
      payload: { dataIsOutdated: {} },
    }),
    [dispatch],
  )

  return {
    setUser,
    setDemand,
    setExecutive,
    setAssistants,
    setSuperIntendent,
    setFilterPolicy,
    setDataIsOutdated,
    setTrackSaleSchedule,
    cleanDataIsOutdated,
    cleanTrackSaleSchedule,
    cleanUser,
    cleanDemand,
    cleanExecutive,
    cleanAssistants,
    cleanSuperIntendent,
    cleanFilterPolici,
  }
}

export default persistReducer(
  {
    key: 'security',
    storage,
  },
  reducer,
)
