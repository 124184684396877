import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'

import * as Yup from 'yup'
import { isEqual, isEmpty } from 'lodash'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { isCnpj } from 'validator-brazil'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'

import makeStyles from '@material-ui/core/styles/makeStyles'

import Autocomplete from '@material-ui/lab/Autocomplete'
import SearchIcon from '@material-ui/icons/Search'

import useYup from '../../../../../shared/hooks/useYup'
import { usePrice } from '../../../../../shared/contexts/PriceContext'

import useBrokerClient from '../../../../../clients/BrokerClient/useBrokerClient'
import useViaCepClient from '../../../../../clients/ViaCepClient/useViaCepClient'
import useReceitaWSClient from '../../../../../clients/ReceitaWSClient/useReceitaWSClient'
import useUserClient from '../../../../../clients/UserClient'

import {
  useAlert,
  CEPInput,
  useLoader,
  ButtonLight,
  ButtonGradient,
  CPFOrCNPJInput,
} from '../../../../../shared/components'
import { GlobalMessages } from '../../../../../shared/messages'
import CollapsePrice from '../../../../../shared/components/CollapsePrice/CollapsePrice'
import DropdownSelectOne from '../../../../../shared/components/DropdownSelectOne/DropdownSelectOne'

import ClaimantData from '../../../../../models/JudicialGuaranteeLaborModels/ClaimantData'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  noOptionsLabel: {
    fontSize: 14,
    color: '#c93952',
    fontWeight: 'bold',
  },
}))

const Claimant = forwardRef((props, ref) => {
  const classes = useStyles()
  const {
    quote,
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props
  const { cep, cpfOrCnpj } = useYup()
  const { showLoader, hideLoader } = useLoader()
  const { addMsgDanger } = useAlert()

  const brokerClient = useBrokerClient()
  const viaCepClient = useViaCepClient()
  const receitaWSClient = useReceitaWSClient()
  const userClient = useUserClient()

  const {
    onNext,
    actionNext,
    setCnpjClaimant,
    setNameClaimant,
    setFormikChanged,
    judicialGuaranteeLabor,
    setJudicialGuaranteeLabor,
  } = usePrice()

  const [city, setCity] = useState([''])
  const [state, setState] = useState([''])
  const [selectedState, setSelectedState] = useState('')
  const [claimantDisabled, setClaimantDisabled] = useState(true)
  const [policyHolderSelected, setPolicyHolderSelected] = useState('')

  const initialValuesForm = useMemo(() => (Object.assign(
    ClaimantData({
      ...quote?.Claimant,
      State: (!isEmpty(quote?.Claimant?.State)
        && state.includes(quote?.Claimant?.State))
        ? quote?.Claimant?.State
        : undefined,
    } ?? {}),
  )), [state, quote?.Claimant])

  const policyHolderCnpj = useMemo(() => (
    Number(policyHolderSelected?.replace(/[^0-9]/g, ''))
  ), [policyHolderSelected])

  const fieldValueIquals = () => (cpfOrCnpj
    .test('document', GlobalMessages.EQUAL_TO_POLICY_HOLDER,
      (value) => (
        value && (value && Number(value) !== policyHolderCnpj)
      ))
    .required())

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesForm,
    validationSchema: Yup.object({
      document: fieldValueIquals(),
      name: !claimantDisabled ? Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Apenas letras são permitidas').required('Campo obrigatório') : Yup.string().required(),
      cep: cep.required(),
      place: Yup.string().required(),
      number: Yup.string().required(),
      complement: Yup.string(),
      district: Yup.string().required(),
      state: Yup.string().required(),
      city: Yup.string().required(),
    }),
    onSubmit: (data) => {
      const values = {
        ...judicialGuaranteeLabor,
        ...{ claimant: { ...data } },
      }
      setJudicialGuaranteeLabor(values)

      const {
        document,
        name,
        cep: cepValue,
        place,
        number,
        complement,
        district,
        state: stateValue,
        city: cityValue,
      } = values.claimant

      const valueInsuredObject = {
        cep: cepValue,
        city: cityValue,
        name,
        complement,
        district,
        document,
        number,
        place,
        state: stateValue,
      }

      if (actionNext) {
        setFormikChanged(!isEqual(initialValuesForm, data))
        onNext(values)

        if (!isCnpj(valueInsuredObject.document)) {
          userClient().createUpdateInsuredObjectProperty(valueInsuredObject)
            .then((response) => {
              addMsgDanger(response.data.error)
            })
        }
      }
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  useEffect(() => {
    brokerClient().getCitiesIbge(selectedState).then((response) => {
      const citiesNames = response.map((ibgeList) => ibgeList.nome)
      setCity(citiesNames.sort())
    })
  }, [selectedState, brokerClient])

  useEffect(() => {
    brokerClient().getStatesIbge().then((response) => {
      const statesNames = response.map((ibgeList) => ibgeList.sigla)
      setState(statesNames.sort())
    })
  }, [brokerClient])

  const handleInfoCnpj = (ev) => {
    const { value } = ev.target
    if (ev?.target?.value === '') {
      formik.setFieldValue('name', '')
    }
    const cnpj = value?.replace(/[^0-9]/g, '')

    if (isCnpj(cnpj)) {
      formik.setFieldValue('name', '')
      formik.setFieldValue('cep', '')
      formik.setFieldValue('place', '')
      formik.setFieldValue('number', '')
      formik.setFieldValue('complement', '')
      formik.setFieldValue('district', '')
      formik.setFieldValue('state', '')
      formik.setFieldValue('city', '')
      showLoader()
      setSelectedState('')
      receitaWSClient().getInfoByCnpj(cnpj).then(async (response) => {
        if (response) {
          formik.setFieldValue('name', response.nome)
          setNameClaimant(response.nome)
          setCnpjClaimant(cnpj)
          hideLoader()
          setClaimantDisabled(true)
        }
      }).catch(() => {
        setClaimantDisabled(false)
        formik.setFieldValue('name', '')
        hideLoader()
      })
    }
    if (cnpj && !isCnpj(cnpj)) {
      city.length = 1
      showLoader()
      setClaimantDisabled(false)
      userClient().getClientInsuredObjectProperty(cnpj).then(async (response) => {
        if (response) {
          formik.setFieldValue('name', response?.data?.nome ?? '')
          formik.setFieldValue('cep', response?.data?.cep ?? '')
          formik.setFieldValue('place', response?.data?.logradouro ?? '')
          formik.setFieldValue('number', response?.data?.numero ?? '')
          formik.setFieldValue('complement', response?.data?.complemento ?? '')
          formik.setFieldValue('district', response?.data?.bairro ?? '')
          formik.setFieldValue('state', response?.data?.uf ?? '')
          formik.setFieldValue('city', response?.data?.cidade ?? '')
          hideLoader()
        }
      }).catch(() => {
        hideLoader()
        formik.setFieldValue('name', '')
      })
    }
    setPolicyHolderSelected(judicialGuaranteeLabor?.policyHolder?.document)
  }

  const handleInfoCep = (ev) => {
    const { value } = ev.target

    const cepValue = value?.replace(/[^0-9]/g, '')

    viaCepClient().getInfoByCep(cepValue).then((response) => {
      formik.setFieldValue('place', response.logradouro ?? '')
      formik.setFieldValue('district', response.bairro ?? '')

      setSelectedState(response.uf ?? '')
      formik.setFieldValue('state', response.uf ?? '')
      formik.setFieldValue('city', response.localidade ?? '')
    })
  }

  const placeholderCEP = '000000-000'
  const placeholderCPFCNPJ = '000.000.000-00 / 00.000.000/0000-00'

  const nameLabel = 'Reclamante'
  const cpfCnpjLabel = 'CPF/CNPJ'
  const cepLabel = 'CEP'
  const numberLabel = 'Número'
  const placeLabel = 'Logradouro'
  const complementLabel = 'Complemento'
  const districtLabel = 'Bairro'
  const cityLabel = 'Cidade'
  const stateLabel = 'Estado'

  const noOptionsMessage = (
    <Typography className={classes.noOptionsLabel}>
      {GlobalMessages.NO_OPTIONS_MESSAGE}
    </Typography>
  )

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={isEmpty(quote?.Claimant) && !formik.isSubmitting}
        title="Reclamante (Segurado)"
        subtitle="Informe abaixo os dados do reclamante"
      >
        <Grid container spacing={5}>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="cpfCnpjLabel" className={classes.label}>{cpfCnpjLabel}</label>
              <CPFOrCNPJInput
                fullWidth
                id="document"
                color="secondary"
                placeholder={placeholderCPFCNPJ}
                {...formik.getFieldProps('document')}
                error={formik.touched.document && !!formik.errors.document}
                onBlur={handleInfoCnpj}
              />
              <FormHelperText
                hidden={!formik.touched.document || !formik.errors.document}
                error={formik.touched.document && !!formik.errors.document}
              >
                {formik.errors.document}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="nameLabel" className={classes.label}>{nameLabel}</label>
              <Input
                fullWidth
                id="name"
                color="secondary"
                disabled={claimantDisabled}
                placeholder="Nome do Reclamante"
                {...formik.getFieldProps('name')}
                error={formik.touched.name && !!formik.errors.name}
              />
              <FormHelperText
                hidden={!formik.touched.name || !formik.errors.name}
                error={formik.touched.name && !!formik.errors.name}
              >
                {formik.errors.name}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={2} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="cepLabel" className={classes.label}>{cepLabel}</label>
              <CEPInput
                fullWidth
                id="cep"
                color="secondary"
                placeholder={placeholderCEP}
                {...formik.getFieldProps('cep')}
                error={formik.touched.cep && !!formik.errors.cep}
                onBlur={handleInfoCep}
                onFocus={(event) => event.target.setAttribute('autocomplete', 'none')}
              />
              <FormHelperText
                hidden={!formik.touched.cep || !formik.errors.cep}
                error={formik.touched.cep && !!formik.errors.cep}
              >
                {formik.errors.cep}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={5} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="placeLabel" className={classes.label}>{placeLabel}</label>
              <Input
                fullWidth
                id="place"
                color="secondary"
                placeholder="Rua, Avenida"
                {...formik.getFieldProps('place')}
                error={formik.touched.place && !!formik.errors.place}
              />
              <FormHelperText
                hidden={!formik.touched.place || !formik.errors.place}
                error={formik.touched.place && !!formik.errors.place}
              >
                {formik.errors.place}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={1} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="numberLabel" className={classes.label}>{numberLabel}</label>
              <Input
                fullWidth
                id="number"
                color="secondary"
                placeholder="000"
                {...formik.getFieldProps('number')}
                error={formik.touched.number && !!formik.errors.number}
              />
              <FormHelperText
                hidden={!formik.touched.number || !formik.errors.number}
                error={formik.touched.number && !!formik.errors.number}
              >
                {formik.errors.number}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="complementLabel" className={classes.label}>{complementLabel}</label>
              <Input
                fullWidth
                id="complement"
                color="secondary"
                placeholder="Apto, Quadra, Lote"
                {...formik.getFieldProps('complement')}
                error={formik.touched.complement && !!formik.errors.complement}
              />
              <FormHelperText
                hidden={!formik.touched.complement || !formik.errors.complement}
                error={formik.touched.complement && !!formik.errors.complement}
              >
                {formik.errors.complement}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="districtLabel" className={classes.label}>{districtLabel}</label>
              <Input
                fullWidth
                id="district"
                color="secondary"
                placeholder="Vila, Setor"
                {...formik.getFieldProps('district')}
                error={formik.touched.district && !!formik.errors.district}
              />
              <FormHelperText
                hidden={!formik.touched.district || !formik.errors.district}
                error={formik.touched.district && !!formik.errors.district}
              >
                {formik.errors.district}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="stateLabel" className={classes.label}>{stateLabel}</label>
              <DropdownSelectOne
                id="state"
                options={state}
                value={selectedState}
                {...formik.getFieldProps('state')}
                error={formik.touched.state && !!formik.errors.state}
                onChange={(e) => {
                  const { value } = e.target
                  setSelectedState(value)

                  formik.setFieldValue('state', value)
                }}
              />
              <FormHelperText
                hidden={!formik.touched.state || !formik.errors.state}
                error={formik.touched.state && !!formik.errors.state}
              >
                {formik.errors.state}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="cityLabel" className={classes.label}>{cityLabel}</label>
              <Autocomplete
                className={classes.textField}
                id="city"
                options={city}
                noOptionsText={noOptionsMessage}
                getOptionLabel={(option) => option}
                onInputChange={(e, value) => {
                  formik.values.city = value
                }}
                getOptionSelected={(option, value) => option?.iso === value?.iso}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onFocus={(event) => event.target.setAttribute('autocomplete', 'none')}
                    placeholder="Procurar"
                    margin="normal"
                    error={formik.touched.city && !!formik.errors.city}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {...formik.getFieldProps('city')}
              />
              <FormHelperText
                hidden={!formik.touched.city || !formik.errors.city}
                error={formik.touched.city && !!formik.errors.city}
              >
                {formik.errors.city}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.buttonsStep}>
              <Box>
                <ButtonLight
                  onClick={handlePrevious}
                  title="Voltar"
                >
                  Voltar
                </ButtonLight>
              </Box>
              <Box ml={4}>
                <ButtonGradient
                  onClick={handleNext}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
            <ReactTooltip place="top" type="dark" />
          </Grid>
        </Grid>
      </CollapsePrice>
    </>
  )
})

Claimant.propTypes = {
  quote: PropTypes.any,
  checked: PropTypes.bool,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

Claimant.defaultProps = {
  checked: false,
  quote: undefined,
}

export default Claimant
