import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import ViewerDocument from '../../../../shared/components/ViewerDocument/ViewerDocument'

import useQuotationClient from '../../../../clients/QuotationClient/useQuotationClient'

const DraftViewer = () => {
  const quotationClient = useQuotationClient()

  const { quotationIdentifier } = useParams()

  const [draftData, setDraftData] = useState('')

  useEffect(() => {
    quotationClient().getDraftQuote({
      base64: true,
      codigo: quotationIdentifier,
    }).then((response) => {
      setDraftData(response.data)
    })
  }, [quotationIdentifier, quotationClient])

  return (
    <ViewerDocument documentBase64={draftData} />
  )
}

export default DraftViewer
