import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  Paper,
  Collapse,
  makeStyles,
  Typography,
} from '@material-ui/core'
import ButtonLight from '../Button/ButtonLight'
import { ReactComponent as DownloadIcon } from '../../../assets/img/icons/ico-download.svg'

import { AlertProvider } from '..'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiCollapse-root': {
      borderRadius: 14,
    },
  },
  header: (props) => ({
    height: props.headerSize,
    padding: 40,
    display: 'flex',
    alignItems: 'center',
  }),
  content: {
    padding: theme.spacing(4, 5),
  },
  labelInfo: {
    color: theme.palette.text.tertiary,
    [theme.breakpoints.down('xs')]: {
      marginLeft: -30,
    },
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'space-around',
    },
  },
  btnEdit: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'end',
    '& .MuiTypography-root': {
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('xs')]: {
      width: 200,
    },
  },
  titleDownload: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 40,
      textDecoration: 'none',
    },
  },
  elevationActive: {
    transition: 'all 450ms linear',
    boxShadow: '0px 0 25px #00000029',
  },
  titlesGrid: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: -30,
    },
  },
}))

const CollapsePrice = (props) => {
  const {
    title,
    titleTwo,
    checked,
    subtitle,
    children,
    headerSize,
    hiddenEdit,
    handleEdit,
    titleDownload,
    downloadDocument,
  } = props
  const classes = useStyles({ headerSize: checked && headerSize ? headerSize : 120 })

  useEffect(() => {
    if (checked) {
      const element = document.getElementById('step-checked')
      const y = (element.getBoundingClientRect().top * 0.50) + window.pageYOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }, [checked])

  return (
    <>
      <Box className={classes.root}>
        <Box mb={2} hidden={!checked}>
          <AlertProvider id="register" />
        </Box>
        <Collapse in={checked} collapsedSize={120} id={checked ? 'step-checked' : ''}>
          <Paper className={checked ? classes.elevationActive : ''}>
            <Grid container>
              <Grid
                item
                xs={12}
                className={classes.header}
              >
                <Grid container className={classes.container}>
                  <Grid
                    item
                    xs={checked || hiddenEdit ? 11 : 8}
                    sm={11}
                    style={{ marginTop: checked || hiddenEdit ? 10 : 0 }}
                    className={classes.titlesGrid}
                  >
                    <Typography gutterBottom variant="h5" color={!checked ? 'textSecondary' : 'initial'}>
                      {title}
                    </Typography>
                    {titleTwo && (
                      <Typography gutterBottom variant="h6" color={!checked ? 'textSecondary' : 'initial'}>
                        {titleTwo}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={checked || hiddenEdit ? 1 : 4}
                    sm={1}
                    className={classes.btnEdit}
                  >
                    <Typography color="primary" onMouseUp={handleEdit} hidden={checked || hiddenEdit}>
                      Editar &gt;
                    </Typography>
                    {titleDownload && (
                      <a
                        href={downloadDocument}
                        download={titleDownload}
                        className={classes.titleDownload}
                      >
                        <ButtonLight
                          variant="text"
                        >
                          <Box display="flex" justifyContent="center" alignItems="center">
                            <Box mr={1}>
                              <DownloadIcon />
                            </Box>

                            <Box sx={{ fontSize: 17 }}>
                              <b>{titleDownload}</b>
                            </Box>
                          </Box>
                        </ButtonLight>
                      </a>
                    )}
                  </Grid>
                  <Grid item xs={11} hidden={!checked}>
                    <Typography className={classes.labelInfo}>
                      {subtitle}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.content}>
                {children}
              </Grid>
            </Grid>
          </Paper>
        </Collapse>
      </Box>
    </>
  )
}

CollapsePrice.propTypes = {
  headerSize: PropTypes.number,
  checked: PropTypes.bool,
  hiddenEdit: PropTypes.bool,
  subtitle: PropTypes.any,
  titleTwo: PropTypes.string,
  title: PropTypes.string.isRequired,
  handleEdit: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  titleDownload: PropTypes.string,
  downloadDocument: PropTypes.string,
}

CollapsePrice.defaultProps = {
  titleTwo: '',
  subtitle: '',
  checked: false,
  headerSize: 120,
  hiddenEdit: true,
  titleDownload: '',
  downloadDocument: '',
}

export default CollapsePrice
