import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'
import { isEmpty, isEqual } from 'lodash'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { useHistory, useLocation } from 'react-router-dom'

import {
  toast,
  ToastContainer,
} from 'react-toastify'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'

import makeStyles from '@material-ui/core/styles/makeStyles'

import Delete from '@material-ui/icons/Delete'
import CloudUpload from '@material-ui/icons/CloudUpload'

import { useDropzone } from 'react-dropzone'
import { GlobalMessages } from '../../../../../shared/messages'
import 'react-toastify/dist/ReactToastify.css'

import useUtils from '../../../../../shared/hooks/useUtils'

import { usePrice } from '../../../../../shared/contexts/PriceContext'
import {
  ButtonLight,
  ButtonGradient,
} from '../../../../../shared/components'
import CollapsePrice from '../../../../../shared/components/CollapsePrice/CollapsePrice'

import {
  QUOTE_JUDICIAL_CIVEL,
  STATUS_QUOTE_NOT_SENDED,
  QUOTE_URL_EXECUTIVE_REQUEST_JC,
  QUOTE_URL_EXECUTIVE_REQUEST_CONFIRMATION,
} from '../../../../../constants'

import useQuotationClient from '../../../../../clients/QuotationClient/useQuotationClient'

import FileUploadData from '../../../../../models/JudicialGuaranteeCivelModels/FileUploadData'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    padding: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  subLabel: {
    display: 'flex',
    marginTop: '10px',
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  previewChip: {
    minWidth: 200,
    maxWidth: 400,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    cursor: 'pointer',
  },
}))

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

const FileUpload = forwardRef((props, ref) => {
  const history = useHistory()
  const location = useLocation()
  const [filesJudicialGuaranteeCivel, setFilesJudicialGuaranteeCivel] = useState([])
  const [rerender, setRerender] = useState(false)

  const quotationClient = useQuotationClient()

  const {
    quote,
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props
  const { ternaryCheck } = useUtils()

  const getTokenUrl = location?.pathname?.split(':')
  const routeSolicitation = getTokenUrl[0]
  const tokenParams = getTokenUrl[1]

  const {
    actionNext,
    isFormikChanged,
    judicialGuaranteeCivel,
    setJudicialGuaranteeCivel,
  } = usePrice()

  const initialValuesForm = useMemo(() => (Object.assign(
    FileUploadData(quote?.FileUpload ?? {}),
  )), [quote?.FileUpload])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesForm,
    onSubmit: (data) => {
      const values = {
        ...judicialGuaranteeCivel,
        ...{ fileUpload: [...data.documents ?? []] },
      }
      setJudicialGuaranteeCivel(QUOTE_URL_EXECUTIVE_REQUEST_JC === routeSolicitation
        ? { ...values, RequestByExecutive: true }
        : values)

      if (actionNext) {
        let routeConfirmation = `/cotacao/${quote?.quotationIdentifier}/status`
        const isEdit = !isEmpty(quote?.quotationIdentifier) && (
          isFormikChanged || !isEqual(initialValuesForm, data)
        )

        const isConfirmation = isEdit || isEmpty(quote?.quotationIdentifier)

        if (isConfirmation) {
          routeConfirmation = QUOTE_URL_EXECUTIVE_REQUEST_JC === routeSolicitation
            ? `${QUOTE_URL_EXECUTIVE_REQUEST_CONFIRMATION}:${tokenParams}`
            : '/cotacao/confirmacao'
        }

        history.push(routeConfirmation, {
          quotation: isConfirmation ? {
            isEdit,
            data: QUOTE_URL_EXECUTIVE_REQUEST_JC === routeSolicitation
              ? { ...values, RequestByExecutive: true }
              : values,
            Status: ternaryCheck(
              !!quote?.status,
              quote?.status,
              STATUS_QUOTE_NOT_SENDED,
            ),
            QuoteType: QUOTE_JUDICIAL_CIVEL,
            QuotationIdentifier: quote?.quotationIdentifier,
          } : undefined,
        })
      }
    },
  })

  const {
    setValues,
  } = formik

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((item) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(item)
      reader.onload = () => {
        if (!!reader.result) {
          const fileB64 = btoa(
            new Uint8Array(reader.result)
              .reduce((data, byte) => data + String.fromCharCode(byte), ''),
          )
          const payload = {
            DocumentName: item.name,
            DocumentType: String(item.type).replace('application/', ''),
            content: fileB64,
          }

          quotationClient().sendDocumentsWings(payload).then((response) => {
            const { data } = response
            setFilesJudicialGuaranteeCivel([...filesJudicialGuaranteeCivel, ...[{
              DocumentID: data, DocumentName: item.name,
            }]])
            toast.success(`${item.name} foi anexado!`, {
              position: 'bottom-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }, () => {
            toast.error(`Ocorreu um erro e ${item.name} não foi anexado!`, {
              position: 'bottom-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          })
        }
      }
    })
  }, [quotationClient, filesJudicialGuaranteeCivel])

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: 'application/pdf', multiple: false })
  const classes = useStyles()

  const removeFile = (index) => {
    const localfileJudicialGuaranteeCivel = filesJudicialGuaranteeCivel
    localfileJudicialGuaranteeCivel.splice(index, 1)
    setFilesJudicialGuaranteeCivel(localfileJudicialGuaranteeCivel)
    setRerender(!rerender)
  }

  const handleClick = (documentID) => {
    window.open(`${process.env.REACT_APP_BFF_URL}/wings/document?wingsDocumentId=${documentID}`, '_blank')
  }

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
  ])

  useEffect(() => {
    let active = true
    if (active) {
      setFilesJudicialGuaranteeCivel(quote?.FileUpload?.Documents ?? quote?.FileUpload ?? [])
    }
    active = false
  }, [
    quote,
  ])

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))
  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={isEmpty(quote?.FileUpload) && !formik.isSubmitting}
        title="Arquivos"
        subtitle="Selecione os arquivos"
      >
        <Grid item xs={12}>
          <div className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p>Arraste e solte os arquivos em PDF aqui ou clique para selecionar</p>
              <CloudUpload
                color="primary"
                fontSize="large"
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          {
            (filesJudicialGuaranteeCivel?.Documents ?? filesJudicialGuaranteeCivel)
              ?.map((item, index) => (
                <Chip
                  className={classes.previewChip}
                  key={index}
                  label={item.DocumentName}
                  onClick={() => {
                    handleClick(item.DocumentID)
                  }}
                  onDelete={() => {
                    removeFile(index)
                  }}
                  deleteIcon={<Delete />}
                  variant="outlined"
                />
              ))
          }
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.buttonsStep}>
            <Box>
              <ButtonLight
                onClick={handlePrevious}
                title="Voltar"
              >
                Voltar
              </ButtonLight>
            </Box>
            <Box ml={4}>
              <ButtonGradient
                onClick={() => {
                  formik.setFieldValue('documents', filesJudicialGuaranteeCivel)
                  setValues({
                    documents: filesJudicialGuaranteeCivel,
                  })
                  handleNext()
                }}
                title="Continuar"
              >
                Continuar
              </ButtonGradient>
            </Box>
          </Box>
          <ReactTooltip place="top" type="dark" />
        </Grid>
      </CollapsePrice>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
})

FileUpload.propTypes = {
  quote: PropTypes.any,
  checked: PropTypes.bool,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

FileUpload.defaultProps = {
  checked: false,
  quote: undefined,
}

export default FileUpload
