import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { isEmpty } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import makeStyles from '@material-ui/core/styles/makeStyles'

import Alert from '@material-ui/lab/Alert'

import OverdueTicketsIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'

import Nav from './Nav'
import Main from './Main'
import Footer from './footer/Footer'
import Header from './header/Header'
import SubFooter from './footer/SubFooter'
import useSecurity from '../../security/useSecurity'

import ExecutiveBtn from '../components/ExecutiveButton/ExecutiveBtn'
import CallCenterBtn from '../components/CallCenterButton/CallCenterBtn'

import useUtils from '../hooks/useUtils'
import { useSecurityAction } from '../../store/ducks/security'

import { STATUS_FINANCIAL_DUE } from '../../constants'

import useRatingClient from '../../clients/RatingClient'
import useUserClient from '../../clients/UserClient/useUserClient'
import useFinancialClient from '../../clients/FinancialClient/useFinancialClient'
import useRegistrationUpdateClient from '../../clients/RegistrationUpdateClient/useRegistrationUpdateClient'
import useMissedAppointmentClient from '../../clients/MissedAppointmentClient/useMissedAppointmentClient'

import PortalUpdates from '../../pages/panel/fragments/PortalUpdates'
import ModalRegistrationUpdate from '../../pages/registration-update/fragments/ModalRegistrationUpdate'

import FeedBackModal from './fragments/FeedBackModal'
import PortalUpdatesModal from './fragments/PortalUpdatesModal'

import { useAlert } from '../components'
import { useBreadcrumb } from '../contexts/BreadcrumbContext'
import TermsOfUseModal from '../../pages/terms-of-use/TermsOfUseModal'
import useTermsOfUseClient from '../../clients/TermsOfUseClient/useTermsOfUseClient'

const useStyles = makeStyles((theme) => ({
  navbar: {
    zIndex: 1200,
    height: '100%',
    display: 'flex',
    position: 'fixed',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
  container: (props) => {
    const styles = {
      height: '100%',
      backgroundColor: '#F6F6F6',
    }

    if (props.error) {
      styles.position = 'absolute'
    }

    return styles
  },
  subContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    width: '100%',
    display: 'flex',
  },
  footer: {
    width: '100%',
    height: theme.sizes.header,
    '& footer': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  pined: {
    height: 'auto',
    position: 'relative',
    '& > nav': {
      width: 240,
      '&:hover': {
        animation: 'none',
      },
    },
  },
  overdueTickets: {
    color: '#F6F6F6',
    backgroundColor: theme.palette.text.quaternary,
  },
  messageOverdueTickets: {
    [theme.breakpoints.down('xs')]: {
      minHeight: 60,
    },
    color: '#F6F6F6',
    backgroundColor: theme.palette.text.quaternary,
    marginLeft: 155,
    marginRight: 155,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
      paddingBottom: 20,
    },
    paddingBottom: 0,
    paddingTop: 0,
    display: 'flex',
    alignItems: 'center',
    '& .MuiAlert-message': {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  seePendingButton: {
    fontSize: 14,
    cursor: 'pointer',
    fontWeight: 'bold',
    paddingLeft: theme.spacing(4),
    color: theme.palette.text.contrastText,
    [theme.breakpoints.down('xs')]: {
      marginLeft: -25,
      marginTop: 10,
    },
  },
  overdueTicketsIcon: {
    [theme.breakpoints.down('xs')]: {
      marginTop: -25,
    },
    color: '#FF3C3C',
  },
  quoteApprovedIcon: {
    color: '#36C576',
  },
}))

const Master = (props) => {
  const history = useHistory()
  const location = useLocation()
  const { formatDate } = useUtils()
  const { addMsgDanger } = useAlert()

  const userClient = useUserClient()
  const ratingClient = useRatingClient()
  const financialClient = useFinancialClient()
  const termsOfUseClient = useTermsOfUseClient()
  const missedAppointmentClient = useMissedAppointmentClient()
  const registrationUpdateClient = useRegistrationUpdateClient()

  const error = useMemo(() => {
    const { pathname } = location
    return pathname.search('error') === 1
  }, [location])

  const classes = useStyles({ error })
  const { bgImage, crumbs, children } = props

  const {
    signout,
    getUser,
    cleanAllData,
    getExecutive,
    getTicketsExpired,
  } = useSecurity()

  const {
    setUser,
    setDataIsOutdated,
    setTrackSaleSchedule,
  } = useSecurityAction()

  const { setTitlePage } = useBreadcrumb()

  const executiveSetted = useMemo(() => getExecutive(), [getExecutive])

  const isHome = useMemo(() => location?.pathname === '/inicio', [location])

  const user = getUser()
  const [hasPin] = useState(false)
  const [openNavItems] = useState(false)
  const [mobileOpen, setMobileOpen] = useState(false)
  const [closeOverdueTickets, setCloseOverdueTickets] = useState(false)
  const [openModalNps, setOpenModalNps] = useState(false)
  const [openModalPortalUpdates, handleModalPortalUpdates] = useState(false)
  const [openModalRegistrationUpdate, handleModalRegistrationUpdate] = useState(false)
  const [showUpdatesPanel, setShowUpdatesPanel] = useState(false)
  const [openModalTermsOfUse, setOpenModalTermsOfUse] = useState(false)
  const [currentTicketsExpired, setCurrentTicketsExpired] = useState()
  const downXS = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen)

  const sair = () => signout()

  const closeModalPortalUpdates = () => {
    handleModalPortalUpdates(false)
  }

  const closeModalRegistrationUpdate = (value) => {
    handleModalRegistrationUpdate(value ?? false)
  }

  const closeModalTermsOfUse = () => {
    setOpenModalTermsOfUse(false)
  }

  const closeModalTermsOfUseLogout = () => {
    setOpenModalTermsOfUse(false)
    sair()
  }

  const isDateGreaterOrEqual = useCallback(() => {
    const currentDate = new Date()
    const targetDate = new Date(user?.updateDeniedAt)
    const thirtyDaysFromNow = targetDate?.setDate(targetDate.getDate() + 30)
    return user?.updateDeniedAt ? (currentDate >= thirtyDaysFromNow) : false
  }, [user])

  useEffect(() => {
    const updateWindowDimensions = () => {
      if (window.innerWidth >= 960) {
        setMobileOpen(false)
      }
    }

    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  const messageNumberTickets = ` ${currentTicketsExpired} Boletos `
  const messageNumberQuotes = ` ${user?.quoteApproved?.quantidade}`

  const handleClickSeePending = () => {
    history.push('/financeiro', { status: [STATUS_FINANCIAL_DUE] })
  }

  const registrationUpdateVerify = localStorage.getItem('RegistrationUpdate')
  const storageMissedAppointmentDate = localStorage.getItem('missedAppointmentDate')

  const checksMissedAppointment = useCallback(() => {
    missedAppointmentClient().getCheckNomination().then(() => {
      const currentDate = new Date()
      localStorage.setItem('missedAppointmentDate', currentDate)
    }, (err) => {
      const currentDate = new Date()
      localStorage.setItem('missedAppointmentDate', currentDate)
      cleanAllData()
      addMsgDanger({
        message: err?.data?.message,
        labelButton: 'Fechar',
        action: () => window.location.reload(),
      })
      history.replace('/login')
    })
  }, [missedAppointmentClient, history, cleanAllData, addMsgDanger])

  const currentDate = new Date()

  const checksMissedAppointmentDate = !storageMissedAppointmentDate
    || formatDate(storageMissedAppointmentDate) !== formatDate(currentDate)

  useEffect(() => {
    setTimeout(() => {
      if (checksMissedAppointmentDate) {
        checksMissedAppointment()
      }
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCloseOverdueTickets(false)
    setShowUpdatesPanel(isHome)
    if (isHome && getTicketsExpired() > 0) {
      setCloseOverdueTickets(true)
    }
  }, [isHome, getTicketsExpired, messageNumberQuotes])

  useEffect(() => {
    if (isHome) {
      financialClient().getFinancialTickets({}).then((response) => {
        const data = response?.data?.data
        setCurrentTicketsExpired(data?.countExpired)
      })

      ratingClient().getFeedBack().then((response) => {
        setTrackSaleSchedule(user.trackSaleSchedule)
        setOpenModalNps(false)
        if (response?.data) {
          setOpenModalNps(true)
        }
      })

      registrationUpdateClient().getUserOutdated(user?.email).then((response) => {
        setDataIsOutdated(response?.data?.data?.isOutdated)
        closeModalRegistrationUpdate(false)
        if (response?.data?.data?.isOutdated
          && (registrationUpdateVerify === null || isDateGreaterOrEqual())) {
          closeModalRegistrationUpdate(true)
        }
      })

      if (user.newUser) {
        handleModalPortalUpdates(true)
      } else {
        handleModalPortalUpdates(false)
      }
    }
  }, [
    user,
    isHome,
    location,
    ratingClient,
    financialClient,
    isDateGreaterOrEqual,
    setDataIsOutdated,
    setTrackSaleSchedule,
    registrationUpdateClient,
    registrationUpdateVerify,
  ])

  const toHome = useCallback(() => history.push('/inicio'), [history])

  const errorHandlingGetClient = useCallback((err) => {
    addMsgDanger({
      action: () => toHome(),
      status: err?.status ?? err?.data?.status,
      message: 'Tente novamente mais tarde ou contate o Administrador do sistema.',
    })
  }, [toHome, addMsgDanger])

  const checkIsPhysicalPersonRoute = useCallback(() => {
    if ((user?.isPhysicalPerson)
      && (location?.pathname.includes('cotacao')
        || location?.pathname.includes('endossos')
        || location?.pathname.includes('status-solicitacao')
      )
    ) {
      toHome()
    }
  }, [location, toHome, user])

  const getClientsVerifyIsPhysicalPerson = useCallback(() => {
    let clientsUser = []

    userClient().getClientByUserLogged(user?.email).then((response) => {
      const filterNotLead = response?.data?.filter((item) => item?.lead === false)
      clientsUser = filterNotLead?.filter(({ cpfcnpj }) => cpfcnpj?.length === 14)

      if (clientsUser?.length === 0) {
        setUser({ ...user, isPhysicalPerson: true })
      } else {
        setUser({ ...user, isPhysicalPerson: false })
      }
    }, (err) => errorHandlingGetClient(err))
  }, [user, setUser, userClient, errorHandlingGetClient])

  const checkIfAreAnPhysicalPerson = useCallback(() => {
    if (user?.isPhysicalPerson === undefined) {
      getClientsVerifyIsPhysicalPerson()
    }

    checkIsPhysicalPersonRoute()
  }, [user, checkIsPhysicalPersonRoute, getClientsVerifyIsPhysicalPerson])

  useEffect(() => checkIfAreAnPhysicalPerson(), [checkIfAreAnPhysicalPerson])

  useEffect(() => {
    const routeStatusQuotation = location?.pathname?.split('/')

    if (location?.pathname !== '/cotacao/confirmacao'
      && `/${routeStatusQuotation[1]}/${routeStatusQuotation[3]}` !== '/cotacao/status') {
      setTitlePage(() => crumbs[crumbs.length - 1]?.name)
    }
  }, [crumbs, setTitlePage, location])

  useEffect(() => {
    termsOfUseClient().getHasAceptedLastVersion().then((response) => {
      setUser({ ...user, hasAceptedTermsOfUse: response?.data })
      setOpenModalTermsOfUse(!response?.data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Grid item className={clsx(classes.navbar, hasPin && classes.pined)}>
        <Nav
          logout={sair}
          hasPin={hasPin}
          mobileOpen={mobileOpen}
          openAllItems={openNavItems}
          onDrawerToggle={handleDrawerToggle}
        />
      </Grid>

      <Grid item className={classes.subContainer}>
        <Header
          logout={sair}
          crumbs={crumbs}
          isError={error}
          bgImage={bgImage}
          handleDrawerToggle={handleDrawerToggle}
          openModalPortalUpdates={openModalPortalUpdates}
          closeModalRegistrationUpdate={closeModalRegistrationUpdate}
        />

        <Grid container className={classes.container}>
          {isHome && (
            <>
              <Grid item xs={12}>
                <Collapse
                  in={closeOverdueTickets}
                  className={classes.overdueTickets}
                >
                  <Box
                    pt={2}
                    sx={downXS
                      ? {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                      }
                      : {}}
                  >
                    <Alert icon={false} className={classes.messageOverdueTickets}>
                      <Typography variant="h6" color="secondary">
                        <b>Alertas</b>
                      </Typography>
                    </Alert>
                    {downXS && (<Box minWidth={200} />)}
                    {downXS && (
                      <Box mt={-2.5}>
                        <Button onClick={() => setCloseOverdueTickets(false)}>
                          <CloseIcon color="secondary" />
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                <Collapse in={closeOverdueTickets} className={classes.overdueTickets}>
                  <Alert
                    severity="error"
                    icon={<OverdueTicketsIcon fontSize="inherit" className={classes.overdueTicketsIcon} />}
                    className={classes.messageOverdueTickets}
                    action={downXS ? (<></>) : (
                      <Button onClick={() => setCloseOverdueTickets(false)}>
                        <CloseIcon color="secondary" />
                      </Button>
                    )}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      ml={downXS ? -1 : 0}
                      mt={downXS ? 0.5 : 0}
                      flexDirection={downXS ? 'column' : 'row'}

                    >
                      <Box>
                        <Typography variant="body2">
                          Você tem
                          <b>{messageNumberTickets}</b>
                          com pagamentos em atraso.
                        </Typography>
                      </Box>
                      <Box>
                        <Button
                          color="inherit"
                          onClick={handleClickSeePending}
                          className={classes.seePendingButton}
                        >
                          Ver Pendências &gt;
                        </Button>
                      </Box>
                    </Box>
                  </Alert>
                </Collapse>
              </Grid>
            </>
          )}

          <Main className={classes.content}>
            {children}
          </Main>
        </Grid>

        {showUpdatesPanel && (
          <PortalUpdates />
        )}

        <footer hidden={error}>
          <SubFooter />
          <Footer />
        </footer>

        <CallCenterBtn hidden={!isEmpty(executiveSetted)} />

        <ExecutiveBtn hidden={isEmpty(executiveSetted)} />

        {(location?.pathname === '/inicio')
          && (
            <FeedBackModal
              open={openModalNps}
              setOpen={setOpenModalNps}
            />
          )}

        {(location?.pathname === '/inicio')
          && (
            <PortalUpdatesModal
              openModal={openModalPortalUpdates}
              closeModal={closeModalPortalUpdates}
            />
          )}

        {openModalRegistrationUpdate && (
          <ModalRegistrationUpdate
            openModal={openModalRegistrationUpdate}
            closeModal={closeModalRegistrationUpdate}
          />
        )}

        <TermsOfUseModal
          openModal={openModalTermsOfUse}
          closeModal={closeModalTermsOfUse}
          logoutCloseModal={closeModalTermsOfUseLogout}
        />

      </Grid>
    </>
  )
}

Master.propTypes = {
  crumbs: PropTypes.array,
  bgImage: PropTypes.any.isRequired,
  children: PropTypes.element.isRequired,
}

Master.defaultProps = {
  crumbs: [],
}

export default Master
