const CreateClient = {
  Name: '',
  CPFCNPJ: '',
  Email: '',
  Phone: undefined,
  State: '',
  City: '',
  CellPhone: undefined,
}

export default CreateClient
