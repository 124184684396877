import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'

import makeStyles from '@material-ui/core/styles/makeStyles'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isEqual, isEmpty } from 'lodash'
import ReactTooltip from 'react-tooltip'

import { usePrice } from '../../../../../shared/contexts/PriceContext'

import { PRODUCT_JUDICIAL_GUARANTEE_APPEAL } from '../../../../../constants'

import {
  ButtonLight,
  ButtonGradient,
} from '../../../../../shared/components'
import { GlobalMessages } from '../../../../../shared/messages'
import CollapsePrice from '../../../../../shared/components/CollapsePrice/CollapsePrice'
import DropdownSelectOne from '../../../../../shared/components/DropdownSelectOne/DropdownSelectOne'

import useProductQuotationClient from '../../../../../clients/ProductClient/useProductQuotationClient'
import DetailsProductData from '../../../../../models/JudicialGuaranteeAppealModels/DetailsProductData'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
}))

const DetailsProduct = forwardRef((props, ref) => {
  const classes = useStyles()
  const {
    quote,
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props

  const {
    onNext,
    actionNext,
    setFormikChanged,
    setResourceTypeProduct,
    judicialGuaranteeAppeal,
    setJudicialGuaranteeAppeal,
    setAppealSelected,
  } = usePrice()

  const productQuotationClient = useProductQuotationClient()

  const [modalities, setModalities] = useState([''])
  const [appealTypes, setAppealTypes] = useState([''])

  const getAppealObject = useCallback((appealSelected) => (
    appealTypes.find((appealType) => appealSelected === appealType?.value)
  ), [appealTypes])

  const initialValuesForm = useMemo(() => (Object.assign(
    DetailsProductData(quote?.DetailsProduct ?? {}),
  )), [quote?.DetailsProduct])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesForm,
    validationSchema: Yup.object({
      appealType: Yup.string().required(),
      modality: Yup.string().required(),
    }),
    onSubmit: (data) => {
      const values = {
        ...judicialGuaranteeAppeal,
        ...{ detailsProduct: { ...data } },
      }
      setJudicialGuaranteeAppeal(values)

      if (actionNext) {
        setFormikChanged(!isEqual(initialValuesForm, data))
        onNext(values)
      }
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  useEffect(() => {
    const productJudicialGuaranteeAppeal = productQuotationClient().getProducts()
      .find((p) => p?.value === PRODUCT_JUDICIAL_GUARANTEE_APPEAL)
    setAppealTypes(productJudicialGuaranteeAppeal?.appeals.sort())
    setModalities(productJudicialGuaranteeAppeal?.subModalities.sort())
  }, [productQuotationClient])

  const modalityLabel = 'Modalidade'
  const appealLabel = 'Tipo de Recurso'

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={isEmpty(quote?.DetailsProduct) && !formik.isSubmitting}
        title="Detalhes do Produto"
        subtitle="Informe abaixo os detalhes do produto"
      >
        <Grid container spacing={5}>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="modalityLabel" className={classes.label}>{modalityLabel}</label>
              <DropdownSelectOne
                id="modality"
                options={modalities}
                placeholder="Selecione"
                {...formik.getFieldProps('modality')}
                error={formik.touched.modality && !!formik.errors.modality}
                helpertext={formik.touched.modality && formik.errors.modality}
              />
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="appealLabel" className={classes.label}>{appealLabel}</label>
              <DropdownSelectOne
                id="appealType"
                options={appealTypes}
                placeholder="Selecione"
                {...formik.getFieldProps('appealType')}
                error={formik.touched.appealType && !!formik.errors.appealType}
                helpertext={formik.touched.appealType && formik.errors.appealType}
                onChange={(e) => {
                  const { value } = e.target
                  const appealObject = getAppealObject(value)
                  setAppealSelected(appealObject?.text)
                  formik.setFieldValue('appealType', appealObject?.value)
                  setResourceTypeProduct(appealObject?.id)
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.buttonsStep}>
              <Box>
                <ButtonLight
                  onClick={handlePrevious}
                  title="Voltar"
                >
                  Voltar
                </ButtonLight>
              </Box>
              <Box ml={4}>
                <ButtonGradient
                  onClick={handleNext}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
            <ReactTooltip place="top" type="dark" />
          </Grid>
        </Grid>
      </CollapsePrice>
    </>
  )
})

DetailsProduct.propTypes = {
  quote: PropTypes.any,
  checked: PropTypes.bool,
  children: PropTypes.any,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

DetailsProduct.defaultProps = {
  children: '',
  checked: false,
  quote: undefined,
}

export default DetailsProduct
