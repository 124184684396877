import React, {
} from 'react'
import clsx from 'clsx'
import { PropTypes } from 'prop-types'

import Box from '@material-ui/core/Box'

import makeStyles from '@material-ui/core/styles/makeStyles'

import { Check } from '@material-ui/icons'
import { BsClockFill } from 'react-icons/bs'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 22,
    display: 'flex',
    color: '#FFFFFF',
    alignItems: 'center',
    background: '#CCCCCC',
    justifyContent: 'center',
  },
  circle: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: 'currentBackground',
  },
  completed: {
    zIndex: 1,
    fontSize: 18,
    background: theme.palette.success.main,
  },
  active: {
    background: theme.palette.success.main,
  },
}))

const CircleIconStep = (props) => {
  const { active, completed } = props

  const classes = useStyles()

  return (
    <Box
      className={clsx(
        classes.root,
        classes.circle,
        {
          [classes.active]: active,
          [classes.completed]: completed,
        },
      )}
    >
      {(!active && !completed) && <BsClockFill />}
      {completed && <Check />}
    </Box>
  )
}

CircleIconStep.propTypes = {
  active: PropTypes.any,
  completed: PropTypes.any,
}

CircleIconStep.defaultProps = {
  active: false,
  completed: false,
}

export default CircleIconStep
