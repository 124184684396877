/* eslint-disable max-len */
import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

import makeStyles from '@material-ui/core/styles/makeStyles'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isEqual, isEmpty } from 'lodash'
import ReactTooltip from 'react-tooltip'

import { usePrice } from '../../../../../shared/contexts/PriceContext'
import useUtils from '../../../../../shared/hooks/useUtils'

import {
  DateInput,
  ButtonLight,
  ButtonGradient,
  useAlert,
} from '../../../../../shared/components'
import CollapsePrice from '../../../../../shared/components/CollapsePrice/CollapsePrice'
import { GuaranteedMessages, GlobalMessages } from '../../../../../shared/messages'
import CurrencyInput from '../../../../../shared/components/CurrencyInput/CurrencyInput'

import ObjectPropertyData from '../../../../../models/PerformanceModels/ObjectPropertyData'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  labelObject: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  labelMessage: {
    color: 'red',
    fontSize: 14,
    margin: 2,
  },
}))

const ObjectProperty = forwardRef((props, ref) => {
  const classes = useStyles()
  const { addMsgWarning } = useAlert()
  const {
    isBlank, date, formatDateApi, formatDate,
  } = useUtils()
  const {
    quote,
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props

  const {
    onNext,
    actionNext,
    setFormikChanged,
    guaranteePerformance,
    setGuaranteePerformance,
  } = usePrice()

  const calculateDeadline = (dateInitial, dateFinal) => {
    const initial = new Date(dateInitial)
    const final = new Date(dateFinal)
    const differenceInMilliseconds = final - initial
    // eslint-disable-next-line sonarjs/prefer-immediate-return
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24)

    return differenceInDays
  }

  const initialValuesForm = useMemo(() => (Object.assign(
    ObjectPropertyData(quote?.ObjectProperty ?? {}),
  )), [quote?.ObjectProperty])

  const [percentage, setPercentage] = useState('')
  const [requiredNumber, setRequiredNumber] = useState(true)

  const minStartDateValidity = useMemo(() => {
    const twoYearsRetroactive = date().subtract(2, 'years')

    return formatDateApi(twoYearsRetroactive)
  }, [formatDateApi, date])

  const minDateValidation = useCallback(() => {
    if (minStartDateValidity !== undefined) {
      return date(minStartDateValidity)
    }
    return undefined
  }, [date, minStartDateValidity])

  const isRequiredNumber = () => Yup.string()
    .when(() => (!requiredNumber
      ? Yup.string().max(50, GlobalMessages.LIMIT_MAX_CHARACTER(50))
      : Yup.string().max(50, GlobalMessages.LIMIT_MAX_CHARACTER(50)).required()))

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesForm,
    validationSchema: Yup.object({
      deadline: Yup.string().required(),
      objectText: Yup.string(),
      noticeNumber: isRequiredNumber(),
      contractNumber: isRequiredNumber(),
      processNumber: isRequiredNumber(),
      complementObjectText: Yup.string().max(1500, GlobalMessages.LIMIT_MAX_CHARACTER(1500))
        .required(),
      additiveNumber: Yup.string(),
      startDateValidity: Yup.date()
        .min(minStartDateValidity, `A data mínima permitida para o início de vigência é ${formatDate(date(minStartDateValidity))}.`)
        .required(),
      percentageGuarantee: Yup.string().required(),
      endDateValidity: Yup.date().when('startDateValidity', (startDateValidity) => startDateValidity
        && Yup.date().min(startDateValidity, GlobalMessages.DATA_FINAL_MAIOR_INICIAL).required()),
      contractValue: Yup.number()
        .test('contractValue', GlobalMessages.CAMPO_MAIOR_ZERO('Valor Contrato'), (value) => (value && Number(value) !== 0))
        .required(),
      guaranteeAmount: Yup.number()
        .test('guaranteeAmount', GlobalMessages.CAMPO_MAIOR_ZERO('Valor Garantia'), (value) => (value && Number(value) !== 0)),
    }),
    onSubmit: (data) => {
      if (percentage) {
        formik.values.percentageGuarantee = percentage
      }
      const values = {
        ...guaranteePerformance,
        ...{ objectProperty: { ...data } },
      }

      const dateFinal = values?.objectProperty?.endDateValidity
      const dateInitial = values?.objectProperty?.startDateValidity
      const deadlineInDays = values?.objectProperty?.deadline

      if (calculateDeadline(dateInitial, dateFinal) === Number(deadlineInDays)) {
        setGuaranteePerformance(values)

        if (actionNext) {
          setFormikChanged(!isEqual(initialValuesForm, data))
          onNext(values)
        }
      } else {
        addMsgWarning({
          message: 'O prazo esta incorreto, para prosseguir informe o prazo novamente.',
          action: () => formik.setFieldValue('deadline', ''),
        })
      }
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  const [endDate, setEndDate] = useState('')
  const [deadline, setDeadline] = useState('')
  const [concatTextDefault, setConcatTextDefault] = useState('')

  useEffect(() => {
    const objectContractNumber = formik.getFieldProps('contractNumber')
    const objectAdditiveNumber = formik.getFieldProps('additiveNumber')
    const objectNoticeNumber = formik.getFieldProps('noticeNumber')
    const objectProcessNumber = formik.getFieldProps('processNumber')
    const complementObjectText = formik.getFieldProps('complementObjectText')

    if (objectContractNumber?.value || objectNoticeNumber?.value || objectProcessNumber?.value) {
      let complementContract = ''
      if (objectContractNumber?.value) {
        complementContract += `Contrato: ${objectContractNumber?.value}`
      }

      if (objectAdditiveNumber?.value && !objectContractNumber?.value) {
        complementContract += `Aditivo: ${objectAdditiveNumber?.value}`
      }

      if (objectAdditiveNumber?.value && objectContractNumber?.value) {
        complementContract += `, Aditivo: ${objectAdditiveNumber?.value}`
      }

      if (objectNoticeNumber?.value && (!objectAdditiveNumber?.value && !objectContractNumber?.value)) {
        complementContract += `Edital: ${objectNoticeNumber?.value}`
      }

      if (objectNoticeNumber?.value && (objectAdditiveNumber?.value || objectContractNumber?.value)) {
        complementContract += `, Edital: ${objectNoticeNumber?.value}`
      }

      if (objectProcessNumber?.value) {
        if (objectContractNumber?.value === '' && objectAdditiveNumber?.value === '' && objectNoticeNumber?.value === '') {
          complementContract += `Processo: ${objectProcessNumber?.value}.`
        } else {
          complementContract += ` e no Processo: ${objectProcessNumber?.value}.`
        }
      }

      if (complementObjectText?.value) {
        complementContract += complementObjectText?.value.trim()
      }

      setConcatTextDefault(GuaranteedMessages
        .SEGURO_GARANTE_INDENIZACAO_NUMERO_CONTRATO_COMPLEMENTO(complementContract))
    } else {
      setConcatTextDefault('')
    }
  }, [formik, setConcatTextDefault])

  useEffect(() => {
    const endDateValidity = formik.getFieldProps('endDateValidity')
    const startDateValidity = formik.getFieldProps('startDateValidity')

    setDeadline('')
    if (startDateValidity?.value && endDateValidity?.value) {
      const deadlineCount = date(endDateValidity?.value)
        .diff(date(startDateValidity?.value), 'days')
      setDeadline(deadlineCount)
    }
  }, [formik, date, setDeadline])

  const handleChangeDeadline = () => {
    const objectDeadline = formik.getFieldProps('deadline')
    const startDateValidity = formik.getFieldProps('startDateValidity')

    setEndDate('')
    if (!isEmpty(objectDeadline?.value) && !isEmpty(startDateValidity?.value)) {
      const endDateUpdated = date(startDateValidity?.value)
        .add(objectDeadline?.value, 'days')
      setEndDate(formatDateApi(endDateUpdated))
    }
  }
  const handlePercentageGuaranteeAmount = useCallback((event) => {
    const { value } = event.target
    const valueDecimal = value?.replace('.', ',')

    formik.setFieldValue('percentageGuarantee', valueDecimal)

    const objectGuaranteeAmount = formik.values.contractValue * (valueDecimal?.replace(',', '.') / 100)

    return objectGuaranteeAmount.toFixed(2)
  }, [formik])

  const handleGuaranteeAmount = useCallback((event) => {
    const { value } = event.target
    const valueDecimal = value?.replace(/[^0-9,]*/g, '')?.replace(',', '.')

    formik.setFieldValue('guaranteeAmount', valueDecimal)

    const objectPercentageGuarantee = (valueDecimal * 100) / formik.values.contractValue

    return objectPercentageGuarantee.toFixed(3)?.slice(0, -1)?.replace('.', ',')
  }, [formik])

  useEffect(() => {
    const contractNumber = formik.getFieldProps('contractNumber')
    const noticeNumber = formik.getFieldProps('noticeNumber')
    const processNumber = formik.getFieldProps('processNumber')

    setRequiredNumber(!(contractNumber?.value
      || noticeNumber?.value
      || processNumber?.value))
  }, [formik, setRequiredNumber])

  const contractNumberLabel = 'Número do Contrato'
  const additiveNumberLabel = 'Número do Aditivo'
  const numberLabel = 'Número do Edital'
  const processNumberLabel = 'Número do Processo'
  const complementObjectLabel = 'Complemento Obrigação Garantia (Objeto)'
  const objectLabel = 'Obrigação Garantia (Objeto)'
  const guaranteeAmountLabel = 'Valor Garantia'
  const startDateValidityLabel = 'Início de Vigência'
  const endDateValidityLabel = 'Fim de Vigência'
  const deadlineLabel = 'Prazo (Dias)'
  const percentageGuaranteeLabel = '% Garantia'
  const contractValueLabel = 'Valor Contrato'

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={isEmpty(quote?.ObjectProperty) && !formik.isSubmitting}
        title="Objeto Segurado"
        subtitle="Informe abaixo os dados do objeto segurado"
      >
        <Grid container spacing={5}>
          <Grid item sm={3} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="contractNumberLabel" className={classes.label}>{contractNumberLabel}</label>
              <Input
                fullWidth
                id="contractNumber"
                color="secondary"
                placeholder="000000"
                {...formik.getFieldProps('contractNumber')}
                error={formik.touched.contractNumber && !!formik.errors.contractNumber}
                onKeyUp={() => formik.setFieldValue('objectText', concatTextDefault)}
              />
              <FormHelperText
                hidden={!formik.touched.contractNumber || !formik.errors.contractNumber}
                error={formik.touched.contractNumber && !!formik.errors.contractNumber}
              >
                {formik.errors.contractNumber}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="additiveNumberLabel" className={classes.label}>{additiveNumberLabel}</label>
              <Input
                fullWidth
                id="additiveNumber"
                color="secondary"
                placeholder="000000"
                {...formik.getFieldProps('additiveNumber')}
                error={formik.touched.additiveNumber && !!formik.errors.additiveNumber}
                onKeyUp={() => formik.setFieldValue('objectText', concatTextDefault)}
              />
              <FormHelperText
                hidden={!formik.touched.additiveNumber || !formik.errors.additiveNumber}
                error={formik.touched.additiveNumber && !!formik.errors.additiveNumber}
              >
                {formik.errors.additiveNumber}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="numberLabel" className={classes.label}>{numberLabel}</label>
              <Input
                fullWidth
                id="noticeNumber"
                color="secondary"
                placeholder="000000/0000"
                {...formik.getFieldProps('noticeNumber')}
                error={formik.touched.noticeNumber && !!formik.errors.noticeNumber}
                onKeyUp={() => formik.setFieldValue('objectText', concatTextDefault)}
              />
              <FormHelperText
                hidden={!formik.touched.noticeNumber || !formik.errors.noticeNumber}
                error={formik.touched.noticeNumber && !!formik.errors.noticeNumber}
              >
                {formik.errors.noticeNumber}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="processNumberLabel" className={classes.label}>{processNumberLabel}</label>
              <Input
                fullWidth
                id="processNumber"
                color="secondary"
                placeholder="000000"
                {...formik.getFieldProps('processNumber')}
                error={formik.touched.processNumber && !!formik.errors.processNumber}
                onKeyUp={() => formik.setFieldValue('objectText', concatTextDefault)}
              />
              <FormHelperText
                hidden={!formik.touched.processNumber || !formik.errors.processNumber}
                error={formik.touched.processNumber && !!formik.errors.processNumber}
              >
                {formik.errors.processNumber}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="objectLabel" className={classes.labelObject}>{objectLabel}</label>
              <TextField
                id="objectText"
                multiline
                variant="outlined"
                fullWidth
                minRows={3}
                disabled
                {...formik.getFieldProps('objectText')}
                error={formik.touched.objectText && !!formik.errors.objectText}
                onChange={(e) => {
                  const { value } = e.target
                  formik.setFieldValue('objectText', value?.replace('', '')?.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, ''))
                }}
              />
              <FormHelperText
                hidden={!formik.touched.objectText || !formik.errors.objectText}
                error={formik.touched.objectText && !!formik.errors.objectText}
              >
                {formik.errors.objectText}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="complementObjectLabel" className={classes.labelObject}>{complementObjectLabel}</label>
              <TextField
                id="complementObjectText"
                multiline
                variant="outlined"
                fullWidth
                minRows={2}
                disabled={requiredNumber}
                {...formik.getFieldProps('complementObjectText')}
                onKeyUp={() => {
                  formik.setFieldValue('objectText', concatTextDefault)
                }}
                onChange={(e) => {
                  const { value } = e.target
                  formik.setFieldValue('complementObjectText', value?.replace('', '')?.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, ''))
                }}
              />
              <FormHelperText
                hidden={!formik.touched.complementObjectText || !formik.errors.complementObjectText}
                error={formik.touched.complementObjectText && !!formik.errors.complementObjectText}
              >
                {formik.errors.complementObjectText}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="contractValueLabel" className={classes.label}>{contractValueLabel}</label>
              <CurrencyInput
                fullWidth
                id="contractValue"
                color="secondary"
                {...formik.getFieldProps('contractValue')}
                error={
                  formik.touched.contractValue && !!formik.errors.contractValue
                }
              />
              <FormHelperText
                hidden={
                  !formik.touched.contractValue || !formik.errors.contractValue
                }
                error={
                  formik.touched.contractValue && !!formik.errors.contractValue
                }
              >
                {formik.errors.contractValue}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="percentageGuaranteeLabel" className={classes.label}>{percentageGuaranteeLabel}</label>
              <Input
                fullWidth
                color="secondary"
                id="percentageGuarantee"
                {...formik.getFieldProps('percentageGuarantee')}
                error={formik.touched.percentageGuarantee && !!formik.errors.percentageGuarantee}
                helpertext={formik.touched.percentageGuarantee && formik.errors.percentageGuarantee}
                onKeyUp={(e) => {
                  formik.values.guaranteeAmount = handlePercentageGuaranteeAmount(e)
                  setPercentage(formik.values.percentageGuarantee?.replace(',', '.'))
                }}
              />
              <FormHelperText
                hidden={
                  !formik.touched.percentageGuarantee || !formik.errors.percentageGuarantee
                }
                error={
                  formik.touched.percentageGuarantee && !!formik.errors.percentageGuarantee
                }
              >
                {formik.errors.percentageGuarantee}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="guaranteeAmountLabel" className={classes.label}>{guaranteeAmountLabel}</label>
              <CurrencyInput
                fullWidth
                id="guaranteeAmount"
                title={guaranteeAmountLabel}
                color="secondary"
                {...formik.getFieldProps('guaranteeAmount')}
                error={
                  formik.touched.guaranteeAmount && !!formik.errors.guaranteeAmount
                }
                onKeyUp={(e) => {
                  formik.values.guaranteeAmount = handleGuaranteeAmount(e)
                }}
                onBlur={(e) => {
                  formik.values.percentageGuarantee = handleGuaranteeAmount(e)
                  setPercentage(formik.values.percentageGuarantee?.replace(',', '.'))
                }}
              />
              <FormHelperText
                hidden={
                  !formik.touched.guaranteeAmount || !formik.errors.guaranteeAmount
                }
                error={
                  formik.touched.guaranteeAmount && !!formik.errors.guaranteeAmount
                }
              >
                {formik.errors.guaranteeAmount}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="startDateValidityLabel" className={classes.label}>
                {startDateValidityLabel}
              </label>
              <DateInput
                minDate={minDateValidation()}
                id="startDateValidity"
                title={startDateValidityLabel}
                {...formik.getFieldProps('startDateValidity')}
                error={formik.touched.startDateValidity
                  && !!formik.errors.startDateValidity}
                helperText={formik.touched.startDateValidity
                  && formik.errors.startDateValidity}
                onChange={(event) => {
                  const { value } = event.target
                  if (isBlank(value)) {
                    formik.setFieldValue('startDateValidity', '')
                  }
                  formik.setFieldValue('startDateValidity', formatDateApi(value))

                  formik.setFieldValue('deadline', deadline)
                }}
                onBlur={() => formik.setFieldValue('deadline', deadline)}
              />
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="endDateValidityLabel" className={classes.label}>
                {endDateValidityLabel}
              </label>
              <DateInput
                minDate={formatDateApi()}
                id="endDateValidity"
                title={endDateValidityLabel}
                disabled={!formik.values.startDateValidity}
                {...formik.getFieldProps('endDateValidity')}
                error={formik.touched.endDateValidity
                  && !!formik.errors.endDateValidity}
                helperText={formik.touched.endDateValidity
                  && formik.errors.endDateValidity}
                onChange={(event) => {
                  const { value } = event.target
                  if (isBlank(value)) {
                    formik.setFieldValue('endDateValidity', '')
                  }
                  formik.setFieldValue('endDateValidity', formatDateApi(value))

                  formik.setFieldValue('deadline', deadline)

                  setEndDate(formatDateApi(value))
                }}
                onBlur={() => formik.setFieldValue('deadline', deadline)}
              />
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="deadlineLabel" className={classes.label}>{deadlineLabel}</label>
              <Input
                fullWidth
                id="deadline"
                color="secondary"
                disabled={!formik.values.startDateValidity}
                {...formik.getFieldProps('deadline')}
                error={formik.touched.deadline && !!formik.errors.deadline}
                helperText={formik.touched.deadline
                  && formik.errors.deadline}
                onKeyUp={(event) => {
                  const { value } = event.target
                  if (isBlank(value)) {
                    formik.setFieldValue('deadline', '')
                  }
                  formik.setFieldValue('deadline', value)
                  handleChangeDeadline()
                }}
                onBlur={(event) => {
                  const { value } = event.target
                  if (!isBlank(value)) {
                    formik.setFieldValue('endDateValidity', endDate)
                  }
                }}
              />
              <FormHelperText
                hidden={
                  !formik.touched.deadline || !formik.errors.deadline
                }
                error={
                  formik.touched.deadline && !!formik.errors.deadline
                }
              >
                {formik.errors.deadline}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.buttonsStep}>
              <Box>
                <ButtonLight
                  onClick={handlePrevious}
                  title="Voltar"
                >
                  Voltar
                </ButtonLight>
              </Box>
              <Box ml={4}>
                <ButtonGradient
                  onClick={handleNext}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
            <ReactTooltip place="top" type="dark" />
          </Grid>
        </Grid>
      </CollapsePrice>
    </>
  )
})

ObjectProperty.propTypes = {
  quote: PropTypes.any,
  checked: PropTypes.bool,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

ObjectProperty.defaultProps = {
  quote: undefined,
  checked: false,
}

export default ObjectProperty
