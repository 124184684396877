import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { HELP_LINKS } from '../../constants/HelpLinks'
import { ButtonGradient, HelpLink } from '../../shared/components'
import DropdownSelectOne from '../../shared/components/DropdownSelectOne/DropdownSelectOne'

import useProductQuotationClient from '../../clients/ProductClient/useProductQuotationClient'

import { PRODUCT_JUDICIAL_GUARANTEE_APPEAL, QUOTE_URL_EXECUTIVE_REQUEST } from '../../constants'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiCollapse-root': {
      borderRadius: 14,
    },
  },
  header: {
    height: 120,
    padding: 40,
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: theme.spacing(4, 5),
  },
  labelInfo: {
    color: theme.palette.text.tertiary,
  },
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
    },
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  textField: {
    '& .MuiTextField-root': {
      marginTop: 20,
    },
  },
}))

const DEFAULT_QUOTATION = {
  brokerProduct: '',
  brokerModality: '',
}

const APPEAL_TYPE_PUBLIC = 'Public'

const CategoryProduct = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const productQuotationClient = useProductQuotationClient()

  const [products, setProducts] = useState([''])
  const [modalities, setModalities] = useState([''])
  const [selectedProduct, setSelectedProduct] = useState('')
  const [selectedModality, setSelectedModality] = useState('')

  const getTokenUrl = location?.pathname?.split(':')
  const routeSolicitation = getTokenUrl[0]
  const tokenParams = getTokenUrl[1]

  useEffect(() => {
    if (QUOTE_URL_EXECUTIVE_REQUEST === routeSolicitation) {
      setProducts(productQuotationClient().getAutomaticProductsForExecutiveRequest().sort())
    } else {
      setProducts(productQuotationClient().getProducts().sort())
    }
  }, [routeSolicitation, productQuotationClient])

  useEffect(() => {
    if (selectedProduct) {
      setModalities(productQuotationClient().getModalitiesByProduct(selectedProduct).sort())
    }
  }, [productQuotationClient, selectedProduct])

  const modalitiesIsEmpty = () => modalities.filter((m) => m).length === 0

  const handlePriceQuotation = () => {
    let path = QUOTE_URL_EXECUTIVE_REQUEST === routeSolicitation
      ? `${QUOTE_URL_EXECUTIVE_REQUEST}${selectedProduct?.path}/:${tokenParams}`
      : `cotacao/${selectedProduct?.path}`

    if (selectedModality?.path && selectedModality.path !== '') {
      if (QUOTE_URL_EXECUTIVE_REQUEST === routeSolicitation) {
        path = `${QUOTE_URL_EXECUTIVE_REQUEST}${selectedProduct?.path}/${selectedModality.path}/:${tokenParams}`
      } else {
        path += `/${selectedModality.path}`
      }
    }

    if (selectedProduct?.value === PRODUCT_JUDICIAL_GUARANTEE_APPEAL) {
      const typeOtherModality = productQuotationClient().getAllTypeOtherModality().find(
        ({ value }) => value === APPEAL_TYPE_PUBLIC,
      )

      const appealType = typeOtherModality?.modalities?.find(
        ({ id }) => id === selectedModality?.value,
      )

      if (!isEmpty(appealType)) {
        history.push(QUOTE_URL_EXECUTIVE_REQUEST === routeSolicitation
          ? `${QUOTE_URL_EXECUTIVE_REQUEST}${selectedModality?.path}/:${tokenParams}`
          : `cotacao/${selectedModality?.path}`, {
          guaranteeOtherJudicial: {
            modality: appealType?.value,
            modalityType: typeOtherModality?.value,
          },
          appealTypeId: appealType?.id,
        })
        return
      }
    }

    if (selectedProduct?.path === 'fianca-locaticia' && QUOTE_URL_EXECUTIVE_REQUEST !== routeSolicitation) {
      history.push(path, {
        modalityLeaseBail: selectedModality?.value,
      })
      return
    }

    history.push(path)
  }

  const formik = useFormik({
    initialValues: { ...DEFAULT_QUOTATION },
    validationSchema: Yup.object({
      brokerProduct: Yup.string().required(),
      brokerModality: Yup.string().when('brokerProduct', () => (!modalitiesIsEmpty() ? Yup.string().required() : Yup.string())),
    }),
    onSubmit: () => {
      handlePriceQuotation()
    },
  })

  const filterSelectedOptionObject = (e, arrayOptions) => {
    const selectedOptionObject = e.target.value
    if (!selectedOptionObject) {
      return ''
    }

    return arrayOptions.find((option) => option.value === selectedOptionObject) ?? undefined
  }

  const modalityLabel = 'Modalidade'
  const productLabel = 'Produto'

  return (
    <>
      <Box className={classes.root}>
        <Box display="flex" justifyContent="flex-end" mb={3}>
          {routeSolicitation !== QUOTE_URL_EXECUTIVE_REQUEST && (
            <HelpLink text="Como realizar nova solicitação de Apólice" link={HELP_LINKS.createPolicy} minWidth={350} />
          )}
        </Box>

        <Paper elevation={1}>
          <Grid container>
            <Grid
              item
              xs={12}
              className={classes.header}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" color="initial">
                    Dados do Produto
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.labelInfo}>
                    Informe abaixo os dados do produto
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.content}>
              <Grid container spacing={5}>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <label htmlFor="productLabel" className={classes.label}>{productLabel}</label>
                    <DropdownSelectOne
                      id="brokerProduct"
                      options={products}
                      {...formik.getFieldProps('brokerProduct')}
                      error={formik.touched.brokerProduct && !!formik.errors.brokerProduct}
                      onClick={(e) => {
                        const optionSelected = filterSelectedOptionObject(e, products)

                        setSelectedProduct(optionSelected)
                        formik.values.brokerProduct = optionSelected?.path
                      }}
                    />
                    <FormHelperText
                      hidden={!formik.touched.brokerProduct || !formik.errors.brokerProduct}
                      error={formik.touched.brokerProduct && !!formik.errors.brokerProduct}
                    >
                      {formik.errors.brokerProduct}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12} hidden={modalitiesIsEmpty()}>
                  <FormControl fullWidth>
                    <label htmlFor="modalityLabel" className={classes.label}>{modalityLabel}</label>
                    <DropdownSelectOne
                      id="brokerModality"
                      options={modalities}
                      placeholder="Produto sem modalidade"
                      {...formik.getFieldProps('brokerModality')}
                      disabled={modalitiesIsEmpty()}
                      error={
                        !modalitiesIsEmpty()
                        && formik.touched.brokerModality
                        && !!formik.errors.brokerModality
                      }
                      onClick={(e) => {
                        const optionSelected = filterSelectedOptionObject(e, modalities)

                        setSelectedModality(optionSelected)
                        formik.values.brokerModality = optionSelected?.path
                      }}
                    />
                    <FormHelperText
                      hidden={
                        modalitiesIsEmpty()
                        || !formik.touched.brokerModality
                        || !formik.errors.brokerModality
                      }
                      error={formik.touched.brokerModality && !!formik.errors.brokerModality}
                    >
                      {formik.errors.brokerModality}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box mt={5} className={classes.buttonsStep}>
                    <Box>
                      <ButtonGradient
                        onClick={formik.submitForm}
                        title="Continuar"
                      >
                        Continuar
                      </ButtonGradient>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  )
}

export default CategoryProduct
