import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'

import * as Yup from 'yup'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import ReactTooltip from 'react-tooltip'
import { isEqual, isEmpty } from 'lodash'
import { isCnpj } from 'validator-brazil'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'

import makeStyles from '@material-ui/core/styles/makeStyles'

import useUtils from '../../../../../shared/hooks/useUtils'
import { usePrice } from '../../../../../shared/contexts/PriceContext'

import {
  useLoader,
  DateInput,
  ButtonLight,
  ButtonGradient,
  CPFOrCNPJInput,
  ProcessNumberInput,
} from '../../../../../shared/components'
import CollapsePrice from '../../../../../shared/components/CollapsePrice/CollapsePrice'
import CurrencyInput from '../../../../../shared/components/CurrencyInput/CurrencyInput'
import DropdownSelectOne from '../../../../../shared/components/DropdownSelectOne/DropdownSelectOne'

import { JudicialGuaranteeeCivelMessages, GlobalMessages } from '../../../../../shared/messages'

import useUserClient from '../../../../../clients/UserClient/useUserClient'
import useStickClient from '../../../../../clients/StickClient/useStickClient'
import useReceitaWSClient from '../../../../../clients/ReceitaWSClient/useReceitaWSClient'
import useCourtRegionalLaborClient from '../../../../../clients/CourtRegionalLaborClient'

import ObjectPropertyData from '../../../../../models/JudicialGuaranteeCivelModels/ObjectPropertyData'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  labelObject: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  info: {
    verticalAlign: 'bottom',
  },
  labelMessage: {
    color: 'red',
    fontSize: 14,
    margin: 2,
  },
  noOptionsLabel: {
    fontSize: 14,
    color: '#c93952',
    fontWeight: 'bold',
  },
}))

const ObjectProperty = forwardRef((props, ref) => {
  const classes = useStyles()
  const {
    date,
    isBlank,
    formatDateApi,
  } = useUtils()

  const {
    quote,
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props

  const {
    onNext,
    actionNext,
    namePolicyHolder,
    setFormikChanged,
    judicialGuaranteeCivel,
    setJudicialGuaranteeCivel,
  } = usePrice()

  const { showLoader, hideLoader } = useLoader()
  const receitaWSClient = useReceitaWSClient()
  const userClient = useUserClient()
  const stickClient = useStickClient()
  const courtRegionalLaborClient = useCourtRegionalLaborClient()

  const [sticks, setSticks] = useState([{}])
  const [courtSection, setCourtSection] = useState({})
  const [courtRegional, setCourtRegional] = useState({})
  const [stickOptions, setStickOptions] = useState([''])
  const [stickDisabled, setStickDisabled] = useState(true)
  const [claimantDisabled, setClaimantDisabled] = useState(true)
  const [courtRegionalLabors, setCourtRegionalLabors] = useState([''])

  const mountDataCourt = (dataForm) => ({
    ...dataForm,
    courtSection: { ...courtSection },
    courtRegionalLabor: { ...courtRegional },
  })

  const minStartDateValidity = useMemo(() => {
    const startData = date()
    return !isEmpty(startData)
      ? date(startData).subtract(1, 'days')
      : undefined
  }, [date])

  const initialValuesForm = useMemo(() => (Object.assign(
    ObjectPropertyData({
      ...quote?.ObjectProperty,
      CourtSection: quote?.ObjectProperty?.CourtSection?.descricao
        ?? undefined,
      CourtRegionalLabor: quote?.ObjectProperty?.CourtRegionalLabor?.descricao
        ?? undefined,
    } ?? {}),
  )), [quote?.ObjectProperty])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValuesForm,
      name: quote?.Claimant?.Name ?? '',
      document: quote?.Claimant?.Document ?? '',
    },
    validationSchema: Yup.object({
      document: Yup.number().required(),
      name: !claimantDisabled ? Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Apenas letras são permitidas').required('Campo obrigatório') : Yup.string().required(),
      deadline: Yup.string().when((value) => (
        !value
          ? Yup.string().required()
          : Yup.string()
      )),
      processNumber: Yup.string().max(50, GlobalMessages.LIMIT_MAX_CHARACTER(50)).required(),
      courtSection: Yup.string().required(),
      courtRegionalLabor: Yup.string().required(),
      proposedLegalAction: Yup.string().required(),
      objectText: Yup.string().required(),
      startDateValidity: Yup.date().when(() => (
        minStartDateValidity && Yup.date()
          .min(minStartDateValidity, 'O início de vigência deverá ser maior ou igual a data atual.')
          .required()
      )),
      endDateValidity: Yup.date().when('startDateValidity', (startDateValidity) => startDateValidity
        && Yup.date().min(startDateValidity, GlobalMessages.DATA_FINAL_MAIOR_INICIAL).required()),
      guaranteeValue: Yup.number()
        .test('guaranteeValue', GlobalMessages.CAMPO_MAIOR_ZERO('Valor da Garantia'), (value) => (value && Number(value) !== 0))
        .test('guaranteeValue', GlobalMessages.LIMIT_MAX_CHARACTER('15'), (value) => (value && Number(value) < 9999999999999.99))
        .required(),
      complementObjectText: Yup.string().max(1500, GlobalMessages.LIMIT_MAX_CHARACTER(1500))
        .required(),
    }),
    onSubmit: (data) => {
      const values = {
        ...judicialGuaranteeCivel,
        ...{
          objectProperty: {
            ...mountDataCourt({
              deadline: data.deadline,
              objectText: data.objectText,
              processNumber: data.processNumber,
              guaranteeValue: data.guaranteeValue,
              proposedLegalAction: data.proposedLegalAction,
              endDateValidity: data.endDateValidity,
              startDateValidity: data.startDateValidity,
              complementObjectText: data.complementObjectText,
            }),
          },
        },
        ...{
          claimant: {
            name: data.name,
            document: data.document,
          },
        },
      }
      setJudicialGuaranteeCivel(values)
      if (actionNext) {
        setFormikChanged(!isEqual(initialValuesForm, data))
        onNext(values)
      }
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  const { setFieldValue } = formik

  const [DEADLINE_DEFAULT] = useState(2)
  const [DEADLINE_YEARS] = useState([2, 3, 4, 5])
  const [deadline, setDeadline] = useState('')
  const [concatTextDefault, setConcatTextDefault] = useState('')

  useEffect(() => {
    const dateEnd = date().add(DEADLINE_DEFAULT, 'y')

    setFieldValue('endDateValidity', formatDateApi(dateEnd))
    setFieldValue('deadline', DEADLINE_DEFAULT)
    setFieldValue('startDateValidity', formatDateApi(date()))
  }, [setFieldValue, DEADLINE_DEFAULT, formatDateApi, date])

  useEffect(() => {
    const insuredStick = formik.getFieldProps('courtSection')

    const objectProcessNumber = formik.getFieldProps('processNumber')
    const objectName = formik.getFieldProps('name')
    const objectDocument = formik.getFieldProps('document')
    const objectProposedLegalAction = formik.getFieldProps('proposedLegalAction')
    const complementObjectText = formik.getFieldProps('complementObjectText')
    const insuredCourtRegionalLabor = formik.getFieldProps('courtRegionalLabor')

    if (objectProcessNumber?.value
      || insuredStick?.value
      || insuredCourtRegionalLabor?.value
      || objectProposedLegalAction?.value
    ) {
      const newConcatTextDefault = JudicialGuaranteeeCivelMessages.JUDICIAL_CIVEL_COMPLEMENTO({
        processNumber: !isEmpty(objectProcessNumber?.value)
          ? objectProcessNumber?.value : undefined,
        proposedLegalAction: !isEmpty(objectProposedLegalAction?.value)
          ? objectProposedLegalAction?.value : undefined,
        courtSection: !isEmpty(insuredStick?.value)
          ? insuredStick?.value : undefined,
        name: !isEmpty(objectName?.value)
          ? objectName?.value : undefined,
        document: !isEmpty(objectDocument?.value)
          ? objectDocument?.value : undefined,
        complementObjectText: !isEmpty(complementObjectText?.value)
          ? complementObjectText?.value : undefined,
      })

      if (newConcatTextDefault !== concatTextDefault) {
        setConcatTextDefault(newConcatTextDefault)
        formik.setFieldValue('objectText', newConcatTextDefault)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik,
    // namePolicyHolder,
    // concatTextDefault,
    setConcatTextDefault,
  ])

  useEffect(() => {
    const insuredStick = formik.getFieldProps('courtSection')

    const objectProcessNumber = formik.getFieldProps('processNumber')
    const objectName = formik.getFieldProps('name')
    const objectDocument = formik.getFieldProps('document')
    const objectProposedLegalAction = formik.getFieldProps('proposedLegalAction')
    const complementObjectText = formik.getFieldProps('complementObjectText')
    const insuredCourtRegionalLabor = formik.getFieldProps('courtRegionalLabor')

    if (objectProcessNumber?.value
      || insuredStick?.value
      || insuredCourtRegionalLabor?.value
      || objectProposedLegalAction?.value
    ) {
      setConcatTextDefault(JudicialGuaranteeeCivelMessages.JUDICIAL_CIVEL_COMPLEMENTO({
        processNumber: !isEmpty(objectProcessNumber?.value)
          ? objectProcessNumber?.value : undefined,
        proposedLegalAction: !isEmpty(objectProposedLegalAction?.value)
          ? objectProposedLegalAction?.value : undefined,
        courtSection: !isEmpty(insuredStick?.value)
          ? insuredStick?.value : undefined,
        name: !isEmpty(objectName?.value)
          ? objectName?.value : undefined,
        document: !isEmpty(objectDocument?.value)
          ? objectDocument?.value : undefined,
        complementObjectText: !isEmpty(complementObjectText?.value)
          ? complementObjectText?.value : undefined,
      }))
    }
  }, [
    formik,
    namePolicyHolder,
    concatTextDefault,
    setConcatTextDefault,
  ])

  useEffect(() => {
    const endDateValidity = formik.getFieldProps('endDateValidity')
    const startDateValidity = formik.getFieldProps('startDateValidity')

    setDeadline('')
    if (startDateValidity?.value && endDateValidity?.value) {
      const deadlineCount = date(endDateValidity?.value)
        .diff(date(startDateValidity?.value), 'years')
      if (deadlineCount < 2 || deadlineCount > 5) {
        setDeadline('')
        return
      }
      setDeadline(deadlineCount)
    }
  }, [formik, date, setDeadline])

  const formatCourtRegionalLabors = useCallback(
    (courtRegionalLaborsResponse) => courtRegionalLaborsResponse.map(
      ({ descricao, id }) => ({ id, text: descricao }),
    ), [],
  )

  useEffect(() => {
    courtRegionalLaborClient().getAll('?tipotribunal=1').then((response) => {
      setCourtRegionalLabors(formatCourtRegionalLabors(response)
        .sort((a, b) => (a.id - b.id)))
    })
  }, [
    formatCourtRegionalLabors,
    courtRegionalLaborClient,
  ])

  useEffect(() => {
    if (!isEmpty(courtRegional) && courtRegional.id) {
      stickClient().getById(courtRegional.id).then((res) => {
        const sticksResponse = res.data.sort((a, b) => a.descricao.localeCompare(b.descricao))

        setSticks(sticksResponse)
        setStickOptions(sticksResponse.map(({ descricao }) => descricao))
      })
    }
  }, [stickClient, courtRegional])

  const handleCourtRegionalLabor = (e) => {
    const { value } = e.target

    if (value) {
      const courtRegLaborFound = courtRegionalLabors
        .find((regCourtItem) => regCourtItem.text === value)

      setCourtRegional({
        descricao: courtRegLaborFound.text,
        id: courtRegLaborFound.id,
      })
      formik.values.courtRegionalLabor = courtRegLaborFound?.text
      formik.setFieldValue('objectText', concatTextDefault)
    }
  }

  const handleCourtSection = useCallback((value) => {
    const courtSectionFound = sticks.find((stickItem) => stickItem.descricao === value)

    if (!isEmpty(courtSectionFound)) {
      setCourtSection({
        id: courtSectionFound.id,
        descricao: courtSectionFound.descricao,
      })
    }
  }, [sticks, setCourtSection])

  const handleInfoProcessNumber = (ev) => {
    const { value } = ev.target
    courtRegionalLabors.map((item) => item.id)

    setStickDisabled(true)

    if (value === '') {
      setCourtSection({})
      setCourtRegional({})
      formik.setFieldValue('courtSection', '')
      formik.setFieldValue('courtRegionalLabor', '')
    }

    const processNumberValue = value?.split('.', 4)

    const filterCourt = courtRegionalLabors
      .filter((item) => item?.id === Number(processNumberValue[3]))
    if (processNumberValue.length > 3
      && filterCourt.length > 0) {
      handleCourtRegionalLabor({
        target: {
          value: filterCourt[0]?.text,
        },
      })
      setStickDisabled(false)
    }

    if (filterCourt.length === 0) {
      handleCourtRegionalLabor({
        target: { value: '' },
      })
    }
  }

  const handleInfoCnpj = (evento) => {
    const { value } = evento.target
    if (evento?.target?.value === '') {
      formik.setFieldValue('name', '')
    }

    const cnpj = value?.replace(/[^0-9]/g, '')

    if (isCnpj(cnpj)) {
      showLoader()
      receitaWSClient().getInfoByCnpj(cnpj).then(async (response) => {
        if (response) {
          formik.setFieldValue('name', response.nome)
          formik.setFieldValue('objectText', concatTextDefault)
          hideLoader()
          setClaimantDisabled(true)
        }
      }).catch(() => {
        setClaimantDisabled(false)
        formik.setFieldValue('name', '')
        formik.setFieldValue('objectText', concatTextDefault)
        hideLoader()
      })
    }
    if (cnpj && !isCnpj(cnpj)) {
      showLoader()
      setClaimantDisabled(false)
      userClient().getClientInsuredObjectProperty(cnpj).then(async (response) => {
        if (response) {
          formik.setFieldValue('name', response?.data?.nome)
          formik.setFieldValue('objectText', concatTextDefault)
          hideLoader()
        }
      }).catch(() => {
        hideLoader()
        formik.setFieldValue('name', '')
        formik.setFieldValue('objectText', concatTextDefault)
      })
    }
  }

  useEffect(() => {
    if (quote?.ObjectProperty) {
      const fragmentData = quote?.ObjectProperty

      setCourtSection(fragmentData?.CourtSection)
      setCourtRegional(fragmentData?.CourtRegionalLabor)
    }
    if (formik.values.processNumber) {
      setStickDisabled(false)
    }
  }, [quote?.ObjectProperty, formik.values.processNumber])

  const stickLabel = 'Vara Cível'
  const deadlineLabel = 'Prazo (Anos)'
  const nameLabel = 'Reclamante'
  const cpfCnpjLabel = 'CPF/CNPJ  do Reclamante'
  const guaranteeValueLabel = 'Valor da Garantia'
  const endDateValidityLabel = 'Fim de Vigência'
  const processNumberLabel = 'Número do Processo'
  const proposedLegalActionLabel = 'Ação Proposta'
  const objectLabel = 'Obrigação Garantia (Objeto)'
  const startDateValidityLabel = 'Início de Vigência'
  const placeholderProcessNumber = '0000000-00.0000.0.00.0000'
  const complementObjectLabel = 'Complemento Obrigação Garantia (Objeto)'
  const courtRegionalLaborLabel = 'Tribunal Cível'

  const placeholderCPFCNPJ = '000.000.000-00 / 00.000.000/0000-00'

  const noOptionsMessage = (
    <Typography className={classes.noOptionsLabel}>
      {GlobalMessages.NO_OPTIONS_MESSAGE}
    </Typography>
  )

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={isEmpty(quote?.ObjectProperty) && !formik.isSubmitting}
        title="Objeto Segurado"
        subtitle="Informe abaixo os dados do objeto segurado"
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="cpfCnpjLabel" className={classes.label}>{cpfCnpjLabel}</label>
                  <CPFOrCNPJInput
                    fullWidth
                    id="document"
                    color="secondary"
                    placeholder={placeholderCPFCNPJ}
                    {...formik.getFieldProps('document')}
                    error={formik.touched.document && !!formik.errors.document}
                    onBlur={handleInfoCnpj}
                  />
                  <FormHelperText
                    hidden={!formik.touched.document || !formik.errors.document}
                    error={formik.touched.document && !!formik.errors.document}
                  >
                    {formik.errors.document}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="nameLabel" className={classes.label}>{nameLabel}</label>
                  <Input
                    fullWidth
                    id="name"
                    color="secondary"
                    placeholder="Nome do Reclamante"
                    disabled={claimantDisabled}
                    {...formik.getFieldProps('name')}
                    error={formik.touched.name && !!formik.errors.name}
                    onKeyUp={() => formik.setFieldValue('objectText', concatTextDefault)}
                  />
                  <FormHelperText
                    hidden={!formik.touched.name || !formik.errors.name}
                    error={formik.touched.name && !!formik.errors.name}
                  >
                    {formik.errors.name}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="processNumberLabel" className={classes.label}>{processNumberLabel}</label>
              <ProcessNumberInput
                fullWidth
                id="processNumber"
                color="secondary"
                placeholder={placeholderProcessNumber}
                {...formik.getFieldProps('processNumber')}
                error={formik.touched.processNumber && !!formik.errors.processNumber}
                onKeyUp={handleInfoProcessNumber}
                onBlur={() => formik.setFieldValue('objectText', concatTextDefault)}
              />
              <FormHelperText
                hidden={!formik.touched.processNumber || !formik.errors.processNumber}
                error={formik.touched.processNumber && !!formik.errors.processNumber}
              >
                {formik.errors.processNumber}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={5} sm={7} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="courtRegionalLaborLabel" className={classes.label}>{courtRegionalLaborLabel}</label>
              <DropdownSelectOne
                id="courtRegionalLabor"
                options={courtRegionalLabors}
                placeholder={courtRegionalLaborLabel}
                {...formik.getFieldProps('courtRegionalLabor')}
                error={formik.touched.courtRegionalLabor && !!formik.errors.courtRegionalLabor}
                onChange={(e) => {
                  formik.values.courtSection = ''
                  handleCourtRegionalLabor(e)
                }}
              />
              <FormHelperText
                hidden={!formik.touched.courtRegionalLabor || !formik.errors.courtRegionalLabor}
                error={formik.touched.courtRegionalLabor && !!formik.errors.courtRegionalLabor}
              >
                {formik.errors.courtRegionalLabor}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={4} sm={5} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="stickLabel" className={classes.label}>{stickLabel}</label>
              <Autocomplete
                className={classes.textField}
                id="courtSection"
                options={stickOptions}
                disabled={stickDisabled}
                noOptionsText={noOptionsMessage}
                getOptionLabel={(option) => option ?? ''}
                onInputChange={(e, value) => {
                  formik.values.courtSection = value ?? ''
                  handleCourtSection(value)
                }}
                onKeyUp={handleCourtSection}
                getOptionSelected={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onFocus={(event) => event.target.setAttribute('autocomplete', 'none')}
                    placeholder="Procurar"
                    margin="normal"
                    error={formik.touched.courtSection && !!formik.errors.courtSection}
                    fullWidth
                    onBlur={() => formik.setFieldValue('objectText', concatTextDefault)}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {...formik.getFieldProps('courtSection')}
              />
              <FormHelperText
                hidden={!formik.touched.courtSection || !formik.errors.courtSection}
                error={formik.touched.courtSection && !!formik.errors.courtSection}
              >
                {formik.errors.courtSection}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="proposedLegalActionLabel" className={classes.label}>
                {proposedLegalActionLabel}
              </label>
              <Input
                fullWidth
                id="proposedLegalAction"
                style={{ marginTop: 11 }}
                color="secondary"
                placeholder="XXXXXX"
                {...formik.getFieldProps('proposedLegalAction')}
                error={formik.touched.proposedLegalAction && !!formik.errors.proposedLegalAction}
                onKeyUp={() => formik.setFieldValue('objectText', concatTextDefault)}
              />
              <FormHelperText
                hidden={!formik.touched.proposedLegalAction || !formik.errors.proposedLegalAction}
                error={formik.touched.proposedLegalAction && !!formik.errors.proposedLegalAction}
              >
                {formik.errors.proposedLegalAction}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="objectLabel" className={classes.labelObject}>{objectLabel}</label>
              <TextField
                id="objectText"
                multiline
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 1000 }}
                minRows={3}
                disabled
                {...formik.getFieldProps('objectText')}
                error={formik.touched.objectText && !!formik.errors.objectText}
                onChange={(e) => {
                  const { value } = e.target
                  formik.setFieldValue('objectText', value?.replace('', '')?.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, ''))
                }}
              />
              <FormHelperText
                hidden={!formik.touched.objectText || !formik.errors.objectText}
                error={formik.touched.objectText && !!formik.errors.objectText}
              >
                {formik.errors.objectText}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="complementObjectLabel" className={classes.labelObject}>{complementObjectLabel}</label>
              <TextField
                id="complementObjectText"
                multiline
                variant="outlined"
                fullWidth
                minRows={2}
                {...formik.getFieldProps('complementObjectText')}
                onKeyUp={() => {
                  formik.setFieldValue('objectText', concatTextDefault)
                }}
                onChange={(e) => {
                  const { value } = e.target
                  formik.setFieldValue('complementObjectText', value?.replace('', '')?.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, ''))
                }}
              />
              <FormHelperText
                hidden={!formik.touched.complementObjectText || !formik.errors.complementObjectText}
                error={formik.touched.complementObjectText && !!formik.errors.complementObjectText}
              >
                {formik.errors.complementObjectText}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="guaranteeValueLabel" className={classes.label}>{guaranteeValueLabel}</label>
              <CurrencyInput
                fullWidth
                id="guaranteeValue"
                color="secondary"
                {...formik.getFieldProps('guaranteeValue')}
                error={
                  formik.touched.guaranteeValue && !!formik.errors.guaranteeValue
                }
              />
              <FormHelperText
                hidden={
                  !formik.touched.guaranteeValue || !formik.errors.guaranteeValue
                }
                error={
                  formik.touched.guaranteeValue && !!formik.errors.guaranteeValue
                }
              >
                {formik.errors.guaranteeValue}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item sm={4} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="startDateValidityLabel" className={classes.label}>
                    {startDateValidityLabel}
                  </label>
                  <DateInput
                    minDate={moment()}
                    id="startDateValidity"
                    title={startDateValidityLabel}
                    {...formik.getFieldProps('startDateValidity')}
                    error={formik.touched.startDateValidity && !!formik.errors.startDateValidity}
                    helperText={formik.touched.startDateValidity && formik.errors.startDateValidity}
                    onChange={(event) => {
                      const { value } = event.target
                      if (isBlank(value)) {
                        formik.setFieldValue('startDateValidity', '')
                      }
                      formik.setFieldValue('startDateValidity', formatDateApi(value))

                      formik.setFieldValue('deadline', deadline)
                    }}
                    onBlur={() => formik.setFieldValue('deadline', deadline)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="deadlineLabel" className={classes.label}>{deadlineLabel}</label>
                  <DropdownSelectOne
                    id="deadline"
                    options={DEADLINE_YEARS}
                    placeholder="Selecione"
                    {...formik.getFieldProps('deadline')}
                    error={formik.touched.deadline && !!formik.errors.deadline}
                    onChange={(e) => {
                      const { value } = e.target
                      formik.setFieldValue('deadline', value)

                      const newEndDate = formatDateApi(date(formik.values.startDateValidity).add(value, 'years'))
                      formik.setFieldValue('endDateValidity', newEndDate)
                    }}
                  />
                  <FormHelperText
                    hidden={!formik.touched.deadline || !formik.errors.deadline}
                    error={formik.touched.deadline && !!formik.errors.deadline}
                  >
                    {formik.errors.deadline}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="endDateValidityLabel" className={classes.label}>
                    {endDateValidityLabel}
                  </label>
                  <DateInput
                    id="endDateValidity"
                    title={endDateValidityLabel}
                    disabled
                    {...formik.getFieldProps('endDateValidity')}
                    error={formik.touched.endDateValidity && !!formik.errors.endDateValidity}
                    helperText={formik.touched.endDateValidity && formik.errors.endDateValidity}
                    onChange={(event) => {
                      const { value } = event.target
                      if (isBlank(value)) {
                        formik.setFieldValue('endDateValidity', '')
                      }
                      formik.setFieldValue('endDateValidity', formatDateApi(value))

                      formik.setFieldValue('deadline', deadline)
                    }}
                    onBlur={() => formik.setFieldValue('deadline', deadline)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.buttonsStep}>
              <Box>
                <ButtonLight
                  onClick={handlePrevious}
                  title="Voltar"
                >
                  Voltar
                </ButtonLight>
              </Box>
              <Box ml={4}>
                <ButtonGradient
                  onClick={handleNext}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
            <ReactTooltip place="top" type="dark" />
          </Grid>
        </Grid>
      </CollapsePrice>
    </>
  )
})

ObjectProperty.propTypes = {
  quote: PropTypes.any,
  checked: PropTypes.bool,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

ObjectProperty.defaultProps = {
  quote: undefined,
  checked: false,
}

export default ObjectProperty
