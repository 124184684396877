import { React } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography,
  Icon,
} from '@material-ui/core'
import useStyles from './useStyles'

const BadgeStatus = (props) => {
  const classes = useStyles()
  const {
    label,
    icon,
    color,
  } = props

  return (
    <Box className={classes.container}>
      <Icon
        style={{ color }}
        className={classes.Icon}
      >
        {icon}
      </Icon>
      <Typography className={classes.label}>
        {label}
      </Typography>
    </Box>
  )
}

BadgeStatus.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
}

export default BadgeStatus
