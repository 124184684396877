import { useCallback } from 'react'

import {
  JUDICIAL_APPEAL_EMBARGOES,
  JUDICIAL_APPEAL_INTERLOCUTORY,
  JUDICIAL_APPEAL_MAGAZINE_RESOURCE,
  JUDICIAL_APPEAL_ORDINARY_RESOURCE,
  JUDICIAL_APPEAL_TERMINATION_ACTION,
  JUDICIAL_APPEAL_EXTRAORDINARY_RESOURCE,
  GUARANTEE_PERFORMANCE_PRIVATE,
  GUARANTEE_PERFORMANCE_PUBLIC,
  PRODUCT_ENGINEERING_RISK,
  PRODUCT_GUARANTEE_MODALITY,
  PRODUCT_JUDICIAL_GUARANTEE_APPEAL,
} from '../../constants'

import {
  ENGINEERING_RISK_CONSTRUCTION,
} from '../../constants/EngineeringRiskConstants'

import {
  GUARANTEE_CUSTOMS_PUBLIC,
  GUARANTEE_JUDICIAL_CIVIL_PUBLIC,
  GUARANTEE_JUDICIAL_LABOR_PUBLIC,
  GUARANTEE_COMPLETION_BOND_PUBLIC,
  GUARANTEE_WORK_COMPLETION_PUBLIC,
  GUARANTEE_ADVANCE_PAYMENTS_PUBLIC,
  GUARANTEE_PAYMENT_WITHHOLDING_PUBLIC,
  GUARANTEE_ENERGY_PURCHASE_SALE_PUBLIC,
  GUARANTEE_DEALERSHIP_PERFORMER_PUBLIC,
  GUARANTEE_CORRECTIVE_MAINTENANCE_PUBLIC,
  GUARANTEE_JUDICIAL_TAX_ENFORCEMENT_PUBLIC,
  GUARANTEE_PROVISION_SERVICES_ANEEL_PUBLIC,
  GUARANTEE_ADMINISTRATIVE_TAX_CREDITS_PUBLIC,
  GUARANTEE_ADMINISTRATIVE_TAX_PORTION_PUBLIC,
  GUARANTEE_PAYMENTS_PRIVATE,
  GUARANTEE_FINANCIAL_FINEP_PRIVATE,
  GUARANTEE_ADVANCE_PAYMENTS_PRIVATE,
  GUARANTEE_REAL_ESTATE_OFFICE_PRIVATE,
  GUARANTEE_PAYMENT_WITHHOLDING_PRIVATE,
  GUARANTEE_ENERGY_PURCHASE_SALE_PRIVATE,
  GUARANTEE_CORRECTIVE_MAINTENANCE_PRIVATE,
} from '../../constants/OtherGuaranteeConstants'

const PRODUCT_JUDICIAL_GUARANTEE_APPEAL_NAME = 'Judicial Depósito Recursal'

const APPEALS = [
  { id: JUDICIAL_APPEAL_INTERLOCUTORY, value: 'AggravationInstrument', text: 'Agravo de instrumento' },
  { id: JUDICIAL_APPEAL_EMBARGOES, value: 'Embargo', text: 'Embargos' },
  { id: JUDICIAL_APPEAL_MAGAZINE_RESOURCE, value: 'RevisionAppeal', text: 'Recurso de revista' },
  { id: JUDICIAL_APPEAL_TERMINATION_ACTION, value: 'TerminationActionAppeal', text: 'Recurso em ação rescisória' },
  { id: JUDICIAL_APPEAL_EXTRAORDINARY_RESOURCE, value: 'ExtraordinaryAppeal', text: 'Recurso extraordinário' },
  { id: JUDICIAL_APPEAL_ORDINARY_RESOURCE, value: 'OrdinaryAppeal', text: 'Recurso ordinário' },
]

const useProductQuotationClient = () => {
  const getProducts = useCallback(() => [
    {
      value: 1,
      text: 'Fiança Locatícia',
      path: 'fianca-locaticia',
      modalities: [
        { value: 1, text: 'Fiança locatícia - PF' },
        { value: 2, text: 'Fiança locatícia - PJ' },
      ],
    },
    {
      value: PRODUCT_JUDICIAL_GUARANTEE_APPEAL,
      text: 'Garantia Judicial',
      modalities: [
        { value: GUARANTEE_JUDICIAL_CIVIL_PUBLIC, path: 'judicial-civel', text: 'Judicial Cível' },
        { value: PRODUCT_JUDICIAL_GUARANTEE_APPEAL, path: 'judicial-deposito-recursal', text: PRODUCT_JUDICIAL_GUARANTEE_APPEAL_NAME },
        { value: GUARANTEE_JUDICIAL_TAX_ENFORCEMENT_PUBLIC, path: 'garantia', text: 'Judicial Execução Fiscal' },
        { value: GUARANTEE_JUDICIAL_LABOR_PUBLIC, path: 'judicial-trabalhista', text: 'Judicial Trabalhista' },
        { value: GUARANTEE_ADMINISTRATIVE_TAX_PORTION_PUBLIC, path: 'garantia', text: 'Parcelamento Administrativo Fiscal' },
      ],
      subModalities: [PRODUCT_JUDICIAL_GUARANTEE_APPEAL_NAME, 'Judicial Depósito Recursal Substituição'],
      appeals: APPEALS,
    },
    {
      value: PRODUCT_ENGINEERING_RISK,
      path: 'risco-engenharia',
      text: 'Risco de Engenharia',
      modalitiesProposal: [
        { id: ENGINEERING_RISK_CONSTRUCTION, text: 'Obras Civis em Construção, Instalação e Montagem' },
      ],
    },
    {
      value: PRODUCT_GUARANTEE_MODALITY,
      path: 'garantia',
      text: 'Seguro Garantia',
      modalities: [
        { value: 1, path: 'licitante', text: 'Licitante (BID)' },
        { value: 2, path: 'performance', text: 'Performance (Construção, Fornecimento ou Prestação de Serviços)' },
        { value: 3, path: '', text: 'Outros' },
      ],
      modalitiesPerformance: [
        { id: GUARANTEE_PERFORMANCE_PRIVATE, text: 'Construção, Fornecimento ou Prestação de serviço – Privada' },
        { id: GUARANTEE_PERFORMANCE_PUBLIC, text: 'Construção, Fornecimento ou Prestação de serviço – Pública' },
      ],
      typeOtherModalities: [
        {
          text: 'Pública',
          value: 'Public',
          modalities: [
            {
              value: 'AdvancePaymentsPublic',
              text: 'Adiantamento de Pagamentos',
              id: GUARANTEE_ADVANCE_PAYMENTS_PUBLIC,
            },
            {
              value: 'AdministrativeTaxCreditsPublic',
              id: GUARANTEE_ADMINISTRATIVE_TAX_CREDITS_PUBLIC,
              text: 'Administrativo de Créditos Tributários',
            },
            {
              value: 'CustomsPublic',
              id: GUARANTEE_CUSTOMS_PUBLIC,
              text: 'Aduaneiro',
            },
            /* {
              value: 'PerformingNatPetroleumAgencyPublic',
              id: GUARANTEE_PERFORMING_NAT_PETROLEUM_AGENCY_PUBLIC,
              text: 'Agência Nacional de Petróleo Executante',
            },
            {
              value: 'BiddingNatPetroleumAgencyPublic',
              id: GUARANTEE_BIDDING_NAT_PETROLEUM_AGENCY_PUBLIC,
              text: 'Agência Nacional de Petróleo Licitante',
            }, */
            {
              value: 'CompletionBondPublic',
              id: GUARANTEE_COMPLETION_BOND_PUBLIC,
              text: 'Completion Bond',
            },
            /* {
              value: 'ProvisionServicesAnatelPublic',
              id: GUARANTEE_PROVISION_SERVICES_ANATEL_PUBLIC,
              text: 'Construção, Fornecimento ou Prestação de Serviços - ANATEL',
            }, */
            {
              value: 'ProvisionServicesAneelPublic',
              id: GUARANTEE_PROVISION_SERVICES_ANEEL_PUBLIC,
              text: 'Garantia de Registro - Estudos - ANEEL',
            },
            {
              value: 'DealershipPerformerPublic',
              id: GUARANTEE_DEALERSHIP_PERFORMER_PUBLIC,
              text: 'Executante Concessionário',
            },
            {
              value: 'EnergyPurchaseSalePublic',
              id: GUARANTEE_ENERGY_PURCHASE_SALE_PUBLIC,
              text: 'Garantia de Compra e Venda de Energia',
            },
            {
              value: 'WorkCompletionPublic',
              id: GUARANTEE_WORK_COMPLETION_PUBLIC,
              text: 'Término de Obras',
            },
            /* {
              value: 'BidderCCEEPublic',
              id: GUARANTEE_BIDDER_CCEE_PUBLIC,
              text: 'Licitante',
            }, */
            {
              value: 'JudicialCivilPublic',
              id: GUARANTEE_JUDICIAL_CIVIL_PUBLIC,
              text: 'Judicial - Cível',
            },
            {
              value: 'JudicialLaborPublic',
              id: GUARANTEE_JUDICIAL_LABOR_PUBLIC,
              text: 'Judicial - Trabalhista',
            },
            {
              value: 'JudicialTaxEnforcementPublic',
              id: GUARANTEE_JUDICIAL_TAX_ENFORCEMENT_PUBLIC,
              text: 'Judicial Execução Fiscal',
            },
            {
              value: 'CorrectiveMaintenancePublic',
              id: GUARANTEE_CORRECTIVE_MAINTENANCE_PUBLIC,
              text: 'Manutenção Corretiva',
            },
            {
              value: 'AdministrativeTaxPortionPublicNew',
              id: GUARANTEE_ADMINISTRATIVE_TAX_PORTION_PUBLIC,
              text: 'Parcelamento Administrativo Fiscal',
            },
            {
              value: 'PaymentWithholdingPublic',
              id: GUARANTEE_PAYMENT_WITHHOLDING_PUBLIC,
              text: 'Retenção de Pagamentos',
            },
            /* {
              value: 'CompletionWorksBancoBrasilPublic',
              id: GUARANTEE_COMPLETION_WORKS_BANCO_BRASIL_PUBLIC,
              text: 'Término de Obras - Banco do Brasil',
            },
            {
              value: 'CompletionWorksCAVAPublic',
              id: GUARANTEE_COMPLETION_WORKS_CAVA_PUBLIC,
              text: 'Término de Obras - CAVA',
            },
            {
              value: 'CompletionWorksInfrastructurePublic',
              id: GUARANTEE_COMPLETION_WORKS_INFRASTRUCTURE_PUBLIC,
              text: 'Término de Obras - Infraestrutura',
            },
            {
              value: 'CompletionWorksCorrectiveMaintenancePublic',
              id: GUARANTEE_COMPLET_WORKS_CORRECT_MAINTENANCE_PUBLIC,
              text: 'Término de Obras - Manutenção Corretiva',
            }, */
          ],
        },
        {
          text: 'Privada',
          value: 'Private',
          modalities: [
            {
              value: 'AdvancePaymentsPrivate',
              id: GUARANTEE_ADVANCE_PAYMENTS_PRIVATE,
              text: 'Adiantamento de Pagamentos',
            },
            /* {
              value: 'DealershipPerformerPrivate',
              id: GUARANTEE_DEALERSHIP_PERFORMER_PRIVATE,
              text: 'Executante Concessionário Setor Privado',
            }, */
            {
              value: 'EnergyPurchaseSalePrivate',
              id: GUARANTEE_ENERGY_PURCHASE_SALE_PRIVATE,
              text: 'Garantia de Compra e Venda de Energia',
            },
            /* {
              value: 'EnergyPurchaseSaleFinesPrivate',
              id: GUARANTEE_ENERGY_PURCHASE_SALE_FINES_PRIVATE,
              text: 'Garantia de Compra e Venda de Energia com Multas',
            }, */
            {
              value: 'PaymentsGuaranteePrivate',
              id: GUARANTEE_PAYMENTS_PRIVATE,
              text: 'Garantia de Pagamentos',
            },
            {
              value: 'FinancialGuaranteePrivate',
              id: GUARANTEE_FINANCIAL_FINEP_PRIVATE,
              text: 'Garantia Financeira - FINEP',
            },
            /* {
              value: 'BidderGuaranteePrivate',
              id: GUARANTEE_BIDDER_PRIVATE,
              text: 'Licitante',
            }, */
            {
              value: 'RealEstateOfficePrivate',
              id: GUARANTEE_REAL_ESTATE_OFFICE_PRIVATE,
              text: 'Imobiliário',
            },
            {
              value: 'CorrectiveMaintenancePrivate',
              id: GUARANTEE_CORRECTIVE_MAINTENANCE_PRIVATE,
              text: 'Manutenção Corretiva',
            },
            {
              value: 'PaymentWithholdingPrivate',
              id: GUARANTEE_PAYMENT_WITHHOLDING_PRIVATE,
              text: 'Retenção de Pagamentos',
            },
          ],
        },
      ],
    },
  ], [])

  const getModalitiesByProduct = useCallback((product) => (
    !product?.noShowModalities && product.modalities ? product?.modalities : []
  ), [])

  const getAllTypeOtherModality = useCallback(() => getProducts()
    .find((products) => products.value === PRODUCT_GUARANTEE_MODALITY)
    .typeOtherModalities, [getProducts])

  const getAutomaticProductsForExecutiveRequest = useCallback(() => [
    {
      value: PRODUCT_JUDICIAL_GUARANTEE_APPEAL,
      text: 'Garantia Judicial',
      modalities: [
        { value: GUARANTEE_JUDICIAL_CIVIL_PUBLIC, path: 'judicial-civel', text: 'Judicial Cível' },
        { value: PRODUCT_JUDICIAL_GUARANTEE_APPEAL, path: 'judicial-deposito-recursal', text: PRODUCT_JUDICIAL_GUARANTEE_APPEAL_NAME },
        { value: GUARANTEE_JUDICIAL_LABOR_PUBLIC, path: 'judicial-trabalhista', text: 'Judicial Trabalhista' },
      ],
      subModalities: [PRODUCT_JUDICIAL_GUARANTEE_APPEAL_NAME, 'Judicial Depósito Recursal Substituição'],
      appeals: APPEALS,
    },
    {
      value: PRODUCT_GUARANTEE_MODALITY,
      path: 'garantia',
      text: 'Seguro Garantia',
      modalities: [
        { value: 1, path: 'licitante', text: 'Licitante (BID)' },
        { value: 2, path: 'performance', text: 'Performance (Construção, Fornecimento ou Prestação de Serviços)' },
      ],
      modalitiesPerformance: [
        { id: GUARANTEE_PERFORMANCE_PRIVATE, text: 'Construção, Fornecimento ou Prestação de serviço – Privada' },
        { id: GUARANTEE_PERFORMANCE_PUBLIC, text: 'Construção, Fornecimento ou Prestação de serviço – Pública' },
      ],
      typeOtherModalities: [],
    },
  ], [])

  return useCallback(() => ({
    getProducts,
    getModalitiesByProduct,
    getAllTypeOtherModality,
    getAutomaticProductsForExecutiveRequest,
  }), [
    getProducts,
    getModalitiesByProduct,
    getAllTypeOtherModality,
    getAutomaticProductsForExecutiveRequest,
  ])
}

export default useProductQuotationClient
