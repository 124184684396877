const ClaimantData = ({
  Cep,
  City,
  Place,
  State,
  Number,
  Document,
  District,
  Name,
  Complement,
}) => ({
  cep: Cep ?? '',
  city: City ?? '',
  place: Place ?? '',
  state: State ?? '',
  number: Number ?? '',
  name: Name ?? '',
  document: Document ?? '',
  district: District ?? '',
  complement: Complement ?? '',
})

export default ClaimantData
