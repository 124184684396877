import { useCallback } from 'react'
import produce from 'immer'
import { useDispatch } from 'react-redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const INITIAL_STATE = {

}

export const Types = {
  FILTER: 'globalFilter/opportunities',
}

const reducer = (state = INITIAL_STATE, action) => {
  if (action.type === Types.FILTER) {
    const { filter } = action.payload
    return produce(state, (draft) => {
      draft.filter = filter
    })
  }
  return state
}

export const setFilterStore = (filter) => ({
  type: Types.FILTER,
  payload: { filter },
})

export const useOpportunitiesGlobalFiltersAction = () => {
  const dispatch = useDispatch()
  const setFilter = useCallback(
    (filter) => dispatch({
      type: Types.FILTER,
      payload: { filter },
    }),
    [dispatch],

  )

  const cleanOpportunities = useCallback(
    () => dispatch({
      type: Types.FILTER,
      payload: {},
    }),
    [dispatch],
  )

  return {
    setFilter,
    cleanOpportunities,
  }
}

export default persistReducer(
  {
    key: 'opportunitiesGlobalFilters',
    storage,
  },
  reducer,
)
