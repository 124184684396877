import React from 'react'
import { PropTypes } from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { Error } from '@material-ui/icons'

import { isEmpty } from 'lodash'

import useQuotationClient from '../../../../clients/QuotationClient/useQuotationClient'
import { ReactComponent as DownloadIcon } from '../../../../assets/img/icons/ico-download.svg'

import useDownload from '../../../../shared/hooks/useDownload'
import { useAlert } from '../../../../shared/components'
import useUtils from '../../../../shared/hooks/useUtils'

import {
  STATUS_PROPOSAL_DONE,
  STATUS_PROPOSAL_EXPIRED,
  STATUS_PROPOSAL_ACCEPTED,
  STATUS_PROPOSAL_REQUESTED,
  STATUS_QUOTE_DENIED,
  STATUS_QUOTE_EXPIRED,
  STATUS_QUOTE_APPROVED,
  COLOR_ERROR,
} from '../../../../constants'

import useStyles from './styles/containerFields'

const cepLabel = 'CEP:'
const typeLabel = 'Tipo:'
const nameLabel = 'Nome:'
const cityLabel = 'Cidade:'
const stateLabel = 'Estado:'
const numberLabel = 'Número:'
const districtLabel = 'Bairro:'
const placeLabel = 'Logradouro:'
const cpfCnpjLabel = 'CPF/CNPJ:'
const complementLabel = 'Complemento:'
const deadlineLabel = 'Prazo (meses):'
const emailLabel = 'E-mail do Solicitante:'
const phoneLabel = 'Telefone do Solicitante:'
const requesterLabel = 'Nome do Solicitante:'
const endDateValidityLabel = 'Fim de Vigência:'
const guaranteedLabel = 'Garantido / Locatário:'
const startDateValidityLabel = 'Início de Vigência:'
const endContractLeaseLabel = 'Final Contrato Locação:'
const valueLeaseMonthlyLabel = 'Valor Mensal do Aluguel:'
const startContractLeaseLabel = 'Início Contrato Locação:'
const insuranceCompanyName = 'Nome da Seguradora:'
const idClientControlLabel = 'Identificador do Controle do Cliente:'

const LabelValueCondominium = 'Condomínio'
const LabelValueElectricEnergy = 'Energia'
const LabelValueGAS = 'Gás'
const LabelValueIPTU = 'IPTU'
const LabelValueWater = 'Água'
const LabelValueAir = 'Ar-Condicionado'
const LabelValuePromotionalFund = 'Fundo de Promoção'
const LabelFineTermination = 'Contratar Multa por rescisão:'
const LabelPropertyDamage = 'Contratar Cobertura de Danos ao imóvel:'
const LabelInternalExternalPainting = 'Contratar Cobertura Pintura interna e externa:'

const LabelDataExpiracao = 'Data de Expiração'
const LabelDataLiberacao = 'Data de Liberação'
const LabelDataNegacao = 'Data Negação'

const formaDePagamento = 'Forma de Pagamento'
const premioLiquido = 'Prêmio Líquido'
const premioTotal = 'Prêmio Total'
const parcelas = 'Parcelas'
const pagamentoPrimeiraParcela = 'Pagamento 1ª Parcela'

const textQuoteDenied = 'Prezado cliente, após análise técnica e politicas de subscrição da seguradora, não será possível prosseguir com a emissão desta solicitação. Para mais detalhes, entre em contato com seu executivo comercial.'

const ContainerFields = ((props) => {
  const classes = useStyles()
  const { quotation, quotationStatus, proposal } = props
  const { formatDate, formatCurrency, formatCPFOrCNPJ } = useUtils()

  const isProposal = () => !isEmpty(quotationStatus) && ([
    STATUS_PROPOSAL_REQUESTED,
    STATUS_PROPOSAL_DONE,
    STATUS_PROPOSAL_ACCEPTED,
    STATUS_PROPOSAL_EXPIRED,
  ].includes(quotationStatus))

  const { downloadPDF } = useDownload()
  const { addMsgDanger } = useAlert()
  const quotationClient = useQuotationClient()

  const downloadDocuments = (document) => {
    quotationClient().downloadDocumentsWings(document).then((response) => {
      downloadPDF(response?.data, document)
    }, () => {
      addMsgDanger('Não foi possível baixar o arquivo, tente mais tarde ou contate o Administrador.')
    })
  }

  return (
    <>
      {
        quotationStatus === STATUS_QUOTE_DENIED && (
          <Grid container>
            <Grid item xs={12} className={classes.text}>
              <Box display="flex">
                <Box mr={1}>
                  <Error style={{ color: COLOR_ERROR }} />
                </Box>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {textQuoteDenied}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                className={classes.header}
                data-content="Cotação Negada"
              />
              <Grid container className={classes.confirmationBox} spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {LabelDataNegacao}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatDate(proposal?.quoteDenied?.DeniedAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
      {
        quotationStatus === STATUS_QUOTE_EXPIRED && (
          <Grid container className={classes.root}>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                className={classes.header}
                data-content="Cotação Expirada"
              />
              <Grid container className={classes.confirmationBox} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {LabelDataExpiracao}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatDate(proposal?.quote.ExpiredAt)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {LabelDataLiberacao}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatDate(proposal?.quote.CreatedAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Dados do Garantido / Locatário"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {guaranteedLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Guaranteed.Name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {requesterLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Guaranteed.Requester}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {phoneLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Guaranteed.Phone}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {emailLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Guaranteed.Email}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {idClientControlLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Guaranteed.CustomerControlIdentifier}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Dados do Segurado / Locador"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {nameLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Insured.Name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {cpfCnpjLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCPFOrCNPJ(quotation?.Insured.Document)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {cepLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Insured.CEP}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {numberLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Insured.Number}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {placeLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Insured.Place}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {complementLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Insured.Complement}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {districtLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Insured.District}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {stateLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Insured.State}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {cityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Insured.City}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Dados do Imóvel / Objeto do Seguro"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {typeLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.PropertyType}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {cepLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.CEP}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {placeLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.Place}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {numberLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.Number}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {complementLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.Complement}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {districtLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.District}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {stateLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.State}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {cityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.ObjectProperty.City}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Dados do Imóvel / Objeto do Seguro"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {startContractLeaseLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatDate(quotation?.Insurance.StartContractLease)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {endContractLeaseLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatDate(quotation?.Insurance.EndContractLease)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {deadlineLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Insurance.Deadline}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {startDateValidityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatDate(quotation?.Insurance.StartDateValidity)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {endDateValidityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatDate(quotation?.Insurance.EndDateValidity)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Encargos"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {valueLeaseMonthlyLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.Charges?.ValueLease)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {LabelValueCondominium}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.Charges?.ValueCondominium)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {LabelValueElectricEnergy}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.Charges?.ValueElectricEnergy)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {LabelValueGAS}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.Charges?.ValueGAS)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {LabelValueIPTU}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.Charges?.ValueIPTU)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {LabelValueWater}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.Charges?.ValueWater)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {LabelValueAir}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.Charges?.ValueAir)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {LabelValuePromotionalFund}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.Charges?.ValuePromotionalFund)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} />
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {LabelFineTermination}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Charges?.fineTermination ? 'Sim' : 'Não'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {LabelPropertyDamage}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Charges?.propertyDamage ? 'Sim' : 'Não'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {LabelInternalExternalPainting}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Charges?.internalExternalPainting ? 'Sim' : 'Não'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {(proposal?.quote?.InsurerName !== null
          || !isEmpty(proposal?.quote?.InsurerName))
          && (
            <Grid item xs={12}>
              <Typography
                gutterBottom
                className={classes.header}
                data-content="Seguradora"
              />
              <Grid container className={classes.confirmationBox} spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {insuranceCompanyName}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {proposal?.quote?.InsurerName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}

        <Grid item xs={12} className={classes.containerRentContract}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Contrato de Aluguel / Outros Arquivos"
          />
          <Grid className={classes.rentContract}>
            <Grid item xs={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                Nome do Arquivo:
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                Download:
              </Typography>
            </Grid>
          </Grid>
          {(quotation?.FileUpload?.Documents ?? quotation?.FileUpload)
            ?.map((item, index) => (
              <Grid key={index} item xs={12} className={classes.rentContract}>
                <Grid item xs={4}>
                  <Typography className={classes.confirmationValue}>
                    {item?.DocumentName}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Box>
                    <Button
                      variant="text"
                      onClick={() => downloadDocuments(item?.DocumentID)}
                    >
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <Box mr={1}>
                          <DownloadIcon />
                        </Box>

                        <Box sx={{ textDecoration: 'underline', fontSize: 17 }}>
                          <b>Download</b>
                        </Box>
                      </Box>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            ))}
        </Grid>
        {
          isProposal() && (
            <Grid item xs={12}>
              <Typography
                gutterBottom
                className={classes.header}
                data-content="Forma de Pagamento"
              />
              <Grid container className={classes.confirmationBox} spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {formaDePagamento}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    Boleto
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {premioLiquido}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatCurrency(proposal?.quote?.CommercialPremium)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {premioTotal}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatCurrency(proposal?.quote?.GrossPremium)}
                  </Typography>
                </Grid>

                {
                  quotationStatus !== STATUS_QUOTE_APPROVED && (
                    <>
                      <Grid item xs={12} sm={4}>
                        <Typography gutterBottom className={classes.confirmationLabel}>
                          {parcelas}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.confirmationValue}>
                          {proposal?.proposal?.Request?.Payment.Installments}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography gutterBottom className={classes.confirmationLabel}>
                          {pagamentoPrimeiraParcela}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.confirmationValue}>
                          {proposal?.proposal?.Request?.Payment.FirstInstallmentDueDateDelay}
                        </Typography>
                      </Grid>
                    </>
                  )
                }

              </Grid>
            </Grid>
          )
        }
      </Grid>
    </>
  )
})

ContainerFields.propTypes = {
  quotation: PropTypes.any.isRequired,
  quotationStatus: PropTypes.any,
  proposal: PropTypes.any.isRequired,
}
ContainerFields.defaultProps = {
  quotationStatus: undefined,
}

export default ContainerFields
