import React, {
  useRef,
  useEffect,
  useContext,
  useCallback,
} from 'react'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'

import AuthContext from '../../shared/contexts/AuthContext'

import { useAlert } from '../../shared/components'
import SlashScreen from '../../shared/components/SlashScreen'

import useGoogleAnalytics from '../../shared/hooks/useGoogleAnalytics'

import useSecurity from '../../security/useSecurity'

import { ExecutiveData } from '../../models'
import { useSecurityAction } from '../../store/ducks/security'

import { ERROR_MISSED_APPOINTMENT } from '../../constants'

import useUserClient from '../../clients/UserClient/useUserClient'
import useFinancialClient from '../../clients/FinancialClient/useFinancialClient'

const AccessCallback = () => {
  const {
    setUser,
    setExecutive,
    setAssistants,
    setSuperIntendent,
  } = useSecurityAction()
  const {
    user,
    isLoginSocialNetwork,
    setMaintenanceValue,
  } = useContext(AuthContext)
  const { signout, cleanAllData } = useSecurity()
  const { event } = useGoogleAnalytics()

  const history = useHistory()
  const { addMsgDanger } = useAlert()

  const userClient = useUserClient()
  const financialClient = useFinancialClient()

  const handleSuccess = useCallback((owner, loggedUser) => {
    const {
      name,
      phone,
      mobilePhone,
      phone2,
      mobilePhone2,
      updateDeniedAt,
      isNewUser,
      isResponsible,
      ticketsExpired,
      trackSaleSchedule,
      cpfcnpj: cpfCnpj,
      hasAceptedTermsOfUse,
      newExecutiveNotification,
    } = loggedUser

    const {
      uid,
      email,
      photoURL,
      displayName,
    } = user

    const nameUser = name ?? displayName
    setUser({
      uid,
      email,
      cpfCnpj,
      phone,
      mobilePhone,
      phone2,
      mobilePhone2,
      updateDeniedAt,
      ticketsExpired,
      name: nameUser,
      newUser: isNewUser || false,
      avatar: photoURL,
      hasAceptedTermsOfUse,
      newExecutiveNotification,
      isTrackSaleSchedule: trackSaleSchedule || {},
      isResponsibleClient: isResponsible || false,
    })

    if (owner) {
      const assistantsData = loggedUser?.assistants?.map((item) => ExecutiveData({
        ...item?.result?.records[0]?.owner,
        ...item?.result?.photo,
      }))

      const superIntendentData = {
        ...loggedUser?.superIntendent?.result?.records[0]?.owner,
        ...loggedUser?.superIntendent?.result?.photo,
      }
      setAssistants(assistantsData)
      setExecutive(ExecutiveData(owner))
      setSuperIntendent(ExecutiveData(superIntendentData))
      localStorage.setItem('newExecutiveNotification', loggedUser?.newExecutiveNotification)
    }

    event('Acesso', 'Cliente', nameUser || '')

    setTimeout(() => {
      history.replace('/')
    })
  }, [
    user,
    history,
    event,
    setUser,
    setExecutive,
    setAssistants,
    setSuperIntendent,
  ])

  const handleExecutive = useCallback((loggedUser) => {
    const { executive } = loggedUser
    const executiveData = executive?.result?.done && executive?.result?.totalSize > 0
      ? executive?.result?.records[0]
      : {}

    if (isEmpty(executiveData)) {
      handleSuccess(null, loggedUser)
      return
    }

    const { owner } = executiveData
    handleSuccess({ ...owner, ...executive?.result?.photo }, loggedUser)
  }, [
    handleSuccess,
  ])

  const loginUserRequest = useCallback(() => {
    userClient().loginUser().then(({ data: loginUserData }) => {
      const getIpAddress = async () => {
        const res = await userClient().getIPAddress()
        localStorage.setItem('ipAddress', res?.ip)
        userClient().sendIPAddress(user?.email, res?.ip, 'CLIENTE')
      }
      getIpAddress()
      financialClient().getFinancialTickets({}).then(({ data: financialTickets }) => {
        const { data: financialData } = financialTickets

        if (financialData) {
          handleExecutive({
            ...loginUserData?.data,
            ticketsExpired: financialData?.countExpired,
          })
          return
        }

        handleExecutive({ ...loginUserData?.data })
      }).catch(() => handleExecutive({ ...loginUserData?.data }))
    }, (error) => {
      if (error?.data?.message === ERROR_MISSED_APPOINTMENT && error?.status === 401) {
        cleanAllData()
        addMsgDanger({
          message: error?.data?.message,
          labelButton: 'Fechar',
          action: () => window.location.reload(),
        })
        history.replace('/login')
      } else {
        signout().then(() => {
          if (error.status === 400 && isLoginSocialNetwork) {
            setTimeout(() => history.replace('/register', {
              userSocialNetwork: {
                email: user?.email,
                name: user?.displayName,
              },
            }))
            return
          }
          setTimeout(() => {
            history.replace('/login')
          })
        })
      }
    })
  }, [
    user,
    history,
    signout,
    cleanAllData,
    userClient,
    addMsgDanger,
    financialClient,
    handleExecutive,
    isLoginSocialNetwork,
  ])

  const getIpAddress = async () => {
    const res = await userClient().getIPAddress()
    localStorage.setItem('ipAddress', res?.ip)
  }
  useEffect(() => {
    getIpAddress()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const teste = useRef(false)

  useEffect(() => {
    if (user && !teste.current) {
      // userClient().maintenanceWings().then((response) => {
      // const data = response
      // setMaintenanceValue(data)
      loginUserRequest()
      // }, (err) => {
      //   if (err === 'maintenance') {
      //     setMaintenanceValue(err)
      //   }
      // })
    }

    return () => {
      teste.current = true
    }
  }, [
    user,
    userClient,
    loginUserRequest,
    setMaintenanceValue,
  ])

  return (<SlashScreen />)
}

export default AccessCallback
