import React, {
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { makeStyles } from '@material-ui/core'

import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import {
  ButtonLight,
  GreenCheckbox,
  ButtonGradient,
  useAlert,
} from '../../../shared/components'
import { GlobalMessages } from '../../../shared/messages'
import BadgeStatus from '../../../shared/components/BadgeStatus'
import ButtonDownload from '../../../shared/components/Button/ButtonDownload'
import ModalInfo from '../../../shared/components/ModalInfo'
import useStylesDownload from './useStyles'
import {
  STATUS_PROPOSAL_REQUESTED,
  STATUS_PROPOSAL_DONE,
  STATUS_PROPOSAL_ACCEPTED,
  STATUS_PROPOSAL_EXPIRED,
  STATUS_POLICY_ISSUED,
  COLOR_SUCCESS,
} from '../../../constants'

import useProposalClient from '../../../clients/ProposalClient/useProposalClient'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiCollapse-root': {
      borderRadius: 14,
    },
  },
  header: {
    height: 120,
    padding: 40,
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: theme.spacing(2, 5, 4),
  },
  labelInfo: {
    color: theme.palette.text.tertiary,
  },
  buttonsStep: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& .MuiBox-root': {
      minWidth: 190,
    },
    '@media (max-width: 699px)': {
      justifyContent: 'center',
    },
  },
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 600,
    },
  },
}))

const DEFAULT_CONFIRMATION = {
  responsibilityConfirmation: false,
}

const AcceptProposal = forwardRef((props, ref) => {
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const classes = useStyles()
  const classesDownload = useStylesDownload()
  const { quotationIdentifier } = useParams()
  const {
    children,
    quotationStatus,
  } = props
  const { addMsgSuccess, addMsgDanger } = useAlert()
  const history = useHistory()
  const formik = useFormik({
    initialValues: { ...DEFAULT_CONFIRMATION },
    validationSchema: Yup.object({
      responsibilityConfirmation: Yup.boolean()
        .required()
        .oneOf([true], 'Os termos e condições devem ser lidos e aceitos.'),
    }),

    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(false)
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  const [checked, setChecked] = useState(false)

  const resposibilityLabel = 'Li e Aceito os Termos e Condições'

  const renderLabelTitle = () => {
    if (quotationStatus === STATUS_PROPOSAL_REQUESTED) {
      return (
        <Typography variant="h5" color="primary">
          Sua proposta está sendo gerada, logo estará disponível para aceite
        </Typography>
      )
    }
    if (quotationStatus === STATUS_PROPOSAL_DONE) {
      return (
        <Typography variant="h5" color="primary">
          Sua proposta está Pronta
        </Typography>
      )
    }
    if (quotationStatus === STATUS_PROPOSAL_ACCEPTED) {
      return (
        <Typography variant="h5" color="primary">
          Sua Proposta está Aceita
        </Typography>
      )
    }
    if (quotationStatus === STATUS_PROPOSAL_EXPIRED) {
      return (
        <Typography variant="h5" color="primary">
          Sua Proposta está Expirada
        </Typography>
      )
    }
    if (quotationStatus === STATUS_POLICY_ISSUED) {
      return (
        <Typography gutterBottom variant="h5" color="initial">
          Confirme os dados da sua cotação
        </Typography>
      )
    }
    return ''
  }

  const handleHire = () => {
    setOpenModalConfirm(true)
  }

  const handleClick = () => {
    history.replace('/status-solicitacao')
  }

  const useProposal = useProposalClient()

  const HandleConfirmation = () => {
    useProposal().approveProposal(quotationIdentifier).then(() => {
      addMsgSuccess({
        title: 'Proposta aceita!',
        message: 'Sucesso! Agora, é só aguardar mais detalhes da sua proposta.',
        action: handleClick,
      })
    }, (response) => {
      addMsgDanger(response.data)
    })

    setOpenModalConfirm(false)
  }

  const renderBadgeStatus = () => {
    if (quotationStatus === STATUS_PROPOSAL_REQUESTED) {
      return (
        <BadgeStatus
          label="Proposta Solicitada"
          icon="timer"
          color="#3F4159"
        />
      )
    }
    if (quotationStatus === STATUS_PROPOSAL_DONE) {
      return (
        <BadgeStatus
          label="Proposta Pronta"
          icon="check_circle"
          color="#3F4159"
        />
      )
    }
    if (quotationStatus === STATUS_PROPOSAL_ACCEPTED) {
      return (
        <BadgeStatus
          label="Proposta Aceita"
          icon="check_circle"
          color={COLOR_SUCCESS}
        />
      )
    }
    if (quotationStatus === STATUS_PROPOSAL_EXPIRED) {
      return (
        <BadgeStatus
          label="Proposta Expirada"
          icon="timer_off"
          color="#FA7936"
        />
      )
    }

    return ''
  }
  return (
    <>
      {openModalConfirm && (
        <ModalInfo
          close={false}
          open={openModalConfirm}
          className={classes.modal}
        >
          <Box p={2} alignItems="center" justifyContent="center">
            <Box>
              <Box mt={2} mb={7}>
                <Typography align="center" variant="h4">Aceitar Proposta?</Typography>
              </Box>

              <Box mb={7}>
                <Typography align="center">
                  Tem certeza que deseja aceitar a proposta?
                </Typography>
              </Box>

              <Grid item xs={12}>
                <Box justifyContent="center" className={classes.buttonsStep}>
                  <Box>
                    <ButtonGradient
                      onClick={HandleConfirmation}
                    >
                      Sim
                    </ButtonGradient>
                  </Box>
                  <Box>
                    <ButtonLight
                      onClick={() => {
                        setOpenModalConfirm(false)
                      }}
                    >
                      Não
                    </ButtonLight>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Box>
        </ModalInfo>
      )}
      <Box className={classes.root}>
        <Paper elevation={1}>
          <Grid container>
            <Grid
              item
              xs={12}
              className={classes.header}
            >
              <Grid container>
                <Grid item xs={12}>
                  {renderLabelTitle()}
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.labelInfo}>
                    Confira abaixo as informações da sua cotação:
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end">
                {renderBadgeStatus()}
              </Grid>
            </Grid>
            {
              quotationStatus !== STATUS_PROPOSAL_REQUESTED
              && (
                <Grid container>
                  {
                    quotationStatus !== STATUS_POLICY_ISSUED
                    && (
                      <Box ml={4} className={classesDownload.containerDownload}>
                        <ButtonDownload
                          label="Download Minuta"
                          url={`/cotacao/${quotationIdentifier}/minuta`}
                        />
                      </Box>
                    )
                  }
                </Grid>
              )
            }
            <Grid item xs={12} className={classes.content}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  {children}
                </Grid>
                {
                  quotationStatus !== STATUS_PROPOSAL_REQUESTED
                  && quotationStatus !== STATUS_PROPOSAL_EXPIRED
                  && quotationStatus !== STATUS_POLICY_ISSUED
                  && quotationStatus !== STATUS_PROPOSAL_ACCEPTED
                  && (
                    <Grid item xs={12}>
                      <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <FormControlLabel
                          control={(
                            <GreenCheckbox
                              id="responsibilityConfirmation"
                              checked={checked}
                              {...formik.getFieldProps('responsibilityConfirmation')}
                              onChange={() => {
                                setChecked(!checked)
                                formik.values.responsibilityConfirmation = !checked
                              }}
                            />
                          )}
                        />
                        <Typography
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                          onClick={() => {
                            addMsgSuccess({
                              title: 'Termos e Condições',
                              labelButton: 'Fechar',
                              message: `Transparência - A Finlândia Corretora de Seguros busca garantir o mais alto nível de integridade
                              corporativa e ética em suas atividades, cumprindo com seus deveres de forma
                              honesta e transparente. Baseados em nossos valores, temos o compromisso de
                              respeitar e cumprir rigorosamente as legislações e regulamentos aplicáveis ao
                              nosso negócio, garantindo a agilidade na prestação de serviços e a segurança
                              dos nossos clientes. Por isso, temos diretrizes bem definidas aplicadas ao
                              nosso dia a dia, que nos permite realizar cotações nas mais diversas
                              companhias e proporcionar um atendimento eficaz por meio dessas parcerias. Vale
                              ressaltar que possuímos sócios em comum com a Pottencial Seguradora, o que só
                              reforça a nossa eficiência, solidez e estabilidade nos negócios. Nossa política
                              de comissionamento, enquanto corretora, será de 15% a 27% sobre o prêmio, para
                              as modalidades de seguro garantia e fiança locatícia, de 15% a 30% sobre o
                              prêmio para as demais modalidades de seguro. Informamos ainda que, como
                              instrumento de incentivo, respeitamos os limites éticos e normativos
                              aplicáveis, a corretora possui contrato de incentivo com as seguradoras do
                              mercado.`,
                              action: '',
                              maxWidth: 'md',
                            })
                          }}
                        >
                          {resposibilityLabel}
                        </Typography>
                      </Grid>
                      <FormHelperText
                        hidden={
                          !formik.touched.responsibilityConfirmation
                          || !formik.errors.responsibilityConfirmation
                        }
                        error={
                          formik.touched.responsibilityConfirmation
                          && !!formik.errors.responsibilityConfirmation
                        }
                      >
                        {formik.errors.responsibilityConfirmation}
                      </FormHelperText>
                    </Grid>
                  )
                }

                <Grid item xs={12}>
                  <Box className={classes.buttonsStep}>
                    <Box>
                      <ButtonLight
                        onClick={handleClick}
                        title="Voltar"
                      >
                        Voltar
                      </ButtonLight>
                    </Box>
                    {
                      quotationStatus !== STATUS_PROPOSAL_REQUESTED
                      && quotationStatus !== STATUS_PROPOSAL_ACCEPTED
                      && quotationStatus !== STATUS_PROPOSAL_EXPIRED
                      && quotationStatus !== STATUS_POLICY_ISSUED
                      && (
                        <Box>
                          <ButtonGradient
                            onClick={handleHire}
                            title="Contratar"
                          >
                            Contratar
                          </ButtonGradient>
                        </Box>
                      )
                    }

                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  )
})

AcceptProposal.propTypes = {
  children: PropTypes.any,
  quotationStatus: PropTypes.any.isRequired,
  proposal: PropTypes.any.isRequired,
}

AcceptProposal.defaultProps = {
  children: '',
}

export default AcceptProposal
