import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import EventEmitter from 'events'
import PropTypes from 'prop-types'
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { SlashScreen } from '..'

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    backgroundColor: '#00000030',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000,
  },
}))

const emitter = new EventEmitter()

const LOADER_SHOW = 'loader.show'
const LOADER_HIDE = 'loader.hide'
const LOADER_ENABLE = 'loader.enable'
const LOADER_DISABLE = 'loader.disable'
const DELAY = 2
let timeoutId = null

const Loader = ({ show }) => {
  const classes = useStyles()
  const [disabled, setDisabled] = useState(0)
  const [showByEvent, setShowByEvent] = useState(false)
  const [optionsLoader, setOptionsLoader] = useState([])
  const [message, setMessage] = useState(<SlashScreen />)

  const textMessage = useMemo(() => (
    <div style={{ fontSize: '15px', color: 'white', marginTop: 10 }}>
      {optionsLoader.title ?? 'Aguarde mais um pouquinho,'}
      <br />
      {optionsLoader.subtitle ?? 'estamos carregando...'}
    </div>
  ), [optionsLoader])

  useEffect(() => {
    const showLoader = (options) => {
      setShowByEvent(true)
      setOptionsLoader(options)
    }
    emitter.on(LOADER_SHOW, showLoader)

    const hideLoader = () => setShowByEvent(false)
    emitter.on(LOADER_HIDE, hideLoader)

    const enableLoader = () => setDisabled((count) => (count > 0 ? count - 1 : 0))
    emitter.on(LOADER_ENABLE, enableLoader)

    const disableLoader = () => setDisabled((count) => ++count)
    emitter.on(LOADER_DISABLE, disableLoader)

    return () => {
      emitter.removeListener(LOADER_SHOW, showLoader)
      emitter.removeListener(LOADER_HIDE, hideLoader)
      emitter.removeListener(LOADER_ENABLE, enableLoader)
      emitter.removeListener(LOADER_DISABLE, disableLoader)
    }
  }, [])

  useEffect(() => {
    if (disabled === 0 && (show || showByEvent)) {
      setMessage(<SlashScreen />)
      if (timeoutId) clearTimeout(timeoutId)
      timeoutId = setTimeout(() => setMessage(
        <SlashScreen>
          {textMessage}
        </SlashScreen>,
      ), DELAY * 1000)
    }
  }, [disabled, show, showByEvent, textMessage])

  return (
    <>
      {disabled === 0 && (show || showByEvent) && (
        <Box className={classes.root}>
          <Card>
            <CardContent>
              <Box p={1}>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  direction="column"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography align="center" variant="h6">
                      {message}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  )
}

export const useLoader = () => {
  const enableLoader = useCallback(() => emitter.emit(LOADER_ENABLE), [])

  const disableLoader = useCallback(() => emitter.emit(LOADER_DISABLE), [])

  const showLoader = useCallback((options) => emitter.emit(LOADER_SHOW, options ?? []), [])

  const hideLoader = useCallback(() => emitter.emit(LOADER_HIDE), [])

  return {
    showLoader,
    hideLoader,
    enableLoader,
    disableLoader,
  }
}

Loader.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default Loader
