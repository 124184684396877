import React, {
  useRef,
  useState,
  useEffect,
} from 'react'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { HubConnectionState } from '@microsoft/signalr'

import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import Icon from '@material-ui/core/Icon'
import Card from '@material-ui/core/Card'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import SvgIcon from '@material-ui/core/SvgIcon'
import Popover from '@material-ui/core/Popover'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import * as Icons from '@material-ui/icons'

import makeStyles from '@material-ui/core/styles/makeStyles'

import useSecurity from '../../../security/useSecurity'

import useNotificationClient from '../../../clients/NotificationClient'
import { useNotificationsAction } from '../../../store/ducks/notifications'

const useStyles = makeStyles((theme) => ({
  menu: {
    '& .MuiPopover-paper': {
      width: 296,
      maxHeight: 400,
      overflow: 'hidden',
      borderRadius: '14px',
    },
  },
  btn: {
    color: theme.palette.text.secondary,
  },
  notifications: {
    '& .MuiCardContent-root': {
      overflow: 'hidden',
    },
    '& .MuiList-root': {
      width: '100%',
      height: '100%',
      maxHeight: 335,
      overflowY: 'overlay',
      boxSizing: 'content-box',
      paddingRight: theme.spacing(4),
      '& .MuiButtonBase-root': {
        paddingLeft: 0,
      },
    },
  },
  titleNotifications: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  plusNotifications: {
    color: theme.palette.text.tertiary,
  },
  notificationChildren: {
    borderRadius: 4,
    alignItems: 'start',
    marginBlock: theme.spacing(1),
    backgroundColor: '#0000000a',
    '& .MuiListItemIcon-root': {
      minWidth: 35,
      justifyContent: 'center',
      marginTop: theme.spacing(1),
    },
  },
}))

const NotificationMenu = () => {
  const classes = useStyles()
  const history = useHistory()

  const [anchorEl, setAnchorEl] = useState(null)
  const [connection, setConnection] = useState(null)
  const [buttonHover, setButtonHover] = useState(false)

  const notificationClient = useNotificationClient()

  const { getUser, getEmail } = useSecurity()

  const {
    news,
  } = useSelector(({ notifications }) => ({
    news: notifications.news ?? [],
  }))

  const updatedNotifies = useRef(news)

  const { cleanNotifications, setNotifications } = useNotificationsAction()

  const toogleIcon = () => {
    setButtonHover(true)
  }

  const toogleIconOutlined = () => {
    setButtonHover(false)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    toogleIcon()
  }

  const handleClose = () => {
    setAnchorEl(null)
    toogleIconOutlined()
    updatedNotifies.current = []

    if (news.length > 0) {
      cleanNotifications()
      notificationClient().readAll()
    }
  }

  useEffect(() => {
    const newConnection = notificationClient().connectWebSocket()
    setConnection(newConnection)
  }, [notificationClient])

  useEffect(() => {
    if (connection && connection?.state === HubConnectionState.Disconnected) {
      connection.start()
        .then(() => {
          connection.invoke('Subscribe', { UserEmail: getEmail() })
          connection.on('Registered', (messages) => {
            if (messages?.length > 0) {
              const messagesUpdated = messages.map((message) => (
                notificationClient().getByTopic(message)
              ))

              updatedNotifies.current = [...messagesUpdated]

              setNotifications(updatedNotifies.current)
            }
          })
          connection.on('Receive', (message) => {
            const latestNotifies = [...updatedNotifies.current]

            const notificationTopic = notificationClient()
              .getByTopic(message)

            if (notificationTopic && !latestNotifies.find(({ id }) => id === message?.id)) {
              updatedNotifies.current = [
                ...latestNotifies,
                notificationTopic,
              ]

              setNotifications(updatedNotifies.current)
            }
          })
        })
    }
  }, [news, connection, notificationClient, getEmail, setNotifications])

  useEffect(() => {
    if (isEmpty(getUser()) && connection && connection?.state === HubConnectionState.Connected) {
      connection.stop()
    }
  }, [connection, getUser])

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Box>
        <Button
          className={classes.btn}
          onClick={handleClick}
          aria-controls="simple-menu"
          title="Alertas"
        >
          <Badge
            color="primary"
            badgeContent={news?.length}
            overlap="rectangular"
          >
            {
              buttonHover
                ? <Icon color="primary">notifications</Icon>
                : <Icon>notifications_none</Icon>
            }
          </Badge>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.menu}
          anchorOrigin={{
            vertical: 70,
            horizontal: 58,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box>
            <Box className={classes.notifications}>
              <Card sx={{ maxWidth: 345 }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" color="primary" component="div">
                    Alertas
                  </Typography>
                  <List>
                    {isEmpty(news) && (
                      <ListItem>
                        <ListItemText
                          primary={(
                            <>
                              <Typography
                                component="span"
                                variant="subtitle1"
                                className={classes.titleNotifications}
                              >
                                Não há alertas!
                              </Typography>
                            </>
                          )}
                        />
                      </ListItem>
                    )}
                    {news.map((notification, key) => {
                      const labelId = `list-label-${notification?.id}`

                      return (
                        <ListItem
                          button
                          key={key}
                          className={classes.notificationChildren}
                          style={{
                            borderLeft: `4px solid ${notification?.color}`,
                          }}
                          onClick={() => {
                            handleClose()
                            setTimeout(() => history.push(notification?.url))
                          }}
                        >
                          {notification?.icon && (
                            <ListItemIcon>
                              <SvgIcon
                                component={Icons[
                                  Object.keys(Icons).find((k) => k === notification?.icon)
                                ]}
                                style={{ color: notification?.color }}
                              />
                            </ListItemIcon>
                          )}
                          <ListItemText
                            id={labelId}
                            style={{ marginLeft: !notification?.icon ? 20 : 'initial' }}
                            primary={(
                              <>
                                <Typography
                                  component="span"
                                  variant="subtitle1"
                                  className={classes.titleNotifications}
                                >
                                  {notification?.title ?? 'Nova Notificação'}
                                </Typography>
                              </>
                            )}
                            secondary={(
                              <Typography
                                component="span"
                                variant="subtitle1"
                                className={classes.plusNotifications}
                              >
                                Clique aqui e saiba mais
                              </Typography>
                            )}
                          />
                        </ListItem>
                      )
                    })}
                  </List>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Popover>
      </Box>
    </>
  )
}

export default NotificationMenu
