import {
  useMemo,
  useCallback,
} from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import emitter from './EventSecurity'
import firebase from './firebase/FirebaseConfig'

import { useSecurityAction } from '../store/ducks/security'
import { useNotificationsAction } from '../store/ducks/notifications'
import { useGlobalFilterListAction } from '../store/ducks/globalFilterList'

import { useAlert } from '../shared/components'

import useUserClient from '../clients/UserClient/useUserClient'

const useSecurity = () => {
  const userClient = useUserClient()

  const history = useHistory()
  const { addMsgDanger } = useAlert()

  const {
    cleanUser,
    cleanExecutive,
    cleanAssistants,
    cleanSuperIntendent,
    cleanTrackSaleSchedule,
    cleanDataIsOutdated,
  } = useSecurityAction()

  const {
    cleanFilter,
  } = useGlobalFilterListAction()

  const {
    cleanNotifications,
  } = useNotificationsAction()

  const {
    user,
    executive,
    assistants,
    superIntendent,
    policy,
    dataIsOutdated,
  } = useSelector(({ security }) => ({
    user: security.user,
    executive: security.executive,
    assistants: security.assistants,
    superIntendent: security.superIntendent,
    policy: security.policy,
    dataIsOutdated: security.dataIsOutdated,
  }))

  const getUser = useCallback(() => user || '', [user])

  const getName = useCallback(() => user?.name || '', [user])

  const getEmail = useCallback(() => user?.email || '', [user])

  const getCpfCnpj = useCallback(() => user?.cpfCnpj || '', [user])

  const getExecutive = useCallback(() => executive || {}, [executive])

  const getAssistants = useCallback(() => assistants || [], [assistants])

  const getSuperIntendent = useCallback(() => superIntendent || {}, [superIntendent])

  const getTicketsExpired = useCallback(() => user?.ticketsExpired || 0, [user])

  const getNotificationToken = useCallback(() => user?.notificationToken || '', [user])

  const getUserFirebase = useCallback(() => firebase.auth().currentUser || null, [])

  const isUserResponsibleClient = useCallback(() => user?.isResponsibleClient || false, [user])

  const getTrackSaleSchedule = useCallback(() => user?.isTrackSaleSchedule || {}, [user])

  const getDataIsOutdated = useCallback(() => dataIsOutdated || {}, [dataIsOutdated])

  const getFilterPolicy = useCallback(() => policy || {}, [policy])

  const avatarURL = useMemo(() => {
    const { avatar } = getUser()
    return avatar || ''
  }, [getUser])

  const reload = useCallback(() => new Promise((resolve) => emitter.emit('security.reload', resolve)), [])

  const cleanAllData = useCallback(() => {
    cleanUser()
    cleanFilter()
    cleanExecutive()
    cleanAssistants()
    cleanSuperIntendent()
    cleanTrackSaleSchedule()
    cleanDataIsOutdated()
    cleanNotifications()
  }, [
    cleanUser,
    cleanFilter,
    cleanExecutive,
    cleanAssistants,
    cleanSuperIntendent,
    cleanTrackSaleSchedule,
    cleanDataIsOutdated,
    cleanNotifications,
  ])

  const verifyToken = useCallback((currentUser) => {
    currentUser.getIdToken().then((idToken) => {
      const decode = JSON.parse(atob(idToken.split('.')[1]))
      const expirationTime = decode.exp * 1000
      const newToken = () => currentUser.getIdToken(true).then((resp) => console.log('newToken sucesso', resp), () => {
        history.replace('/login')
        addMsgDanger({
          title: 'Sessão expirada!',
          message: 'Faça o login novamente!',
          labelButton: 'Fechar',
          action: () => window.location.reload(),
        })
      })

      if (expirationTime - 300000 < new Date().getTime()) {
        newToken()
        return true
      }
      return false
    })
  }, [history, addMsgDanger])

  const signout = useCallback(() => new Promise((resolve) => {
    const data = { email: getEmail(), notificationToken: getNotificationToken() }
    userClient().logoutUser(data?.email && data?.notificationToken ? { ...data } : {})
    cleanAllData()
    setTimeout(() => firebase.auth().signOut().then(() => {
    }))

    resolve()
  }), [
    getEmail,
    cleanAllData,
    userClient,
    getNotificationToken,
  ])

  const signin = useCallback(async (email, password) => firebase.auth()
    .signInWithEmailAndPassword(email, password), [])

  const signinByGoogle = useCallback(async () => {
    const providerAuthGoogle = new firebase.auth.GoogleAuthProvider()
    providerAuthGoogle.setCustomParameters({
      prompt: 'select_account',
    })

    return firebase.auth().signInWithPopup(providerAuthGoogle)
  }, [])

  const signinByMicrosoft = useCallback(async () => {
    const providerAuthMicrosoft = new firebase.auth.OAuthProvider('microsoft.com')
    providerAuthMicrosoft.setCustomParameters({
      prompt: 'select_account',
    })

    return firebase.auth().signInWithRedirect(providerAuthMicrosoft)
  }, [])

  const verifyJwt = useCallback((token) => {
    try {
      let jsonPayload = null
      const base64Url = token.split('.')[1]

      if (base64Url) {
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        jsonPayload = decodeURIComponent(atob(base64).split('')
          .map((c) => {
            const charCode = `00${c.charCodeAt(0).toString(16)}`.slice(-2)
            return `%${charCode}`
          }).join(''))
      }

      return Boolean(JSON.parse(jsonPayload))
    } catch (e) {
      return false
    }
  }, [])

  return {
    reload,
    signin,
    signout,
    signinByGoogle,
    signinByMicrosoft,
    verifyToken,
    getUser,
    getName,
    getEmail,
    getCpfCnpj,
    getExecutive,
    getAssistants,
    getSuperIntendent,
    getFilterPolicy,
    getUserFirebase,
    getTicketsExpired,
    getDataIsOutdated,
    getTrackSaleSchedule,
    isUserResponsibleClient,
    avatarURL,
    verifyJwt,
    cleanAllData,
  }
}

export default useSecurity
