import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import makeStyles from '@material-ui/core/styles/makeStyles'

import icoSuccess from '../../assets/img/icons/ico-alert-success.svg'

import { ButtonGradient, ButtonLight } from '../../shared/components'
import { QUOTE_URL_EXECUTIVE_REQUEST } from '../../constants'

const useStyles = makeStyles((theme) => ({
  modal: {
    zIndex: 999,
    position: 'fixed',
    backgroundColor: '#fff',
    width: 650,
    height: 415,
    borderRadius: 16,
    boxShadow: '0px 3px 6px #00000029',
    '& img': {
      width: 174,
    },
    [theme.breakpoints.down('md')]: {
      width: 350,
      height: 500,
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: 70,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    textAlign: 'center',
    color: '#060606',
  },
  text: {
    color: '#666',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 130,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}))

const ExecutiveRedirectModal = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const {
    identifier,
    tokenParams,
  } = props

  const redirectExecutiveGeneralData = `${process.env.REACT_APP_BFF_URL_EXECUTIVE}solicitacoes/${identifier}/dados-gerais`

  const redirectExecutive = () => {
    window.location.href = redirectExecutiveGeneralData
  }

  const downMD = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <Modal
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      open
    >
      <Box className={classes.modal}>
        <Box mt={2} mb={3} align="center">
          <img src={icoSuccess} alt="thumb" />
        </Box>
        <Box mt={2} mb={3} align="center">
          <Box className={classes.textContainer}>
            <Box>
              <Typography className={classes.title}>
                {identifier}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.text}>
                Cotação criada com sucesso.
              </Typography>
            </Box>
          </Box>
          <Box className={classes.buttonContainer}>
            <Box mr={2} width={200} mt={downMD ? 3 : 0}>
              <ButtonGradient
                onClick={() => {
                  if (identifier?.substr(0, 3) !== 'END') {
                    history.replace(`${QUOTE_URL_EXECUTIVE_REQUEST}:${tokenParams}`)
                  } else {
                    window.location.reload()
                  }
                }}
              >
                Nova Solicitação
              </ButtonGradient>
            </Box>
            <Box width={downMD ? 300 : 400} mt={downMD ? 2 : 0}>
              <ButtonLight onClick={redirectExecutive}>
                Acompanhar Solicitação no Portal Executivo
              </ButtonLight>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

ExecutiveRedirectModal.propTypes = {
  tokenParams: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
}

export default ExecutiveRedirectModal
