import React, { useContext } from 'react'

const PriceContext = React.createContext()

export const usePrice = () => {
  const {
    guaranteeOther,
    engineeringRisk,
    judicialGuaranteeLabor,
    judicialGuaranteeCivel,
    judicialGuaranteeAppeal,
    guaranteePerformance,
    guaranteeBID,
    leaseBail,
    actionNext,
    actionPrevious,
    onNext,
    onRegister,
    confirmation,
    setConfirmation,
    setLeaseBail,
    setGuaranteeBID,
    setGuaranteeOther,
    setEngineeringRisk,
    setGuaranteePerformance,
    setJudicialGuaranteeLabor,
    setJudicialGuaranteeCivel,
    setJudicialGuaranteeAppeal,
    resourceTypeProduct,
    setResourceTypeProduct,
    namePolicyHolder,
    setNamePolicyHolder,
    cnpjClaimant,
    setCnpjClaimant,
    nameClaimant,
    setNameClaimant,
    addMsgError,
    isFormikChanged,
    setFormikChanged,
    appealSelected,
    setAppealSelected,
  } = useContext(PriceContext)

  return {
    guaranteeOther,
    engineeringRisk,
    judicialGuaranteeLabor,
    judicialGuaranteeCivel,
    judicialGuaranteeAppeal,
    guaranteeBID,
    guaranteePerformance,
    leaseBail,
    actionNext,
    actionPrevious,
    onNext,
    onRegister,
    confirmation,
    setConfirmation,
    setLeaseBail,
    setGuaranteeBID,
    setGuaranteeOther,
    setEngineeringRisk,
    setGuaranteePerformance,
    setJudicialGuaranteeLabor,
    setJudicialGuaranteeCivel,
    setJudicialGuaranteeAppeal,
    resourceTypeProduct,
    setResourceTypeProduct,
    namePolicyHolder,
    setNamePolicyHolder,
    cnpjClaimant,
    setCnpjClaimant,
    nameClaimant,
    setNameClaimant,
    addMsgError,
    isFormikChanged,
    setFormikChanged,
    appealSelected,
    setAppealSelected,
  }
}

export default PriceContext
