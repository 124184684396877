const font = ['Montserrat', 'sans-serif'].join(',')

export const textStyle = {
  fontFamily: font,
  fontWeight: 600,
  color: '#3F4159',
}

const typographyConfig = {
  fontFamily: ['Montserrat', 'sans-serif'].join(','),
  h1: textStyle,
  h2: textStyle,
  h3: textStyle,
  h4: textStyle,
  h5: textStyle,
  h6: textStyle,
  subtitle1: {
    fontFamily: font,
  },
  subtitle2: {
    fontFamily: font,
  },
  body2: {
    fontSize: window.innerWidth < 1200 ? '.75rem' : '.85rem',
  },
}

export default typographyConfig
