import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'
import { isCpf } from 'validator-brazil'
import { useParams, useHistory } from 'react-router-dom'

import * as Yup from 'yup'
import moment from 'moment'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import FormGroup from '@material-ui/core/FormGroup'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { usePrice } from '../../../../shared/contexts/PriceContext'

import useUtils from '../../../../shared/hooks/useUtils'
import {
  useAlert,
  DateInput,
  useLoader,
  ButtonLight,
  GreenCheckbox,
  ButtonGradient,
} from '../../../../shared/components'
import { GlobalMessages } from '../../../../shared/messages'
import CardIconTicketAndInvoice from './fragments/CardIconTicketAndInvoice'
import DropdownSelectOne from '../../../../shared/components/DropdownSelectOne/DropdownSelectOne'

import { usePaymentQuotationClient, useQuotationClient } from '../../../../clients/QuotationClient'

import icoTicket from '../../../../assets/img/icons/ico-boleto.svg'
import iconInvoice from '../../../../assets/img/icons/ico-invoice.svg'

import {
  QUOTE_BID,
  QUOTE_LEASE_BAIL,
  QUOTE_PERFORMANCE,
  QUOTE_JUDICIAL_LABOR,
  QUOTE_JUDICIAL_CIVEL,
  QUOTE_JUDICIAL_APPEAL,
  STATUS_PROPOSAL_DONE,
  STATUS_PROPOSAL_REQUESTED,
} from '../../../../constants'

import ModalPolicyEmission from './fragments/ModalPolicyEmission'
import ModalAnalysisRequest from './fragments/ModalAnalysisRequest'

import useStyles from './styles/infoPayment'

const SECONDS_WAITING = 6000
const MIN_PARCEL_VALUE_BID = 500
const MAX_SECONDS_WAITING = 60000
const MIN_PARCEL_VALUE_PERFORMANCE = 500
const MIN_PARCEL_VALUE_JUDICIAL_LABOR = 500
const MIN_PARCEL_VALUE_JUDICIAL_CIVEL = 500
const MIN_PARCEL_VALUE_LEASE_BAIL_PF = 1
const MIN_PARCEL_VALUE_LEASE_BAIL_PJ = 400

const GARANTIA_BID = 'garantia-bid'
const JUDICIAL_CIVEL = 'judicial-civel'
const JUDICIAL_LABOR = 'judicial-trabalhista'
const FIANCA_LOCATICIA = 'fianca-locaticia'
const GARANTIA_PERFORMANCE = 'garantia-performance'

const DEFAULT_CONFIRMATION = {
  paymentType: '',
  installments: '',
  firstInstallmentDueDateDelay: '',
  responsibilityConfirmation: false,
}

const styleIconTicket = {
  height: 40,
  width: 59.1,
  marginTop: 0,
  marginBottom: 0,
}
const styleIconInvoice = {
  height: 76,
  width: 60,
  marginTop: -5,
  marginBottom: -10,
}

const InfoPayment = forwardRef((props, ref) => {
  const classes = useStyles()

  const history = useHistory()

  const { handleNext, handlePrevious, quotationFull } = props

  const {
    onRegister,
    actionNext,
    addMsgError,
    confirmation,
    setConfirmation,
  } = usePrice()

  const { quote } = confirmation

  const { addMsgSuccess } = useAlert()
  const { showLoader, hideLoader } = useLoader()
  const { quotationIdentifier: quotationIdentifierParam } = useParams()
  const {
    date,
    formatDate,
    ternaryCheck,
    formatDateApi,
    formatCurrency,
    checkStringEmpty,
    makeQueryPromise,
  } = useUtils()

  const [parcels, setParcels] = useState([''])
  const [checked, setChecked] = useState(false)
  const [dataFormik, setDataFormik] = useState()
  const [datePayment, setDatePayment] = useState('')
  const [selectedParcel, setSelectedParcel] = useState(1)
  const [idButtonClicked, setIdButtonClicked] = useState()
  const [selectedDaysParcel, setSelectedDaysParcel] = useState('')
  const [selectedPaymentType, setSelectedPaymentType] = useState(1)
  const [issueByInvoice, setIssueByInvoice] = useState(false)

  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [openModalConfirmPolicy, setOpenModalConfirmPolicy] = useState(false)

  const minDatePayment = useMemo(() => new Date(Date.now() + (3600 * 1000 * 24)), [])

  const maxDatePayment = useMemo(() => date().add(30, 'days'), [date])

  // eslint-disable-next-line max-len
  const checkJDR = useCallback(() => quotationFull?.QuoteType === QUOTE_JUDICIAL_APPEAL, [quotationFull])

  const quotationIdentifier = useMemo(() => ternaryCheck(
    !isEmpty(quotationIdentifierParam),
    quotationIdentifierParam,
    quotationFull?.Identifier,
  ), [quotationIdentifierParam, quotationFull?.Identifier, ternaryCheck])

  const quotationClient = useQuotationClient()
  const paymentQuotationClient = usePaymentQuotationClient()

  const getCommercialPremiumFormat = () => (
    formatCurrency(ternaryCheck(
      Number(quote?.CommercialPremium) < Number(quote?.GrossPremium),
      quote?.GrossPremium,
      quote?.CommercialPremium + quote?.IOF,
    ))
  )

  const isAcceptedRequired = (buttonClicked) => quotationFull?.QuoteType === QUOTE_LEASE_BAIL
    || !(['policy', 'proposal'].includes(buttonClicked))

  const isQuoteTypeShowButtons = () => ([
    QUOTE_BID,
    QUOTE_PERFORMANCE,
    QUOTE_JUDICIAL_LABOR,
    QUOTE_JUDICIAL_CIVEL,
    QUOTE_JUDICIAL_APPEAL,
  ].includes(quotationFull?.QuoteType))

  const isStatusShowButtonRequestAnalysis = () => ([
    STATUS_PROPOSAL_DONE,
    STATUS_PROPOSAL_REQUESTED,
  ].includes(quotationFull?.Status))

  const checkProductId = useCallback((productIdLoaded) => (
    productIdLoaded === quote?.ProductId
  ), [quote?.ProductId])

  const checkGrossPremium = useCallback((grossPremiumLoaded) => (
    quote?.GrossPremium > grossPremiumLoaded
  ), [quote?.GrossPremium])

  const checkStatusObject = useCallback((object, status) => (
    object?.Status === status
  ), [])

  const verifyProposal = (id) => {
    setIdButtonClicked(id)
    handleNext()
  }

  const viewDraft = (quoteResponse) => new Promise((resolve) => {
    setIdButtonClicked('draft')
    if (isQuoteTypeShowButtons() && checkStatusObject(quoteResponse, STATUS_PROPOSAL_DONE)) {
      const windowOpened = window.open(`${process.env.REACT_APP_BFF_URL}/quote/draft/document?codigo=${quotationIdentifier}`, '_blank')
      resolve(windowOpened)
      return
    }

    handleNext()
  })

  const getQuotationByIdentifier = useCallback((quoteIdentifier) => new Promise(
    (resolve, reject) => {
      quotationClient().getQuotationDetails(quoteIdentifier)
        .then((response) => {
          resolve(response?.data)
        }, (error) => {
          reject(error)
        })
    },
  ), [quotationClient])

  const startIntervalGetQuote = (quoteRegisterData) => {
    let promiseRequest = null
    let countSecondsWaiting = 1
    const intervalApprovation = setInterval(async () => {
      if (countSecondsWaiting * SECONDS_WAITING >= MAX_SECONDS_WAITING) {
        hideLoader()
        onRegister(quoteRegisterData)
        clearInterval(intervalApprovation)
      } else {
        if (!promiseRequest) {
          let quoteResponse = null
          promiseRequest = makeQueryPromise(getQuotationByIdentifier(quotationIdentifier))

          await promiseRequest.then((response) => {
            if (promiseRequest.isFulfilled()) {
              quoteResponse = response
            }
          }).catch(() => {
            promiseRequest = null
          })

          if (!isEmpty(quoteResponse) && checkStatusObject(quoteResponse, STATUS_PROPOSAL_DONE)) {
            viewDraft(quoteResponse).then((tabOpened) => {
              tabOpened.focus()
              hideLoader()
              window.location.reload(false)
              clearInterval(intervalApprovation)
            })
          } else {
            promiseRequest = null
          }
        }

        countSecondsWaiting++
      }
    }, SECONDS_WAITING)
  }

  const sendAnalysisRequest = (dataPayment) => {
    if (!!idButtonClicked && idButtonClicked !== 'draft') {
      onRegister(dataPayment)
      return
    }

    showLoader()
    startIntervalGetQuote(dataPayment)
  }

  const setParcelsPayment = useCallback((maxParcelValue, maxParcelas) => {
    const parcelas = []
    const isMaxParcel = ternaryCheck(maxParcelValue > maxParcelas, maxParcelas, maxParcelValue)

    for (let i = 1; i <= isMaxParcel; i++) {
      parcelas.push({ value: i, text: `${i}x de ${formatCurrency((quote?.GrossPremium / i))}` })
    }

    setParcels(parcelas)
  }, [quote, formatCurrency, setParcels, ternaryCheck])

  const handleConfirmation = ({ analysisText }) => {
    quotationClient().sendAnalysisRequest({
      identifier: quotationIdentifier,
      reasonAnalysis: analysisText,
    }).then(({ data }) => {
      const dataSuccess = {
        title: data?.Title,
        message: data?.Message,
        action: () => history.replace('/status-solicitacao'),
      }

      addMsgSuccess(dataSuccess)
    }, (error) => {
      addMsgError(error)
    })
  }

  const handleParcelasBID = useCallback(() => {
    const startDate = moment(quotationFull?.BID?.ObjectProperty?.StartDateValidity)
    const endData = moment(quotationFull?.BID?.ObjectProperty?.EndDateValidity)

    const vigencia = (endData.diff(startDate, 'months') - 1)
    const maxParcelaTempo = vigencia === 0 ? 1 : vigencia

    const maxParcelaValor = Math.floor(quote?.GrossPremium / MIN_PARCEL_VALUE_BID)

    setParcelsPayment(
      ternaryCheck(maxParcelaValor < maxParcelaTempo, maxParcelaValor, maxParcelaTempo),
      5,
    )
  }, [quote, quotationFull, setParcelsPayment, ternaryCheck])

  const handleParcelasPerformance = useCallback(() => {
    const startDate = moment(quotationFull?.Performance?.ObjectProperty?.StartDateValidity)
    const endData = moment(quotationFull?.Performance?.ObjectProperty?.EndDateValidity)

    const vigencia = (endData.diff(startDate, 'months') - 1)
    const maxParcelaTempo = (vigencia === 0 || vigencia === -1) ? 1 : vigencia

    const maxParcelaValor = Math.floor(quote?.GrossPremium / MIN_PARCEL_VALUE_PERFORMANCE)

    setParcelsPayment(
      ternaryCheck(maxParcelaValor < maxParcelaTempo, maxParcelaValor, maxParcelaTempo),
      5,
    )
  }, [quote, quotationFull, setParcelsPayment, ternaryCheck])

  const handleParcelasJudicialLabor = useCallback(() => {
    const startDate = moment(quotationFull?.JudicialLabor?.ObjectProperty?.StartDateValidity)
    const endData = moment(quotationFull?.JudicialLabor?.ObjectProperty?.EndDateValidity)

    const vigencia = (endData.diff(startDate, 'months') - 1)
    const maxParcelaTempo = vigencia === 0 ? 1 : vigencia

    const maxParcelaValor = Math.floor(quote?.GrossPremium / MIN_PARCEL_VALUE_JUDICIAL_LABOR)

    setParcelsPayment(
      ternaryCheck(maxParcelaValor < maxParcelaTempo, maxParcelaValor, maxParcelaTempo),
      5,
    )
  }, [quote, quotationFull, setParcelsPayment, ternaryCheck])

  const handleParcelasJudicialCivel = useCallback(() => {
    const startDate = moment(quotationFull?.JudicialCivel?.ObjectProperty?.StartDateValidity)
    const endData = moment(quotationFull?.JudicialCivel?.ObjectProperty?.EndDateValidity)

    const vigencia = (endData.diff(startDate, 'months') - 1)
    const maxParcelaTempo = vigencia === 0 ? 1 : vigencia

    const maxParcelaValor = Math.floor(quote?.GrossPremium / MIN_PARCEL_VALUE_JUDICIAL_CIVEL)

    setParcelsPayment(
      ternaryCheck(maxParcelaValor < maxParcelaTempo, maxParcelaValor, maxParcelaTempo),
      5,
    )
  }, [quote, quotationFull, setParcelsPayment, ternaryCheck])

  const isCpfGuarantee = useCallback(() => {
    const cpfGuarantee = quotationFull?.RentalGuarantee?.Guaranteed?.CpfCnpj
    return !isEmpty(cpfGuarantee) && isCpf(cpfGuarantee)
  }, [quotationFull])

  const handleHire = () => {
    setOpenModalConfirm(true)
  }

  const handleHirePolicy = (formikData) => {
    setOpenModalConfirmPolicy(true)
    setDataFormik(formikData)
  }

  const closeModal = () => {
    setOpenModalConfirm(false)
    setOpenModalConfirmPolicy(false)
  }

  const deadlineInsurance = quotationFull?.RentalGuarantee?.Insurance?.Deadline
  const maxParcelaTempo = ternaryCheck(deadlineInsurance <= 23, (deadlineInsurance - 2), 18)
  const maxParcelaValor = ternaryCheck(maxParcelaTempo > 1, maxParcelaTempo, 1)

  const handleParcelasFLPF = useCallback(() => {
    setParcelsPayment(
      maxParcelaValor,
      maxParcelaValor,
    )
  }, [setParcelsPayment, maxParcelaValor])

  const handleParcelasFLPJ = useCallback(() => {
    const minDivider = Math.floor(quote?.GrossPremium / MIN_PARCEL_VALUE_LEASE_BAIL_PJ)
    const maxParcela = ternaryCheck(maxParcelaValor < minDivider, maxParcelaValor, minDivider)

    setParcelsPayment(
      maxParcela,
      maxParcela,
    )
  }, [quote, maxParcelaValor, setParcelsPayment, ternaryCheck])

  const handleParcelas = useCallback(() => {
    if (checkJDR() && issueByInvoice) {
      setParcelsPayment(1, 1)
    } else {
      setParcelsPayment(1, 5)
    }

    if (checkProductId(GARANTIA_BID) && checkGrossPremium(MIN_PARCEL_VALUE_BID)) {
      handleParcelasBID()
    }

    if (checkProductId(GARANTIA_PERFORMANCE) && checkGrossPremium(MIN_PARCEL_VALUE_PERFORMANCE)
    ) {
      handleParcelasPerformance()
    }

    if (checkProductId(JUDICIAL_LABOR) && checkGrossPremium(MIN_PARCEL_VALUE_JUDICIAL_LABOR)) {
      handleParcelasJudicialLabor()
    }

    if (checkProductId(JUDICIAL_CIVEL) && checkGrossPremium(MIN_PARCEL_VALUE_JUDICIAL_CIVEL)) {
      handleParcelasJudicialCivel()
    }

    if (checkProductId(FIANCA_LOCATICIA) && (!isCpfGuarantee
      && checkGrossPremium(MIN_PARCEL_VALUE_LEASE_BAIL_PJ)
    )) {
      handleParcelasFLPJ()
    }

    if (checkProductId(FIANCA_LOCATICIA) && (isCpfGuarantee
      && checkGrossPremium(MIN_PARCEL_VALUE_LEASE_BAIL_PF))) {
      handleParcelasFLPF()
    }
  }, [
    checkJDR,
    issueByInvoice,
    setParcelsPayment,
    handleParcelasBID,
    handleParcelasPerformance,
    isCpfGuarantee,
    checkProductId,
    checkGrossPremium,
    handleParcelasFLPJ,
    handleParcelasFLPF,
    handleParcelasJudicialLabor,
    handleParcelasJudicialCivel,
  ])

  const formik = useFormik({
    initialValues: { ...DEFAULT_CONFIRMATION },
    validationSchema: Yup.object({
      paymentType: Yup.string().required(),
      installments: Yup.number().required(),
      firstInstallmentDueDateDelay: Yup.number().when(() => (!issueByInvoice
        ? Yup.number()
          .test('firstInstallmentDueDateDelay', `A data mínima permitida é ${formatDate(date().add(1, 'days'))}`, (value) => (value && Number(value) > 0))
          .test('firstInstallmentDueDateDelay', `A data máxima permitida é ${formatDate(date().add(30, 'days'))}`, (value) => (value && Number(value) < 31))
          .required()
        : Yup.number()
      )),
      responsibilityConfirmation: Yup.boolean()
        .required()
        .oneOf([true], 'Os termos e condições devem ser lidos e aceitos.'),
    }),
    onSubmit: (data) => {
      const values = {
        ...{ identifier: quotationIdentifier },
        ...{
          payment: {
            ...data,
            firstInstallmentDueDateDelay: !issueByInvoice
              ? formik.values.firstInstallmentDueDateDelay : null,
          },
        },
        ...{
          acceptRequired: isAcceptedRequired(idButtonClicked),
        },
      }
      setConfirmation({ ...confirmation, ...values })

      if (actionNext) {
        handleHirePolicy(values)
      }
    },
  })

  const getDiffDate = (dateFinal) => {
    const formatApi = 'YYYY-MM-DD'
    const inicial = date(minDatePayment)?.format(formatApi)
    const final = date(dateFinal)?.format(formatApi)
    const diffIn = new Date(final) - new Date(inicial)
    const diffDays = (diffIn / (1000 * 60 * 60 * 24)) + 1
    return diffDays > 0 ? diffDays : 0
  }

  const handleDate = (e) => {
    const daysDiff = getDiffDate(e.target.value)
    formik.setFieldValue('firstInstallmentDueDateDelay', daysDiff)
    setDatePayment(formatDateApi(date(e.target.value)))
    setSelectedDaysParcel(daysDiff)
  }

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  const { setValues } = formik

  useEffect(() => {
    handleParcelas()
    setValues({
      installments: checkStringEmpty(selectedParcel),
      paymentType: checkStringEmpty(selectedPaymentType),
      responsibilityConfirmation: ternaryCheck(checked, checked, false),
      firstInstallmentDueDateDelay: checkStringEmpty(selectedDaysParcel),
    })
  }, [
    checked,
    setValues,
    confirmation,
    ternaryCheck,
    handleParcelas,
    selectedParcel,
    checkStringEmpty,
    selectedDaysParcel,
    selectedPaymentType,
    paymentQuotationClient,
  ])

  const handleViewDraft = () => {
    window.open(`/cotacao/${quotationIdentifier}/minuta`, '_blank')
  }

  const policyHolderJDR = quotationFull?.AppealDeposit?.PolicyHolderClaimant?.Document

  const checkissueByInvoice = (value, value2) => {
    if (issueByInvoice) {
      return value
    }
    return value2
  }

  const getPaymentType = useCallback(() => {
    quotationClient().getPaymentType(policyHolderJDR)
      .then((response) => {
        setIssueByInvoice(response?.data?.emissaoEmFaturaC)
        if (response?.data?.emissaoEmFaturaC) {
          setSelectedPaymentType(5)
        } else {
          setSelectedPaymentType(1)
        }
      })
  }, [quotationClient, policyHolderJDR])

  useEffect(() => {
    if (checkJDR()) {
      getPaymentType()
    }
  }, [checkJDR, getPaymentType])

  const parcelLabel = 'Informe o número de parcelas'
  const resposibilityLabel = 'Li e Aceito os Termos e Condições'

  return (
    <>
      <Box className={classes.root}>
        <Paper elevation={1}>
          <Grid container>
            <Grid
              item
              xs={12}
              className={classes.header}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" color="initial">
                    Dados de Pagamento
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.labelInfo}>
                    Informe abaixo os dados de pagamento
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.content}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Grid container className={classes.root}>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        className={classes.subheader}
                        data-content="Forma de Pagamento"
                      />

                      <Grid container className={classes.paymentsTypes} spacing={2}>
                        <Grid item xs={12}>
                          <CardIconTicketAndInvoice
                            text={checkissueByInvoice('Fatura', 'Boleto')}
                            svg={checkissueByInvoice(iconInvoice, icoTicket)}
                            styles={checkissueByInvoice(styleIconInvoice, styleIconTicket)}
                            className={(selectedPaymentType === 1 || selectedPaymentType === 5) ? 'selected' : ''}
                            onClick={() => {
                              if (issueByInvoice) {
                                setSelectedPaymentType(5)
                              } else {
                                setSelectedPaymentType(1)
                              }
                              setSelectedParcel(1)
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        className={classes.subheader}
                        data-content="Valores"
                      />

                      <Grid container className={classes.confirmationBox} spacing={2}>
                        <Grid item xs={12}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Prêmio Líquido
                          </Typography>
                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {formatCurrency(quote?.CommercialPremium)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography gutterBottom className={classes.confirmationLabel}>
                            Prêmio Total
                          </Typography>
                          <Typography variant="subtitle1" className={classes.confirmationValue}>
                            {getCommercialPremiumFormat()}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        className={classes.subheader}
                        data-content="Parcelas"
                      />

                      <Grid container className={classes.confirmationBox} spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <label htmlFor="parcelLabel" className={classes.label}>{parcelLabel}</label>
                            <DropdownSelectOne
                              id="installments"
                              options={parcels}
                              placeholder="Selecione"
                              {...formik.getFieldProps('installments')}
                              error={formik.touched.installments && !!formik.errors.installments}
                              onChange={(e) => {
                                const { value } = e.target
                                setSelectedParcel(value)
                              }}
                            />
                            <FormHelperText
                              hidden={!formik.touched.installments || !formik.errors.installments}
                              error={formik.touched.installments && !!formik.errors.installments}
                            >
                              {formik.errors.installments}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.confirmationBox} spacing={2}>
                        {!issueByInvoice && (
                          <Grid item xs={12} sm={6}>
                            <FormGroup>
                              <InputLabel className={classes.labelInfo}>
                                Pagamento 1ª parcela
                              </InputLabel>

                              <DateInput
                                minDate={date(minDatePayment)}
                                maxDate={maxDatePayment}
                                value={datePayment}
                                title="Pagamento 1ª parcela"
                                error={formik.touched.firstInstallmentDueDateDelay
                                  && !!formik.errors.firstInstallmentDueDateDelay}
                                helperText={formik.touched.firstInstallmentDueDateDelay
                                  && formik.errors.firstInstallmentDueDateDelay}
                                onChange={(event) => {
                                  handleDate(event)
                                }}
                              />
                            </FormGroup>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <FormControlLabel
                      control={(
                        <GreenCheckbox
                          id="responsibilityConfirmation"
                          checked={checked}
                          {...formik.getFieldProps('responsibilityConfirmation')}
                          onChange={() => {
                            setChecked(!checked)
                            formik.values.responsibilityConfirmation = !checked
                          }}
                        />
                      )}
                    />
                    <Typography
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      onClick={() => {
                        addMsgSuccess({
                          title: 'Termos e Condições',
                          labelButton: 'Fechar',
                          message: `Transparência - A Finlândia Corretora de Seguros busca garantir o mais alto nível de integridade
                            corporativa e ética em suas atividades, cumprindo com seus deveres de forma
                            honesta e transparente. Baseados em nossos valores, temos o compromisso de
                            respeitar e cumprir rigorosamente as legislações e regulamentos aplicáveis ao
                            nosso negócio, garantindo a agilidade na prestação de serviços e a segurança
                            dos nossos clientes. Por isso, temos diretrizes bem definidas aplicadas ao
                            nosso dia a dia, que nos permite realizar cotações nas mais diversas
                            companhias e proporcionar um atendimento eficaz por meio dessas parcerias. Vale
                            ressaltar que possuímos sócios em comum com a Pottencial Seguradora, o que só
                            reforça a nossa eficiência, solidez e estabilidade nos negócios. Nossa política
                            de comissionamento, enquanto corretora, será de 15% a 27% sobre o prêmio, para
                            as modalidades de seguro garantia e fiança locatícia, de 15% a 30% sobre o
                            prêmio para as demais modalidades de seguro. Informamos ainda que, como
                            instrumento de incentivo, respeitamos os limites éticos e normativos
                            aplicáveis, a corretora possui contrato de incentivo com as seguradoras do
                            mercado.`,
                          action: '',
                          maxWidth: 'md',
                        })
                      }}
                    >
                      {resposibilityLabel}
                    </Typography>
                  </Grid>
                  <FormHelperText
                    hidden={
                      !formik.touched.responsibilityConfirmation
                      || !formik.errors.responsibilityConfirmation
                    }
                    error={
                      formik.touched.responsibilityConfirmation
                      && !!formik.errors.responsibilityConfirmation
                    }
                  >
                    {formik.errors.responsibilityConfirmation}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.buttonsStep}>
                    <Box>
                      <ButtonLight
                        onClick={handlePrevious}
                        title="Voltar"
                      >
                        Voltar
                      </ButtonLight>
                    </Box>
                    {!isQuoteTypeShowButtons() ? (
                      <Box>
                        <ButtonGradient
                          onClick={() => verifyProposal('proposal')}
                          title="Pedir Proposta"
                        >
                          Pedir Proposta
                        </ButtonGradient>
                      </Box>
                    ) : (
                      <>
                        <Box>
                          <ButtonLight
                            onClick={handleViewDraft}
                          >
                            Visualizar Minuta
                          </ButtonLight>
                        </Box>
                        {
                          (quotationFull?.QuoteType !== QUOTE_LEASE_BAIL
                            && !isStatusShowButtonRequestAnalysis()
                          ) && (
                            <>
                              <Box>
                                <ButtonLight
                                  id="policy"
                                  onClick={handleHire}
                                >
                                  Solicitar Análise
                                </ButtonLight>
                              </Box>
                              <ModalAnalysisRequest
                                closeModal={closeModal}
                                openModal={openModalConfirm}
                                handleConfirmation={handleConfirmation}
                              />
                            </>
                          )
                        }
                        <Box>
                          <ButtonGradient
                            onClick={() => verifyProposal('policy')}
                          >
                            Emitir Apólice
                          </ButtonGradient>
                        </Box>
                        <ModalPolicyEmission
                          closeModal={closeModal}
                          openModal={openModalConfirmPolicy}
                          handleConfirmation={() => {
                            setOpenModalConfirmPolicy(false)

                            quotationClient()
                              .sendInfoPayment({ request: { ...dataFormik } }).then((response) => {
                                sendAnalysisRequest(response?.data)
                              }, (response) => {
                                addMsgError(response.data)
                              })
                          }}
                        />
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  )
})

InfoPayment.propTypes = {
  quotation: PropTypes.object,
  quotationFull: PropTypes.object,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

InfoPayment.defaultProps = {
  quotation: {},
  quotationFull: {},
}

export default InfoPayment
