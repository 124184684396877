import React, {
  useState,
  useEffect,
} from 'react'
import { PropTypes } from 'prop-types'
import { isEmpty } from 'lodash'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import useQuotationClient from '../../../../clients/QuotationClient/useQuotationClient'
import { ReactComponent as DownloadIcon } from '../../../../assets/img/icons/ico-download.svg'

import useDownload from '../../../../shared/hooks/useDownload'
import { useAlert } from '../../../../shared/components'
import useUtils from '../../../../shared/hooks/useUtils'

import useProductQuotationClient from '../../../../clients/ProductClient/useProductQuotationClient'
import useQuotationResourceClient from '../../../../clients/QuotationClient/useQuotationResourceClient'

import useStyles from './styles/containerFields'

const productLabel = 'Produto:'
const modalityLabel = 'Modalidade:'
const contractPenaltyLabel = 'O contrato exige cobertura para multa?'
const employmentClauseAgreementLabel = 'O contrato exige cobertura para cláusula trabalhista?'

const dateOpeningLabel = 'Data de Abertura:'
const nameInsuredLabel = 'Segurado:'
const policyHolderLabel = 'Tomador:'
const cpfCnpjLabel = 'CPF/CNPJ:'
const claimantLabel = 'Reclamante:'

const resourceOptionsLabel = 'Recursos Adicionais:'
const effortValueLabel = 'Valor Empenho:'
const dateSignatureLabel = 'Data de Assinatura:'
const dateServicesLabel = 'Data de Serviços:'
const resourceAdvanceLabel = 'Adiantamento de Recursos:'

const valueContractLabel = 'Valor Contrato:'
const deadlineLabel = 'Prazo (Dias):'
const objectGuaranteeLabel = 'Obrigação Garantia (Objeto):'
const guaranteeAmountLabel = 'Valor Garantia:'
const dateValidityLabel = 'Data de Início:'
const endDateValidityLabel = 'Data do Vencimento:'

const CDALabel = 'CDA:'
const objectNumberLabel = 'Objeto'
const numberEdictLabel = 'Número do Edital:'
const contractNumberLabel = 'Número do Contrato:'
const additiveNumberLabel = 'Número do Aditivo:'
const processNumberLabel = 'Número do Processo:'
const nameJudgmentLabel = 'Juízo:'
const nameProposalLabel = 'Ação Proposta:'
const numberInfractionNoticeLabel = 'Número Auto Infração:'
const insuredImportanceLabel = 'Importância Segurada:'
const startDateValidityLabel = 'Início de Vigência:'
const endDateValidityVigLabel = 'Fim de Vigência:'
const percentageHarmLabel = 'Percentual de Agravo (%):'

const cepLabel = 'CEP:'
const cityLabel = 'Cidade:'
const stateLabel = 'Estado:'
const numberLabel = 'Número:'
const districtLabel = 'Bairro:'
const placeLabel = 'Logradouro:'
const complementLabel = 'Complemento:'

const requesterLabel = 'Nome do Solicitante'
const requesterPhoneLabel = 'Telefone do Solicitante'
const requesterEmailLabel = 'E-mail do Solicitante'
const idClientControlLabel = 'Identificador do Controle do Cliente'
const commentsLabel = 'Observações'

const ContainerFields = (props) => {
  const classes = useStyles()
  const { quotation } = props
  const {
    formatDate,
    formatCurrency,
    formatCPFOrCNPJ,
    formatPhoneOrCellphone,
  } = useUtils()

  const { downloadPDF } = useDownload()
  const { addMsgDanger } = useAlert()
  const quotationClient = useQuotationClient()

  const [resources, setResources] = useState([])
  const [modalities, setModalities] = useState([])
  const [modalityType, setModalityType] = useState('')

  const productQuotationClient = useProductQuotationClient()
  const quotationResourceClient = useQuotationResourceClient()

  useEffect(() => {
    productQuotationClient().getAllTypeOtherModality().forEach((otherModality) => {
      if (otherModality.value === quotation?.detailsProduct.modalityType) {
        setModalityType(otherModality?.text)
        setModalities(otherModality?.modalities)
      }
    })
  }, [quotation, setModalities, productQuotationClient])

  useEffect(() => {
    quotationResourceClient().getAll().then((quotationResources) => {
      setResources(quotationResources)
    })
  }, [setResources, quotationResourceClient])

  const getTextModalityByValue = (valueModality) => (modalities?.find((modality) => (
    modality.value === valueModality
  ))?.text ?? '')

  const downloadDocuments = (document) => {
    quotationClient().downloadDocumentsWings(document).then((response) => {
      downloadPDF(response?.data, document)
    }, () => {
      addMsgDanger('Não foi possível baixar o arquivo, tente mais tarde ou contate o Administrador.')
    })
  }

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Detalhes do Produto"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} lg={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {productLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.Product?.Name ?? 'Seguro Garantia'}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {modalityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {`${getTextModalityByValue(quotation?.detailsProduct.modality)} - ${modalityType}`}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6} hidden={!quotation?.detailsProduct.coverageFine}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {contractPenaltyLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.detailsProduct.coverageFine ? 'Sim' : 'Não'}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6} hidden={!quotation?.detailsProduct.coverageLabor}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {employmentClauseAgreementLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.detailsProduct.coverageLabor ? 'Sim' : 'Não'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Dados do Segurado"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} hidden={!quotation?.insured.dateOpening}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {dateOpeningLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.insured.dateOpening}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {policyHolderLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.insured.policyHolder}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {cpfCnpjLabel}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {formatCPFOrCNPJ(quotation?.insured.document)}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography gutterBottom className={classes.confirmationLabel}>
                    {nameInsuredLabel}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.confirmationValue}>
                    {quotation?.insured.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {quotation?.resource && (
          <Grid item xs={12} hidden={!quotation?.resource}>
            <Typography
              gutterBottom
              className={classes.header}
              data-content="Recursos"
            />
            <Grid container className={classes.confirmationBox} spacing={2}>
              <Grid item xs={12}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {resourceAdvanceLabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {quotation?.resource.resourceAdvance ? 'Sim' : 'Não'}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {valueContractLabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {formatCurrency(quotation?.resource.contractValue)}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {effortValueLabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {formatCurrency(quotation?.resource.effortValue)}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {dateSignatureLabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {formatDate(quotation?.resource.dateSignature)}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {dateServicesLabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {formatDate(quotation?.resource.dateServices)}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {resourceOptionsLabel}
                </Typography>
                {resources.map((item, index) => (
                  <Typography
                    key={index}
                    variant="subtitle1"
                    hidden={!quotation?.resource[item.id]}
                    className={classes.confirmationValue}
                  >
                    {item.text}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
        {quotation?.claimant && (
          <Grid item xs={12}>
            <Typography
              gutterBottom
              className={classes.header}
              data-content="Reclamante"
            />
            <Grid container className={classes.confirmationBox} spacing={2}>
              <Grid item xs={12} sm={3} md={2}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {cpfCnpjLabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {formatCPFOrCNPJ(quotation?.claimant?.document)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} md={4}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {claimantLabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {quotation?.claimant?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} lg={6}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {cepLabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {quotation?.claimant.cep}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.confirmationBox} spacing={2}>
              <Grid item xs={12} sm={3} lg={2}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {placeLabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {quotation?.claimant.place}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} lg={4}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {numberLabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {quotation?.claimant.number}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} lg={6}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {complementLabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {quotation?.claimant.complement}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} lg={2}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {districtLabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {quotation?.claimant.district}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} lg={4}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {stateLabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {quotation?.claimant.state}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} lg={6}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {cityLabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {quotation?.claimant.city}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Objeto Segurado"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} lg={3} hidden={!quotation?.objectProperty.processNumber}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {processNumberLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.objectProperty.processNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3} hidden={!quotation?.objectProperty.objectNumber}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {objectNumberLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.objectProperty.objectNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3} hidden={!quotation?.objectProperty.contractNumber}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {contractNumberLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.objectProperty.contractNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3} hidden={!quotation?.objectProperty.additiveNumber}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {additiveNumberLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.objectProperty.additiveNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3} hidden={!quotation?.objectProperty.noticeNumber}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {numberEdictLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.objectProperty.noticeNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3} hidden={!quotation?.objectProperty.proposalNumber}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {nameProposalLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.objectProperty.proposalNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3} hidden={!quotation?.objectProperty.nameJudgment}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {nameJudgmentLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.objectProperty.nameJudgment}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              lg={3}
              hidden={!quotation?.objectProperty.numberInfractionNotice}
            >
              <Typography gutterBottom className={classes.confirmationLabel}>
                {numberInfractionNoticeLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.objectProperty.numberInfractionNotice}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3} hidden={!quotation?.objectProperty.CDANumber}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {CDALabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.objectProperty.CDANumber}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3} hidden={!quotation?.objectProperty.contractValue}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {valueContractLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.objectProperty.contractValue)}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3} hidden={!quotation?.objectProperty.guaranteeValue}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {guaranteeAmountLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.objectProperty.guaranteeValue)}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3} hidden={!quotation?.objectProperty.percentageHarm}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {percentageHarmLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.objectProperty.percentageHarm}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3} hidden={!quotation?.objectProperty.insuredImportance}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {insuredImportanceLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatCurrency(quotation?.objectProperty.insuredImportance)}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {!isEmpty(quotation?.objectProperty.CDANumber)
                  ? startDateValidityLabel
                  : dateValidityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatDate(quotation?.objectProperty.startDateValidity)}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {!isEmpty(quotation?.objectProperty.CDANumber)
                  ? endDateValidityVigLabel
                  : endDateValidityLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatDate(quotation?.objectProperty.endDateValidity)}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {deadlineLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.objectProperty.deadline}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {objectGuaranteeLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.objectProperty.objectText}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Solicitante"
          />
          <Grid container className={classes.confirmationBox} spacing={2}>
            <Grid item xs={12} lg={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {requesterLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.requester.name}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {requesterPhoneLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {formatPhoneOrCellphone(quotation?.requester.phone)}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {requesterEmailLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.requester.email}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              hidden={!quotation?.requester.customerControlIdentifier}
            >
              <Typography gutterBottom className={classes.confirmationLabel}>
                {idClientControlLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.requester.customerControlIdentifier}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={4} hidden={!quotation?.requester.comments}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {commentsLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {quotation?.requester.comments}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.containerFileUpload}>
          <Typography
            gutterBottom
            className={classes.header}
            data-content="Arquivos"
          />
          <Grid className={classes.fileUpload}>
            <Grid item xs={4}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                Nome do Arquivo:
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                Download:
              </Typography>
            </Grid>
          </Grid>
          {(quotation?.fileUpload?.Documents ?? quotation?.fileUpload)
            ?.map((item, index) => (
              <Grid key={index} item xs={12} className={classes.fileUpload}>
                <Grid item xs={4}>
                  <Typography className={classes.confirmationValue}>
                    {item?.DocumentName}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Box>
                    <Button
                      variant="text"
                      onClick={() => downloadDocuments(item?.DocumentID)}
                    >
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <Box mr={1}>
                          <DownloadIcon />
                        </Box>

                        <Box sx={{ textDecoration: 'underline', fontSize: 17 }}>
                          <b>Download</b>
                        </Box>
                      </Box>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </>
  )
}

ContainerFields.propTypes = {
  quotation: PropTypes.any.isRequired,
}

export default ContainerFields
