import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'

import UserMenu from './UserMenu'
import NotificationMenu from './NotificationMenu'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      justifyContent: 'center',
      '& :nth-child(1)': {
        order: 2,
      },
      '& :nth-child(2)': {
        order: 3,
      },
      '& :nth-child(3)': {
        order: 1,
      },
    },
  },
  containerHeader: {
    padding: '8px',
    display: 'flex',
    borderRadius: '35px',
    backgroundColor: '#FFFFFF',
  },
  containerTools: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '6px',
    paddingRight: '15px',
  },
  btn: {
    color: theme.palette.text.secondary,
  },
}))

const HeaderMenu = ({ logout, openModalPortalUpdates, closeModalRegistrationUpdate }) => {
  const classes = useStyles()

  return (
    <>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        className={classes.root}
      >
        <Grid item className={classes.containerHeader}>
          <Grid item className={classes.containerTools}>
            <NotificationMenu logout={logout} />
          </Grid>
          <Grid item xs md="auto">
            <UserMenu
              logout={logout}
              openModalPortalUpdates={openModalPortalUpdates}
              closeModalRegistrationUpdate={closeModalRegistrationUpdate}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

HeaderMenu.propTypes = {
  logout: PropTypes.func.isRequired,
  openModalPortalUpdates: PropTypes.bool.isRequired,
  closeModalRegistrationUpdate: PropTypes.func.isRequired,
}

export default HeaderMenu
