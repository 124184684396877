import React, { useContext } from 'react'

const ScreenLoaderContext = React.createContext()

export const useScreenLoader = () => {
  const {
    refreshLogin,
    setRefreshLogin,
    screenLoaded,
  } = useContext(ScreenLoaderContext)

  return {
    refreshLogin,
    setRefreshLogin,
    screenLoaded,
  }
}

export default ScreenLoaderContext
