import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from '../Auth'
import MasterRoutes from '../../routes/MasterRoutes'
import BackgroundHome from '../../assets/img/backgrounds/bg-home.webp'

const PrivateRoute = (props) => {
  const {
    layout: Layout, component: Component, functionality, bgImage, isLoadedScreen, ...other
  } = props

  const breadcrumbs = (routesMain) => routesMain.filter(
    ({ path }) => other.computedMatch.path.includes(path),
  ).map(({ path, routes, ...rest }) => ({
    path: Object.keys(other.computedMatch.params).length
      ? Object.keys(other.computedMatch.params).reduce(
        (path2, param) => path2.replace(
          `:${param}`, other.computedMatch.params[param],
        ), path,
      )
      : path,
    ...rest,
  }))

  const { user } = useContext(AuthContext)

  return (
    <Route
      render={(routeProps) => (user
        ? (
          <Layout
            bgImage={bgImage}
            crumbs={breadcrumbs(MasterRoutes)}
          >
            <React.Suspense fallback={<></>}>
              <Component {...routeProps} />
            </React.Suspense>
          </Layout>
        ) : (
          <Redirect to="/login" />
        )
      )}
      {...other}
    />
  )
}

PrivateRoute.propTypes = {
  functionality: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  component: PropTypes.any,
  bgImage: PropTypes.any,
  isLoadedScreen: PropTypes.bool,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
}

PrivateRoute.defaultProps = {
  component: undefined,
  isLoadedScreen: false,
  bgImage: BackgroundHome,
  functionality: undefined,
}

export default PrivateRoute
