import React, { useMemo, useState, useCallback } from 'react'

import Routes from './routes'
import useSecurity from './security/useSecurity'

import Loader from './shared/components/Loader'
import AlertProvider from './shared/components/Alert'
import ScreenLoaderContext from './shared/contexts/ScreenLoaderContext'
import BreadcrumbContext from './shared/contexts/BreadcrumbContext'

import './helpers/yup'
import './assets/style/global.scss'
import AxiosSetting from './helpers/axios'

function App() {
  const { signout } = useSecurity()
  const { Provider: ScreenLoaderProvider } = ScreenLoaderContext
  const { Provider: BreadcrumbProvider } = BreadcrumbContext

  const [loading, setLoading] = useState(false)
  const [showLoading, setShowLoading] = useState(true)
  const [refreshLogin, setRefreshLogin] = useState(false)
  const [titlePage, setTitlePage] = useState([])

  const signOut = useCallback(() => setTimeout(() => {
    signout()
  }), [signout])

  const handleError = useMemo(
    () => ({
      401: () => {
        signOut()
      },
      403: () => {
        signOut()
      },
    }),
    [signOut],
  )

  return (
    <>
      <AxiosSetting
        handleError={handleError}
        handleShowLoading={setShowLoading}
        onStartRequest={() => {
          if (showLoading) {
            setLoading(true)
          }
        }}
        onStopRequest={() => setLoading(false)}
      />
      <Loader show={loading} />
      <AlertProvider />

      <ScreenLoaderProvider value={{
        refreshLogin,
        setRefreshLogin,
        screenLoaded: !loading,
      }}
      >
        <BreadcrumbProvider value={{ titlePage, setTitlePage }}>
          <Routes />
        </BreadcrumbProvider>
      </ScreenLoaderProvider>
    </>
  )
}

export default App
