import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { isEqual, isEmpty } from 'lodash'
import ReactTooltip from 'react-tooltip'
import { isCnpj } from 'validator-brazil'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'

import makeStyles from '@material-ui/core/styles/makeStyles'

import Autocomplete from '@material-ui/lab/Autocomplete'
import SearchIcon from '@material-ui/icons/Search'

import { usePrice } from '../../../../../shared/contexts/PriceContext'
import useYup from '../../../../../shared/hooks/useYup'

import useReceitaWSClient from '../../../../../clients/ReceitaWSClient/useReceitaWSClient'

import {
  useAlert,
  useLoader,
  ButtonLight,
  ButtonGradient,
  CPFOrCNPJInput,
} from '../../../../../shared/components'
import { GlobalMessages } from '../../../../../shared/messages'
import CollapsePrice from '../../../../../shared/components/CollapsePrice/CollapsePrice'
import useSecurity from '../../../../../security/useSecurity'
import useUserClient from '../../../../../clients/UserClient/useUserClient'
import InsuredData from '../../../../../models/JudicialGuaranteeCivelModels/InsuredData'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  help: {
    color: theme.palette.text.quaternary,
  },
  noOptionsLabel: {
    fontSize: 14,
    color: '#c93952',
    fontWeight: 'bold',
  },
}))

const Insured = forwardRef((props, ref) => {
  const classes = useStyles()
  const userClient = useUserClient()
  const {
    getEmail,
  } = useSecurity()

  const {
    quote,
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props
  const { cpfOrCnpj } = useYup()
  const { showLoader, hideLoader } = useLoader()
  const { addMsgDanger } = useAlert()
  const receitaWSClient = useReceitaWSClient()

  const {
    onNext,
    actionNext,
    setFormikChanged,
    judicialGuaranteeCivel,
    setJudicialGuaranteeCivel,
  } = usePrice()

  const [hasNoneClient, setHasNoneClient] = useState(false)
  const [policiesHolder, setPoliciesHolder] = useState([{}])
  const [hasUniqueClient, setHasUniqueClient] = useState(true)
  const [claimantDisabled, setClaimantDisabled] = useState(true)
  const [policyHolderSelected, setPolicyHolderSelected] = useState('')
  const [policyHolderOptions, setPolicyHolderOptions] = useState([''])

  const initialValuesForm = useMemo(() => (Object.assign(
    InsuredData(quote?.Insured ?? {}),
  )), [quote?.Insured])

  const policyHolderCnpj = useMemo(() => (
    Number(policyHolderSelected?.replace(/[^0-9]/g, ''))
  ), [policyHolderSelected])

  const fieldValueIquals = () => (cpfOrCnpj
    .test('document', GlobalMessages.EQUAL_TO_POLICY_HOLDER,
      (value) => (
        value && (value && Number(value) !== policyHolderCnpj)
      ))
    .required())

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValuesForm,
      policyHolder: quote?.PolicyHolder?.Name ?? '',
      documentNumber: quote?.PolicyHolder?.Document ?? '',
    },
    validationSchema: Yup.object({
      document: fieldValueIquals(),
      name: !claimantDisabled ? Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Apenas letras são permitidas').required('Campo obrigatório') : Yup.string().required(),
      policyHolder: Yup.string().max(200)
        .when(() => (hasNoneClient ? Yup.string() : Yup.string().required())),
    }),
    onSubmit: (data) => {
      const values = {
        ...judicialGuaranteeCivel,
        ...{
          insured: {
            name: data.name,
            document: data.document,
          },
        },
        ...{
          policyHolder: {
            name: data.policyHolder,
            document: data.documentNumber,
          },
        },
      }

      userClient().getClientByUserLogged(getEmail()).then((response) => {
        const filterNotLead = response?.data?.filter((item) => item?.lead === false)
        const filterNomination = filterNotLead
          ?.filter((item) => item?.cpfcnpj === values.policyHolder.document)
        if (filterNomination?.length > 0) {
          setJudicialGuaranteeCivel({ ...values, ClientIsNominated: true })
        } else {
          setJudicialGuaranteeCivel({ ...values, ClientIsNominated: false })
        }
      }, () => {
        setJudicialGuaranteeCivel({ ...values })
      })

      const {
        name,
        document,
      } = values.insured

      const valueInsuredObject = {
        name,
        document,
      }

      if (actionNext) {
        setFormikChanged(!isEqual(initialValuesForm, data))
        onNext(values)
        if (!isCnpj(valueInsuredObject.document)) {
          userClient().createUpdateInsuredObjectProperty(valueInsuredObject)
            .then((response) => {
              addMsgDanger(response.data.error)
            })
        }
      }
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  const formatClients = useCallback((clientsUser) => clientsUser.map(({ name, cpfcnpj }) => ({
    id: cpfcnpj,
    text: `${cpfcnpj} ${name}`,
  })), [])

  const { setValues } = formik

  useEffect(() => {
    userClient().getClientByUserLogged(getEmail(), true).then((response) => {
      const filterNotLead = response?.data?.filter((item) => item?.lead === false)
      const clientsData = formatClients(filterNotLead
        ?.filter(({ cpfcnpj }) => cpfcnpj?.length === 14))

      setHasNoneClient(clientsData.length === 0)

      if (clientsData.length !== 0) {
        setHasUniqueClient(false)
        setPoliciesHolder(clientsData)
        setPolicyHolderOptions(clientsData.map(({ text }) => text))

        if (clientsData.length === 1) {
          const { text: nameClient, id: documentPolicy } = clientsData[0]
          setValues({
            policyHolder: nameClient,
            documentNumber: documentPolicy?.replace(/[^0-9]/g, ''),
          })
          setHasUniqueClient(true)
        }
      }
    })
  }, [
    getEmail,
    setValues,
    userClient,
    formatClients,
    setHasUniqueClient,
  ])

  const handleInfoCnpj = (evento) => {
    const { value } = evento.target
    if (evento?.target?.value === '') {
      formik.setFieldValue('name', '')
    }

    const cnpj = value?.replace(/[^0-9]/g, '')

    if (isCnpj(cnpj)) {
      showLoader()
      receitaWSClient().getInfoByCnpj(cnpj).then(async (response) => {
        if (response) {
          formik.setFieldValue('name', response.nome)
          hideLoader()
          setClaimantDisabled(true)
        }
      }).catch(() => {
        setClaimantDisabled(false)
        formik.setFieldValue('name', '')
        hideLoader()
      })
    }
    if (cnpj && !isCnpj(cnpj)) {
      showLoader()
      setClaimantDisabled(false)
      userClient().getClientInsuredObjectProperty(cnpj).then(async (response) => {
        if (response) {
          formik.setFieldValue('name', response?.data?.nome)
          hideLoader()
        }
      }).catch(() => {
        formik.setFieldValue('name', '')
        hideLoader()
      })
    }
  }

  const handlePolicyHolder = useCallback((value) => {
    const policiesHolderFound = policiesHolder.find((item) => item?.text === value)
    if (!isEmpty(policiesHolderFound)) {
      formik.setFieldValue('documentNumber', policiesHolderFound?.id)
    }
  }, [formik, policiesHolder])

  const placeholderCPFCNPJ = '000.000.000-00 / 00.000.000/0000-00'

  const policyHolderLabel = 'Tomador'
  const nameLabel = 'Segurado ( Tribunal )'
  const cpfCnpjLabel = 'CPF/CNPJ do Segurado ( Tribunal )'

  const noOptionsMessage = (
    <Typography className={classes.noOptionsLabel}>
      {GlobalMessages.NO_OPTIONS_MESSAGE}
    </Typography>
  )

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={isEmpty(quote?.Insured) && !formik.isSubmitting}
        title="Dados do Segurado"
        subtitle="Informe abaixo os dados do segurado"
      >
        <Grid container spacing={5}>
          <Grid item sm={12} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="policyHolderLabel" className={classes.label}>{policyHolderLabel}</label>
              <Autocomplete
                options={policyHolderOptions}
                disabled={hasUniqueClient}
                noOptionsText={noOptionsMessage}
                getOptionLabel={(option) => option ?? ''}
                onInputChange={(e, value) => {
                  if (e?.type === 'click') {
                    formik.setFieldValue('policyHolder', value)
                    handlePolicyHolder(value)
                    setPolicyHolderSelected(value)
                  }
                }}
                getOptionSelected={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onFocus={(event) => event.target.setAttribute('autocomplete', 'none')}
                    placeholder="Procurar"
                    margin="normal"
                    error={formik.touched.policyHolder && !!formik.errors.policyHolder}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {...formik.getFieldProps('policyHolder')}
              />
              <FormHelperText
                hidden={!formik.touched.policyHolder || !formik.errors.policyHolder}
                error={formik.touched.policyHolder && !!formik.errors.policyHolder}
              >
                {formik.errors.policyHolder}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="cpfCnpjLabel" className={classes.label}>{cpfCnpjLabel}</label>
                  <CPFOrCNPJInput
                    fullWidth
                    id="document"
                    color="secondary"
                    placeholder={placeholderCPFCNPJ}
                    {...formik.getFieldProps('document')}
                    error={formik.touched.document && !!formik.errors.document}
                    onBlur={handleInfoCnpj}
                  />
                  <FormHelperText
                    hidden={!formik.touched.document || !formik.errors.document}
                    error={formik.touched.document && !!formik.errors.document}
                  >
                    {formik.errors.document}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="nameLabel" className={classes.label}>{nameLabel}</label>
                  <Input
                    fullWidth
                    id="name"
                    color="secondary"
                    placeholder="Nome do Segurado"
                    disabled={claimantDisabled}
                    {...formik.getFieldProps('name')}
                    error={formik.touched.name && !!formik.errors.name}
                  />
                  <FormHelperText
                    hidden={!formik.touched.name || !formik.errors.name}
                    error={formik.touched.name && !!formik.errors.name}
                  >
                    {formik.errors.name}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.buttonsStep}>
              <Box>
                <ButtonLight
                  onClick={handlePrevious}
                  title="Voltar"
                >
                  Voltar
                </ButtonLight>
              </Box>
              <Box ml={4}>
                <ButtonGradient
                  onClick={handleNext}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
            <ReactTooltip place="top" type="dark" />
          </Grid>
        </Grid>
      </CollapsePrice>
    </>
  )
})

Insured.propTypes = {
  quote: PropTypes.any,
  checked: PropTypes.bool,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

Insured.defaultProps = {
  checked: false,
  quote: undefined,
}

export default Insured
