const ObjectPropertyData = ({
  Name,
  Document,
  Deadline,
  ObjectText,
  CourtSection,
  ProcessNumber,
  GuaranteeValue,
  ProposedLegalAction,
  EndDateValidity,
  StartDateValidity,
  CourtRegionalLabor,
  ComplementObjectText,
}) => ({
  name: Name ?? '',
  document: Document ?? '',
  deadline: Deadline ?? '',
  objectText: ObjectText ?? '',
  courtSection: CourtSection ?? '',
  processNumber: ProcessNumber ?? '',
  guaranteeValue: GuaranteeValue ?? 0,
  proposedLegalAction: ProposedLegalAction ?? '',
  endDateValidity: EndDateValidity ?? '',
  startDateValidity: StartDateValidity ?? '',
  courtRegionalLabor: CourtRegionalLabor ?? '',
  complementObjectText: ComplementObjectText ?? '',
})

export default ObjectPropertyData
