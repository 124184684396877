import React from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
  root: {
    flex: '1',
    display: 'flex',
  },
}))

const ViewerDocument = ({ documentBase64 }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <embed src={`data:application/pdf;base64,${documentBase64}`} height="100%" width="100%" />
    </Box>
  )
}

ViewerDocument.propTypes = {
  documentBase64: PropTypes.string.isRequired,
}

export default ViewerDocument
