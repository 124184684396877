import { useCallback } from 'react'
import produce from 'immer'
import storage from 'redux-persist/lib/storage'
import { useDispatch } from 'react-redux'
import { persistReducer } from 'redux-persist'

const INITIAL_STATE = {
  quoteFilter: {},
  policyFilter: {},
  financialFilter: {},
  opportunitiesFilter: {},
}

export const Types = {
  QUOTE: 'globalFilter/quote',
  POLICY: 'globalFilter/policy',
  FINANCIAL: 'globalFilter/financial',
  OPPORTUNITIES: 'globalFilter/opportunities',
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.QUOTE: {
      const { filter } = action.payload

      return produce(state, (draft) => {
        draft.quoteFilter = filter
      })
    }

    case Types.POLICY: {
      const { filter } = action.payload

      return produce(state, (draft) => {
        draft.policyFilter = filter
      })
    }

    case Types.FINANCIAL: {
      const { filter } = action.payload

      return produce(state, (draft) => {
        draft.financialFilter = filter
      })
    }

    case Types.OPPORTUNITIES: {
      const { filter } = action.payload

      return produce(state, (draft) => {
        draft.opportunitiesFilter = filter
      })
    }

    default: {
      return state
    }
  }
}

export const useGlobalFilterListAction = () => {
  const dispatch = useDispatch()
  const setQuoteFilter = useCallback(
    (filter) => dispatch({
      type: Types.QUOTE,
      payload: { filter },
    }),
    [dispatch],
  )

  const setPolicyFilter = useCallback(
    (filter) => dispatch({
      type: Types.POLICY,
      payload: { filter },
    }),
    [dispatch],
  )

  const setFinancialFilter = useCallback(
    (filter) => dispatch({
      type: Types.FINANCIAL,
      payload: { filter },
    }),
    [dispatch],
  )

  const setOpportunitiesFilter = useCallback(
    (filter) => dispatch({
      type: Types.OPPORTUNITIES,
      payload: { filter },
    }),
    [dispatch],
  )

  const cleanQuoteFilter = useCallback(
    () => dispatch({
      type: Types.QUOTE,
      payload: {},
    }),
    [dispatch],
  )

  const cleanPolicyFilter = useCallback(
    () => dispatch({
      type: Types.POLICY,
      payload: {},
    }),
    [dispatch],
  )

  const cleanFinancialFilter = useCallback(
    () => dispatch({
      type: Types.FINANCIAL,
      payload: {},
    }),
    [dispatch],
  )

  const cleanOpportunitiesFilter = useCallback(
    () => dispatch({
      type: Types.OPPORTUNITIES,
      payload: {},
    }),
    [dispatch],
  )

  const cleanFilter = useCallback(() => {
    cleanQuoteFilter()
    cleanPolicyFilter()
    cleanFinancialFilter()
    cleanOpportunitiesFilter()
  }, [
    cleanQuoteFilter,
    cleanPolicyFilter,
    cleanFinancialFilter,
    cleanOpportunitiesFilter,
  ])

  return {
    setQuoteFilter,
    setPolicyFilter,
    setFinancialFilter,
    setOpportunitiesFilter,
    cleanFilter,
  }
}

export default persistReducer(
  {
    key: 'globalFilterList',
    storage,
  },
  reducer,
)
