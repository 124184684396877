import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import Typography from '@material-ui/core/Typography'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import makeStyles from '@material-ui/core/styles/makeStyles'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isEqual, isEmpty } from 'lodash'
import ReactTooltip from 'react-tooltip'

import { usePrice } from '../../../../../shared/contexts/PriceContext'

import { PRODUCT_GUARANTEE_MODALITY } from '../../../../../constants'

import {
  ButtonLight,
  ButtonGradient,
} from '../../../../../shared/components'
import { GlobalMessages } from '../../../../../shared/messages'
import CollapsePrice from '../../../../../shared/components/CollapsePrice/CollapsePrice'
import DropdownSelectOne from '../../../../../shared/components/DropdownSelectOne/DropdownSelectOne'

import useProductQuotationClient from '../../../../../clients/ProductClient/useProductQuotationClient'
import ModalityData from '../../../../../models/PerformanceModels/ModalityData'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  subLabel: {
    display: 'flex',
    marginTop: '10px',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const Modality = forwardRef((props, ref) => {
  const classes = useStyles()
  const {
    quote,
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props

  const {
    onNext,
    actionNext,
    setFormikChanged,
    guaranteePerformance,
    setGuaranteePerformance,
  } = usePrice()

  const productQuotationClient = useProductQuotationClient()
  const [modalityTypes, setModalityTypes] = useState([''])
  const [modalitySelect, setModalitySelect] = useState({})

  const initialValuesForm = useMemo(() => (Object.assign(
    ModalityData(quote?.Rule ?? {}),
  )), [quote?.Rule])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesForm,
    validationSchema: Yup.object({
      modality: Yup.string().required(),
      coverageFine: Yup.string()
        .when('modality', () => (modalitySelect?.id === 1 ? Yup.string().required() : Yup.string())),
      coverageLabor: Yup.string().required(),
    }),
    onSubmit: (data) => {
      if (modalitySelect?.id === 2) {
        formik.values.coverageFine = 'true'
      }
      const values = {
        ...guaranteePerformance,
        ...{ rule: { ...data } },
      }
      setGuaranteePerformance(values)

      if (actionNext) {
        setFormikChanged(!isEqual(initialValuesForm, data))
        onNext(values)
      }
    },
  })

  const filterSelectedOptionObject = useCallback((e, arrayOptions) => {
    const selectedOptionObject = e.target.value
    if (!selectedOptionObject) {
      return ''
    }

    return arrayOptions.find((option) => option.text === selectedOptionObject) ?? undefined
  }, [])

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  useEffect(() => {
    const productModality = productQuotationClient().getProducts()
      .find((p) => p?.value === PRODUCT_GUARANTEE_MODALITY)
    setModalityTypes(productModality?.modalitiesPerformance.sort())
  }, [productQuotationClient])

  const { setFieldTouched } = formik

  useEffect(() => {
    if (quote?.Rule) {
      const fragmentData = quote?.Rule

      const optionSelected = filterSelectedOptionObject({
        target: {
          value: fragmentData?.Modality,
        },
      }, modalityTypes)
      setModalitySelect(optionSelected)
    }
  }, [quote, modalityTypes, filterSelectedOptionObject])

  useEffect(() => {
    if (!isEmpty(modalitySelect) && initialValuesForm?.modality) {
      setFieldTouched('modality', true)
    }
  }, [modalitySelect, initialValuesForm, setFieldTouched])

  const modalityLabel = 'Modalidade'
  const fineLabel = 'O contrato exige cobertura para multa:'
  const laborLabel = 'O contrato exige cobertura para cláusula trabalhista:'
  const alertMsg = '(*) Esta cobertura poderá onerar o custo da garantia'
  const alertMsgMandatoryFine = '(*) A cobertura para multa é obrigatória para performance pública'

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={isEmpty(quote?.Rule) && !formik.isSubmitting}
        title="Detalhes do Produto"
        subtitle="Informe abaixo os detalhes do produto"
      >
        <Grid container spacing={5}>
          <Grid item sm={12} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="modalityLabel" className={classes.label}>{modalityLabel}</label>
              <DropdownSelectOne
                id="modality"
                options={modalityTypes}
                placeholder="Selecione"
                {...formik.getFieldProps('modality')}
                error={formik.touched.modality && !!formik.errors.modality}
                helpertext={formik.touched.modality && formik.errors.modality}
                onChange={(e) => {
                  const optionSelected = filterSelectedOptionObject(e, modalityTypes)
                  formik.setFieldValue('modality', optionSelected?.text)

                  formik.setFieldValue('coverageFine', '')
                  formik.setFieldValue('coverageLabor', '')

                  setModalitySelect(optionSelected)
                }}
              />
            </FormControl>
            {
              (!isEmpty(modalitySelect) && modalitySelect?.id !== 2) && (
                <Grid
                  item
                  sm={12}
                  xs={12}
                >
                  <FormControl fullWidth>
                    <label htmlFor="FineLabel" className={classes.subLabel}>{fineLabel}</label>
                    <RadioGroup
                      row
                      id="coverageFine"
                      name="coverageFine"
                      aria-label="gender"
                      {...formik.getFieldProps('coverageFine')}
                      touched={formik.touched.coverageFine?.toString()}
                    >
                      <FormControlLabel value="true" control={<Radio color="primary" />} label="Sim" />
                      <FormControlLabel value="false" control={<Radio color="primary" />} label="Não" />
                      <Grid className={classes.text}>
                        <Typography hidden={formik.getFieldProps('coverageFine').value === '' || formik.getFieldProps('coverageFine').value === 'false'}>{alertMsg}</Typography>
                      </Grid>
                    </RadioGroup>
                    <FormHelperText
                      hidden={!formik.touched.coverageFine || !formik.errors.coverageFine}
                      error={formik.touched.coverageFine && !!formik.errors.coverageFine}
                    >
                      {formik.errors.coverageFine}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )
            }
            {
              (!isEmpty(modalitySelect) && modalitySelect?.id === 2) && (
                <Grid
                  item
                  sm={12}
                  xs={12}
                >
                  <FormControl fullWidth>
                    <label htmlFor="FineLabel" className={classes.subLabel}>{fineLabel}</label>
                    <RadioGroup
                      row
                      id="coverageFine"
                      name="coverageFine"
                      aria-label="gender"
                      defaultValue="true"
                      touched={formik.touched.coverageFine?.toString()}
                    >
                      <FormControlLabel value="true" control={<Radio color="primary" />} label="Sim" />
                      <FormControlLabel value="false" control={<Radio disabled color="primary" />} label="Não" />
                      <Grid className={classes.text}>
                        <Typography>{alertMsgMandatoryFine}</Typography>
                      </Grid>
                    </RadioGroup>
                    <FormHelperText
                      hidden={!formik.touched.coverageFine || !formik.errors.coverageFine}
                      error={formik.touched.coverageFine && !!formik.errors.coverageFine}
                    >
                      {formik.errors.coverageFine}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )
            }
            {
              (!isEmpty(modalitySelect)
                && (modalitySelect?.id === 2 || modalitySelect?.id === 1)) && (
                <>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                  >
                    <FormControl fullWidth>
                      <label htmlFor="FineLabel" className={classes.subLabel}>{laborLabel}</label>
                      <RadioGroup
                        row
                        id="coverageLabor"
                        aria-label="gender"
                        {...formik.getFieldProps('coverageLabor')}
                        touched={formik.touched.coverageLabor?.toString()}
                      >
                        <FormControlLabel value="true" control={<Radio color="primary" />} label="Sim" />
                        <FormControlLabel value="false" control={<Radio color="primary" />} label="Não" />
                        <Grid className={classes.text}>
                          <Typography hidden={formik.getFieldProps('coverageLabor').value === '' || formik.getFieldProps('coverageLabor').value === 'false'}>{alertMsg}</Typography>
                        </Grid>
                      </RadioGroup>
                      <FormHelperText
                        hidden={!formik.touched.coverageLabor || !formik.errors.coverageLabor}
                        error={formik.touched.coverageLabor && !!formik.errors.coverageLabor}
                      >
                        {formik.errors.coverageLabor}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </>
              )
            }
          </Grid>

          <Grid item xs={12}>
            <Box className={classes.buttonsStep}>
              <Box>
                <ButtonLight
                  onClick={handlePrevious}
                  title="Voltar"
                >
                  Voltar
                </ButtonLight>
              </Box>
              <Box ml={4}>
                <ButtonGradient
                  onClick={handleNext}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
            <ReactTooltip place="top" type="dark" />
          </Grid>
        </Grid>
      </CollapsePrice>
    </>
  )
})

Modality.propTypes = {
  quote: PropTypes.any,
  checked: PropTypes.bool,
  children: PropTypes.any,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

Modality.defaultProps = {
  quote: undefined,
  children: '',
  checked: false,
}

export default Modality
