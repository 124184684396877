import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import ViewerDocument from '../../../shared/components/ViewerDocument/ViewerDocument'

import useFinancialClient from '../../../clients/FinancialClient/useFinancialClient'

const AllTicketViewer = () => {
  const financialClient = useFinancialClient()

  const { documentNumber } = useParams()

  const [draftData, setDocumentData] = useState('')

  useEffect(() => {
    financialClient().getAllTickets(documentNumber).then((response) => {
      setDocumentData(response.data)
    })
  }, [documentNumber, financialClient])

  return (
    <ViewerDocument documentBase64={draftData} />
  )
}

export default AllTicketViewer
