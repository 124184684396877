import axios from 'axios'
import { useCallback } from 'react'

const useStickClient = () => {
  const getById = useCallback((Id) => axios.get(`/Vara/findById?id=${Id}`).then((res) => res?.data), [])

  return useCallback(() => ({
    getById,
  }), [
    getById,
  ])
}

export default useStickClient
