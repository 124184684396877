import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'

import PriceContext from '../../../../shared/contexts/PriceContext'
import { EclipseStep } from '../../../../shared/components/StepIcon'
import {
  useAlert,
} from '../../../../shared/components'

import Requester from './fragments/Requester'
import FileUpload from './fragments/FileUpload'
import DetailsProduct from './fragments/DetailsProduct'
import ValuesDeadlines from './fragments/ValuesDeadlines'
import PolicyHolderClaimant from './fragments/PolicyHolderClaimant'
import InsuredObjectProperty from './fragments/InsuredObjectProperty'
import StepperQuotation from '../StepperQuotation'

import FileUploadData from '../../../../models/JudicialGuaranteeLaborModels/FileUploadData'
import RequesterData from '../../../../models/JudicialGuaranteeAppealModels/RequesterData'
import DetailsProductData from '../../../../models/JudicialGuaranteeAppealModels/DetailsProductData'
import PolicyHolderClaimantData from '../../../../models/JudicialGuaranteeAppealModels/PolicyHolderClaimantData'
import InsuredObjectPropertyData from '../../../../models/JudicialGuaranteeAppealModels/InsuredObjectPropertyData'

const StepType = {
  DETAILS_PRODUCT: 0,
  POLICY_HOLDER_CLAIMANT: 1,
  VALUES_DEADLINES: 2,
  INSURED_OBJECT_PROPERTY: 3,
  REQUESTER: 4,
  FILE_UPLOAD: 5,
}

const JudicialGuaranteeeAppeal = () => {
  const { Provider } = PriceContext

  const history = useHistory()

  const { state } = useLocation()

  const { addMsgDanger: addMsgError, addMsgSuccess } = useAlert()
  const { addMsgDanger, cleanMsg } = useAlert('register')

  const [steps] = useState([
    StepType.DETAILS_PRODUCT,
    StepType.POLICY_HOLDER_CLAIMANT,
    StepType.VALUES_DEADLINES,
    StepType.INSURED_OBJECT_PROPERTY,
    StepType.REQUESTER,
    StepType.FILE_UPLOAD,
  ])
  const [stepsRef, setStepsRef] = useState({})
  const [actionNext, setActionNext] = useState(false)
  const [quoteEditData, setQuoteEditData] = useState({})
  const [actionPrevious, setActionPrevious] = useState(false)
  const [isFormikChanged, setIsFormikChanged] = useState(false)
  const [activeStep, setActiveStep] = useState(StepType.DETAILS_PRODUCT)
  const [judicialGuaranteeAppeal, setJudicialGuaranteeAppeal] = useState({})
  const [appealSelected, setAppealSelected] = useState('')

  const [resourceTypeProduct, setResourceTypeProduct] = useState('')

  const RequesterRef = useRef()
  const FileUploadRef = useRef()
  const DetailsProductRef = useRef()
  const ValuesDeadlinesRef = useRef()
  const PolicyHolderClaimantRef = useRef()
  const InsuredObjectPropertyRef = useRef()

  const setDataCamelCase = useCallback((quote) => {
    setJudicialGuaranteeAppeal({
      requester: RequesterData(quote?.Requester),
      fileUpload: FileUploadData(quote?.FileUpload),
      detailsProduct: DetailsProductData(quote?.DetailsProduct),
      policyHolderClaimant: PolicyHolderClaimantData(quote?.PolicyHolderClaimant),
      insuredObjectProperty: InsuredObjectPropertyData(quote?.InsuredObjectProperty),
    })
  }, [setJudicialGuaranteeAppeal])

  useEffect(() => {
    if (state?.quote) {
      setDataCamelCase({ ...state?.quote })
      setQuoteEditData({ ...state?.quote })
      setActiveStep(steps[steps.length - 1])
    }
  }, [state?.quote, steps, setActiveStep, setQuoteEditData, setDataCamelCase])

  useEffect(() => {
    const stepsControl = {}
    stepsControl[StepType.REQUESTER] = RequesterRef
    stepsControl[StepType.FILE_UPLOAD] = FileUploadRef
    stepsControl[StepType.DETAILS_PRODUCT] = DetailsProductRef
    stepsControl[StepType.VALUES_DEADLINES] = ValuesDeadlinesRef
    stepsControl[StepType.POLICY_HOLDER_CLAIMANT] = PolicyHolderClaimantRef
    stepsControl[StepType.INSURED_OBJECT_PROPERTY] = InsuredObjectPropertyRef

    setStepsRef(stepsControl)
  }, [
    setStepsRef,
    ValuesDeadlinesRef,
    RequesterRef,
    FileUploadRef,
    InsuredObjectPropertyRef,
    PolicyHolderClaimantRef,
    DetailsProductRef,
  ])

  const isActiveStep = (step) => activeStep === step

  const handleNext = () => {
    const stepRef = stepsRef[activeStep]
    stepRef.current.onSubmit().then((data) => {
      if (data) {
        addMsgDanger(data)
      } else {
        setActionNext(true)
        setActionPrevious(false)
      }
    })
  }

  const onNext = () => {
    cleanMsg()
    if (steps.includes(activeStep + 1)) {
      setActiveStep(activeStep + 1)
    }
  }

  const onPrevious = () => {
    cleanMsg()
    setActiveStep(activeStep - 1)
  }

  const onEdit = (step) => {
    setActiveStep(step)

    cleanMsg()
    setActionNext(false)
    setActionPrevious(true)
  }

  const onRegister = (data) => {
    const dataSuccess = {
      title: data?.Title,
      message: data?.Message,
      labelButton: 'Acompanhe sua cotação',
      action: () => history.replace(`/cotacao/${data?.Identifier}/status`),
    }

    addMsgSuccess(dataSuccess)
  }

  const handleRegister = () => {
    const stepRef = stepsRef[activeStep]
    stepRef.current.onSubmit().then((data) => {
      addMsgError(data)
      if (!data) {
        onEdit(StepType.FILE_UPLOAD)
      }
    })
  }

  const setFormikChanged = (isChanged) => {
    if (!isFormikChanged) {
      setIsFormikChanged(isChanged)
    }
  }

  return (
    <Provider value={{
      judicialGuaranteeAppeal,
      setJudicialGuaranteeAppeal,
      resourceTypeProduct,
      setResourceTypeProduct,
      actionNext,
      actionPrevious,
      onNext,
      onRegister,
      addMsgError,
      isFormikChanged,
      setFormikChanged,
      appealSelected,
      setAppealSelected,
    }}
    >
      {/* STEPS */}
      <StepperQuotation steps={steps} EclipseStep={EclipseStep} activeStep={activeStep} />

      <Grid container spacing={5}>
        <Grid item xs={12}>
          <DetailsProduct
            quote={quoteEditData}
            ref={stepsRef[StepType.DETAILS_PRODUCT]}
            checked={isActiveStep(StepType.DETAILS_PRODUCT)}
            handleNext={handleNext}
            handlePrevious={() => history.goBack()}
            handleStep={() => onEdit(StepType.DETAILS_PRODUCT)}
          />
        </Grid>
        <Grid item xs={12}>
          <PolicyHolderClaimant
            quote={quoteEditData}
            ref={stepsRef[StepType.POLICY_HOLDER_CLAIMANT]}
            checked={isActiveStep(StepType.POLICY_HOLDER_CLAIMANT)}
            handleNext={handleNext}
            handlePrevious={onPrevious}
            handleStep={() => onEdit(StepType.POLICY_HOLDER_CLAIMANT)}
          />
        </Grid>
        <Grid item xs={12}>
          <ValuesDeadlines
            quote={quoteEditData}
            ref={stepsRef[StepType.VALUES_DEADLINES]}
            checked={isActiveStep(StepType.VALUES_DEADLINES)}
            handleNext={handleNext}
            handlePrevious={onPrevious}
            handleStep={() => onEdit(StepType.VALUES_DEADLINES)}
          />
        </Grid>
        <Grid item xs={12}>
          <InsuredObjectProperty
            quote={quoteEditData}
            ref={stepsRef[StepType.INSURED_OBJECT_PROPERTY]}
            checked={isActiveStep(StepType.INSURED_OBJECT_PROPERTY)}
            handleNext={handleNext}
            handlePrevious={onPrevious}
            handleStep={() => onEdit(StepType.INSURED_OBJECT_PROPERTY)}
          />
        </Grid>
        <Grid item xs={12}>
          <Requester
            quote={quoteEditData}
            ref={stepsRef[StepType.REQUESTER]}
            checked={isActiveStep(StepType.REQUESTER)}
            handleNext={handleNext}
            handlePrevious={onPrevious}
            handleStep={() => onEdit(StepType.REQUESTER)}
          />
        </Grid>
        <Grid item xs={12}>
          <FileUpload
            quote={quoteEditData}
            ref={stepsRef[StepType.FILE_UPLOAD]}
            checked={isActiveStep(StepType.FILE_UPLOAD)}
            handleNext={handleNext}
            handlePrevious={onPrevious}
            handleStep={handleRegister}
          />
        </Grid>
      </Grid>
    </Provider>
  )
}

export default JudicialGuaranteeeAppeal
