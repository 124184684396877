import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'
import { TextField, InputLabel, FormHelperText } from '@material-ui/core'

const CEPInput = (props) => {
  const {
    id, label, color, error, helpertext,
  } = props

  const field = useMemo(() => {
    const inputProps = {
      ...props,
      onBlur: undefined,
      onChange: undefined,
    }

    return <TextField {...inputProps} margin="normal" />
  }, [props])

  return (
    <>
      <InputLabel
        htmlFor={id}
        color={color}
        error={error}
      >
        {label}
      </InputLabel>

      <InputMask
        {...props}
        maskChar={null}
        mask="99999-999"
      >
        {() => field}
      </InputMask>

      <FormHelperText hidden={!error} error={error}>{helpertext}</FormHelperText>
    </>
  )
}

CEPInput.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  error: PropTypes.bool,
  helpertext: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

CEPInput.defaultProps = {
  color: 'secondary',
  error: false,
  helpertext: '',
  label: '',
  onBlur: () => { },
  onChange: () => { },
}

export default CEPInput
