import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

import Requester from '../fragments/Requester'
import FileUpload from '../fragments/FileUpload'
import PolicyHolder from '../fragments/PolicyHolder'
import NewStateDocument from '../fragments/NewStateDocument'
import CurrentStateDocument from '../fragments/CurrentStateDocument'

import {
  STEP_TYPE_SECOND_TEMPLATE,
  STEP_TYPE_SECOND_OTHER_BROKERAGE_TEMPLATE,
} from '../../../constants/EndorsementConstants'

const SecondTemplateEndorsement = (props) => {
  const {
    refs,
    quote,
    checked,
    handleStep,
    handleNext,
    handlePrevious,
    handleRegister,
    handleNextExternal,
    otherBrokerageDocument,
  } = props

  const verifyOtherBrokerage = otherBrokerageDocument
    ? STEP_TYPE_SECOND_OTHER_BROKERAGE_TEMPLATE : STEP_TYPE_SECOND_TEMPLATE

  return (
    <>
      {!otherBrokerageDocument && (
        <Grid item xs={12}>
          <CurrentStateDocument
            quote={quote}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            ref={refs[STEP_TYPE_SECOND_TEMPLATE.CURRENT_STATE_DOCUMENT]}
            checked={checked(STEP_TYPE_SECOND_TEMPLATE.CURRENT_STATE_DOCUMENT) ?? false}
            handleStep={handleRegister}
          />
        </Grid>
      )}
      {otherBrokerageDocument && (
        <Grid item xs={12}>
          <PolicyHolder
            quote={quote}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            ref={refs[STEP_TYPE_SECOND_OTHER_BROKERAGE_TEMPLATE.POLICY_HOLDER]}
            checked={checked(STEP_TYPE_SECOND_OTHER_BROKERAGE_TEMPLATE.POLICY_HOLDER) ?? false}
            handleStep={handleRegister}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <NewStateDocument
          quote={quote}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          ref={refs[verifyOtherBrokerage.NEW_STATE_DOCUMENT]}
          checked={checked(verifyOtherBrokerage.NEW_STATE_DOCUMENT) ?? false}
          handleStep={handleRegister}
        />
      </Grid>

      <Grid item xs={12}>
        <Requester
          quote={quote}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          ref={refs[verifyOtherBrokerage.REQUESTER]}
          checked={checked(verifyOtherBrokerage.REQUESTER) ?? false}
          handleStep={() => handleStep(verifyOtherBrokerage.REQUESTER)}
        />
      </Grid>

      <Grid item xs={12}>
        <FileUpload
          quote={quote}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          ref={refs[verifyOtherBrokerage.FILE_UPLOAD]}
          checked={checked(verifyOtherBrokerage.FILE_UPLOAD) ?? false}
          handleStep={handleRegister}
          handleNextExternal={handleNextExternal}
        />
      </Grid>
    </>
  )
}

SecondTemplateEndorsement.propTypes = {
  quote: PropTypes.any,
  refs: PropTypes.any.isRequired,
  checked: PropTypes.any.isRequired,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  handleRegister: PropTypes.func.isRequired,
  handleNextExternal: PropTypes.func.isRequired,
  otherBrokerageDocument: PropTypes.bool.isRequired,
}

SecondTemplateEndorsement.defaultProps = {
  quote: undefined,
}

export default SecondTemplateEndorsement
