import { useCallback } from 'react'
import produce from 'immer'
import { useDispatch } from 'react-redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const INITIAL_STATE = {
  news: [],
}

export const Types = {
  NEWS: 'notifications/news',
}

const reducer = (state = INITIAL_STATE, action) => {
  if (action.type === Types.NEWS) {
    const { news } = action.payload
    return produce(state, (draft) => {
      draft.news = news
    })
  }
  return state
}

export const setNotificationsStore = (news) => ({
  type: Types.NEWS,
  payload: { news },
})

export const useNotificationsAction = () => {
  const dispatch = useDispatch()
  const setNotifications = useCallback(
    (news) => dispatch({
      type: Types.NEWS,
      payload: { news },
    }),
    [dispatch],

  )

  const cleanNotifications = useCallback(() => {
    dispatch({
      type: Types.NEWS,
      payload: { news: [] },
    })
  }, [dispatch])

  return {
    setNotifications,
    cleanNotifications,
  }
}

export default persistReducer(
  {
    key: 'notifications',
    storage,
  },
  reducer,
)
