import React, { forwardRef } from 'react'
import { PropTypes } from 'prop-types'
import AcceptProposal from '../AcceptProposal'
import ContainerFields from '../../quotations/judicial-guarantee-civel/ContainerFields'

const Confirmation = forwardRef((props, ref) => {
  const { quotation, quotationStatus, proposal } = props

  return (
    <>
      <AcceptProposal
        ref={ref}
        {...props}
      >
        <ContainerFields
          quotation={quotation}
          quotationStatus={quotationStatus}
          proposal={proposal}
        />
      </AcceptProposal>
    </>
  )
})

Confirmation.propTypes = {
  quotation: PropTypes.any.isRequired,
  quotationStatus: PropTypes.any.isRequired,
  proposal: PropTypes.any.isRequired,
}

export default Confirmation
