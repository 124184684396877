/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  forwardRef,
  useCallback,
  useMemo,
  useState,
  useImperativeHandle,
} from 'react'
import { useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import { useDropzone } from 'react-dropzone'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'

import makeStyles from '@material-ui/core/styles/makeStyles'
import { Delete, CloudUpload } from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  ButtonLight,
  ButtonGradient,
} from '../../../shared/components'
import {
  ENDORSEMENT_TYPE_DEADLINE_EXTENSION,
  ENDORSEMENT_TYPE_INCREASE_INSURED_AMOUNT,
  ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT,
} from '../../../constants/EndorsementConstants'

import {
  ENDORSEMENT_URL_EXECUTIVE_REQUEST,
  PRODUCT_PERFORMANCE,
  PRODUCT_PERFORMANCE_PUBLIC,
} from '../../../constants'

import { useEndorsement } from '../../../shared/contexts/EndorsementContext'
import CollapsePrice from '../../../shared/components/CollapsePrice/CollapsePrice'
import { GlobalMessages } from '../../../shared/messages'

import usePolicyClient from '../../../clients/PolicyClient/usePolicyClient'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    padding: 10,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  subLabel: {
    display: 'flex',
    marginTop: '10px',
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  previewChip: {
    minWidth: 200,
    maxWidth: 400,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    cursor: 'pointer',
  },
  labelInfo: {
    color: theme.palette.text.tertiary,
  },
  messageAttachDocuments: {
    marginBottom: 10,
  },

}))

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

const FileUpload = forwardRef((props, ref) => {
  const location = useLocation()
  const [rerender, setRerender] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const policyClient = usePolicyClient()

  const {
    checked,
    handleStep,
    handleNext,
    handlePrevious,
    handleNextExternal,
  } = props

  const {
    endorsement,
    policy,
    onRegister,
    actionNext,
    addMsgError,
    setEndorsement,
    endorsementDocument,
    setEndorsementDocument,
    otherBrokerageDocument,
  } = useEndorsement()

  const typesToAutomaticEmission = [
    ENDORSEMENT_TYPE_DEADLINE_EXTENSION,
    ENDORSEMENT_TYPE_INCREASE_INSURED_AMOUNT,
    ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT,
  ]

  const modalityToAutomaticEmission = [
    PRODUCT_PERFORMANCE_PUBLIC,
  ]

  const productToAutomaticEmission = [
    PRODUCT_PERFORMANCE,
  ]

  const getTokenUrl = location?.pathname?.split(':')
  const routeSolicitation = getTokenUrl[0]
  const tokenParams = getTokenUrl[1]

  const addMsgErrorHandle = (error) => {
    addMsgError({
      title: error?.data?.Title,
      action: () => setButtonDisabled(false),
    })
  }

  const formik = useFormik({
    onSubmit: (data) => {
      const values = {
        ...endorsement,
        ...{ endorsementDocuments: { documents: data.documents ?? [] } },
      }

      setEndorsement(values)

      if (actionNext) {
        delete values?.currentStateDocument?.historyDocuments
        policyClient().registerEndorsement({
          endorsement:
            ENDORSEMENT_URL_EXECUTIVE_REQUEST === routeSolicitation
              ? { ...values, RequestByExecutive: true }
              : values,
        }).then((response) => {
          onRegister(response.data)
        }, (response) => {
          addMsgErrorHandle(response)
        })
      }
    },
  })

  const { setValues } = formik

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((item) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(item)
      reader.onload = () => {
        if (!!reader.result) {
          const fileB64 = btoa(
            new Uint8Array(reader.result)
              .reduce((data, byte) => data + String.fromCharCode(byte), ''),
          )
          const payload = {
            documentName: item.name,
            documentType: String(item.type).replace('application/', ''),
            content: fileB64,
          }

          policyClient().sendDocumentsWings(payload).then((response) => {
            const { data } = response
            setEndorsementDocument(
              [
                ...endorsementDocument,
                ...[{
                  documentID: data,
                  documentName: item.name,
                }]],
            )
            toast.success(`${item.name} foi anexado!`, {
              position: 'bottom-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }, () => {
            toast.error(`Ocorreu um erro e ${item.name} não foi anexado!`, {
              position: 'bottom-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          })
        }
      }
    })
  }, [policyClient, endorsementDocument])

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: 'application/pdf', multiple: false })
  const classes = useStyles()

  const removeFile = (index) => {
    const localfileEndorsement = endorsementDocument
    localfileEndorsement.splice(index, 1)
    setEndorsementDocument(localfileEndorsement)
    setRerender(!rerender)
  }

  const handleClick = (documentID) => {
    window.open(`${process.env.REACT_APP_BFF_URL}/wings/document?wingsDocumentId=${documentID}`, '_blank')
  }

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
  ])

  const handleContinue = () => {
    setButtonDisabled(true)
    const values = {
      ...endorsement,
      ...{ endorsementDocuments: { documents: endorsementDocument ?? [] } },
    }

    setEndorsement(ENDORSEMENT_URL_EXECUTIVE_REQUEST === routeSolicitation
      ? { ...values, RequestByExecutive: true }
      : values)
    handleNextExternal()
  }

  const handleRequestEndorsement = () => {
    setButtonDisabled(true)
    setValues({
      documents: endorsementDocument,
    })
    handleNext()
  }

  const isAutomaticEmission = () => modalityToAutomaticEmission
    .includes(policy?.modalidade?.codigoModalidade)
    && typesToAutomaticEmission
      .includes(endorsement?.endorsementRequest?.endorsementTypeId)
    && productToAutomaticEmission.includes(policy?.modalidade?.product?.id)

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={!formik.isSubmitting}
        title="Arquivos"
        headerSize={240}
        subtitle={(
          <span>
            Para agilizar o processo, havendo possibilidade favor anexar os seguintes documentos:
            <br />
            - Edital
            <br />
            - Minuta e/ou Contrato
            <br />
            - Aditivos
            <br />
            <br />
            Selecione os arquivos
          </span>
        )}
      >
        <Grid item xs={12}>
          <div className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p>Arraste e solte os arquivos em PDF aqui ou clique para selecionar</p>
              <CloudUpload
                color="primary"
                fontSize="large"
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          {
            endorsementDocument.map((item, index) => (
              <Chip
                className={classes.previewChip}
                key={index}
                label={item.documentName}
                onClick={() => {
                  handleClick(item.documentID)
                }}
                onDelete={() => {
                  removeFile(index)
                }}
                deleteIcon={<Delete />}
                variant="outlined"
              />
            ))
          }
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.buttonsStep}>
            <Box width={190}>
              <ButtonLight
                onClick={handlePrevious}
                title="Voltar"
              >
                Voltar
              </ButtonLight>
            </Box>
            <Box ml={4} width={190}>
              {(!otherBrokerageDocument ? !isAutomaticEmission() : true) && (
                <ButtonGradient
                  disabled={buttonDisabled}
                  onClick={handleRequestEndorsement}
                  title="Solicitar Endosso"
                >
                  Solicitar Endosso
                </ButtonGradient>
              )}

              {(!otherBrokerageDocument ? isAutomaticEmission() : false) && (
                <ButtonGradient
                  disabled={buttonDisabled}
                  onClick={handleContinue}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              )}
            </Box>
            {buttonDisabled && (
              <Box
                mt={1.5}
                ml={1}
              >
                <CircularProgress style={{ width: 30, height: 30 }} />
              </Box>
            )}
          </Box>
          <ReactTooltip place="top" type="dark" />
        </Grid>
      </CollapsePrice>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
})

FileUpload.propTypes = {
  checked: PropTypes.bool,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  handleNextExternal: PropTypes.func.isRequired,
}

FileUpload.defaultProps = {
  checked: false,
}

export default FileUpload
