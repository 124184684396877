export const HELP_LINKS = {
  createPolicy: 'https://www.canva.com/design/DAFPmXhtsN8/QFMazlGdR--klIY0AWADOA/view?utm_content=DAFPmXhtsN8&utm_campaign=designshare&utm_medium=link&utm_source=publishpresent',
  createEndorsement: 'https://www.canva.com/design/DAFPmYlWxOg/K73Rx8YilpAbPLUjk00C9A/view?utm_content=DAFPmYlWxOg&utm_campaign=designshare&utm_medium=link&utm_source=publishpresent',
  statusSolicitation: 'https://www.canva.com/design/DAFPmWgoztU/VzqCW2FDdg8siC3PjOuuSw/view?utm_content=DAFPmWgoztU&utm_campaign=designshare&utm_medium=link&utm_source=publishpresent',
  consultPolicy: 'https://www.canva.com/design/DAFPma3Yz8c/LuArexp3LVD61EHO2KMEGA/view?utm_content=DAFPma3Yz8c&utm_campaign=designshare&utm_medium=link&utm_source=publishpresent',
  financial: 'https://www.canva.com/design/DAFPmWT-2vo/gpVGLTRgEBjooLTiR9zXrQ/view?utm_content=DAFPmWT-2vo&utm_campaign=designshare&utm_medium=link&utm_source=publishpresent',
  registerUser: 'https://www.canva.com/design/DAFPmSru2WY/AMA-PTPxuS8zwd94cr7vrw/view?utm_content=DAFPmSru2WY&utm_campaign=designshare&utm_medium=link&utm_source=publishpresent',
  all: 'https://www.canva.com/design/DAFPUQM8oro/QpLf_MItpnCBGgXpL4xBtg/view?utm_content=DAFPUQM8oro&utm_campaign=designshare&utm_medium=link&utm_source=publishpresent',
}

export default HELP_LINKS
