import { combineReducers } from 'redux'
import security from './security'
import context from './context'
import notifications from './notifications'
import globalFilterList from './globalFilterList'
import quoteGlobalFilter from './quoteGlobalFilter'
import policyGlobalFilter from './policyGlobalFilter'
import opportunitiesGlobalFilter from './opportunitiesGlobalFilter'

export default combineReducers({
  security,
  context,
  notifications,
  globalFilterList,
  quoteGlobalFilter,
  policyGlobalFilter,
  opportunitiesGlobalFilter,
})
