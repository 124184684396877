export const ENDORSEMENT_TYPE_DATA_OR_OBJECT_CORRECTION = 1
export const ENDORSEMENT_TYPE_CANCELLATION = 2
export const ENDORSEMENT_TYPE_DEADLINE_EXTENSION = 3
export const ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT = 4
export const ENDORSEMENT_TYPE_INCREASE_INSURED_AMOUNT = 5
export const ENDORSEMENT_TYPE_EXTENSION_REDUCTION_INSURED_AMOUNT = 8
export const ENDORSEMENT_TYPE_REDUCTION_INSURED_AMOUNT = 9
export const ENDORSEMENT_TYPE_INCLUSION_COVERAGE = 10
export const ENDORSEMENT_TYPE_APPEAL_WRITE_OFF = 18

export const STEP_TYPE_FIRST_TEMPLATE = {
  ENDORSEMENT_REQUEST: 0,
  INSURED: 1,
  REQUESTER: 2,
  FILE_UPLOAD: 3,
}

export const STEP_TYPE_SECOND_TEMPLATE = {
  ENDORSEMENT_REQUEST: 0,
  CURRENT_STATE_DOCUMENT: 1,
  NEW_STATE_DOCUMENT: 2,
  REQUESTER: 3,
  FILE_UPLOAD: 4,
}

export const STEP_TYPE_FIRST_OTHER_BROKERAGE_TEMPLATE = {
  ENDORSEMENT_REQUEST: 0,
  POLICY_HOLDER: 1,
  INSURED: 2,
  REQUESTER: 3,
  FILE_UPLOAD: 4,
}

export const STEP_TYPE_SECOND_OTHER_BROKERAGE_TEMPLATE = {
  ENDORSEMENT_REQUEST: 0,
  POLICY_HOLDER: 1,
  NEW_STATE_DOCUMENT: 2,
  REQUESTER: 3,
  FILE_UPLOAD: 4,
}
