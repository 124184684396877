import { useCallback } from 'react'

const useDownload = () => {
  const base64toBlob = useCallback((base64Data) => {
    const sliceSize = 1024
    const byteCharacters = atob(base64Data)
    const bytesLength = byteCharacters.length
    const slicesCount = Math.ceil(bytesLength / sliceSize)
    const byteArrays = new Array(slicesCount)

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize
      const end = Math.min(begin + sliceSize, bytesLength)

      const bytes = new Array(end - begin)
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0)
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes)
    }
    return new Blob(byteArrays, { type: 'application/pdf' })
  }, [])

  const convertPdfToBase64 = (file) => (new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  }))

  const downloadPDF = useCallback((pdf, name) => {
    const link = document.createElement('a')
    const linkSource = `data:application/pdf;base64,${pdf}`
    link.download = name
    link.href = linkSource
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [])

  const downloadExcel = useCallback((response, name) => {
    const link = document.createElement('a')
    const linkSource = window.URL.createObjectURL(new Blob([response.data]))
    link.setAttribute('download', name)
    link.href = linkSource
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [])

  const downloadBlob = useCallback((responseDownload, name) => {
    responseDownload.blob().then((blob) => {
      const link = document.createElement('a')
      const linkSource = URL.createObjectURL(blob)
      link.download = name
      link.href = linkSource
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }, [])

  const printBase64 = (data) => {
    let blob = null
    blob = base64toBlob(data)
    const blobURL = URL.createObjectURL(blob)
    const theWindow = window.open(blobURL)
    const theDoc = theWindow.document
    const theScript = document.createElement('script')
    const injectThis = () => window.print()
    theScript.innerHTML = `window.onload = ${injectThis.toString()};`
    theDoc.body.appendChild(theScript)
  }

  return {
    printBase64,
    downloadPDF,
    base64toBlob,
    downloadBlob,
    downloadExcel,
    convertPdfToBase64,
  }
}

export default useDownload
