import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'

import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { isEqual, isEmpty } from 'lodash'
import ReactTooltip from 'react-tooltip'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'

import makeStyles from '@material-ui/core/styles/makeStyles'

import Autocomplete from '@material-ui/lab/Autocomplete'
import SearchIcon from '@material-ui/icons/Search'

import { usePrice } from '../../../../../shared/contexts/PriceContext'

import useUserClient from '../../../../../clients/UserClient/useUserClient'

import {
  ButtonLight,
  ButtonGradient,
} from '../../../../../shared/components'
import { GlobalMessages } from '../../../../../shared/messages'
import CollapsePrice from '../../../../../shared/components/CollapsePrice/CollapsePrice'
import useSecurity from '../../../../../security/useSecurity'

import InsuredData from '../../../../../models/JudicialGuaranteeLaborModels/InsuredData'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  help: {
    color: theme.palette.text.quaternary,
  },
  noOptionsLabel: {
    fontSize: 14,
    color: '#c93952',
    fontWeight: 'bold',
  },
}))

const Insured = forwardRef((props, ref) => {
  const classes = useStyles()
  const userClient = useUserClient()
  const {
    getEmail,
  } = useSecurity()

  const {
    quote,
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props

  const {
    onNext,
    actionNext,
    setFormikChanged,
    setNamePolicyHolder,
    judicialGuaranteeLabor,
    setJudicialGuaranteeLabor,
  } = usePrice()

  const [hasNoneClient, setHasNoneClient] = useState(false)
  const [policiesHolder, setPoliciesHolder] = useState([{}])
  const [hasUniqueClient, setHasUniqueClient] = useState(true)
  const [policyHolderOptions, setPolicyHolderOptions] = useState([''])

  const initialValuesForm = useMemo(() => (Object.assign(
    InsuredData(quote?.PolicyHolder ?? {}),
  )), [quote?.PolicyHolder])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesForm,
    validationSchema: Yup.object({
      name: Yup.string().max(200)
        .when(() => (hasNoneClient ? Yup.string() : Yup.string().required())),
    }),
    onSubmit: (data) => {
      const values = {
        ...judicialGuaranteeLabor,
        ...{ policyHolder: { ...data } },
      }

      userClient().getClientByUserLogged(getEmail()).then((response) => {
        const filterNotLead = response?.data?.filter((item) => item?.lead === false)
        const filterNomination = filterNotLead
          ?.filter((item) => item?.cpfcnpj === values.policyHolder.document)
        if (filterNomination?.length > 0) {
          setJudicialGuaranteeLabor({ ...values, ClientIsNominated: true })
        } else {
          setJudicialGuaranteeLabor({ ...values, ClientIsNominated: false })
        }
      }, () => {
        setJudicialGuaranteeLabor({ ...values })
      })

      if (actionNext) {
        setFormikChanged(!isEqual(initialValuesForm, data))
        onNext(values)
      }
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  const formatClients = useCallback((clientsUser) => clientsUser.map(({ name, cpfcnpj }) => ({
    id: cpfcnpj,
    text: `${cpfcnpj} ${name}`,
  })), [])

  const { setValues } = formik

  useEffect(() => {
    userClient().getClientByUserLogged(getEmail(), true).then((response) => {
      const filterNotLead = response?.data?.filter((item) => item?.lead === false)
      const clientsData = formatClients(filterNotLead
        ?.filter(({ cpfcnpj }) => cpfcnpj?.length === 14))

      setHasNoneClient(clientsData.length === 0)

      if (clientsData.length !== 0) {
        setHasUniqueClient(false)
        setPoliciesHolder(clientsData)
        setPolicyHolderOptions(clientsData.map(({ text }) => text))

        if (clientsData.length === 1) {
          const { text: nameClient, id: documentPolicy } = clientsData[0]
          setValues({
            name: nameClient,
            document: documentPolicy?.replace(/[^0-9]/g, ''),
          })
          setHasUniqueClient(true)
        }
      }
    })
  }, [
    getEmail,
    setValues,
    userClient,
    formatClients,
    setHasUniqueClient,
  ])

  const handlePolicyHolder = useCallback((value) => {
    const policiesHolderFound = policiesHolder.find((item) => item?.text === value)
    if (!isEmpty(policiesHolderFound)) {
      setNamePolicyHolder(value)
      formik.setFieldValue('document', policiesHolderFound?.id)
    }
  }, [formik, policiesHolder, setNamePolicyHolder])

  const policyHolderLabel = 'Tomador'

  const noOptionsMessage = (
    <Typography className={classes.noOptionsLabel}>
      {GlobalMessages.NO_OPTIONS_MESSAGE}
    </Typography>
  )

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={isEmpty(quote?.PolicyHolder) && !formik.isSubmitting}
        title="Dados do Tomador"
        subtitle="Informe abaixo os dados do tomador"
      >
        <Grid container spacing={5}>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="policyHolderLabel" className={classes.label}>{policyHolderLabel}</label>
              <Autocomplete
                id="name"
                color="secondary"
                placeholder="Nome do Tomador"
                noOptionsText={noOptionsMessage}
                options={policyHolderOptions}
                disabled={hasUniqueClient}
                getOptionLabel={(option) => option ?? ''}
                onInputChange={(e, value) => {
                  if (e?.type === 'click') {
                    formik.setFieldValue('name', value)
                    handlePolicyHolder(value)
                  }
                }}
                getOptionSelected={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onFocus={(event) => event.target.setAttribute('autocomplete', 'none')}
                    placeholder="Procurar"
                    margin="normal"
                    error={formik.touched.name && !!formik.errors.name}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {...formik.getFieldProps('name')}
              />
              <FormHelperText
                hidden={!formik.touched.name || !formik.errors.name}
                error={formik.touched.name && !!formik.errors.name}
              >
                {formik.errors.name}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.buttonsStep}>
              <Box>
                <ButtonLight
                  onClick={handlePrevious}
                  title="Voltar"
                >
                  Voltar
                </ButtonLight>
              </Box>
              <Box ml={4}>
                <ButtonGradient
                  onClick={handleNext}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
            <ReactTooltip place="top" type="dark" />
          </Grid>
        </Grid>
      </CollapsePrice>
    </>
  )
})

Insured.propTypes = {
  quote: PropTypes.any,
  checked: PropTypes.bool,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

Insured.defaultProps = {
  checked: false,
  quote: undefined,
}

export default Insured
