import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'

import * as Yup from 'yup'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import ReactTooltip from 'react-tooltip'
import { isEqual, isEmpty } from 'lodash'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Input from '@material-ui/core/Input'
import Tooltip from '@material-ui/core/Tooltip'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'

import makeStyles from '@material-ui/core/styles/makeStyles'

import useUtils from '../../../../../shared/hooks/useUtils'
import { usePrice } from '../../../../../shared/contexts/PriceContext'

import {
  DateInput,
  ButtonLight,
  ButtonGradient,
  ProcessNumberInput,
} from '../../../../../shared/components'
import CollapsePrice from '../../../../../shared/components/CollapsePrice/CollapsePrice'
import CurrencyInput from '../../../../../shared/components/CurrencyInput/CurrencyInput'
import DropdownSelectOne from '../../../../../shared/components/DropdownSelectOne/DropdownSelectOne'

import { JudicialGuaranteeeLaborMessages, GlobalMessages } from '../../../../../shared/messages'

import useCourtRegionalLaborClient from '../../../../../clients/CourtRegionalLaborClient/useCourtRegionalLaborClient'
import useStickClient from '../../../../../clients/StickClient/useStickClient'

import ObjectPropertyData from '../../../../../models/JudicialGuaranteeLaborModels/ObjectPropertyData'

const useStyles = makeStyles((theme) => ({
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
  },
  labelObject: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  info: {
    verticalAlign: 'bottom',
  },
  labelMessage: {
    color: 'red',
    fontSize: 14,
    margin: 2,
  },
  noOptionsLabel: {
    fontSize: 14,
    color: '#c93952',
    fontWeight: 'bold',
  },
}))

const ObjectProperty = forwardRef((props, ref) => {
  const classes = useStyles()
  const {
    date,
    isBlank,
    formatDateApi,
  } = useUtils()

  const {
    quote,
    checked,
    handleStep,
    handleNext,
    handlePrevious,
  } = props

  const {
    onNext,
    actionNext,
    cnpjClaimant,
    nameClaimant,
    namePolicyHolder,
    setFormikChanged,
    judicialGuaranteeLabor,
    setJudicialGuaranteeLabor,
  } = usePrice()

  const courtRegionalLaborClient = useCourtRegionalLaborClient()
  const stickClient = useStickClient()

  const [sticks, setSticks] = useState([{}])
  const [percentage, setPercentage] = useState('')
  const [stickOptions, setStickOptions] = useState([''])
  const [stickDisabled, setStickDisabled] = useState(true)
  const [courtSection, setCourtSection] = useState({})
  const [courtRegional, setCourtRegional] = useState({})
  const [courtRegionalLabors, setCourtRegionalLabors] = useState([''])

  const mountDataCourt = (dataForm) => ({
    ...dataForm,
    courtSection: { ...courtSection },
    courtRegionalLabor: { ...courtRegional },
  })

  const minStartDateValidity = useMemo(() => {
    const startData = date()
    return !isEmpty(startData)
      ? date(startData).subtract(1, 'days')
      : undefined
  }, [date])

  const initialValuesForm = useMemo(() => (Object.assign(
    ObjectPropertyData({
      ...quote?.ObjectProperty,
      CourtSection: quote?.ObjectProperty?.CourtSection?.descricao
        ?? undefined,
      CourtRegionalLabor: quote?.ObjectProperty?.CourtRegionalLabor?.descricao
        ?? undefined,
    } ?? {}),
  )), [quote?.ObjectProperty])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesForm,
    validationSchema: Yup.object({
      deadline: Yup.string().when((value) => (
        !value
          ? Yup.string().required()
          : Yup.string()
      )),
      processNumber: Yup.string().max(50, GlobalMessages.LIMIT_MAX_CHARACTER(50)).required(),
      courtSection: Yup.string().required(),
      courtRegionalLabor: Yup.string(),
      proposedLegalAction: Yup.string().required(),
      objectText: Yup.string().required(),
      startDateValidity: Yup.date().when(() => (
        minStartDateValidity && Yup.date()
          .min(minStartDateValidity, 'O início de vigência deverá ser maior ou igual a data atual.')
          .required()
      )),
      endDateValidity: Yup.date().when('startDateValidity', (startDateValidity) => startDateValidity
        && Yup.date().min(startDateValidity, GlobalMessages.DATA_FINAL_MAIOR_INICIAL).required()),
      contractValue: Yup.number()
        .test('contractValue', GlobalMessages.CAMPO_MAIOR_ZERO('Valor da Garantia'), (value) => (value && Number(value) !== 0))
        .test('contractValue', GlobalMessages.LIMIT_MAX_CHARACTER('15'), (value) => (value && Number(value) < 9999999999999.99))
        .required(),
      percentageHarm: Yup.string()
        .test('percentageHarm', GlobalMessages.CAMPO_MAIOR_ZERO('Percentual de Agravo (%)'), (value) => (value) !== '0')
        .required(),
      insuredImportance: Yup.number()
        .test('percentageHarm', GlobalMessages.CAMPO_MAIOR_ZERO('Importância Segurada'), (value) => Number(value) !== 0)
        .required(),
    }),
    onSubmit: (data) => {
      let percentageValue = percentage

      if (!percentageValue) {
        const ratio = ((formik.values.insuredImportance / formik.values.contractValue) - 1) * 100

        const percentageFloat = ratio.toFixed(2)

        percentageValue = percentageFloat.replace(',', '.')
        setPercentage(percentageValue)
      }

      formik.values.percentageHarm = percentageValue
      const values = {
        ...judicialGuaranteeLabor,
        ...{
          objectProperty: {
            ...mountDataCourt(data),
          },
        },
      }
      setJudicialGuaranteeLabor(values)

      if (actionNext) {
        setFormikChanged(!isEqual(initialValuesForm, data))
        onNext(values)
      }
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: GlobalMessages.VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  const { setFieldValue } = formik

  const [DEADLINE_DEFAULT] = useState(3)
  const [DEADLINE_YEARS] = useState([3, 4, 5])
  const [deadline, setDeadline] = useState('')
  const [concatTextDefault, setConcatTextDefault] = useState('')

  useEffect(() => {
    const dateEnd = date().add(DEADLINE_DEFAULT, 'y')

    setFieldValue('endDateValidity', formatDateApi(dateEnd))
    setFieldValue('deadline', DEADLINE_DEFAULT)
    setFieldValue('startDateValidity', formatDateApi(date()))
  }, [setFieldValue, DEADLINE_DEFAULT, formatDateApi, date])

  function cpfMask(valor) {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
  }

  function cnpjMask(valor) {
    return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
  }

  useEffect(() => {
    const insuredStick = formik.getFieldProps('courtSection')
    const objectProcessNumber = formik.getFieldProps('processNumber')
    const objectProposedLegalAction = formik.getFieldProps('proposedLegalAction')
    const insuredCourtRegionalLabor = formik.getFieldProps('courtRegionalLabor')

    if (objectProcessNumber?.value
      || insuredStick?.value
      || insuredCourtRegionalLabor?.value
      || objectProposedLegalAction?.value
    ) {
      let claimantDocument = ''
      if (judicialGuaranteeLabor?.claimant?.document.length <= 11) {
        claimantDocument = cpfMask(judicialGuaranteeLabor?.claimant?.document)
      } else {
        claimantDocument = cnpjMask(judicialGuaranteeLabor?.claimant?.document)
      }
      const newConcatTextDefault = JudicialGuaranteeeLaborMessages.JUDICIAL_LABOR_COMPLEMENTO({
        claimantCnpj: claimantDocument,
        claimantName: judicialGuaranteeLabor?.claimant?.name,
        processNumber: !isEmpty(objectProcessNumber?.value)
          ? objectProcessNumber?.value : undefined,
        proposedLegalAction: !isEmpty(objectProposedLegalAction?.value)
          ? objectProposedLegalAction?.value : undefined,
        courtRegionalLabor: !isEmpty(insuredCourtRegionalLabor?.value)
          ? insuredCourtRegionalLabor?.value : undefined,
        courtSection: !isEmpty(insuredStick?.value)
          ? insuredStick?.value : undefined,
      })
      setConcatTextDefault(newConcatTextDefault)
      formik.setFieldValue('objectText', newConcatTextDefault)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [judicialGuaranteeLabor?.claimant?.document,
    judicialGuaranteeLabor?.claimant?.name,
  ])

  useEffect(() => {
    const insuredStick = formik.getFieldProps('courtSection')
    const objectProcessNumber = formik.getFieldProps('processNumber')
    const objectProposedLegalAction = formik.getFieldProps('proposedLegalAction')
    const insuredCourtRegionalLabor = formik.getFieldProps('courtRegionalLabor')

    if (objectProcessNumber?.value
      || insuredStick?.value
      || insuredCourtRegionalLabor?.value
      || objectProposedLegalAction?.value
    ) {
      let claimantDocument = ''
      if (judicialGuaranteeLabor?.claimant?.document.length <= 11) {
        claimantDocument = cpfMask(judicialGuaranteeLabor?.claimant?.document)
      } else {
        claimantDocument = cnpjMask(judicialGuaranteeLabor?.claimant?.document)
      }
      setConcatTextDefault(JudicialGuaranteeeLaborMessages.JUDICIAL_LABOR_COMPLEMENTO({
        claimantCnpj: claimantDocument,
        claimantName: judicialGuaranteeLabor?.claimant?.name,
        processNumber: !isEmpty(objectProcessNumber?.value)
          ? objectProcessNumber?.value : undefined,
        proposedLegalAction: !isEmpty(objectProposedLegalAction?.value)
          ? objectProposedLegalAction?.value : undefined,
        courtRegionalLabor: !isEmpty(insuredCourtRegionalLabor?.value)
          ? insuredCourtRegionalLabor?.value : undefined,
        courtSection: !isEmpty(insuredStick?.value)
          ? insuredStick?.value : undefined,
      }))
    }
  }, [
    formik,
    nameClaimant,
    cnpjClaimant,
    namePolicyHolder,
    concatTextDefault,
    setConcatTextDefault,
    judicialGuaranteeLabor,
  ])

  useEffect(() => {
    const endDateValidity = formik.getFieldProps('endDateValidity')
    const startDateValidity = formik.getFieldProps('startDateValidity')

    setDeadline('')
    if (startDateValidity?.value && endDateValidity?.value) {
      const deadlineCount = date(endDateValidity?.value)
        .diff(date(startDateValidity?.value), 'years')
      if (deadlineCount < 3 || deadlineCount > 5) {
        setDeadline('')
        return
      }
      setDeadline(deadlineCount)
    }
  }, [formik, date, setDeadline])

  const handlePercentageHarmAmount = useCallback((event) => {
    const { value } = event.target
    const valueDecimal = value?.replace('.', ',')

    formik.setFieldValue('percentageHarm', valueDecimal)

    const ObjectInsuredImportance = formik.values.contractValue * (1 + (valueDecimal?.replace(',', '.') / 100))

    return parseFloat(ObjectInsuredImportance.toFixed(2))
  }, [formik])

  const formatCourtRegionalLabors = useCallback(
    (courtRegionalLaborsResponse) => courtRegionalLaborsResponse.map(
      ({ descricao, id }) => ({ id, text: descricao }),
    ), [],
  )

  useEffect(() => {
    courtRegionalLaborClient().getAll('?tipotribunal=0').then((response) => {
      setCourtRegionalLabors(formatCourtRegionalLabors(response)
        .sort((a, b) => (a.id - b.id)))
    })
  }, [
    formatCourtRegionalLabors,
    courtRegionalLaborClient,
  ])

  useEffect(() => {
    if (!isEmpty(courtRegional) && courtRegional.id) {
      stickClient().getById(courtRegional.id).then((res) => {
        const sticksResponse = res.data.sort((a, b) => a.descricao.localeCompare(b.descricao))

        setSticks(sticksResponse)
        setStickOptions(sticksResponse.map(({ descricao }) => descricao))
      })
    }
  }, [stickClient, courtRegional])

  const handleCourtRegionalLabor = (e) => {
    const { value } = e.target

    if (value) {
      const courtRegLaborFound = courtRegionalLabors
        .find((regCourtItem) => regCourtItem.text === value)

      setCourtRegional({
        descricao: courtRegLaborFound.text,
        id: courtRegLaborFound.id,
      })
      formik.values.courtRegionalLabor = courtRegLaborFound?.text
      formik.setFieldValue('objectText', concatTextDefault)
    }
  }

  const handleCourtSection = useCallback((value) => {
    const courtSectionFound = sticks.find((stickItem) => stickItem.descricao === value)

    if (!isEmpty(courtSectionFound)) {
      setCourtSection({
        id: courtSectionFound.id,
        descricao: courtSectionFound.descricao,
      })
    }
  }, [sticks, setCourtSection])

  const handleInfoProcessNumber = (ev) => {
    const { value } = ev.target
    courtRegionalLabors.map((item) => item.id)

    setStickDisabled(true)

    const processNumberValue = value?.split('.', 4)

    if (processNumberValue.length > 3
      && processNumberValue[3] <= 24
      && courtRegionalLabors.indexOf(Number(processNumberValue[3]) - 1)
    ) {
      handleCourtRegionalLabor({
        target: {
          value: courtRegionalLabors[Number(processNumberValue[3]) - 1]?.text,
        },
      })
      setStickDisabled(false)
    }

    if (processNumberValue[3] > 24) {
      handleCourtRegionalLabor({
        target: { value: '' },
      })
    }
  }

  useEffect(() => {
    if (quote?.ObjectProperty) {
      const fragmentData = quote?.ObjectProperty

      setCourtSection(fragmentData?.CourtSection)
      setCourtRegional(fragmentData?.CourtRegionalLabor)
    }
    if (formik.values.processNumber) {
      setStickDisabled(false)
    }
  }, [quote?.ObjectProperty, formik.values.processNumber])

  const stickLabel = 'Vara'
  const deadlineLabel = 'Prazo (Anos)'
  const contractValueLabel = 'Valor da Garantia'
  const endDateValidityLabel = 'Fim de Vigência'
  const processNumberLabel = 'Número do Processo'
  const proposedLegalActionLabel = 'Ação Proposta'
  const objectLabel = 'Obrigação Garantia (Objeto)'
  const startDateValidityLabel = 'Início de Vigência'
  const insuredImportanceLabel = 'Importância Segurada'
  const percentageHarmLabel = 'Percentual de Agravo (%)'
  const placeholderProcessNumber = '0000000-00.0000.0.00.0000'
  const courtRegionalLaborLabel = 'Tribunal e região da Justiça do Trabalho'

  const noOptionsMessage = (
    <Typography className={classes.noOptionsLabel}>
      {GlobalMessages.NO_OPTIONS_MESSAGE}
    </Typography>
  )

  return (
    <>
      <CollapsePrice
        checked={checked}
        handleEdit={handleStep}
        hiddenEdit={isEmpty(quote?.ObjectProperty) && !formik.isSubmitting}
        title="Objeto Segurado"
        subtitle="Informe abaixo os dados do objeto segurado"
      >
        <Grid container spacing={5}>
          <Grid item md={3} sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="proposedLegalActionLabel" className={classes.label}>
                {proposedLegalActionLabel}
                <Tooltip
                  placement="right-end"
                  className={classes.info}
                  title="Exemplos: Ação trabalhista, Embargos, Agravos, Recursos."
                >
                  <Icon
                    data-html
                    style={{ cursor: 'help', marginLeft: 5 }}
                  >
                    help_outline
                  </Icon>
                </Tooltip>
              </label>
              <Input
                fullWidth
                id="proposedLegalAction"
                style={{ marginTop: 11 }}
                color="secondary"
                placeholder="XXXXXX"
                {...formik.getFieldProps('proposedLegalAction')}
                error={formik.touched.proposedLegalAction && !!formik.errors.proposedLegalAction}
                onKeyUp={() => formik.setFieldValue('objectText', concatTextDefault)}
              />
              <FormHelperText
                hidden={!formik.touched.proposedLegalAction || !formik.errors.proposedLegalAction}
                error={formik.touched.proposedLegalAction && !!formik.errors.proposedLegalAction}
              >
                {formik.errors.proposedLegalAction}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="processNumberLabel" className={classes.label}>{processNumberLabel}</label>
              <ProcessNumberInput
                fullWidth
                id="processNumber"
                color="secondary"
                placeholder={placeholderProcessNumber}
                {...formik.getFieldProps('processNumber')}
                error={formik.touched.processNumber && !!formik.errors.processNumber}
                onKeyUp={handleInfoProcessNumber}
                onBlur={() => formik.setFieldValue('objectText', concatTextDefault)}
              />
              <FormHelperText
                hidden={!formik.touched.processNumber || !formik.errors.processNumber}
                error={formik.touched.processNumber && !!formik.errors.processNumber}
              >
                {formik.errors.processNumber}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={5} sm={7} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="courtRegionalLaborLabel" className={classes.label}>{courtRegionalLaborLabel}</label>
              <DropdownSelectOne
                id="courtRegionalLabor"
                options={courtRegionalLabors}
                placeholder={courtRegionalLaborLabel}
                {...formik.getFieldProps('courtRegionalLabor')}
                error={formik.touched.courtRegionalLabor && !!formik.errors.courtRegionalLabor}
                onChange={(e) => {
                  formik.values.courtSection = ''
                  handleCourtRegionalLabor(e)
                }}
              />
              <FormHelperText
                hidden={!formik.touched.courtRegionalLabor || !formik.errors.courtRegionalLabor}
                error={formik.touched.courtRegionalLabor && !!formik.errors.courtRegionalLabor}
              >
                {formik.errors.courtRegionalLabor}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={4} sm={5} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="stickLabel" className={classes.label}>{stickLabel}</label>
              <Autocomplete
                className={classes.textField}
                id="courtSection"
                options={stickOptions}
                disabled={stickDisabled}
                noOptionsText={noOptionsMessage}
                getOptionLabel={(option) => option ?? ''}
                onInputChange={(e, value) => {
                  formik.values.courtSection = value ?? ''
                  handleCourtSection(value)
                }}
                onKeyUp={handleCourtSection}
                getOptionSelected={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onFocus={(event) => event.target.setAttribute('autocomplete', 'none')}
                    placeholder="Procurar"
                    margin="normal"
                    error={formik.touched.courtSection && !!formik.errors.courtSection}
                    fullWidth
                    onBlur={() => formik.setFieldValue('objectText', concatTextDefault)}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {...formik.getFieldProps('courtSection')}
              />
              <FormHelperText
                hidden={!formik.touched.courtSection || !formik.errors.courtSection}
                error={formik.touched.courtSection && !!formik.errors.courtSection}
              >
                {formik.errors.courtSection}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={12} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="objectLabel" className={classes.labelObject}>{objectLabel}</label>
              <TextField
                id="objectText"
                multiline
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 1000 }}
                minRows={3}
                disabled
                {...formik.getFieldProps('objectText')}
                error={formik.touched.objectText && !!formik.errors.objectText}
                onChange={(e) => {
                  const { value } = e.target
                  formik.setFieldValue('objectText', value?.replace('', '')?.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, ''))
                }}
              />
              <FormHelperText
                hidden={!formik.touched.objectText || !formik.errors.objectText}
                error={formik.touched.objectText && !!formik.errors.objectText}
              >
                {formik.errors.objectText}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="contractValueLabel" className={classes.label}>{contractValueLabel}</label>
              <CurrencyInput
                fullWidth
                id="contractValue"
                color="secondary"
                {...formik.getFieldProps('contractValue')}
                error={
                  formik.touched.contractValue && !!formik.errors.contractValue
                }
              />
              <FormHelperText
                hidden={
                  !formik.touched.contractValue || !formik.errors.contractValue
                }
                error={
                  formik.touched.contractValue && !!formik.errors.contractValue
                }
              >
                {formik.errors.contractValue}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="percentageHarmLabel" className={classes.label}>{percentageHarmLabel}</label>
              <Input
                fullWidth
                color="secondary"
                id="percentageHarm"
                {...formik.getFieldProps('percentageHarm')}
                error={formik.touched.percentageHarm && !!formik.errors.percentageHarm}
                onFocus={(e) => {
                  formik.values.insuredImportance = handlePercentageHarmAmount(e)
                  setPercentage(formik?.values?.percentageHarm?.replace(',', '.'))
                }}
                onKeyUp={(e) => {
                  formik.values.insuredImportance = handlePercentageHarmAmount(e)
                  setPercentage(formik?.values?.percentageHarm?.replace(',', '.'))
                }}
                onBlur={(e) => {
                  formik.values.insuredImportance = handlePercentageHarmAmount(e)
                  setPercentage(formik?.values?.percentageHarm?.replace(',', '.'))
                }}
              />
            </FormControl>
            <FormHelperText
              hidden={!formik.touched.percentageHarm || !formik.errors.percentageHarm}
              error={formik.touched.percentageHarm && !!formik.errors.percentageHarm}
            >
              {formik.errors.percentageHarm}
            </FormHelperText>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth>
              <label htmlFor="insuredImportanceLabel" className={classes.label}>{insuredImportanceLabel}</label>
              <CurrencyInput
                fullWidth
                id="insuredImportance"
                title={insuredImportanceLabel}
                color="secondary"
                {...formik.getFieldProps('insuredImportance')}
                error={
                  formik.touched.insuredImportance && !!formik.errors.insuredImportance
                }
              />
              <FormHelperText
                hidden={
                  !formik.touched.insuredImportance || !formik.errors.insuredImportance
                }
                error={
                  formik.touched.insuredImportance && !!formik.errors.insuredImportance
                }
              >
                {formik.errors.insuredImportance}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item sm={4} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="startDateValidityLabel" className={classes.label}>
                    {startDateValidityLabel}
                  </label>
                  <DateInput
                    minDate={moment()}
                    id="startDateValidity"
                    title={startDateValidityLabel}
                    {...formik.getFieldProps('startDateValidity')}
                    error={formik.touched.startDateValidity && !!formik.errors.startDateValidity}
                    helperText={formik.touched.startDateValidity && formik.errors.startDateValidity}
                    onChange={(event) => {
                      const { value } = event.target
                      if (isBlank(value)) {
                        formik.setFieldValue('startDateValidity', '')
                      }
                      formik.setFieldValue('startDateValidity', formatDateApi(value))

                      formik.setFieldValue('deadline', deadline)
                    }}
                    onBlur={() => formik.setFieldValue('deadline', deadline)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="deadlineLabel" className={classes.label}>{deadlineLabel}</label>
                  <DropdownSelectOne
                    id="deadline"
                    options={DEADLINE_YEARS}
                    placeholder="Selecione"
                    {...formik.getFieldProps('deadline')}
                    error={formik.touched.deadline && !!formik.errors.deadline}
                    onChange={(e) => {
                      const { value } = e.target
                      formik.setFieldValue('deadline', value)

                      const newEndDate = formatDateApi(date(formik.values.startDateValidity).add(value, 'years'))
                      formik.setFieldValue('endDateValidity', newEndDate)
                    }}
                  />
                  <FormHelperText
                    hidden={!formik.touched.deadline || !formik.errors.deadline}
                    error={formik.touched.deadline && !!formik.errors.deadline}
                  >
                    {formik.errors.deadline}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="endDateValidityLabel" className={classes.label}>
                    {endDateValidityLabel}
                  </label>
                  <DateInput
                    id="endDateValidity"
                    title={endDateValidityLabel}
                    disabled
                    {...formik.getFieldProps('endDateValidity')}
                    error={formik.touched.endDateValidity && !!formik.errors.endDateValidity}
                    helperText={formik.touched.endDateValidity && formik.errors.endDateValidity}
                    onChange={(event) => {
                      const { value } = event.target
                      if (isBlank(value)) {
                        formik.setFieldValue('endDateValidity', '')
                      }
                      formik.setFieldValue('endDateValidity', formatDateApi(value))

                      formik.setFieldValue('deadline', deadline)
                    }}
                    onBlur={() => formik.setFieldValue('deadline', deadline)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.buttonsStep}>
              <Box>
                <ButtonLight
                  onClick={handlePrevious}
                  title="Voltar"
                >
                  Voltar
                </ButtonLight>
              </Box>
              <Box ml={4}>
                <ButtonGradient
                  onClick={handleNext}
                  title="Continuar"
                >
                  Continuar
                </ButtonGradient>
              </Box>
            </Box>
            <ReactTooltip place="top" type="dark" />
          </Grid>
        </Grid>
      </CollapsePrice>
    </>
  )
})

ObjectProperty.propTypes = {
  quote: PropTypes.any,
  checked: PropTypes.bool,
  handleStep: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
}

ObjectProperty.defaultProps = {
  quote: undefined,
  checked: false,
}

export default ObjectProperty
