import React from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Step from '@material-ui/core/Step'
import Stepper from '@material-ui/core/Stepper'
import StepLabel from '@material-ui/core/StepLabel'

import { makeStyles } from '@material-ui/core'

import { HelpLink } from '../../../shared/components'
import { HELP_LINKS } from '../../../constants/HelpLinks'
import { ROUTES_EXECUTIVE_REQUEST } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  step: {
    width: 135,
    flexDirection: 'column',
    textAlign: 'center',
    '& .MuiStepLabel-labelContainer': {
      display: 'none',
    },
  },
  helpGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerStepper: {
    width: '100%',
    marginBottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiPaper-root': {
      marginLeft: -40,
      padding: theme.spacing(3, 0),
      backgroundColor: 'transparent',
    },
    '& .MuiStepConnector-root': {
      margin: -50,
      maxWidth: 50,
      '&.MuiStepConnector-active': {
        '& .MuiStepConnector-line': {
          borderTopWidth: 4,
          borderImageSlice: 1,
          borderImage: 'linear-gradient(90deg, #8bf6aa 0%, #25a9cf 50%)',
        },
      },
      '&.MuiStepConnector-completed': {
        '& .MuiStepConnector-line': {
          borderColor: '#49ecae',
        },
      },
      '& .MuiStepConnector-line': {
        borderTopWidth: 4,
        borderColor: theme.palette.text.secondary,
      },
    },
  },
}))

const StepperQuotation = ({ steps, activeStep, EclipseStep }) => {
  const classes = useStyles()
  const location = useLocation()

  const getTokenUrl = location?.pathname?.split(':')
  const routeSolicitation = getTokenUrl[0]

  return (
    <Box className={classes.containerStepper}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => {
              const labelProps = {
                StepIconComponent: EclipseStep,
                classes: {
                  horizontal: classes.step,
                },
              }

              return (
                <Step key={label}>
                  <StepLabel {...labelProps}>
                    {label}
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </Grid>

        <Grid item xs={12} md={4} className={classes.helpGrid}>
          {!ROUTES_EXECUTIVE_REQUEST.includes(routeSolicitation) && (
            <HelpLink
              link={HELP_LINKS.createPolicy}
              text="Como realizar nova solicitação de Apólice"
              minWidth={350}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

StepperQuotation.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  EclipseStep: PropTypes.element.isRequired,
}

export default StepperQuotation
