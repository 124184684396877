import { useCallback } from 'react'
import axios from 'axios'

const useProposalClient = () => {
  const approveProposal = useCallback(async (identifier) => axios.put(`/quote/approve?identifier=${identifier}`).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  return useCallback(() => ({
    approveProposal,
  }), [
    approveProposal,
  ])
}

export default useProposalClient
