import React from 'react'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { ButtonGradient, ButtonLight, ModalInfo } from '../../../../../shared/components'

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.quaternary,
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  buttonsStep: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& .MuiBox-root': {
      minWidth: 190,
    },
    '@media (max-width: 699px)': {
      justifyContent: 'center',
    },
  },
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 600,
    },
  },
}))

const ModalAnalysisRequest = (props) => {
  const classes = useStyles()
  const { openModal, closeModal, handleConfirmation } = props

  const formik = useFormik({
    initialValues: { analysisText: '' },
    validationSchema: Yup.object({
      analysisText: Yup.string(),
    }),
    onSubmit: (data) => {
      closeModal()
      handleConfirmation(data)
    },
  })

  const analysisTextLabel = 'Motivo da Análise'

  return (
    <>
      {openModal && (
        <ModalInfo
          close={false}
          open={openModal}
          className={classes.modal}
        >
          <Box p={2} alignItems="center" justifyContent="center">
            <Box>
              <Box mt={2} mb={5}>
                <Typography align="center" variant="h4">Solicitação de Análise</Typography>
              </Box>

              <Box mb={4}>
                <FormControl fullWidth>
                  <label htmlFor="analysisTextLabel" className={classes.label}>{analysisTextLabel}</label>
                  <TextField
                    id="analysisText"
                    multiline
                    variant="outlined"
                    fullWidth
                    minRows={2}
                    {...formik.getFieldProps('analysisText')}
                  />
                </FormControl>
              </Box>

              <Box mb={5}>
                <Typography align="center">
                  Deseja enviar a cotação para análise do executivo?
                </Typography>
              </Box>

              <Grid item xs={12}>
                <Box justifyContent="center" className={classes.buttonsStep}>
                  <Box>
                    <ButtonGradient
                      onClick={formik.submitForm}
                    >
                      Sim
                    </ButtonGradient>
                  </Box>
                  <Box>
                    <ButtonLight
                      onClick={closeModal}
                    >
                      Não
                    </ButtonLight>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Box>
        </ModalInfo>
      )}
    </>
  )
}

ModalAnalysisRequest.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
}

export default ModalAnalysisRequest
