import React from 'react'
import PropTypes from 'prop-types'
import Pagination from '@material-ui/lab/Pagination'

import Box from '@material-ui/core/Box'

const Paginator = ({
  totalResults, changePaginator, RPP, filter, setFilter,
}) => {
  const handleChange = (_, value) => {
    setFilter({
      ...filter,
      Page: value,
    })

    changePaginator(value)
  }

  return totalResults > RPP ? (
    <Box display="flex" justifyContent="center" mt={4}>
      <Pagination count={Math.ceil(totalResults / RPP)} page={filter?.Page ?? 1} onChange={handleChange} showFirstButton showLastButton justify="center" />
    </Box>
  ) : ''
}
Paginator.propTypes = {
  totalResults: PropTypes.number.isRequired,
  RPP: PropTypes.number,
  changePaginator: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
}

Paginator.defaultProps = {
  RPP: 10,
}

export default Paginator
