const ValuesDeadlineData = ({
  Deadline,
  PercentageHarm,
  InsuredImportance,
  AppealDepositValue,
}) => ({
  deadline: Deadline ?? '',
  percentageHarm: PercentageHarm ?? 0,
  insuredImportance: InsuredImportance ?? 0,
  appealDepositValue: AppealDepositValue ?? 0,
})

export default ValuesDeadlineData
