import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useHistory, Link } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'

import makeStyles from '@material-ui/core/styles/makeStyles'

import {
  GoEye,
  GoEyeClosed,
} from 'react-icons/go'

import {
  FaGoogle,
  FaMicrosoft,
} from 'react-icons/fa'

import useSecurity from '../../security/useSecurity'
import {
  ERROR_FIREBASE_AUTH_POPUP_CLOSED,
  ERROR_FIREBASE_PASSWORD_ENGLISH,
  ERROR_FIREBASE_NO_USER_RECORD_ENGLISH,
  ERROR_FIREBASE_DISABLED_BY_AN_ADMINISTRATOR,
} from '../../constants'

import {
  ACCESS_EXPIRED,
  ERRO_FIREBASE_SENHA,
  ERRO_SENHA_EXPIRADA,
  ERRO_USUARIO_INEXISTENTE,
} from '../../shared/messages/Global'

import logo from '../../assets/img/logo.svg'
import {
  useAlert,
  useLoader,
  ButtonLight,
  ButtonGradient,
} from '../../shared/components'

import ModalPasswordResetMessage from './utils/ModalPasswordResetMessage'
import useUserClient from '../../clients/UserClient/useUserClient'

const useStyles = makeStyles((theme) => ({
  socialNetworks: {
    display: 'flex',
    padding: theme.spacing(1),
    justifyContent: 'center',
    '& .MuiGrid-root': {
      padding: '0',
      width: 'auto',
    },
  },
  form: {
    width: '100%',
    maxWidth: 390,
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'center',
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(3),
    },
    marginTop: theme.spacing(7),
  },
  link: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  titleContainer: {
    display: 'flex',
    direction: 'column',
    alignItems: 'center',
    '& img': {
      marginBottom: theme.spacing(1),
    },
  },
  title: {
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: 44,
    color: theme.palette.primary.main,
  },
  header: {
    display: 'flex',
    marginBottom: '3em',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    flex: 'auto',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    '& img': {
      width: 180,
      maxWidth: '100%',
    },
    '@media (max-width: 599px)': {
      marginBottom: '3em',
    },
  },
  labelInfo: {
    fontSize: 14,
    marginTop: theme.spacing(4),
  },
  labelAlert: {
    fontSize: 13,
    marginBlock: 2,
    color: theme.palette.error.main,
  },
  containerForm: {
    '& .MuiFormLabel-root': {
      color: theme.palette.text.quaternary,
      fontWeight: 'bold',
      lineHeight: 2,
    },
  },
}))

const CheckLogin = () => {
  const classes = useStyles()
  const history = useHistory()

  const { addMsgDanger } = useAlert()
  const { showLoader, hideLoader } = useLoader()
  const { signin, signinByGoogle, signinByMicrosoft } = useSecurity()

  const userClient = useUserClient()

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  })
  const [openModalExpiredPassword, handleModalExpiredPassword] = React.useState(false)
  const [openModalIncorrectPassword, handleModalIncorrectPassword] = React.useState(false)

  const closeModalExpiredPassword = () => {
    handleModalExpiredPassword(false)
  }

  const closeModalIncorrectPassword = () => {
    handleModalIncorrectPassword(false)
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: Yup.object({
      email: Yup.string().email().required(),
      password: Yup.string().required(),
      // .min(8, 'Senha curta - Deve ter no mínimo 8 caracteres.')
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (data) => {
      try {
        showLoader()
        const response = await userClient().passwordResetStatus(data?.email)
        if (response?.data === true) {
          hideLoader()
          handleModalExpiredPassword(true)
        } else {
          try {
            await signin(data.email, data.password)
            hideLoader()
            history.replace('/callback')
          } catch (e) {
            hideLoader()
            if (![ERROR_FIREBASE_AUTH_POPUP_CLOSED].includes(e.code)) {
              if (e.message === ERROR_FIREBASE_PASSWORD_ENGLISH) {
                handleModalIncorrectPassword(true)
              } else if (e.message === ERROR_FIREBASE_NO_USER_RECORD_ENGLISH) {
                addMsgDanger(ERRO_USUARIO_INEXISTENTE)
              } else if (e.message === ERROR_FIREBASE_DISABLED_BY_AN_ADMINISTRATOR) {
                addMsgDanger({
                  title: ACCESS_EXPIRED,
                  labelButton: 'Fechar',
                  action: () => window.location.reload(),
                })
              } else {
                const error = e
                addMsgDanger(error.message)
              }
            }
          }
        }
      } catch (error) {
        hideLoader()
        addMsgDanger(error.message)
      }
    },
  })

  const loginByGoogle = async () => {
    try {
      showLoader()
      await signinByGoogle().then(() => {
        hideLoader()
        history.replace('/callback')
      })
    } catch (e) {
      if (![ERROR_FIREBASE_AUTH_POPUP_CLOSED].includes(e.code)) {
        addMsgDanger(e.message)
      }
    }
  }

  const loginByMicrosoft = async () => {
    try {
      showLoader()
      await signinByMicrosoft().then(() => {
        hideLoader()
        history.replace('/callback')
      })
    } catch (e) {
      if (![ERROR_FIREBASE_AUTH_POPUP_CLOSED].includes(e.code)) {
        addMsgDanger(e.message)
      }
    }
  }

  const register = () => {
    history.push('/register')
  }

  return (
    <>
      <Grid item className={classes.header}>
        <Box className={classes.logo}>
          <img src={logo} alt="Finlandia" aria-label="Finlandia" />
        </Box>
      </Grid>
      <Box className={classes.titleContainer}>
        <Typography variant="h3" className={classes.title}>Área do Cliente</Typography>
      </Box>
      <p className="text">Digite os dados a seguir para acessar o portal</p>

      <form onSubmit={(e) => { e.preventDefault(); formik.submitForm() }} noValidate autoComplete="off" className={classes.form}>
        <Grid container spacing={4} className={classes.containerForm}>
          <Grid item sm={12} xs={12}>
            <InputLabel htmlFor="standard-adornment-email">E-mail</InputLabel>
            <Input
              fullWidth
              inputlabelprops={{
                shrink: true,
              }}
              title="E-mail"
              id="standard-adornment-email"
              type="text"
              placeholder="exemplo@email.com.br"
              {...formik.getFieldProps('email')}
              error={formik.touched.email && !!formik.errors.email}
              helpertext={formik.touched.email && formik.errors.email}
            />
            <FormHelperText
              hidden={!formik.touched.email || !formik.errors.email}
              error={formik.touched.email && !!formik.errors.email}
            >
              {formik.errors.email}
            </FormHelperText>
          </Grid>
          <Grid item sm={12} xs={12}>
            <InputLabel htmlFor="standard-adornment-password">Senha</InputLabel>
            <Input
              fullWidth
              inputlabelprops={{
                shrink: true,
              }}
              title="Senha"
              id="standard-adornment-password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              placeholder="•••••"
              {...formik.getFieldProps('password')}
              error={formik.touched.password && !!formik.errors.password}
              helpertext={formik.touched.password && formik.errors.password}
              endAdornment={(
                <InputAdornment
                  position="end"
                >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {
                      values.showPassword
                        ? <GoEye />
                        : <GoEyeClosed />
                    }
                  </IconButton>
                </InputAdornment>
              )}
            />
            <FormHelperText
              hidden={!formik.touched.password || !formik.errors.password}
              error={formik.touched.password && !!formik.errors.password}
            >
              {formik.errors.password}
            </FormHelperText>
            <p className={`text ${classes.labelInfo}`} style={{ marginBottom: 0 }}>
              Esqueceu sua Senha?
              {' '}
              <Link to="/recover-password" className={classes.link}>Recupere sua senha</Link>
            </p>
          </Grid>

          <Grid item xs={12} sm={6}>
            <ButtonGradient
              type="submit"
            >
              Entrar
            </ButtonGradient>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ButtonLight
              onClick={register}
            >
              Criar uma conta
            </ButtonLight>
          </Grid>

          <Grid item sm={12} xs={12}>
            <p className="text">
              Ou acesse usando
            </p>
          </Grid>

          <Grid container spacing={4} className={classes.socialNetworks}>
            <Grid item>
              <IconButton
                color="primary"
                aria-label="Acessar com Microsoft"
                onClick={loginByMicrosoft}
              >
                <FaMicrosoft />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                color="primary"
                aria-label="Acessar com Google"
                onClick={loginByGoogle}
              >
                <FaGoogle />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <ModalPasswordResetMessage
        message={ERRO_FIREBASE_SENHA}
        openModal={openModalIncorrectPassword}
        closeModal={closeModalIncorrectPassword}
      />
      <ModalPasswordResetMessage
        message={ERRO_SENHA_EXPIRADA}
        openModal={openModalExpiredPassword}
        closeModal={closeModalExpiredPassword}
      />
    </>
  )
}

export default CheckLogin
