import React, { useEffect } from 'react'

import useSecurity from '../../security/useSecurity'
import SlashScreen from '../../shared/components/SlashScreen'

const Signin = () => {
  const { signin } = useSecurity()

  useEffect(() => {
    signin()
  }, [signin])

  return <SlashScreen />
}

export default Signin
