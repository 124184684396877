import { useCallback } from 'react'

const useQuotationResourceClient = () => {
  const getAll = useCallback(() => new Promise((resolve) => resolve([
    {
      id: 'optionStartServices',
      text: 'Já se tem ordem de início de serviços autorizada',
    },
    {
      id: 'optionChangeQuantitative',
      text: 'Há previsão de se alterar quantitativos',
    },
    {
      id: 'optionChangeContractValue',
      text: 'Há previsão de se alterar o valor do contrato',
    },
    {
      id: 'optionLicensingGranted',
      text: 'O licenciamento ambiental foi concedido',
    },
    {
      id: 'optionChangeContractualInstrument',
      text: 'Há previsão deste instrumento contratual ser alterado',
    },
    {
      id: 'optionChangeQualitative',
      text: 'Há previsão de se alterar qualitativos',
    },
    {
      id: 'optionChangeContractDeadline',
      text: 'Há previsão de se alterar o prazo do contrato',
    },
  ])), [])

  return useCallback(() => ({
    getAll,
  }), [
    getAll,
  ])
}

export default useQuotationResourceClient
