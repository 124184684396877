import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'

import PriceContext from '../../../../shared/contexts/PriceContext'
import { EclipseStep } from '../../../../shared/components/StepIcon'
import {
  useAlert,
} from '../../../../shared/components'
import StepperQuotation from '../StepperQuotation'

import Insured from './fragments/Insured'
import Modality from './fragments/Modality'
import Requester from './fragments/Requester'
import FileUpload from './fragments/FileUpload'
import ObjectProperty from './fragments/ObjectProperty'

import InsuredData from '../../../../models/PerformanceModels/InsuredData'
import ModalityData from '../../../../models/PerformanceModels/ModalityData'
import RequesterData from '../../../../models/PerformanceModels/RequesterData'
import ObjectPropertyData from '../../../../models/PerformanceModels/ObjectPropertyData'
import FileUploadData from '../../../../models/PerformanceModels/FileUploadData'

const StepType = {
  MODALITY: 0,
  INSURED: 1,
  OBJECT_PROPERTY: 2,
  INSURANCE: 3,
  FILE_UPLOAD: 4,
}

const GuaranteePerformance = () => {
  const { Provider } = PriceContext

  const history = useHistory()
  const { state } = useLocation()

  const { addMsgDanger: addMsgError, addMsgSuccess } = useAlert()
  const { addMsgDanger, cleanMsg } = useAlert('register')

  const [steps] = useState([
    StepType.MODALITY,
    StepType.INSURED,
    StepType.OBJECT_PROPERTY,
    StepType.INSURANCE,
    StepType.FILE_UPLOAD,

  ])
  const [stepsRef, setStepsRef] = useState({})
  const [actionNext, setActionNext] = useState(false)
  const [quoteEditData, setQuoteEditData] = useState({})
  const [actionPrevious, setActionPrevious] = useState(false)
  const [isFormikChanged, setIsFormikChanged] = useState(false)
  const [activeStep, setActiveStep] = useState(StepType.MODALITY)
  const [guaranteePerformance, setGuaranteePerformance] = useState({})

  const InsuredRef = useRef()
  const InsuranceRef = useRef()
  const ModalityRef = useRef()
  const ObjectPropertyRef = useRef()
  const FileUploadRef = useRef()

  const setDataCamelCase = useCallback((quote) => {
    setGuaranteePerformance({
      rule: ModalityData(quote?.Rule),
      insured: InsuredData(quote?.Insured),
      requester: RequesterData(quote?.Requester),
      objectProperty: ObjectPropertyData(quote?.ObjectProperty),
      attachments: FileUploadData(quote?.Attachments),
    })
  }, [setGuaranteePerformance])

  useEffect(() => {
    if (state) {
      setDataCamelCase({ ...state?.quote })
      setQuoteEditData({ ...state?.quote })
      setActiveStep(steps[steps.length - 1])
    }
  }, [state, steps, setActiveStep, setQuoteEditData, setDataCamelCase])

  useEffect(() => {
    const stepsControl = {}
    stepsControl[StepType.INSURED] = InsuredRef
    stepsControl[StepType.INSURANCE] = InsuranceRef
    stepsControl[StepType.MODALITY] = ModalityRef
    stepsControl[StepType.OBJECT_PROPERTY] = ObjectPropertyRef
    stepsControl[StepType.FILE_UPLOAD] = FileUploadRef

    setStepsRef(stepsControl)
  }, [
    InsuredRef,
    setStepsRef,
    InsuranceRef,
    ModalityRef,
    FileUploadRef,
    ObjectPropertyRef,
  ])

  const isActiveStep = (step) => activeStep === step

  const handleNext = () => {
    const stepRef = stepsRef[activeStep]
    stepRef.current.onSubmit().then((data) => {
      if (data) {
        addMsgDanger(data)
      } else {
        setActionNext(true)
        setActionPrevious(false)
      }
    })
  }

  const onNext = () => {
    cleanMsg()
    if (steps.includes(activeStep + 1)) {
      setActiveStep(activeStep + 1)
    }
  }

  const onPrevious = () => {
    cleanMsg()
    setActiveStep(activeStep - 1)
  }

  const onEdit = (step) => {
    setActiveStep(step)

    cleanMsg()
    setActionNext(false)
    setActionPrevious(true)
  }

  const onRegister = (data) => {
    const dataSuccess = {
      title: data?.Title,
      message: data?.Message,
      labelButton: 'Acompanhe sua cotação',
      action: () => history.replace(`/cotacao/${data?.Identifier}/status`),
    }

    addMsgSuccess(dataSuccess)
  }

  const handleRegister = () => {
    const stepRef = stepsRef[activeStep]
    stepRef.current.onSubmit().then((data) => {
      addMsgError(data)
      if (!data) {
        onEdit(StepType.FILE_UPLOAD)
      }
    })
  }

  const setFormikChanged = (isChanged) => {
    if (!isFormikChanged) {
      setIsFormikChanged(isChanged)
    }
  }

  return (
    <Provider value={{
      guaranteePerformance,
      setGuaranteePerformance,
      actionNext,
      actionPrevious,
      onNext,
      onRegister,
      addMsgError,
      isFormikChanged,
      setFormikChanged,
    }}
    >
      {/* STEPS */}
      <StepperQuotation steps={steps} EclipseStep={EclipseStep} activeStep={activeStep} />

      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Modality
            quote={quoteEditData}
            ref={stepsRef[StepType.MODALITY]}
            checked={isActiveStep(StepType.MODALITY)}
            handleNext={handleNext}
            handlePrevious={() => history.goBack()}
            handleStep={() => onEdit(StepType.MODALITY)}
          />
        </Grid>
        <Grid item xs={12}>
          <Insured
            quote={quoteEditData}
            ref={stepsRef[StepType.INSURED]}
            checked={isActiveStep(StepType.INSURED)}
            handleNext={handleNext}
            handlePrevious={onPrevious}
            handleStep={() => onEdit(StepType.INSURED)}
          />
        </Grid>
        <Grid item xs={12}>
          <ObjectProperty
            quote={quoteEditData}
            ref={stepsRef[StepType.OBJECT_PROPERTY]}
            checked={isActiveStep(StepType.OBJECT_PROPERTY)}
            handleNext={handleNext}
            handlePrevious={onPrevious}
            handleStep={() => onEdit(StepType.OBJECT_PROPERTY)}
          />
        </Grid>
        <Grid item xs={12}>
          <Requester
            quote={quoteEditData}
            ref={stepsRef[StepType.INSURANCE]}
            checked={isActiveStep(StepType.INSURANCE)}
            handleNext={handleNext}
            handlePrevious={onPrevious}
            handleStep={() => onEdit(StepType.INSURANCE)}
          />
        </Grid>
        <Grid item xs={12}>
          <FileUpload
            quote={quoteEditData}
            ref={stepsRef[StepType.FILE_UPLOAD]}
            checked={isActiveStep(StepType.FILE_UPLOAD)}
            handleNext={handleNext}
            handlePrevious={onPrevious}
            handleStep={handleRegister}
          />
        </Grid>
      </Grid>
    </Provider>
  )
}

export default GuaranteePerformance
