import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import makeStyles from '@material-ui/core/styles/makeStyles'

import useSecurity from '../../security/useSecurity'
import { useSecurityAction } from '../../store/ducks/security'

import { useAlert } from '../../shared/components'

import Main from '../../shared/layouts/Main'
import Footer from '../../shared/layouts/footer/Footer'
import SubFooter from '../../shared/layouts/footer/SubFooter'

import BackgroundApolices from '../../assets/img/backgrounds/bg-apolices.webp'

import CategoryProduct from '../price/CategoryProduct'
import Endorsement from '../endorsement/Endorsement'
import QuotationDetails from '../price/quotations/QuotationDetails'
import GuaranteeBID from '../price/quotations/guarantee-bid/Quotation'
import GuaranteePerformance from '../price/quotations/guarantee-performance/Quotation'
import JudicialGuaranteeLabor from '../price/quotations/judicial-guarantee-labor/Quotation'
import JudicialGuaranteeeAppeal from '../price/quotations/judicial-guarantee-appeal/Quotation'
import JudicialGuaranteeCivel from '../price/quotations/judicial-guarantee-civel/Quotation'

import {
  QUOTE_URL_EXECUTIVE_REQUEST,
  QUOTE_URL_EXECUTIVE_REQUEST_JT,
  QUOTE_URL_EXECUTIVE_REQUEST_JC,
  QUOTE_URL_EXECUTIVE_REQUEST_JDR,
  QUOTE_URL_EXECUTIVE_REQUEST_BID,
  QUOTE_URL_EXECUTIVE_REQUEST_PER,
  ENDORSEMENT_URL_EXECUTIVE_REQUEST,
  QUOTE_URL_EXECUTIVE_REQUEST_CONFIRMATION,
} from '../../constants'
import useUserClient from '../../clients/UserClient/useUserClient'

const POLICY_REQUEST_LABEL = 'Solicitação de Apólice'

const useStyles = makeStyles((theme) => ({
  rootHeader: (props) => {
    const styles = {
      color: theme.palette.primary.contrastText,
      backgroundSize: 'cover',
      backgroundPosition: 'center 25%',
      '& .MuiContainer-root': {
        display: 'flex',
        height: theme.sizes.header,
      },
      '& .MuiTypography-h4': {
        fontSize: '1.9rem',
        color: theme.palette.primary.contrastText,
      },
    }

    if (props.isError) {
      styles.backgroundColor = '#F6F6F6'
    } else {
      styles.animation = 'fadein 0.5s'
      styles.backgroundImage = `linear-gradient(to left, #19BDDDCC, ${theme.palette.primary.main}CC), url(${BackgroundApolices})`
    }

    return styles
  },
}))

const ExecutivePortalRequest = () => {
  const classes = useStyles()

  const { getUser } = useSecurity()
  const { setUser } = useSecurityAction()
  const { addMsgDanger } = useAlert()

  const user = getUser()
  const history = useHistory()
  const location = useLocation()

  const userClient = useUserClient()

  const getExecutiveEmail = () => {
    userClient().getExecutiveEmail().then((response) => {
      setUser({
        email: response?.data,
        ...user,
      })
    }, (error) => {
      if (error?.status === 401) {
        addMsgDanger({
          title: error?.status,
          message: 'Acesso expirado. Tente novamente ou Contate o administrador do sistema.',
          action: () => history.replace('/unauthorized'),
        })
      } else {
        addMsgDanger({
          title: error?.status,
          message: 'Tente novamente ou contate o administrador do sistema.',
          action: () => history.replace('/unauthorized'),
        })
      }
    })
  }

  const getTokenUrl = location?.pathname?.split(':')
  const routeSolicitation = getTokenUrl[0]

  const handleTitlePage = () => {
    switch (routeSolicitation) {
      case QUOTE_URL_EXECUTIVE_REQUEST:
        return POLICY_REQUEST_LABEL
      case ENDORSEMENT_URL_EXECUTIVE_REQUEST:
        return 'Solicitação de Endosso'
      case QUOTE_URL_EXECUTIVE_REQUEST_CONFIRMATION:
        return 'Confirmação'
      case QUOTE_URL_EXECUTIVE_REQUEST_PER:
        return POLICY_REQUEST_LABEL
      case QUOTE_URL_EXECUTIVE_REQUEST_BID:
        return POLICY_REQUEST_LABEL
      case QUOTE_URL_EXECUTIVE_REQUEST_JC:
        return POLICY_REQUEST_LABEL
      case QUOTE_URL_EXECUTIVE_REQUEST_JDR:
        return POLICY_REQUEST_LABEL
      case QUOTE_URL_EXECUTIVE_REQUEST_JT:
        return POLICY_REQUEST_LABEL
      default:
        return ''
    }
  }

  const handleFormsRenders = () => {
    switch (routeSolicitation) {
      case ENDORSEMENT_URL_EXECUTIVE_REQUEST:
        return (
          <Endorsement />
        )
      case QUOTE_URL_EXECUTIVE_REQUEST:
        return (
          <CategoryProduct />
        )
      case QUOTE_URL_EXECUTIVE_REQUEST_CONFIRMATION:
        return (
          <QuotationDetails />
        )
      case QUOTE_URL_EXECUTIVE_REQUEST_PER:
        return (
          <GuaranteePerformance />
        )
      case QUOTE_URL_EXECUTIVE_REQUEST_BID:
        return (
          <GuaranteeBID />
        )
      case QUOTE_URL_EXECUTIVE_REQUEST_JDR:
        return (
          <JudicialGuaranteeeAppeal />
        )
      case QUOTE_URL_EXECUTIVE_REQUEST_JC:
        return (
          <JudicialGuaranteeCivel />
        )
      case QUOTE_URL_EXECUTIVE_REQUEST_JT:
        return (
          <JudicialGuaranteeLabor />
        )

      default:
        return null
    }
  }

  useEffect(() => {
    getExecutiveEmail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box display="flex" flexDirection="column" width="100vw">
      <header className={classes.rootHeader}>
        <Container maxWidth="xl">
          <Grid container alignItems="center">
            <Grid md item xs="auto" sm="auto">
              <Box>
                <Typography component="span" variant="h4" style={{ fontSize: 30, marginLeft: 100 }}>
                  {handleTitlePage()}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </header>
      <Grid>
        <Grid>
          <Main>
            {handleFormsRenders()}
          </Main>
        </Grid>
      </Grid>
      <footer>
        <SubFooter />
        <Footer />
      </footer>
    </Box>
  )
}

export default ExecutivePortalRequest
