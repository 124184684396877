import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { isEmpty } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Step from '@material-ui/core/Step'
import Stepper from '@material-ui/core/Stepper'
import StepLabel from '@material-ui/core/StepLabel'

import EndorsementContext from '../../shared/contexts/EndorsementContext'
import { EclipseStep } from '../../shared/components/StepIcon'
import HelpLink from '../../shared/components/HelpLink'
import {
  useAlert,
} from '../../shared/components'

import EndorsementRequest from './fragments/EndorsementRequest'
import FirstTemplateEndorsement from './templates/FirstTemplateEndorsement'
import SecondTemplateEndorsement from './templates/SecondTemplateEndorsement'
import useEndorsementPolicyClient from '../../clients/PolicyClient/useEndorsementPolicyClient'
import ConfirmationDetails from './fragments/ConfirmationDetails'

import { ENDORSEMENT_URL_EXECUTIVE_REQUEST, QUOTE_LEASE_BAIL } from '../../constants'
import { HELP_LINKS } from '../../constants/HelpLinks'
import { ENDORSEMENT_TYPE_CANCELLATION } from '../../constants/EndorsementConstants'
import Payment from './fragments/Payment'
import useStyles from './styles/endorsement'

const StepType = {
  ENDORSEMENT_REQUEST: 0,
  ENDORSEMENT_FILEUPLOAD: 4,
}

const ExternalStep = {
  QUOTE_REQUEST: 0,
  QUOTE_CONFIRMATION: 1,
  QUOTE_PAYMENT: 2,
}

const Endorsement = () => {
  const { Provider } = EndorsementContext

  const classes = useStyles()
  const history = useHistory()

  const endorsementPolicyClient = useEndorsementPolicyClient()

  const { addMsgDanger: addMsgError, addMsgSuccess } = useAlert()
  const { addMsgDanger, cleanMsg } = useAlert('register')
  const location = useLocation()
  const { state } = useLocation()

  const [steps, setSteps] = useState([0])

  const [template, setTemplate] = useState()
  const [actionNext, setActionNext] = useState(false)
  const [endorsement, setEndorsement] = useState({})
  const [endorsementData, setEndorsementData] = useState({})
  const [policyCurrent, setPolicyCurrent] = useState({})
  const [actionPrevious, setActionPrevious] = useState(false)
  const [activeStep, setActiveStep] = useState(StepType.ENDORSEMENT_REQUEST)
  const [activeExternalStep, setActiveExternalStep] = useState(ExternalStep.QUOTE_REQUEST)
  const [policySelected, setPolicySelected] = useState()
  const [endorsementTypeStateSelected, setEndorsementTypeStateSelected] = useState()
  const [productPolicy, setProductPolicy] = useState('')
  const [policy, setPolicy] = useState({})
  const [policyMain, setPolicyMain] = useState()
  const [lastPolicy, setLastPolicy] = useState()
  const [endorsementDocument, setEndorsementDocument] = useState([])
  const [otherBrokerageDocument, setOtherBrokerageDocument] = useState(false)

  const getTokenUrl = location?.pathname?.split(':')
  const routeSolicitation = getTokenUrl[0]

  const DetailsProductRef = useRef()

  const stepsRef = useMemo(() => {
    const stepsControl = {}
    stepsControl[StepType.ENDORSEMENT_REQUEST] = DetailsProductRef

    if (steps) {
      steps.forEach((stepType) => {
        stepsControl[stepType] = React.createRef(stepType)
      })
    }

    return stepsControl
  }, [steps])

  const addStepsByType = useCallback((quoteType) => {
    let type = quoteType
    if (quoteType?.id === ENDORSEMENT_TYPE_CANCELLATION) {
      type = {
        ...quoteType,
        product: QUOTE_LEASE_BAIL,
      }
    }

    endorsementPolicyClient().findStepsByType(
      type,
      otherBrokerageDocument,
    ).then((templateModality) => {
      if (!isEmpty(templateModality)) {
        setTemplate(templateModality?.id)
        const stepsTemplate = Object.values(templateModality?.steps) ?? []
        setSteps(stepsTemplate ?? [])
      }
    })
  }, [
    setSteps,
    otherBrokerageDocument,
    endorsementPolicyClient,
  ])

  const isActiveStep = (step) => activeStep === step

  const handleNext = () => {
    const stepRef = stepsRef[activeStep]
    if (stepRef) {
      stepRef.current.onSubmit().then((data) => {
        if (data) {
          addMsgDanger(data)
        } else {
          setActionNext(true)
          setActionPrevious(false)
        }
      })
    }
  }

  const onNext = () => {
    cleanMsg()
    if (steps.includes(activeStep + 1)) {
      setActiveStep(activeStep + 1)
    }
  }

  const onPrevious = () => {
    cleanMsg()
    setActiveStep(activeStep - 1)
  }

  const onEdit = (step) => {
    setActiveStep(step)

    cleanMsg()
    setActionNext(false)
    setActionPrevious(true)
  }

  const onRegister = (data) => {
    const dataSuccess = {
      title: data?.Title,
      message: data?.Message,
      labelButton: 'Acompanhe sua solicitação.',
      action: () => history.push('/status-solicitacao'),
    }

    addMsgSuccess(dataSuccess)
  }

  const handleRegister = () => {
    const stepRef = stepsRef[activeStep]
    stepRef.current.onSubmit().then((data) => {
      addMsgError(data)
      if (!data) {
        onEdit(StepType.REQUESTER)
      }
    })
  }

  const handleNextExternal = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setActiveExternalStep(activeExternalStep + 1)
  }

  const handleBackExternal = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setActiveExternalStep(activeExternalStep - 1)
  }

  useEffect(() => {
    if (state) {
      const { toEdit } = state ?? {}

      setProductPolicy(toEdit?.productPolicy)
      setPolicy(toEdit?.policy)
      setPolicyMain(toEdit?.policyMain)
      setLastPolicy(toEdit?.lastPolicy)
      setEndorsementData(toEdit?.endorsementData)
      setEndorsement(toEdit?.endorsement)
      setPolicySelected(toEdit?.policySelected)
      setEndorsementTypeStateSelected(toEdit?.endorsementTypeSelected)
      setActiveStep(StepType.ENDORSEMENT_FILEUPLOAD)
    }
  }, [state])

  useEffect(() => {
    let currentEndorsementData = endorsementData
    if (state) {
      const { toEdit } = state ?? {}
      currentEndorsementData = toEdit?.endorsementData
    }

    if (activeStep === StepType.ENDORSEMENT_REQUEST) {
      if (currentEndorsementData) {
        addStepsByType(currentEndorsementData)
      } else {
        setTemplate()
        setSteps([StepType.ENDORSEMENT_REQUEST])
      }
    }
  }, [activeStep, addStepsByType, endorsementData, state])

  return (
    <Provider value={{
      endorsement,
      setEndorsement,
      actionNext,
      actionPrevious,
      onNext,
      onRegister,
      addMsgError,
      endorsementData,
      setEndorsementData,
      policyCurrent,
      setPolicyCurrent,
      policySelected,
      setPolicySelected,
      endorsementTypeStateSelected,
      setEndorsementTypeStateSelected,
      productPolicy,
      setProductPolicy,
      policy,
      setPolicy,
      policyMain,
      setPolicyMain,
      lastPolicy,
      setLastPolicy,
      endorsementDocument,
      setEndorsementDocument,
      otherBrokerageDocument,
      setOtherBrokerageDocument,
    }}
    >
      {/* STEPS */}
      {activeExternalStep === ExternalStep.QUOTE_REQUEST && (
        <>
          <Box className={classes.containerStepper}>
            <Grid container>
              <Grid item xs={12} md={7}>
                <Stepper activeStep={activeStep} className={classes.stepper}>
                  {steps.map((label) => {
                    const labelProps = {
                      StepIconComponent: EclipseStep,
                      classes: {
                        horizontal: classes.step,
                      },
                    }

                    return (
                      <Step key={label}>
                        <StepLabel {...labelProps}>
                          {label}
                        </StepLabel>
                      </Step>
                    )
                  })}
                </Stepper>
              </Grid>

              <Grid item xs={12} md={5} className={classes.helpGrid}>
                {routeSolicitation !== ENDORSEMENT_URL_EXECUTIVE_REQUEST && (
                  <HelpLink
                    link={HELP_LINKS.createEndorsement}
                    text="Como realizar uma nova solicitação de Endosso"
                    minWidth={360}
                  />
                )}
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={5}>
            <Grid item xs={12}>
              <EndorsementRequest
                ref={stepsRef[StepType.ENDORSEMENT_REQUEST]}
                checked={isActiveStep(StepType.ENDORSEMENT_REQUEST)}
                handleNext={handleNext}
                handlePrevious={() => history.goBack()}
                handleStep={() => onEdit(StepType.ENDORSEMENT_REQUEST)}
              />
            </Grid>

            {template === 'first' && (
              <FirstTemplateEndorsement
                refs={stepsRef}
                handleStep={onEdit}
                checked={isActiveStep}
                handleNext={handleNext}
                handlePrevious={onPrevious}
                handleRegister={handleRegister}
                handleNextExternal={handleNextExternal}
                otherBrokerageDocument={otherBrokerageDocument}
              />
            )}

            {template === 'second' && (
              <SecondTemplateEndorsement
                refs={stepsRef}
                handleStep={onEdit}
                checked={isActiveStep}
                handleNext={handleNext}
                handlePrevious={onPrevious}
                handleRegister={handleRegister}
                handleNextExternal={handleNextExternal}
                otherBrokerageDocument={otherBrokerageDocument}
              />
            )}
          </Grid>
        </>
      )}

      {activeExternalStep === ExternalStep.QUOTE_CONFIRMATION && (
        <>
          <ConfirmationDetails
            handlePrevious={handleBackExternal}
            handleNext={handleNextExternal}
          />
        </>
      )}

      {activeExternalStep === ExternalStep.QUOTE_PAYMENT && (
        <>
          <Payment handlePrevious={handleBackExternal} endorsement={endorsement} />
        </>
      )}
    </Provider>
  )
}

export default Endorsement
