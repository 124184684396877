const useObjects = () => {
  const isEndorsement = (number) => !number.endsWith('00')

  const makePayloadCreateEdit = (endorsement, policy, policyNumber) => (
    {
      endorsement: {
        referencePolicy: {
          policy: {
            ...endorsement?.endorsementRequest?.policy,
            mainPolicyNumber: isEndorsement(policyNumber) ? policy?.numeroPrincipal : policyNumber,
            productCode: policy?.modalidade?.product?.id,
            modalityCode: policy?.modalidade?.codigoModalidade,
            productKey: policy?.modalidade?.productKey,
            endorsementNumber: policy?.numeroDocumentoUltimoEndosso,
          },
          endorsementType: endorsement?.endorsementRequest?.endorsementType,
        },
        currentDocument: {
          deadline: endorsement?.currentStateDocument?.deadline,
          startDateValidity: endorsement?.currentStateDocument?.startDateValidity,
          endDateValidity: endorsement?.currentStateDocument?.endDateValidity,
          contractValue: endorsement?.currentStateDocument?.contractValue,
          guaranteeValue: endorsement?.currentStateDocument?.guaranteeValue,
          objectText: endorsement?.currentStateDocument?.objectText,
          insuredName: endorsement?.currentStateDocument?.name,
          insuredDocument: endorsement?.currentStateDocument?.document,
        },
        newDocument: {
          deadline: endorsement?.newStateDocument?.deadline,
          startDateValidity: endorsement?.newStateDocument?.startDateValidity,
          endDateValidity: endorsement?.newStateDocument?.endDateValidity,
          contractValue: endorsement?.newStateDocument?.contractValue,
          guaranteeValue: endorsement?.newStateDocument?.guaranteeValue,
          objectText: endorsement?.newStateDocument?.objectText,
          civilWork: endorsement?.newStateDocument?.civilWork,
          coverageFine: endorsement?.newStateDocument?.coverageFine,
          coverageLabor: endorsement?.newStateDocument?.coverageLabor,
          insuredName: endorsement?.newStateDocument?.name,
          insuredDocument: endorsement?.newStateDocument?.document,
        },
        requester: {
          ...endorsement?.requester,
        },
        fileUpload: endorsement?.endorsementDocuments?.documents ?? [],
        ClientIsNominated: endorsement?.ClientIsNominated,
        notFoundDocument: endorsement?.notFoundDocument,
        isPolicyUpload: endorsement?.isPolicyUpload,
        finlandiaIsBroker: endorsement?.finlandiaIsBroker,
        RequestByExecutive: endorsement?.RequestByExecutive,
      },
    }
  )

  const makeObjectDetails = (identifier, Policy, EndorsementType, Endorsement, Quote) => (
    {
      identifier,
      Product: {
        Name: Policy?.ProductModality,
      },
      endorsementRequest: {
        endorsementType: EndorsementType,
        policy: {
          policyHolderName: Policy?.PolicyHolderName,
          mainPolicyNumber: Policy?.MainPolicyNumber,
        },
      },
      newStateDocument: {
        civilWork: Endorsement?.NewDocument?.CivilWork,
        objectText: Endorsement?.NewDocument?.ObjectText,
        contractValue: Endorsement?.NewDocument?.ContractValue,
        guaranteeValue: Endorsement?.NewDocument?.GuaranteeValue,
        startDateValidity: Endorsement?.NewDocument?.StartDateValidity,
        endDateValidity: Endorsement?.NewDocument?.EndDateValidity,
        deadline: Endorsement?.NewDocument?.Deadline,
      },
      insured: {
        document: Endorsement?.NewDocument?.InsuredDocument,
        name: Endorsement?.NewDocument?.InsuredName,
      },
      requester: {
        name: Endorsement?.Requester?.Name,
        phone: Endorsement?.Requester?.Phone,
        email: Endorsement?.Requester?.Email,
        note: Endorsement?.Requester?.Note,
        customerControlIdentifier: Endorsement?.Requester?.CustomerControlIdentifier,
      },
      endorsementDocuments: {
        documents: Endorsement?.FileUpload,
      },
      grossPremium: Quote?.GrossPremium ?? 0,
      commercialPremium: Quote?.CommercialPremium ?? 0,
      IOF: Quote?.IOF ?? 0,
    }
  )

  const makeObjectToEdit = (
    identifier,
    lastPolicy,
    policyData,
    EndorsementType,
    Policy,
    Endorsement,
    currentTypeEndorsement,
    endorsementData,
    handlePolicyValidaty,
  ) => (
    {
      isEditing: true,
      quoteCreated: true,
      identifier,
      insured: {
        cep: lastPolicy?.segurado?.cepBeneficiario ?? '',
        state: lastPolicy?.segurado?.ufBeneficiario ?? '',
        name: lastPolicy?.segurado?.nomeBeneficiario ?? '',
        email: lastPolicy?.segurado?.emailBeneficiario ?? '',
        city: lastPolicy?.segurado?.cidadeBeneficiario ?? '',
        number: lastPolicy?.segurado?.numeroBeneficiario ?? '',
        document: lastPolicy?.segurado?.cnpjBeneficiario ?? '',
        district: lastPolicy?.segurado?.bairroBeneficiario ?? '',
        place: lastPolicy?.segurado?.logradouroBeneficiario ?? '',
        complement: lastPolicy?.segurado?.complementoBeneficiario ?? '',
      },
      endorsementRequest: {
        prefix: policyData?.modalidade?.prefixo ?? '',
        policyNumber: Policy?.MainPolicyNumber,
        endorsementType: EndorsementType,
        endorsementTypeId: currentTypeEndorsement.id,
        policy: {
          id: Policy?.Id ?? '',
          mainPolicyId: Policy?.MainPolicyId ?? '',
          endDateValidity: Policy?.EndDateValidity ?? '',
          startDateValidity: Policy?.StartDateValidity ?? '',
          policyHolderName: Policy?.PolicyHolderName ?? '',
          policyHolderDocument: Policy?.PolicyHolderDocument ?? '',
          productModality: Policy?.ProductModality ?? '',
        },
      },
      currentStateDocument: {
        name: Endorsement?.CurrentDocument?.InsuredName ?? '',
        document: Endorsement?.CurrentDocument?.InsuredDocument ?? '',
        deadline: Endorsement?.CurrentDocument?.Deadline ?? 0,
        startDateValidity: Endorsement?.CurrentDocument?.StartDateValidity ?? '',
        endDateValidity: Endorsement?.CurrentDocument?.EndDateValidity ?? '',
        contractValue: Endorsement?.CurrentDocument?.ContractValue ?? 0,
        guaranteeValue: Endorsement?.CurrentDocument?.GuaranteeValue ?? 0,
        objectText: Endorsement?.CurrentDocument?.ObjectText ?? '',
        historyDocuments: lastPolicy?.endossos
          ? handlePolicyValidaty(lastPolicy, policyData)
          : [],
      },
      newStateDocument: {
        name: Endorsement?.NewDocument?.InsuredName ?? '',
        document: Endorsement?.NewDocument?.InsuredDocument ?? '',
        deadline: Endorsement?.NewDocument?.Deadline ?? 0,
        startDateValidity: Endorsement?.NewDocument?.StartDateValidity ?? '',
        endDateValidity: Endorsement?.NewDocument?.EndDateValidity ?? '',
        contractValue: Endorsement?.NewDocument?.ContractValue ?? '',
        guaranteeValue: Endorsement?.NewDocument?.GuaranteeValue ?? '',
        objectText: Endorsement?.NewDocument?.ObjectText ?? '',
        civilWork: Endorsement?.NewDocument?.CivilWork ?? '',
        coverageFine: Endorsement?.NewDocument?.CoverageFine ?? '',
        coverageLabor: Endorsement?.NewDocument?.CoverageLabor ?? '',
        modality: endorsementData?.modality,
      },
      requester: {
        name: Endorsement?.Requester?.Name ?? '',
        phone: Endorsement?.Requester?.Phone ?? '',
        email: Endorsement?.Requester?.Email ?? '',
        note: Endorsement?.Requester?.Note ?? '',
        customerControlIdentifier: Endorsement?.Requester?.CustomerControlIdentifier ?? '',
      },
      endorsementDocuments: {
        documents: Endorsement?.FileUpload ?? [],
      },
    }
  )

  const makeObjectTerm = () => ({
    title: 'Termos e Condições',
    message: `Transparência - A Finlândia Corretora de Seguros busca garantir o mais alto nível de integridade
                corporativa e ética em suas atividades, cumprindo com seus deveres de forma
                honesta e transparente. Baseados em nossos valores, temos o compromisso de
                respeitar e cumprir rigorosamente as legislações e regulamentos aplicáveis ao
                nosso negócio, garantindo a agilidade na prestação de serviços e a segurança
                dos nossos clientes. Por isso, temos diretrizes bem definidas aplicadas ao
                nosso dia a dia, que nos permite realizar cotações nas mais diversas
                companhias e proporcionar um atendimento eficaz por meio dessas parcerias. Vale
                ressaltar que possuímos sócios em comum com a Pottencial Seguradora, o que só
                reforça a nossa eficiência, solidez e estabilidade nos negócios. Nossa política
                de comissionamento, enquanto corretora, será de 15% a 27% sobre o prêmio, para
                as modalidades de seguro garantia e fiança locatícia, de 15% a 30% sobre o
                prêmio para as demais modalidades de seguro. Informamos ainda que, como
                instrumento de incentivo, respeitamos os limites éticos e normativos
                aplicáveis, a corretora possui contrato de incentivo com as seguradoras do
                mercado.`,
  })

  return {
    makePayloadCreateEdit,
    makeObjectDetails,
    makeObjectToEdit,
    makeObjectTerm,
  }
}

export default useObjects
