const ExecutiveData = (props) => {
  const {
    name,
    email,
    phone,
    division,
    mobilePhone,
    fullEmailPhotoUrl,
    standardEmailPhotoUrl,
  } = props

  return {
    name,
    email,
    phone: phone ?? null,
    section: division ?? null,
    cellPhone: mobilePhone ?? null,
    photoFull: fullEmailPhotoUrl ?? null,
    photoStandard: standardEmailPhotoUrl ?? null,
  }
}

export default ExecutiveData
