const EndorsementRequestData = ({
  policy,
  prefix,
  policyNumber,
  endorsementType,
  endorsementDescription,
}) => ({
  policy: policy ?? {},
  prefix: prefix ?? '',
  policyNumber: policyNumber ?? undefined,
  endorsementType: endorsementType ?? '',
  endorsementDescription: endorsementDescription ?? '',
})

export default EndorsementRequestData
