import { useCallback } from 'react'
import {
  QUOTE_BID,
  QUOTE_LEASE_BAIL,
  QUOTE_PERFORMANCE,
  QUOTE_JUDICIAL_LABOR,
  QUOTE_JUDICIAL_CIVEL,
  QUOTE_JUDICIAL_APPEAL,
  QUOTE_GUARANTEE_OTHER,
  QUOTE_ENGINEERING_RISK,
} from '../../constants'

const useQuotationTypesClient = () => {
  const getQuotationsTypes = useCallback((quotation) => [
    {
      attr: 'BID',
      value: QUOTE_BID,
      templateWings: quotation ? {
        ObjectProperty: {
          Deadline: quotation?.objectProperty?.deadline,
          ObjectText: quotation?.objectProperty?.objectText,
          NoticeNumber: quotation?.objectProperty?.noticeNumber,
          ContractValue: quotation?.objectProperty?.contractValue,
          GuaranteeValue: quotation?.objectProperty?.guaranteeValue,
          EndDateValidity: quotation?.objectProperty?.endDateValidity,
          StartDateValidity: quotation?.objectProperty?.startDateValidity,
          ComplementObjectText: quotation?.objectProperty?.complementObjectText,
        },
        Requester: {
          Name: quotation?.requester?.name,
          Note: quotation?.requester?.note,
          Email: quotation?.requester?.email,
          Phone: quotation?.requester?.phone,
          CustomerControlIdentifier: quotation?.requester?.customerControlIdentifier,
        },
        Insured: {
          Name: quotation?.insured?.name,
          BIDType: quotation?.insured?.BIDType,
          Document: quotation?.insured?.document,
          PolicyHolder: quotation?.insured?.policyHolder,
          DocumentNumber: quotation?.insured?.documentNumber,
        },
        FileUpload: {
          Documents: quotation?.fileUpload,
        },
      } : undefined,
    },
    {
      attr: 'Performance',
      value: QUOTE_PERFORMANCE,
      templateWings: quotation ? {
        ObjectProperty: {
          Deadline: quotation?.objectProperty?.deadline,
          ObjectText: quotation?.objectProperty?.objectText,
          NoticeNumber: quotation?.objectProperty?.noticeNumber,
          ProcessNumber: quotation?.objectProperty?.processNumber,
          ContractValue: quotation?.objectProperty?.contractValue,
          ContractNumber: quotation?.objectProperty?.contractNumber,
          AdditiveNumber: quotation?.objectProperty?.additiveNumber,
          GuaranteeAmount: quotation?.objectProperty?.guaranteeAmount,
          EndDateValidity: quotation?.objectProperty?.endDateValidity,
          StartDateValidity: quotation?.objectProperty?.startDateValidity,
          PercentageGuarantee: quotation?.objectProperty?.percentageGuarantee,
          ComplementObjectText: quotation?.objectProperty?.complementObjectText,
        },
        Requester: {
          Name: quotation?.requester?.name,
          Note: quotation?.requester?.note,
          Email: quotation?.requester?.email,
          Phone: quotation?.requester?.phone,
          CustomerControlIdentifier: quotation?.requester?.customerControlIdentifier,
        },
        Rule: {
          Modality: quotation?.rule?.modality,
          CoverageFine: quotation?.rule?.coverageFine,
          CoverageLabor: quotation?.rule?.coverageLabor,
        },
        Insured: {
          Name: quotation?.insured?.name,
          Document: quotation?.insured?.document,
          PolicyHolder: quotation?.insured?.policyHolder,
          DocumentNumber: quotation?.insured?.documentNumber,
        },
        Attachments: {
          Documents: quotation?.attachments,
        },
      } : undefined,
    },
    { value: QUOTE_LEASE_BAIL, attr: 'RentalGuarantee' },
    {
      attr: 'AppealDeposit',
      value: QUOTE_JUDICIAL_APPEAL,
      templateWings: quotation ? {
        DetailsProduct: {
          Modality: quotation?.detailsProduct?.modality,
          AppealType: quotation?.detailsProduct?.appealType,
        },
        PolicyHolderClaimant: {
          Document: quotation?.policyHolderClaimant?.document,
          PolicyHolder: quotation?.policyHolderClaimant?.policyHolder,
        },
        Requester: {
          Name: quotation?.requester?.name,
          Note: quotation?.requester?.note,
          Email: quotation?.requester?.email,
          Phone: quotation?.requester?.phone,
          CustomerControlIdentifier: quotation?.requester?.customerControlIdentifier,
        },
        ValuesDeadlines: {
          Deadline: quotation?.valuesDeadlines?.deadline,
          PercentageHarm: quotation?.valuesDeadlines?.percentageHarm,
          InsuredImportance: quotation?.valuesDeadlines?.insuredImportance,
          AppealDepositValue: quotation?.valuesDeadlines?.appealDepositValue,
        },
        InsuredObjectProperty: {
          CEP: quotation?.insuredObjectProperty?.cep,
          City: quotation?.insuredObjectProperty?.city,
          State: quotation?.insuredObjectProperty?.state,
          Place: quotation?.insuredObjectProperty?.place,
          Number: quotation?.insuredObjectProperty?.number,
          District: quotation?.insuredObjectProperty?.district,
          Document: quotation?.insuredObjectProperty?.document,
          Claimant: quotation?.insuredObjectProperty?.claimant,
          Complement: quotation?.insuredObjectProperty?.complement,
          CourtSection: quotation?.insuredObjectProperty?.courtSection,
          ProcessNumber: quotation?.insuredObjectProperty?.processNumber,
          CourtRegionalLabor: quotation?.insuredObjectProperty?.courtRegionalLabor,
        },
        FileUpload: {
          Documents: quotation?.fileUpload,
        },
      } : undefined,
    },
    {
      attr: 'JudicialLabor',
      value: QUOTE_JUDICIAL_LABOR,
      templateWings: quotation ? {
        PolicyHolder: {
          Document: quotation?.policyHolder?.document,
          Name: quotation?.policyHolder?.name,
        },
        Claimant: {
          Cep: quotation?.claimant?.cep,
          City: quotation?.claimant?.city,
          State: quotation?.claimant?.state,
          Place: quotation?.claimant?.place,
          Number: quotation?.claimant?.number,
          District: quotation?.claimant?.district,
          Document: quotation?.claimant?.document,
          Name: quotation?.claimant?.name,
          Complement: quotation?.claimant?.complement,
        },
        ObjectProperty: {
          Deadline: quotation?.objectProperty?.deadline,
          ObjectText: quotation?.objectProperty?.objectText,
          CourtSection: quotation?.objectProperty?.courtSection,
          ProcessNumber: quotation?.objectProperty?.processNumber,
          ContractValue: quotation?.objectProperty?.contractValue,
          PercentageHarm: quotation?.objectProperty?.percentageHarm,
          ProposedLegalAction: quotation?.objectProperty?.proposedLegalAction,
          EndDateValidity: quotation?.objectProperty?.endDateValidity,
          StartDateValidity: quotation?.objectProperty?.startDateValidity,
          InsuredImportance: quotation?.objectProperty?.insuredImportance,
          CourtRegionalLabor: quotation?.objectProperty?.courtRegionalLabor,
        },
        Requester: {
          Name: quotation?.requester?.name,
          Email: quotation?.requester?.email,
          Phone: quotation?.requester?.phone,
          Note: quotation?.requester?.note,
          CustomerControlIdentifier: quotation?.requester?.customerControlIdentifier,
        },
        FileUpload: {
          Documents: quotation?.fileUpload,
        },
      } : undefined,
    },
    {
      attr: 'JudicialCivel',
      value: QUOTE_JUDICIAL_CIVEL,
      templateWings: quotation ? {
        Insured: {
          Name: quotation?.insured?.name,
          Document: quotation?.insured?.document,
        },
        PolicyHolder: {
          Document: quotation?.policyHolder?.document,
          Name: quotation?.policyHolder?.name,
        },
        Claimant: {
          Document: quotation?.claimant?.document,
          Name: quotation?.claimant?.name,
        },
        ObjectProperty: {
          Deadline: quotation?.objectProperty?.deadline,
          ObjectText: quotation?.objectProperty?.objectText,
          CourtSection: quotation?.objectProperty?.courtSection,
          ProcessNumber: quotation?.objectProperty?.processNumber,
          ProposedLegalAction: quotation?.objectProperty?.proposedLegalAction,
          EndDateValidity: quotation?.objectProperty?.endDateValidity,
          StartDateValidity: quotation?.objectProperty?.startDateValidity,
          GuaranteeValue: quotation?.objectProperty?.guaranteeValue,
          CourtRegionalLabor: quotation?.objectProperty?.courtRegionalLabor,
          ComplementObjectText: quotation?.objectProperty?.complementObjectText,
        },
        Requester: {
          Name: quotation?.requester?.name,
          Email: quotation?.requester?.email,
          Phone: quotation?.requester?.phone,
          Note: quotation?.requester?.note,
          CustomerControlIdentifier: quotation?.requester?.customerControlIdentifier,
        },
        FileUpload: {
          Documents: quotation?.fileUpload,
        },
      } : undefined,
    },
    { value: QUOTE_GUARANTEE_OTHER, attr: 'OtherGuarantee' },
    { value: QUOTE_ENGINEERING_RISK, attr: 'EngineeringRisk' },
  ], [])

  const getAttrQuoteByType = useCallback((quoteType, quotationData) => (
    getQuotationsTypes(quotationData)
      .find((quotationType) => quotationType.value === quoteType)?.attr
  ), [getQuotationsTypes])

  const getQuoteByTypeForWings = useCallback((quotationData) => {
    const quoteForWings = getQuotationsTypes(quotationData?.data).find((quotationType) => (
      quotationType.value === quotationData.QuoteType
    ))

    return {
      attr: quoteForWings?.attr,
      Status: quotationData?.Status,
      QuoteType: quotationData?.QuoteType,
      [quoteForWings?.attr]: quoteForWings?.templateWings,
    }
  }, [getQuotationsTypes])

  return useCallback(() => ({
    getAttrQuoteByType,
    getQuoteByTypeForWings,
  }), [
    getAttrQuoteByType,
    getQuoteByTypeForWings,
  ])
}

export default useQuotationTypesClient
