import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import ViewerDocument from '../../shared/components/ViewerDocument/ViewerDocument'

import useQuotationClient from '../../clients/QuotationClient/useQuotationClient'

const EndorsementReceiptViewer = () => {
  const quotationClient = useQuotationClient()

  const { quotationIdentifier } = useParams()

  const [draftData, setDocumentData] = useState('')

  useEffect(() => {
    quotationClient().getQuoteEndorsementReceiptViewer(quotationIdentifier)
      .then((response) => {
        setDocumentData(response.data)
      })
  }, [quotationIdentifier, quotationClient])

  return (
    <ViewerDocument documentBase64={draftData} />
  )
}

export default EndorsementReceiptViewer
