import { useCallback } from 'react'
import axios from 'axios'

import {
  ENDORSEMENT_TYPE_CANCELLATION,
  ENDORSEMENT_TYPE_APPEAL_WRITE_OFF,
  ENDORSEMENT_TYPE_DEADLINE_EXTENSION,
  ENDORSEMENT_TYPE_INCLUSION_COVERAGE,
  ENDORSEMENT_TYPE_INCREASE_INSURED_AMOUNT,
  ENDORSEMENT_TYPE_REDUCTION_INSURED_AMOUNT,
  ENDORSEMENT_TYPE_DATA_OR_OBJECT_CORRECTION,
  ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT,
  ENDORSEMENT_TYPE_EXTENSION_REDUCTION_INSURED_AMOUNT,
} from '../../constants/EndorsementConstants'

const usePolicyClient = () => {
  const getPolicies = useCallback(async (data) => axios.post('/InsurancePolicy/search', data).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const getPoliciesAndEndorsements = useCallback(async (data) => axios.get('/InsurancePolicy/search-autocomplete', {
    params: { ...data },
  }).then((response) => response.data), [])

  const getPolicyDetails = useCallback(async (policyIdentifier) => axios.get(`/InsurancePolicy/findById?id=${policyIdentifier}&include=endosso&documenttype=Apólice nova&documenttype=Endosso&OrderEndorsement=1`)
    .then((response) => response.data), [])

  const getPolicyDetailsWithoutEndorsement = useCallback(async (policyIdentifier) => axios.get(`/InsurancePolicy/findById?id=${policyIdentifier}&documenttype=Apólice nova&documenttype=Endosso&OrderEndorsement=1`)
    .then((response) => response.data), [])

  const getPolicyEndorsement = useCallback((mainCodeIdentifier) => axios.get('/EndorsementsPolicy/findByMainCode', {
    params: { id: mainCodeIdentifier },
  }).then((response) => response.data), [])

  const getEndorsementProcess = useCallback((policyIdentifier) => axios.get('/verifyEndorsementStatus', {
    params: { IdApolice: policyIdentifier },
  }).then((response) => response.data), [])

  const registerEndorsement = useCallback(async (newEndorsement) => axios.post('/newEndorsement', newEndorsement).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const getEndorsementDetails = useCallback(async (endorsementIdentifier) => axios.get(`/Endorsement?identifier=${endorsementIdentifier}`).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  const getPoliciesSymplifiedByEmail = useCallback((email) => axios.get('/InsuranceHome', {
    params: { email },
  }).then((response) => response.data), [])

  const getDocumentPolicy = useCallback(async (data) => axios.get('InsurancePolicy/Document', {
    params: { ...data },
  }).then((response) => (response?.data ? response : { data: { ...response } })), [])

  const downloadSummaryPolicy = useCallback(async (data) => axios.post('/InsurancePolicy/export/excel', data, {
    responseType: 'arraybuffer',
  }).then((response) => (response?.data ? response : { data: { ...response } })), [])

  const getInsureds = useCallback(() => axios.get('/InsuranceCompany'), [])

  const getBrokers = useCallback(() => axios.get('/Broker'), [])

  const getProducts = useCallback(() => axios.get('/Product'), [])

  const getInvoices = useCallback((policyNumber) => axios.get(`/invoices?policyNumber=${policyNumber}`), [])

  const getInvoiceDocument = useCallback((data) => axios.get('/InsurancePolicy/invoices/document', {
    params: { ...data },
  }).then((response) => (response?.data ? response : { data: { ...response } })), [])

  const getTypesEndorsement = useCallback(() => [
    { id: ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT, text: 'Endosso de prorrogação de prazo com aumento de importância segurada' },
    { id: ENDORSEMENT_TYPE_INCREASE_INSURED_AMOUNT, text: 'Endosso de aumento de importância segurada' },
    { id: ENDORSEMENT_TYPE_DEADLINE_EXTENSION, text: 'Endosso de prorrogação de prazo' },
    { id: ENDORSEMENT_TYPE_CANCELLATION, text: 'Endosso de cancelamento' },
    { id: ENDORSEMENT_TYPE_EXTENSION_REDUCTION_INSURED_AMOUNT, text: 'Endosso de prorrogação de prazo com redução de importância segurada' },
    { id: ENDORSEMENT_TYPE_REDUCTION_INSURED_AMOUNT, text: 'Endosso de redução de importância segurada' },
    { id: ENDORSEMENT_TYPE_DATA_OR_OBJECT_CORRECTION, text: 'Endosso de correção de dados/objeto' },
    { id: ENDORSEMENT_TYPE_APPEAL_WRITE_OFF, text: 'Endosso de baixa recursal' },
    { id: ENDORSEMENT_TYPE_INCLUSION_COVERAGE, text: 'Endosso de inclusão de coberturas' },
  ], [])

  const getAutomaticTypesEndorsementExecutiveRequest = useCallback(() => [
    { id: ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT, text: 'Endosso de prorrogação de prazo com aumento de importância segurada' },
    { id: ENDORSEMENT_TYPE_INCREASE_INSURED_AMOUNT, text: 'Endosso de aumento de importância segurada' },
    { id: ENDORSEMENT_TYPE_DEADLINE_EXTENSION, text: 'Endosso de prorrogação de prazo' },
  ], [])

  const sendDocumentsWings = useCallback(async (data) => axios.post('/document/save', data).then((response) => (
    response?.data ? response : { data: { ...response } })), [])

  return useCallback(() => ({
    getPolicies,
    getBrokers,
    getInsureds,
    getProducts,
    getPolicyDetails,
    getDocumentPolicy,
    getPolicyEndorsement,
    downloadSummaryPolicy,
    getPoliciesSymplifiedByEmail,
    getPolicyDetailsWithoutEndorsement,
    getInvoices,
    getInvoiceDocument,
    getEndorsementProcess,
    getTypesEndorsement,
    getAutomaticTypesEndorsementExecutiveRequest,
    registerEndorsement,
    getEndorsementDetails,
    sendDocumentsWings,
    getPoliciesAndEndorsements,
  }), [
    getPolicies,
    getBrokers,
    getInsureds,
    getProducts,
    getPolicyDetails,
    getDocumentPolicy,
    getPolicyEndorsement,
    downloadSummaryPolicy,
    getPoliciesSymplifiedByEmail,
    getPolicyDetailsWithoutEndorsement,
    getInvoices,
    getInvoiceDocument,
    getEndorsementProcess,
    getTypesEndorsement,
    getAutomaticTypesEndorsementExecutiveRequest,
    registerEndorsement,
    getEndorsementDetails,
    sendDocumentsWings,
    getPoliciesAndEndorsements,
  ])
}

export default usePolicyClient
