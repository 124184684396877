import React from 'react'
import clsx from 'clsx'
import {
  FaYoutube,
  FaInstagram,
  FaLinkedinIn,
} from 'react-icons/fa'
import {
  CgFacebook,
} from 'react-icons/cg'

import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import makeStyles from '@material-ui/core/styles/makeStyles'

import logo from '../../../assets/img/logo.svg'

const useStyles = makeStyles((theme) => ({
  subFooter: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary.main.contrastText,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  contentSocials: {
    fontSize: 25,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '& path': {
      fill: theme.palette.info.dark,
    },
  },
  textFooter: {
    width: 340,
    '@media (max-width: 410px)': {
      width: 410,
    },
  },
  gridColumn: {
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& > .MuiGrid-root': {
      height: 30,
      display: 'flex',
      '@media (max-width: 410px)': {
        justifyContent: 'center',
      },
    },
  },
  info: {
    fontSize: theme.typography.body2,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body2,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.body2,
    },
  },
  labelInfo: {
    fontSize: 16,
    alignSelf: 'center',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  link: {
    paddingBottom: 2,
    position: 'relative',
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
    '&::after': {
      content: '""',
      backgroundColor: theme.palette.primary.contrastText,
      position: 'absolute',
      right: 0,
      bottom: 0,
      left: 0,
      height: 1,
      transformOrigin: '50% 50%',
      transform: 'scaleX(0)',
      transition: 'transform .7s cubic-bezier(.5,0,0,1)',
    },
    '&:hover': {
      '&::after': {
        transform: 'scaleX(1)',
      },
    },
  },
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <Grid maxwidth="xl" container className={classes.subFooter}>
      <Box className={classes.content}>
        <Grid item>
          <Box>
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </Box>
        </Grid>
        <Grid item className={classes.contentSocials}>
          <Link href="https://www.youtube.com/channel/UC-g38qUUt0-SBJlqsc6SkDw">
            <FaYoutube />
          </Link>
          <Link href="https://www.instagram.com/finlandiaseguros/">
            <FaInstagram />
          </Link>
          <Link href="https://pt-br.facebook.com/FinlandiaSeguros/">
            <CgFacebook />
          </Link>
          <Link href="https://www.linkedin.com/company/finl%C3%A2ndia-corretora-de-seguros/">
            <FaLinkedinIn />
          </Link>
        </Grid>
      </Box>
      <Box className={clsx(classes.content, classes.textFooter)}>
        <Grid item>
          <Typography className={classes.info}>
            <Box component="span" mr={1}>
              Somos a maior corretora de seguro garantia do Brasil,
              com mais de uma década e a superação do número de 550 mil
              apólices emitidas ao longo da nossa história.
            </Box>
          </Typography>
        </Grid>
      </Box>
      <Box className={classes.content}>
        <Grid container className={classes.gridColumn}>
          <Grid item>
            <Typography className={classes.labelInfo}>
              <Box component="span" mr={1}>
                Telefone
              </Box>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.info}>
              <Box component="span" mr={1}>
                (31) 3546-1700
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.content}>
        <Grid container className={classes.gridColumn}>
          <Grid item>
            <Typography className={classes.labelInfo}>
              <Box component="span" mr={1}>
                Central de Atendimento
              </Box>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.info}>
              <Box component="span" mr={1}>
                0800 602 7688
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export default Footer
