import React from 'react'

const Register = React.lazy(() => import('../pages/register/Register'))
const Unauthorized = React.lazy(() => import('../pages/error/Unauthorized'))
const ChangePassword = React.lazy(() => import('../pages/recover-password/ChangePassword'))
const RecoverPassword = React.lazy(() => import('../pages/recover-password/RecoverPassword'))
const TermsConditions = React.lazy(() => import('../pages/terms-conditions/TermsConditions'))

const SuspenseRoutes = [
  { path: '/register', name: 'Cadastro', Component: Register },
  { path: '/unauthorized', name: 'Sem Permissão', Component: Unauthorized },
  { path: '/recover-password', name: 'Reload', Component: RecoverPassword },
  {
    path: '/change-password/:token', name: 'Reload', Component: ChangePassword,
  },
  { path: '/silent-register', name: 'Cadastro Via E-mail', Component: Register },
  { path: '/terms-and-conditions', name: 'Termos e Condições', Component: TermsConditions },
]

export default SuspenseRoutes
